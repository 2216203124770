import {
  GET_MEMBER_PENDING,
  GET_MEMBER_SUCCESS,
  GET_MEMBER_ERROR,
  GET_DETAIL_MEMBER_PENDING,
  GET_DETAIL_MEMBER_SUCCESS,
  GET_DETAIL_MEMBER_ERROR,
  POST_MEMBER_PENDING,
  POST_MEMBER_SUCCESS,
  POST_MEMBER_ERROR,
  PUT_MEMBER_PENDING,
  PUT_MEMBER_SUCCESS,
  PUT_MEMBER_ERROR,
  DELETE_MEMBER_PENDING,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_ERROR,
  APPROVED_MEMBER_PENDING,
  APPROVED_MEMBER_SUCCESS,
  APPROVED_MEMBER_ERROR,
  REJECTED_MEMBER_PENDING,
  REJECTED_MEMBER_SUCCESS,
  REJECTED_MEMBER_ERROR,
  EDIT_PHOTO_MEMBER_PENDING,
  EDIT_PHOTO_MEMBER_SUCCESS,
  EDIT_PHOTO_MEMBER_ERROR,
  MEMBER_DATA,
  ADD_MEMBER_DATA,
  SET_MEMBER_MODAL,
} from "../../../actions/anggota/member";

const initialState = {
  pending: false,
  error: null,
  data: null,
  detailData: null,
  isOpenModal: false,
  modalType: "",
  title: "",
  folderName: "",
  fileName: "",
};

const member = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEMBER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_MEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_MEMBER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case GET_DETAIL_MEMBER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_DETAIL_MEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
        detailData: action.data.data.data[0],
      };
    case GET_DETAIL_MEMBER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POST_MEMBER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case POST_MEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case POST_MEMBER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case PUT_MEMBER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case PUT_MEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case PUT_MEMBER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case DELETE_MEMBER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_MEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case DELETE_MEMBER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case APPROVED_MEMBER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case APPROVED_MEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case APPROVED_MEMBER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case REJECTED_MEMBER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case REJECTED_MEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case REJECTED_MEMBER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case EDIT_PHOTO_MEMBER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_PHOTO_MEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case EDIT_PHOTO_MEMBER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case MEMBER_DATA:
      return {
        ...state,
        detailData: action.data,
      };
    case SET_MEMBER_MODAL:
      return {
        ...state,
        isOpenModal: action.data.isOpen,
        modalType: action.data.modalType,
        title: action.data.title,
        folderName: action.data.folderName,
        fileName: action.data.fileName,
      };
    case ADD_MEMBER_DATA:
      return {
        ...state,
        detailData: action.data,
      };
    default:
      return state;
  }
};

export default member;
