import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Container from "../../../../components/container";
import LeftContainer from "../../../../components/container/LeftContainer";
import RightContainer from "../../../../components/container/RightContainer";
import DetailMenu from "../../../../components/global-components/DetailMenu";
import StatusSquare from "../../../../components/global-components/StatusSquare";
import Request, {
  setRequestData,
  setRequestModal,
} from "../../../../store/actions/anggota/permintaan";
import Profile from "./profile";
import { Button } from "@material-ui/core";
import { Row, Column } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import Modal from "../modal";
import DataPerusahaan from "./data-perusahaan";
import AdministrasiPerusahaan from "./administrasi-perusahaan";
import KelengkapanBerkas from "./kelengkapan-berkas";
import { getUser } from "../../../../utils/user";
import CircularProgress from "@material-ui/core/CircularProgress";

let Detail = ({ detailData, onSetRequestModal, pending, onSetRequestData }) => {
  const [selectedMenu, setSelectedMenu] = useState("data-perusahaan");
  const dispatch = useDispatch();
  const user = getUser();
  const path = window.location.pathname.split("/");
  const id = path.pop();

  useEffect(() => {
    onSetRequestData(null);
  }, []);

  useEffect(() => {
    getDetailMember();
  }, [id]);

  const getDetailMember = () => dispatch(Request.getDetail({ id: id }));

  const setModal = (modalType, isOpen, data) => {
    onSetRequestModal(modalType, isOpen);
  };

  return (
    <Container>
      <Modal />
      <Row>
        <LeftContainer>
          {pending ? (
            <CircularProgress color="primary" />
          ) : (
            <>
              <Profile
                name={detailData?.name}
                description={detailData?.member_profile_data?.description}
              />
              <StatusSquare
                statusLabel={
                  detailData?.member_profile_data.status === 0
                    ? "Menunggu verifikasi"
                    : "Data Terverifikasi"
                }
                statusTitle={
                  detailData?.member_profile_data?.status === 0
                    ? "Baru"
                    : detailData?.member_profile_data?.status === 1
                    ? "Terverifikasi"
                    : "Ditolak"
                }
                status={detailData?.member_profile_data?.status}
              />
              {detailData?.member_profile_data.status === 0 &&
                user.role_code === "admin_cabang" && (
                  <Row style={{ width: "100%" }} horizontal="space-between">
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={pending}
                      className={css(styles.approveButton)}
                      onClick={() => setModal("approve", true, null)}
                    >
                      Verifikasi
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={pending}
                      className={css(styles.rejectButton)}
                      onClick={() => setModal("reject", true, null)}
                    >
                      Tolak
                    </Button>
                  </Row>
                )}
              <DetailMenu
                onClick={() => setSelectedMenu("data-perusahaan")}
                menuName="Data Perusahaan"
                isActive={selectedMenu === "data-perusahaan"}
              />
              <DetailMenu
                onClick={() => setSelectedMenu("administrasi-perusahaan")}
                menuName="Administrasi Perusahaan"
                isActive={selectedMenu === "administrasi-perusahaan"}
              />
              {/* <DetailMenu
            onClick={() => setSelectedMenu("kelengkapan-berkas")}
            menuName="Kelengkapan Berkas"
            isActive={selectedMenu === "kelengkapan-berkas"}
          /> */}
            </>
          )}
        </LeftContainer>
        <RightContainer>
          {selectedMenu === "data-perusahaan" ? (
            <DataPerusahaan />
          ) : selectedMenu === "administrasi-perusahaan" ? (
            <AdministrasiPerusahaan />
          ) : (
            // selectedMenu === "kelengkapan-berkas" ? (
            //   <KelengkapanBerkas />
            // ) : (
            <div></div>
          )}
        </RightContainer>
      </Row>
    </Container>
  );
};

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    // marginRight: 10,
    boxShadow: "none",
    width: "45%",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    // marginRight: 10,
    color: "#495057",
    boxShadow: "none",
    width: "45%",
  },
});

const mapStateToProps = ({ request: { data, detailData, pending } }) => {
  return { data, detailData, pending };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSetRequestModal: (modalType, isOpen) =>
      dispatch(setRequestModal(modalType, isOpen)),
    onSetRequestData: (data) => dispatch(setRequestData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
