import React, { useEffect, useState } from "react";
import { string } from "prop-types";
import { Row, Column } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import { getUser } from "../../../utils/user";
import DetailButtonComponent from "../../global-components/DetailButton";
import ArrowLeft from "../../../assets/icon/icon-arrow-left";
import { history } from "../../../utils";
import { setHeaderModal } from "../../../store/actions/header";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import AvatarComponent from "./avatar";
import { useDispatch, connect } from "react-redux";
import Hidden from "@material-ui/core/Hidden";
import Modal from "./modal";
import Menu from "@material-ui/core/Menu";
import Notification from "../../../store/actions/notification";
import moment from "moment";
import { setActiveMenu } from "../../../store/actions";
import { notifAdminAction, notifMemberAction } from "../../../utils/constant";

const HeaderComponent = ({
  title,
  header,
  linkHeader,
  isBackButton,
  onSetHeaderModal,
  notification,
  onSetActiveMenu,
}) => {
  const dispatch = useDispatch();
  const head = header;
  const link = linkHeader;
  const backButton = isBackButton;
  const [anchorEl, setAnchorEl] = useState(null);
  let user = getUser();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (user) {
      getNotification();
    }
  }, []);

  const getNotification = () => dispatch(Notification.get({ length: 5 }));

  let img_profile;

  const setModal = (modalType, isOpen, data) => {
    onSetHeaderModal(modalType, isOpen);
  };

  let dataNotif, totalUnread;
  if (notification.data) {
    dataNotif = notification.data.data.data;
    totalUnread = notification.data.data.totalUnread;
  }
  let titleComponent = head.map((item, i) => {
    if (i === head.length - 1) {
      return null;
    }

    return (
      <Row key={i}>
        <span
          className={css(styles.breadcrumb)}
          onClick={() => {
            history.push(link[i]);
          }}
        >
          {item}
        </span>
        <span className={css(styles.breadcrumbSeparator)}>&gt;</span>
      </Row>
    );
  });

  let titleBig = head[head.length - 1];
  const windowWidth = window.innerWidth;

  const notificationRedirect = (data, selected) => {
    let payload = JSON.parse(data.payload);
    dispatch(Notification.put({ id: data.id }));
    getNotification();

    let route, id;
    if (user.role_code === "member") {
      let notRedirect = ["CHANGE_PASSWORD", "MESSAGE_BLAST"];
      route = selected.route;
      id = payload.id;

      if (!notRedirect.includes(data.type_notif)) {
        if (data.type_notif === "REGISTRATION") {
          history.push(route);
        } else {
          history.push(route + id);
        }
        onSetActiveMenu(selected.activeMenu);
      }
    } else {
      route = selected.route;
      if (data.type_notif === "MEMBER_DUES") {
        id = payload.user_id;
      } else {
        id = payload.id;
      }

      if (data.type_notif !== "MESSAGE_BLAST") {
        history.push(route + id);
        onSetActiveMenu(selected.activeMenu);
      }
    }
  };

  return (
    <Row
      className={css(styles.container)}
      vertical="center"
      horizontal={windowWidth > 959 ? "space-between" : "flex-end"}
    >
      <Modal />
      <Hidden only={["xs", "sm"]}>
        <Column vertical="center" style={{ marginTop: 30 }}>
          <div style={{ display: "flex" }}>{titleComponent}</div>
          <div style={{ display: "flex" }} className={css(styles.title)}>
            {titleBig}
            {backButton && (
              <div
                className={css(styles.goBack)}
                onClick={() => {
                  history.goBack();
                }}
              >
                <ArrowLeft fill="#676d73" /> Kembali
              </div>
            )}
          </div>
        </Column>
      </Hidden>

      <Row vertical="center">
        <Column>
          <span className={css(styles.name, styles.cursorPointer)}>
            {getUser() && getUser().name}
          </span>
          <span className={css(styles.role, styles.cursorPointer)}>
            {getUser() && getUser().role_name}
          </span>
        </Column>
        <div className={css(styles.separator)}></div>
        <Menu
          id="notif-menu"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          // keepMounted
          open={Boolean(anchorEl)}
          onClick={handleClose}
          PaperProps={{
            style: {
              width: 350,
            },
          }}
        >
          <Row
            horizontal="center"
            vertical="center"
            className={css(styles.notifHeader)}
          >
            Notifikasi
          </Row>
          {dataNotif?.map((item, index) => {
            let action =
              user.role_code === "member"
                ? notifMemberAction
                : notifAdminAction;
            const selected = action.filter(
              (value) => value.type === item.type_notif
            );
            return (
              <MenuItem
                onClick={() => {
                  handleClose();
                  notificationRedirect(item, selected[0]);
                }}
                key={index}
                className={css(
                  styles.itemNotifContainer,
                  item.is_read ? styles.bgRead : styles.bgNotRead
                )}
              >
                <Column style={{ width: "100%" }}>
                  <span style={{ fontWeight: "bold", whiteSpace: "normal" }}>
                    {item.title} :{" "}
                    <span
                      dangerouslySetInnerHTML={{ __html: item.body }}
                      style={{ fontWeight: "normal" }}
                    />
                    {/* <span style={{ fontWeight: "normal" }}>{item.body}</span> */}
                  </span>

                  {/* <span style={{  }}>{item.message}</span> */}
                  <span
                    style={{
                      textAlign: "right",
                      fontSize: 10,
                      color: "#979CA0",
                    }}
                  >
                    {moment(item.date).format("DD/MM/YY hh:mm")}
                  </span>
                </Column>
              </MenuItem>
            );
          })}
          {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>My account</MenuItem>
          <MenuItem onClick={handleClose}>Logout</MenuItem> */}
          <Row
            horizontal="center"
            vertical="center"
            className={css(styles.notifFooter)}
            onClick={() => history.push("/notification")}
          >
            <span>Lihat Selengkapnya</span>
          </Row>
        </Menu>
        <DetailButtonComponent
          button={(props) => (
            <Row
              onClick={props.onClick}
              style={{ width: 50, height: 50, cursor: "pointer" }}
            >
              <AvatarComponent image={img_profile} />
            </Row>
          )}
          horizontal="center"
          className={css(styles.cursorPointer)}
        >
          <MenuItem
            className={css(styles.dropdownProfile)}
            // onClick={() => history.push("/notification")}
            onClick={handleClick}
          >
            <span className={css(styles.menuList)}>
              Notifikasi {totalUnread ? `(${totalUnread})` : ""}
            </span>
          </MenuItem>
          <MenuItem
            className={css(styles.dropdownProfile)}
            onClick={() => setModal("change-password", true, null)}
          >
            <span className={css(styles.menuList)}>Ubah Password</span>
          </MenuItem>
          <MenuItem
            className={css(styles.dropdownProfile)}
            onClick={() => {
              history.push("/login");
            }}
          >
            <span style={{ color: "red" }} className={css(styles.menuList)}>
              Logout
            </span>
          </MenuItem>
        </DetailButtonComponent>
      </Row>
    </Row>
  );
};

HeaderComponent.propTypes = {
  title: string,
};

const styles = StyleSheet.create({
  avatar: {
    height: 50,
    width: 50,
    borderRadius: 50,
    border: "1px solid #DFE0EB",
  },
  container: {
    height: 40,
  },
  cursorPointer: {
    cursor: "pointer",
  },
  name: {
    fontFamily: "Muli",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "20px",
    textAlign: "right",
    letterSpacing: 0.2,
    position: "relative",
    color: "#6A6A6A",
  },
  role: {
    fontFamily: "Muli",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "20px",
    textAlign: "right",
    letterSpacing: 0.2,
    color: "#495057",
    opacity: 0.55,
    position: "relative",
    marginTop: 5,
  },
  separator: {
    borderLeft: "1px solid #DFE0EB",
    marginLeft: 20,
    marginRight: 20,
    height: 50,
    width: 2,
  },
  title: {
    fontFamily: "Muli",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 30,
    lineHeight: "30px",
    letterSpacing: 0.3,
    marginTop: 10,
    color: "#495057",
  },
  breadcrumb: {
    fontFamily: "Muli",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 14,
    cursor: "pointer",
    color: "#495057",
    opacity: 0.7,
    "@media (max-width: 768px)": {
      marginLeft: 36,
    },
    "@media (max-width: 468px)": {
      fontSize: 10,
    },
  },
  breadcrumbSeparator: {
    fontFamily: "Muli",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 14,
    color: "#495057",
    opacity: 0.7,
    marginLeft: 20,
    marginRight: 20,
    "@media (max-width: 768px)": {
      marginLeft: 36,
    },
    "@media (max-width: 468px)": {
      fontSize: 10,
    },
  },
  iconStyles: {
    cursor: "pointer",
    marginLeft: 25,
    "@media (max-width: 768px)": {
      marginLeft: 12,
    },
  },
  navLink: {
    textDecoration: "none",
  },
  dropdownProfile: {
    color: "#495057",
    ":hover": {
      textDecoration: "none",
    },
  },
  menuList: {
    marginLeft: 5,
  },
  menuNotificationBadge: {
    position: "absolute",
    right: 15,
  },
  goBack: {
    borderRadius: 30,
    fontSize: 14,
    padding: "0px 20px 0px 10px",
    marginLeft: 20,
    backgroundColor: "#e8eaec",
    ":hover": {
      cursor: "pointer",
    },
  },
  notifHeader: {
    width: 350,
    padding: 15,
    borderBottom: "1px solid #DFE0EB",
    fontSize: 16,
  },
  notifFooter: {
    width: 350,
    padding: 10,
    color: "#00A650",
    ":hover": {
      cursor: "pointer",
    },
  },
  itemNotifContainer: {
    borderBottom: "1px solid #DFE0EB",
    ":hover": {
      backgroundColor: "#e4e5e9",
    },
  },
  bgRead: {
    backgroundColor: "transparent",
  },
  bgNotRead: {
    backgroundColor: "#E9F8F0",
  },
});

const mapStateToProps = ({
  header,
  linkHeader,
  isBackButton,
  notification,
}) => {
  return { header, linkHeader, isBackButton, notification };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetActiveMenu: (menu) => dispatch(setActiveMenu(menu)),
    onSetHeaderModal: (modalType, isOpen) =>
      dispatch(setHeaderModal(modalType, isOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);
