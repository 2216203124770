import React, { useEffect, useState } from "react";
import { formInput, formSelect } from "../../../../../components/commons/form";
import Radio from "../../../../../components/commons/form/radio";
import File from "../../../../../store/actions/file";
import { Field } from "redux-form";
import { Row, Column } from "simple-flexbox";
import CancelIcon from "@material-ui/icons/Cancel";
import { connect, useDispatch } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import ErrorIcon from "@material-ui/icons/Error";
import AddIcon from "@material-ui/icons/Add";
import LabelInputVerticalComponent from "../../../../../components/global-components/LabelInputVertical";

let RenderArray = ({ fields, meta, isDetail, pending, file }) => {
  const dispatch = useDispatch();
  const { submitFailed, error } = meta;

  return (
    <>
      {submitFailed && error && (
        <Row
          style={{
            height: 40,
            color: "#ED2A2A",
            //   border: "1px solid #F9D7D7",
            borderRadius: 3,
            marginBottom: 20,
            marginTop: 20,
            backgroundColor: "#FFDEDE",
            //   opacity: 0.5,
          }}
          horizontal="center"
          vertical="center"
          className="error"
        >
          <span>
            <ErrorIcon style={{ marginRight: 10 }} />
            {error}
          </span>
        </Row>
      )}
      <LabelInputVerticalComponent label="Pilihan Jawaban">
        {fields.map((item, index) => {
          return (
            <Row horizontal="space-between" key={index}>
              <div className="d-flex flex-column w-100 mr-2">
                <div className="mb-2">
                  <Field
                    isDetail={isDetail}
                    name={`${item}.value`}
                    placeholder={`Masukkan Jawaban ${index + 1}`}
                    component={formInput}
                  />
                </div>
              </div>
              {!isDetail && (
                <CancelIcon
                  className={css(styles.closeButton)}
                  onClick={() => fields.remove(index)}
                />
              )}
            </Row>
          );
        })}
        {!isDetail && (
          <Column horizontal="start" vertical="center" className="">
            <Button
              color="primary"
              className={css(styles.approveButton)}
              startIcon={<AddIcon />}
              onClick={() => fields.push()}
              disabled={pending}
            >
              Tambah Pilihan Jawaban
            </Button>
          </Column>
        )}
      </LabelInputVerticalComponent>
    </>
  );
};

const styles = StyleSheet.create({
  closeButton: {
    color: "#CDCFD1",
    marginTop: 7,
    ":hover": {
      color: "#ED2A2A",
    },
  },
  approveButton: {
    zIndex: 0,
    boxShadow: "none",
    // paddingBottom: "10px",
  },
});

const mapStateToProps = ({ file }) => {
  const { pending } = file;

  return { file, pending };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RenderArray);
