import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { reduxForm, Field, FieldArray } from "redux-form";
import Container from "../../../components/container";
import MessageBlast from "../../../store/actions/message-blast";
import { Button } from "@material-ui/core";
import {
  formInput,
  formSelect,
  formCheckbox,
} from "../../../components/commons/form";
import RTE from "../../../components/commons/form/rte";
import LabelInputVerticalComponent from "../../../components/global-components/LabelInputVertical";
import Radio from "../../../components/commons/form/radio";
import { Row, Column } from "simple-flexbox";
import RichTextEditor from "react-rte";
import Region from "../../../store/actions/master/region";
import Member from "../../../store/actions/anggota/member";
import Administrator from "../../../store/actions/anggota/administrator";
import { history } from "../../../utils";
import RenderArray from "./berkasArrayInput";

let Index = ({
  handleSubmit,
  handleRefresh,
  pending,
  region,
  member,
  administrator,
}) => {
  const dispatch = useDispatch();
  const [isGoalName, setIsGoalName] = useState(false);
  const [isAllName, setIsAllName] = useState("");
  const [isGoalRegion, setIsGoalRegion] = useState(false);
  const [isGoalAdmin, setIsGoalAdmin] = useState(false);
  const [isAllAdmin, setIsAllAdmin] = useState("");
  const [arrayFile, setArrayFile] = useState([]);

  const onSubmit = ({
    title,
    message,
    file_upload,
    goalMember,
    goalRegion,
    goalAdmin,
    member_name,
    region_name,
    admin_name,
    isAllAdmin,
    isAllMember,
  }) => {
    const support_file = file_upload?.map((item, index) => {
      return arrayFile[index];
    });
    let member_ids = goalMember ? member_name?.map((item) => item.value) : null;
    let region_ids = goalRegion ? region_name?.map((item) => item.value) : null;
    let administrator_ids = goalAdmin
      ? admin_name?.map((item) => item.value)
      : null;

    const param = {
      title,
      body: message,
      attachments: support_file,
      member_ids: isAllMember === "all" ? null : member_ids,
      is_all_members: goalMember && isAllMember === "all" ? true : false,
      region_ids,
      administrator_ids: isAllAdmin === "all" ? null : administrator_ids,
      is_all_administrators: goalAdmin && isAllAdmin === "all" ? true : false,
    };
    const callback = () => {
      history.push("/message-blast");
    };
    dispatch(MessageBlast.post(param, callback));
  };

  let regionOptions, memberOptions, adminOptions;
  if (region.data) {
    regionOptions = region.data.data.data.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  }

  if (member.data) {
    memberOptions = member.data.data.data.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  }

  if (administrator.data) {
    adminOptions = administrator.data.data.data.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  }

  useEffect(() => {
    getRegion();
    getMember();
    getAdmin();
  }, []);

  const getRegion = () => dispatch(Region.get());
  const getMember = () => dispatch(Member.get());
  const getAdmin = () => dispatch(Administrator.get());

  window.RichTextEditor = RichTextEditor;
  return (
    <Container>
      <Row horizontal="center">
        <form
          className="w-100"
          style={{ padding: 50 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <LabelInputVerticalComponent label="Judul Email">
            <Field
              name="title"
              placeholder="Judul email"
              component={formInput}
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Isi Email">
            <Field
              name="message"
              placeholder="Masukkan isi Email"
              component={RTE}
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Upload Berkas">
            <FieldArray
              name="file_upload"
              placeholder="Masukkan Berkas"
              component={RenderArray}
              callValueFile={(e) => setArrayFile(e)}
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Tujuan">
            <Column>
              <div className="mb-2">
                <Field
                  name="goalMember"
                  label="Anggota"
                  onChange={(e) => {
                    setIsGoalName(e.target.checked);
                  }}
                  component={formCheckbox}
                />
                {/* <label className="ml-2">Nama</label> */}
                {isGoalName && (
                  <>
                    <Field
                      name="isAllMember"
                      component={Radio}
                      options={{
                        all: "Semua Anggota",
                        notAll: "Anggota Tertentu",
                      }}
                      onChange={(e) => setIsAllName(e)}
                    />
                    {isAllName === "notAll" && (
                      <Field
                        placeholder="Nama Anggota"
                        name="member_name"
                        options={memberOptions}
                        isAsync
                        isMulti
                        asyncUrl="/v1/member"
                        component={formSelect}
                      />
                    )}
                  </>
                )}
              </div>
              <div className="mb-2">
                <Field
                  type="checkbox"
                  name="goalRegion"
                  label="Wilayah"
                  onChange={(e) => {
                    setIsGoalRegion(e.target.checked);
                  }}
                  component={formCheckbox}
                />
                {/* <label className="ml-2">Wilayah</label> */}
                {isGoalRegion && (
                  <Field
                    placeholder="Wilayah"
                    name="region_name"
                    options={regionOptions}
                    isAsync
                    isMulti
                    asyncUrl="/v1/region"
                    component={formSelect}
                  />
                )}
              </div>
              <div className="mb-2">
                <Field
                  type="checkbox"
                  name="goalAdmin"
                  label="Pengurus"
                  onChange={(e) => {
                    setIsGoalAdmin(e.target.checked);
                  }}
                  component={formCheckbox}
                />
                {/* <label className="ml-2">Wilayah</label> */}
                {isGoalAdmin && (
                  <>
                    <Field
                      name="isAllAdmin"
                      component={Radio}
                      options={{
                        all: "Semua Pengurus",
                        notAll: "Pengurus Tertentu",
                      }}
                      onChange={(e) => setIsAllAdmin(e)}
                    />
                    {isAllAdmin === "notAll" && (
                      <Field
                        placeholder="Pengurus"
                        name="admin_name"
                        options={adminOptions}
                        isAsync
                        isMulti
                        asyncUrl="/v1/administrator"
                        component={formSelect}
                      />
                    )}
                  </>
                )}
              </div>
            </Column>
          </LabelInputVerticalComponent>
          <div className="d-flex justify-content-between">
            <Button
              variant="outlined"
              className="mt-3"
              disabled={pending}
              color="primary"
              onClick={() => history.goBack()}
            >
              Batal
            </Button>
            <Button
              type="submit"
              className="mt-3"
              disabled={pending}
              variant="contained"
              color="primary"
            >
              Simpan
            </Button>
          </div>
        </form>
      </Row>
    </Container>
  );
};

const validate = ({
  title,
  message,
  goalMember,
  goalRegion,
  goalAdmin,
  member_name,
  region_name,
  admin_name,
  isAllAdmin,
  isAllMember,
}) => {
  const errors = {};
  if (!title) {
    errors.title = "Judul Email harus diisi";
  }
  if (!message) {
    errors.message = "Pesan harus diisi";
  }
  if (!goalMember && !goalRegion && !goalAdmin) {
    errors.goalAdmin = "Tujuan harus diisi";
  }
  if (goalMember) {
    if ((isAllMember === "notAll") & !member_name) {
      errors.member_name = "Tujuan Anggota harus diisi";
    }
  }
  if (goalRegion) {
    if (!region_name) {
      errors.region_name = "Tujuan Wilayah harus diisi";
    }
  }
  if (goalAdmin) {
    if (isAllAdmin === "notAll" && !admin_name) {
      errors.admin_name = "Tujuan Pengurus harus diisi";
    }
  }

  return errors;
};

Index = reduxForm({
  form: "messageBlastAdd",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(Index);

const mapStateToProps = ({
  messageBlast: { pending },
  member,
  region,
  administrator,
}) => {
  let initialValues = {
    isAllAdmin: "all",
    isAllMember: "all",
  };

  return { pending, member, region, administrator, initialValues };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
