import API from "../../API";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
import { setToastModal } from "../../../actions";
import { toastError, toastSuccess } from "../../../../components/commons/toast";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_POLLING_MEMBER_PENDING = "GET_POLLING_MEMBER_PENDING";
export const GET_POLLING_MEMBER_SUCCESS = "GET_POLLING_MEMBER_SUCCESS";
export const GET_POLLING_MEMBER_ERROR = "GET_POLLING_MEMBER_ERROR";
export const GET_DETAIL_POLLING_MEMBER_PENDING =
  "GET_DETAIL_POLLING_MEMBER_PENDING";
export const GET_DETAIL_POLLING_MEMBER_SUCCESS =
  "GET_DETAIL_POLLING_MEMBER_SUCCESS";
export const GET_DETAIL_POLLING_MEMBER_ERROR =
  "GET_DETAIL_POLLING_MEMBER_ERROR";
export const POST_POLLING_MEMBER_PENDING = "POST_POLLING_MEMBER_PENDING";
export const POST_POLLING_MEMBER_SUCCESS = "POST_POLLING_MEMBER_SUCCESS";
export const POST_POLLING_MEMBER_ERROR = "POST_POLLING_MEMBER_ERROR";
export const PUT_POLLING_MEMBER_PENDING = "PUT_POLLING_MEMBER_PENDING";
export const PUT_POLLING_MEMBER_SUCCESS = "PUT_POLLING_MEMBER_SUCCESS";
export const PUT_POLLING_MEMBER_ERROR = "PUT_POLLING_MEMBER_ERROR";
export const DELETE_POLLING_MEMBER_PENDING = "DELETE_POLLING_MEMBER_PENDING";
export const DELETE_POLLING_MEMBER_SUCCESS = "DELETE_POLLING_MEMBER_SUCCESS";
export const DELETE_POLLING_MEMBER_ERROR = "DELETE_POLLING_MEMBER_ERROR";

export const POLLING_MEMBER_DATA = "POLLING_MEMBER_DATA";
export const POLLING_MEMBER_DATA_QUESTION = "POLLING_MEMBER_DATA_QUESTION";
export const SET_POLLING_MEMBER_MODAL = "SET_POLLING_MEMBER_MODAL";
export const SET_POLLING_MEMBER_STEP = "SET_POLLING_MEMBER_STEP";

// URL: URL_{URL}
const POLLING_MEMBER_URL = "/v1/polling";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_POLLING_MEMBER_PENDING));
  API.get(POLLING_MEMBER_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_POLLING_MEMBER_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_POLLING_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const getDetail = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DETAIL_POLLING_MEMBER_PENDING));
  API.get(POLLING_MEMBER_URL + "/answer/find_detail_by_member", {
    params: param,
  })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DETAIL_POLLING_MEMBER_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DETAIL_POLLING_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_POLLING_MEMBER_PENDING));
  API.post(POLLING_MEMBER_URL + "/answer", param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_POLLING_MEMBER_SUCCESS, res));
      toastSuccess("Vote berhasil dilakukan");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_POLLING_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_POLLING_MEMBER_PENDING));
  API.put(POLLING_MEMBER_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_POLLING_MEMBER_SUCCESS, res));
      toastSuccess("Polling berhasil diubah");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_POLLING_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_POLLING_MEMBER_PENDING));
  API.delete(POLLING_MEMBER_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_POLLING_MEMBER_SUCCESS, res));
      toastSuccess("Polling berhasil dihapus");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_POLLING_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const PollingMember = { get, post, put, deleted, getDetail };
export default PollingMember;

export const setPollingMemberData = (data) => (dispatch) =>
  dispatch({ type: POLLING_MEMBER_DATA, data });

export const setPollingMemberModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_POLLING_MEMBER_MODAL, {
      modalType: modalType,
      isOpen: isOpen,
    })
  );

export const setPollingMemberDataQuestion = (data) => (dispatch) =>
  dispatch({ type: POLLING_MEMBER_DATA_QUESTION, data });

export const setPollingMemberStep = (step) => (dispatch) => {
  dispatch({ type: SET_POLLING_MEMBER_STEP, step });
};
