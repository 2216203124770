import React, { useState, useEffect } from "react";
import { Column, Row } from "simple-flexbox";
import { connect, useDispatch } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import { reduxForm, Field } from "redux-form";
import { formInput } from "../../components/commons/form";
import IconInput from "../../assets/icon/icon-input";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { history } from "../../utils";
import { regexPassword } from "../../utils/constant";
import LoginImage from "../../assets/img/login_page.png";

// ACTION REDUX
import Auth from "../../store/actions/auth";

let Verifikasi = ({ handleSubmit, pendingVerifikasi }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    Auth.logout();
  }, []);

  const onSubmit = ({ new_password, confirm_new_password }) => {
    const url = window.location.href.split("/");
    const verify_token = url.pop();
    dispatch(
      Auth.verifikasi({ verify_token, new_password, confirm_new_password })
    );
  };

  return (
    <Grid container>
      <Grid
        item
        xs={1}
        sm={1}
        md={4}
        lg={4}
        className={css(styles.sideContainer)}
      >
        <img
          className={css(styles.loginImg)}
          src={LoginImage}
          alt="login-img"
        />
      </Grid>
      <Grid
        item
        xs={10}
        sm={10}
        md={7}
        lg={7}
        className={css(styles.loginContainer)}
      >
        <Column>
          <span className={css(styles.loginTitle)}>Reset Password</span>
          <span className={css(styles.loginDesc)}>
            Masukkan password baru Anda
          </span>
          <span className={css(styles.passDesc)}>
            (Password terdiri dari 6 karakter berisi angka dan huruf)
          </span>
          <hr className={css(styles.loginSeparator)} />
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={css(styles.loginForm)}
          >
            <Field
              name="new_password"
              placeholder="Password Baru"
              type={!isVisible ? "password" : "text"}
              isVisible={isVisible}
              setIsVisible={() => setIsVisible(!isVisible)}
              isTypePassword
              component={formInput}
              className={css(styles.inputMargin)}
            />
            <Field
              name="confirm_new_password"
              placeholder="Konfirmasi Password"
              type={!isVisible1 ? "password" : "text"}
              isVisible={isVisible1}
              setIsVisible={() => setIsVisible1(!isVisible1)}
              isTypePassword
              component={formInput}
              className={css(styles.inputMargin)}
            />
            <Row horizontal="space-between">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={pendingVerifikasi}
                className={css(styles.approveButton)}
                startIcon={
                  pendingVerifikasi ? (
                    <CircularProgress
                      size={14}
                      color="secondary"
                      style={{ marginRight: 10 }}
                    />
                  ) : (
                    <IconInput />
                  )
                }
              >
                Submit
              </Button>
              <Column>
                <span className={css(styles.daftarLabel)}>
                  Sudah punya akun?
                </span>
                <span
                  onClick={() => history.push("/login")}
                  className={css(styles.inputLinkDaftar)}
                >
                  Login
                </span>
              </Column>
            </Row>
          </form>
        </Column>
      </Grid>
    </Grid>
  );
};

const styles = StyleSheet.create({
  sideContainer: {
    height: "100vh",
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
  loginImg: {
    objectFit: "cover",
    width: "100%",
    display: "block",
    height: "100vh",
  },
  loginContainer: {
    // width: "auto",
    margin: "auto",
  },
  loginTitle: {
    color: "#04AA59",
    fontSize: 36,
    fontWeight: "bold",
    margin: 15,
  },
  loginDesc: {
    fontSize: 16,
    margin: "0 15px",
  },
  passDesc: {
    fontSize: 12,
    margin: "10px 15px 0",
  },
  loginSeparator: {
    maxWidth: 400,
    margin: "20px 15px",
    borderTop: "2px solid rgba(0,0,0,0.1)",
    "@media (max-width: 480px)": {
      width: "100%",
    },
  },
  loginForm: {
    width: 400,
    marginLeft: 15,
    "@media (max-width: 480px)": {
      width: "100%",
    },
  },
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
    margin: "15px 0",
  },
  inputMargin: {
    margin: "15px 0",
  },
  inputLink: {
    fontSize: 16,
    color: "black",
    textDecoration: "none",
    marginLeft: 15,
    marginTop: 30,
    ":hover": {
      cursor: "pointer",
    },
  },
  inputLinkDaftar: {
    fontSize: 16,
    color: "black",
    textDecoration: "none",
    marginTop: 5,
    textAlign: "right",
    ":hover": {
      cursor: "pointer",
    },
  },
  daftarLabel: {
    marginTop: 15,
    textAlign: "right",
  },
});

const validate = ({ new_password, confirm_new_password }) => {
  const errors = {};
  if (!new_password) {
    errors.new_password = "Password Baru harus diisi";
  } else if (!regexPassword.test(new_password)) {
    errors.new_password = "Password Baru tidak valid";
  }
  if (!confirm_new_password) {
    errors.confirm_new_password = "Konfirmasi Password harus diisi";
  } else if (new_password !== confirm_new_password) {
    errors.confirm_new_password = "Konfirmasi Password tidak sama";
  }
  return errors;
};

Verifikasi = reduxForm({
  form: "Verifikasi",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(Verifikasi);

const mapStateToProps = ({ auth: { pendingVerifikasi } }) => {
  return { pendingVerifikasi };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Verifikasi);
