import API from "../../API";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
import { setToastModal } from "../../../actions";
import { toastError, toastSuccess } from "../../../../components/commons/toast";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_ALAT_KESEHATAN_PENDING = "GET_ALAT_KESEHATAN_PENDING";
export const GET_ALAT_KESEHATAN_SUCCESS = "GET_ALAT_KESEHATAN_SUCCESS";
export const GET_ALAT_KESEHATAN_ERROR = "GET_ALAT_KESEHATAN_ERROR";
export const POST_ALAT_KESEHATAN_PENDING = "POST_ALAT_KESEHATAN_PENDING";
export const POST_ALAT_KESEHATAN_SUCCESS = "POST_ALAT_KESEHATAN_SUCCESS";
export const POST_ALAT_KESEHATAN_ERROR = "POST_ALAT_KESEHATAN_ERROR";
export const PUT_ALAT_KESEHATAN_PENDING = "PUT_ALAT_KESEHATAN_PENDING";
export const PUT_ALAT_KESEHATAN_SUCCESS = "PUT_ALAT_KESEHATAN_SUCCESS";
export const PUT_ALAT_KESEHATAN_ERROR = "PUT_ALAT_KESEHATAN_ERROR";
export const DELETE_ALAT_KESEHATAN_PENDING = "DELETE_ALAT_KESEHATAN_PENDING";
export const DELETE_ALAT_KESEHATAN_SUCCESS = "DELETE_ALAT_KESEHATAN_SUCCESS";
export const DELETE_ALAT_KESEHATAN_ERROR = "DELETE_ALAT_KESEHATAN_ERROR";

export const ALAT_KESEHATAN_DATA = "ALAT_KESEHATAN_DATA";
export const SET_ALAT_KESEHATAN_MODAL = "SET_ALAT_KESEHATAN_MODAL";

// URL: URL_{URL}
const ALAT_KESEHATAN_URL = "/v1/medical_device";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_ALAT_KESEHATAN_PENDING));
  API.get(ALAT_KESEHATAN_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_ALAT_KESEHATAN_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_ALAT_KESEHATAN_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_ALAT_KESEHATAN_PENDING));
  API.post(ALAT_KESEHATAN_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_ALAT_KESEHATAN_SUCCESS, res));
      toastSuccess("Alat Kesehatan berhasil ditambahkan");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_ALAT_KESEHATAN_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_ALAT_KESEHATAN_PENDING));
  API.put(ALAT_KESEHATAN_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_ALAT_KESEHATAN_SUCCESS, res));
      toastSuccess("Alat Kesehatan berhasil diubah");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_ALAT_KESEHATAN_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_ALAT_KESEHATAN_PENDING));
  API.delete(ALAT_KESEHATAN_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_ALAT_KESEHATAN_SUCCESS, res));
      toastSuccess("Alat Kesehatan berhasil dihapus");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_ALAT_KESEHATAN_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const Kesehatan = { get, post, put, deleted };
export default Kesehatan;

export const setKesehatanData = (data) => (dispatch) =>
  dispatch({ type: ALAT_KESEHATAN_DATA, data });

export const setKesehatanModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_ALAT_KESEHATAN_MODAL, {
      modalType: modalType,
      isOpen: isOpen,
    })
  );
