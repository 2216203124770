import React, { useState } from "react";
import { connect } from "react-redux";
import { ModalBody, ModalHeader } from "reactstrap";
import { Column, Row } from "simple-flexbox";
import IconDownload from "../../../../assets/icon/icon-download";
import IconRefresh from "../../../../assets/icon/icon-refresh";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

let Index = ({ folderName, fileName, title }) => {
  const [docName, setDocName] = useState(fileName);
  const [isLoad, setIsLoad] = useState(false);

  const handleLoaded = () => {
    setIsLoad(true);
  };

  const handleDownload = () => {
    window.open(
      `${process.env.REACT_APP_GAKESLAB_URL}/v1/file/${folderName}/${fileName}`
    );
  };

  let extention = fileName.split(".");
  return (
    <>
      <ModalHeader>{title ? title : "Berkas"}</ModalHeader>
      <ModalBody>
        {/* {!isLoad && (
          <div className="d-flex justify-content-center">
            <CircularProgress
              color="primary"
              // style={{ marginRight: 10 }}
            />
          </div>
        )} */}
        {extention[1] === "pdf" ? (
          <>
            <Column className={css(styles.bodyModal)}>
              <iframe
                src={`https://docs.google.com/gview?url=${process.env.REACT_APP_GAKESLAB_URL}/v1/file/${folderName}/${fileName}&embedded=true`}
                width="100%"
                height="500px"
                title="pdf"
                onLoad={handleLoaded}
              ></iframe>
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                variant="contained"
                color="primary"
                className={css(styles.rejectButton)}
                startIcon={<IconRefresh />}
                onClick={() => setDocName(fileName)}
              >
                Refresh
              </Button>
              <Button
                onClick={handleDownload}
                variant="contained"
                color="primary"
                className={css(styles.approveButton)}
                startIcon={<IconDownload />}
              >
                Download
              </Button>
            </Row>
          </>
        ) : (
          <div className="d-flex justify-content-center">
            <img
              src={`${process.env.REACT_APP_GAKESLAB_URL}/v1/file/${folderName}/${fileName}`}
              alt="foto"
              onLoad={handleLoaded}
              className={css(styles.imgDocument)}
            ></img>
          </div>
        )}
      </ModalBody>
    </>
  );
};

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    marginTop: 20,
    backgroundColor: "#FBFBFD",
    height: 80,
    paddingLeft: 40,
    paddingRight: 40,
  },
  bodyModal: {
    padding: "0px 40px",
  },
  labelMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
  imgDocument: {
    maxHeight: 400,
    maxWidth: 400,
    objectFit: "contain",
    marginRight: "auto",
    marginLeft: "auto",
  },
});

const mapStateToProps = ({}) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
