import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { ModalBody, ModalHeader } from "reactstrap";
import { Button } from "@material-ui/core";
import { formInput, formSelect } from "../../../../components/commons/form";
import LabelInputVerticalComponent from "../../../../components/global-components/LabelInputVertical";
import Region from "../../../../store/actions/master/region";
import User, { setUserModal } from "../../../../store/actions/system/pengguna";
import { roleUserOptions, regexEmail } from "../../../../utils/constant";

let Add = ({
  onSetUserModal,
  handleSubmit,
  handleRefresh,
  pending,
  region,
}) => {
  const dispatch = useDispatch();

  const onSubmit = ({ name, email, region, role }) => {
    const param = {
      name,
      email,
      region_id: region.value,
      role_code: role.value,
    };
    const callback = () => {
      onSetUserModal("", false);
      handleRefresh();
    };
    dispatch(User.post(param, callback));
  };

  let regionOptions;
  if (region.data) {
    regionOptions = region.data.data.data.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  }

  useEffect(() => {
    getRegion();
  }, []);

  const getRegion = () => dispatch(Region.get());

  return (
    <>
      <ModalHeader>Tambah Pengguna</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <LabelInputVerticalComponent label="Nama Pengguna">
            <Field
              name="name"
              placeholder="Nama Pengguna"
              component={formInput}
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Email">
            <Field name="email" placeholder="Email" component={formInput} />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Wilayah">
            <Field
              name="region"
              placeholder="Wilayah"
              component={formSelect}
              options={regionOptions}
              isAsync
              asyncUrl="/v1/region"
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Role">
            <Field
              name="role"
              placeholder="Role"
              component={formSelect}
              options={roleUserOptions}
            />
          </LabelInputVerticalComponent>
          <div className="d-flex justify-content-between">
            <Button
              variant="outlined"
              className="mt-3"
              disabled={pending}
              color="primary"
              onClick={() => onSetUserModal("", false)}
            >
              Batal
            </Button>
            <Button
              type="submit"
              className="mt-3"
              disabled={pending}
              variant="contained"
              color="primary"
            >
              Simpan
            </Button>
          </div>
        </form>
      </ModalBody>
    </>
  );
};

const validate = ({ name, email, region, role }) => {
  const errors = {};
  if (!name) {
    errors.name = "Nama wilayah harus diisi";
  }
  if (!email) {
    errors.email = "Email harus diisi";
  } else if (!regexEmail.test(email)) {
    errors.email = "Email tidak valid";
  }
  if (!region) {
    errors.region = "Wilayah harus diisi";
  }
  if (!role) {
    errors.role = "Role harus diisi";
  }
  return errors;
};

Add = reduxForm({
  form: "userAdd",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(Add);

const mapStateToProps = ({ user: { pending }, region }) => {
  return { pending, region };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetUserModal: (modalType, isOpen) =>
      dispatch(setUserModal(modalType, isOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Add);
