import React from "react";

export default (props) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={props.opacity || ""}
      d="M6.5625 7.375C5.85938 7.375 5.24414 7.22852 4.6582 6.87695C4.07227 6.55469 3.63281 6.11523 3.31055 5.5293C2.95898 4.94336 2.8125 4.32812 2.8125 3.625C2.8125 2.95117 2.95898 2.33594 3.31055 1.75C3.63281 1.16406 4.07227 0.724609 4.6582 0.373047C5.24414 0.0507812 5.85938 -0.125 6.5625 -0.125C7.23633 -0.125 7.85156 0.0507812 8.4375 0.373047C9.02344 0.724609 9.46289 1.16406 9.81445 1.75C10.1367 2.33594 10.3125 2.95117 10.3125 3.625C10.3125 4.32812 10.1367 4.94336 9.81445 5.5293C9.46289 6.11523 9.02344 6.55469 8.4375 6.87695C7.85156 7.22852 7.23633 7.375 6.5625 7.375ZM9.375 8.3418C10.0488 8.37109 10.6641 8.57617 11.25 8.92773C11.8359 9.2793 12.2754 9.74805 12.627 10.334C12.9492 10.9199 13.125 11.5645 13.125 12.2383V13.4688C13.125 13.8789 12.9785 14.2012 12.7148 14.4648C12.4219 14.7578 12.0996 14.875 11.7188 14.875H1.40625C0.996094 14.875 0.673828 14.7578 0.410156 14.4648C0.117188 14.2012 0 13.8789 0 13.4688V12.2383C0 11.5645 0.146484 10.9199 0.498047 10.334C0.820312 9.74805 1.25977 9.2793 1.8457 8.92773C2.43164 8.57617 3.04688 8.37109 3.75 8.3418L5.15625 13.9375L6.09375 9.95312L5.15625 8.3125H7.96875L7.03125 9.95312L7.96875 13.9375L9.375 8.3418Z"
      fill={props.fill || "#FFFFFF"}
      transform="translate(2,4)"
    />
  </svg>
);
