import React, { useState, useEffect } from "react";
import Bar from "./bar";
import Pagination from "@material-ui/lab/Pagination";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import PollingAdmin from "../../../../store/actions/polling/admin";
import CircularProgress from "@material-ui/core/CircularProgress";

const Index = ({ pending, detailData, total }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const path = window.location.pathname.split("/");
  const type = path.pop();
  const question_id = path.pop();
  const polling_id = path.pop();
  const pageSize = 5;
  const length =
    detailData?.length > 0 ? Math.ceil(detailData?.length / pageSize) : 1;

  const handleChange = (event, value) => {
    setPage(value);
  };

  let higher = detailData?.reduce(function (prev, current) {
    return prev.vote > current.vote ? prev : current;
  });

  let arrHigher = detailData?.filter((item) => item.vote === higher.vote);
  arrHigher?.map((item) => {
    let indHigher = detailData.findIndex((value) => value.title === item.title);
    detailData[indHigher].higher = true;
  });
  useEffect(() => {
    getSummaryQuestion();
  }, []);

  const getSummaryQuestion = () =>
    dispatch(
      PollingAdmin.getSummaryAnswer({
        type,
        question_id,
        polling_id,
        page,
        length: pageSize,
      })
    );
  return (
    <>
      <div className="row" style={{ minHeight: 514 }}>
        <div className="col">
          {pending ? (
            <CircularProgress />
          ) : detailData?.length > 0 ? (
            <>
              <div style={{ minHeight: 465 }}>
                {detailData
                  // .slice(pageSize * (page - 1), pageSize * page)
                  .map((item, index) => {
                    return (
                      <Bar
                        key={index}
                        title={
                          type === "2" || type === "3"
                            ? item.answer_label
                            : item.answer
                        }
                        vote={item.total}
                        total={total}
                        higher={item.higher}
                      />
                    );
                  })}
              </div>
              <div className="d-flex justify-content-end border-top pt-3">
                <Pagination
                  shape="rounded"
                  color="primary"
                  count={length}
                  page={page}
                  onChange={handleChange}
                />
              </div>
            </>
          ) : (
            <div>Tidak ada data</div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ pollingAdmin: { pending, data } }) => {
  const detailData = data?.data?.data?.length > 0 ? data.data.data : null;
  const total = data?.data?.data?.length > 0 ? data.data.totalVoter : null;

  return { detailData, pending, total };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
