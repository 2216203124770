import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { reduxForm, Field, formValueSelector } from "redux-form";
import FormContainer from "../../components/container/Form";
import {
  formInput,
  FormDatepicker,
  normalizeDates,
  formatDates,
  formSelect,
  formInputNumber,
  formCheckbox,
  formFile,
} from "../../components/commons/form";
import Member, {
  setMemberData,
  setMemberModal,
} from "../../store/actions/anggota/member";
import Region from "../../store/actions/master/region";
import BranchMember from "../../store/actions/anggota/branch-member";
import File from "../../store/actions/file";
import { Row, Column } from "simple-flexbox";
import { Button } from "@material-ui/core";
import {
  regexEmail,
  kelembagaanOptions,
  MEMBER_PHOTO,
  LOGO_INSTITUTE,
} from "../../utils/constant";
import { getUser } from "../../utils/user";
import { StyleSheet, css } from "aphrodite";

let Index = ({
  onSetMemberData,
  change,
  handleSubmit,
  detailData,
  region,
  pending,
  reset,
  branchMember,
  initialValues,
  isMemberActive,
}) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [hasBranch, setHasBranch] = useState(initialValues.has_relation_member);
  const [imgName, setImgName] = useState(initialValues.img_name);
  const [logoName, setLogoName] = useState(initialValues.logo_filename);
  const notEdit = () => {
    setIsEdit(!isEdit);
    reset();
  };
  const unduhKTA = () => {
    window.open(
      process.env.REACT_APP_GAKESLAB_URL +
        "/v1/member/export/kta?user_id=" +
        user.id
    );
  };
  const user = getUser();

  useEffect(() => {
    setHasBranch(initialValues.has_relation_member);
    setImgName(initialValues.img_name);
    setLogoName(initialValues.logo_filename);
  }, [initialValues]);

  const onSubmit = (value) => {
    const member_profile_data = detailData.member_profile_data;
    member_profile_data.supporting_file_data =
      detailData.member_profile_data.supporting_file_datas;
    const param = {
      ...member_profile_data,
      id: user.id,
      address: value.address,
      company_technicians_education: value.company_technicians_education,
      company_technicians_name: value.company_technicians_name,
      contact_person_name_1: value.contact_person_name_1,
      contact_person_email_1: value.contact_person_email_1,
      contact_person_phone_number_1: value.contact_person_phone_number_1,
      contact_person_name_2: value.contact_person_name_2,
      contact_person_email_2: value.contact_person_email_2,
      contact_person_phone_number_2: value.contact_person_phone_number_2,
      email: value.email,
      faximile: value.faximile,
      institutional: value.institutional.value,
      img_name: imgName,
      logo_filename: logoName,
      leader_name: value.leader_name,
      name: value.name,
      owned_facility_factory: value.owned_facility_factory,
      owned_facility_other: value.owned_facility_other,
      owned_facility_workshop: value.owned_facility_workshop,
      phone_number: value.phone_number,
      position: value.position,
      relation_member_id: value.relation_member_name?.value,
      region_id: value.region_name.value,
    };

    const callback = () => {
      notEdit();
      dispatch(Member.getDetail({ id: user.id }));
    };

    dispatch(Member.put(param, callback));
  };

  const uploadFile = (e, name) => {
    let formData = new FormData();
    formData.append("file", e);

    const callback = (res) => {
      if (name === "img") {
        setImgName(res.data.data.fileName);
      } else {
        setLogoName(res.data.data.fileName);
      }
    };

    if (name === "img") {
      dispatch(File.upload(formData, callback, MEMBER_PHOTO));
    } else {
      dispatch(File.upload(formData, callback, LOGO_INSTITUTE));
    }
  };

  let regionOptions;
  if (region.data) {
    regionOptions = region.data.data.data.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
    regionOptions.push({ label: "Lainnya", value: "" });
  }

  let memberOptions;
  if (branchMember.data) {
    memberOptions = branchMember.data.data.data.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  }

  useEffect(() => {
    getRegion();
    getMember();
  }, []);

  const getRegion = () => dispatch(Region.get());
  const getMember = () => dispatch(BranchMember.get());

  let role = user.role_code;

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      <Row
        vertical="center"
        horizontal="space-between"
        style={{ width: "100%", marginBottom: 20 }}
      >
        <div>
          <h5>Data Perusahaan</h5>
        </div>
        <div className="d-flex justify-content-between">
          {isMemberActive &&
            (isEdit ? (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={pending}
                  style={{ marginRight: 20 }}
                  onClick={notEdit}
                >
                  Batal
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={pending}
                  type="submit"
                >
                  Submit
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={pending}
                  onClick={unduhKTA}
                  style={{ marginRight: 20 }}
                  hidden={true}
                >
                  Unduh KTA
                </Button>
                
                <Button
                  variant="contained"
                  color="primary"
                  disabled={pending}
                  onClick={notEdit}
                >
                  Edit
                </Button>
              </>
            ))}
        </div>
      </Row>
      {!isEdit && (
        <FormContainer label="Tanggal Approval">
          <Field
            name="approved_at"
            placeholder="Tanggal"
            isDetail={!isEdit}
            parse={normalizeDates}
            format={formatDates}
            component={FormDatepicker}
          />
        </FormContainer>
      )}
      {isEdit && (
        <FormContainer label="No KTA">
          <Field
            name="kta_no"
            placeholder="No KTA"
            isDetail={!isEdit}
            disabled
            component={formInput}
          />
        </FormContainer>
      )}
      <FormContainer label="Masa Aktif KTA">
        <Field
          name="end_active_kta"
          placeholder="Masa Aktif KTA"
          isDetail={!isEdit}
          disabled
          component={formInput}
        />
      </FormContainer>
      <FormContainer label="Wilayah">
        <Field
          isDetail={!isEdit}
          placeholder="Wilayah"
          name="region_name"
          options={regionOptions}
          isAsync
          asyncUrl="/v1/region"
          component={formSelect}
        />
      </FormContainer>
      <FormContainer label="Cabang Perusahaan">
        {isEdit ? (
          <Column>
            <Field
              name="has_relation_member"
              component={formCheckbox}
              onChange={(e) => {
                setHasBranch(e.target.checked);
              }}
              // validate={validate}
            />
            {hasBranch && (
              <Field
                placeholder="Cabang Perusahaan"
                name="relation_member_name"
                options={memberOptions}
                isAsync
                asyncUrl="/v1/member"
                component={formSelect}
              />
            )}
          </Column>
        ) : (
          <Field
            isDetail={!isEdit}
            name="relation_member_name"
            placeholder="Cabang Perusahaan"
            component={formSelect}
          />
        )}
      </FormContainer>
      <FormContainer label="Nama Pimpinan">
        <Field
          isDetail={!isEdit}
          name="leader_name"
          placeholder="Nama Pimpinan"
          component={formInput}
        />
      </FormContainer>
      <FormContainer label="Jabatan">
        <Field
          isDetail={!isEdit}
          name="position"
          placeholder="Jabatan"
          component={formInput}
        />
      </FormContainer>
      <FormContainer label="Alamat Perusahaan">
        <Field
          isDetail={!isEdit}
          name="address"
          placeholder="Alamat Perusahaan"
          component={formInput}
        />
      </FormContainer>
      <FormContainer label="Telepon Perusahaan">
        <Field
          isDetail={!isEdit}
          name="phone_number"
          placeholder="Telepon Perusahaan"
          component={formInputNumber}
        />
      </FormContainer>
      <FormContainer label="Fax Perusahaan">
        <Field
          isDetail={!isEdit}
          name="faximile"
          placeholder="Telepon Perusahaan"
          component={formInputNumber}
        />
      </FormContainer>
      <FormContainer label="Email">
        <Field
          isDetail={!isEdit}
          name="email"
          placeholder="Email"
          component={formInput}
        />
      </FormContainer>
      <FormContainer label="Kontak Person">
        <div className="row">
          <div className="col-6">
            <div>
              {!isEdit && (
                <div className="mt-2">
                  <span>Nama</span>
                </div>
              )}
              <Field
                isDetail={!isEdit}
                name="contact_person_name_1"
                placeholder="Nama"
                component={formInput}
              />
            </div>
            <div>
              {!isEdit && (
                <div className="mt-2">
                  <span>Email</span>
                </div>
              )}
              <Field
                isDetail={!isEdit}
                name="contact_person_email_1"
                placeholder="Email"
                component={formInput}
              />
            </div>
            <div>
              {!isEdit && (
                <div className="mt-2">
                  <span>No Telepon</span>
                </div>
              )}
              <Field
                isDetail={!isEdit}
                name="contact_person_phone_number_1"
                placeholder="No Telepon"
                component={formInput}
              />
            </div>
          </div>
          <div className="col-6">
            <div>
              {!isEdit && (
                <div className="mt-2">
                  <span>Nama</span>
                </div>
              )}
              <Field
                isDetail={!isEdit}
                name="contact_person_name_2"
                placeholder="Nama"
                component={formInput}
              />
            </div>
            <div>
              {!isEdit && (
                <div className="mt-2">
                  <span>Email</span>
                </div>
              )}
              <Field
                isDetail={!isEdit}
                name="contact_person_email_2"
                placeholder="Email"
                component={formInput}
              />
            </div>
            <div>
              {!isEdit && (
                <div className="mt-2">
                  <span>No Telepon</span>
                </div>
              )}
              <Field
                isDetail={!isEdit}
                name="contact_person_phone_number_2"
                placeholder="No Telepon"
                component={formInput}
              />
            </div>
          </div>
        </div>
      </FormContainer>
      <FormContainer label="Kelembagaan">
        <Field
          isDetail={!isEdit}
          placeholder="Kelembagaan"
          name="institutional"
          options={kelembagaanOptions}
          component={formSelect}
        />
      </FormContainer>
      <FormContainer label="Fasilitas Perusahaan">
        <Column>
          <div>
            {!isEdit && (
              <div className="mt-2">
                <span>Pabrik</span>
              </div>
            )}
            <Field
              isDetail={!isEdit}
              name="owned_facility_factory"
              placeholder="Pabrik (alamat)"
              component={formInput}
            />
          </div>
          <div>
            {!isEdit && (
              <div className="mt-2">
                <span>Workshop</span>
              </div>
            )}
            <Field
              isDetail={!isEdit}
              name="owned_facility_workshop"
              placeholder="Workshop (alamat)"
              component={formInput}
            />
          </div>
          <div>
            {!isEdit && (
              <div className="mt-2">
                <span>Lainnya</span>
              </div>
            )}
            <Field
              isDetail={!isEdit}
              name="owned_facility_other"
              placeholder="Lain Lain"
              component={formInput}
            />
          </div>
        </Column>
      </FormContainer>
      <FormContainer label="Tenaga Ahli">
        <Column>
          <div>
            {!isEdit && (
              <div className="mt-2">
                <span>Nama</span>
              </div>
            )}
            <Field
              isDetail={!isEdit}
              name="company_technicians_name"
              placeholder="Nama"
              component={formInput}
            />
          </div>
          <div>
            {!isEdit && (
              <div className="mt-2">
                <span>Pendidikan</span>
              </div>
            )}
            <Field
              isDetail={!isEdit}
              name="company_technicians_education"
              placeholder="Pendidikan"
              component={formInput}
            />
          </div>
        </Column>
      </FormContainer>
      <FormContainer label="Foto Direktur">
        {!isEdit ? (
          <>
            {detailData?.member_profile_data?.img_name ? (
              <img
                src={`${process.env.REACT_APP_GAKESLAB_URL}/v1/file/${MEMBER_PHOTO}/${detailData.member_profile_data.img_name}`}
                alt="foto"
                className={css(styles.imgDocument)}
                width="100%"
              ></img>
            ) : (
              <span>Tidak ada Foto</span>
            )}
          </>
        ) : (
          <Field
            isDetail={!isEdit}
            name="img_name"
            type="file"
            fileType="image"
            title="Masukkan Foto"
            onChange={(e) => uploadFile(e, "img")}
            message="JPG"
            component={formFile}
          />
        )}
      </FormContainer>
      <FormContainer label="Logo Perusahaan">
        {!isEdit ? (
          <>
            {detailData?.member_profile_data?.logo_filename ? (
              <img
                src={`${process.env.REACT_APP_GAKESLAB_URL}/v1/file/${LOGO_INSTITUTE}/${detailData.member_profile_data.logo_filename}`}
                alt="foto"
                className={css(styles.logo)}
                width="100%"
              ></img>
            ) : (
              <span>Tidak ada Foto</span>
            )}
          </>
        ) : (
          <Field
            isDetail={!isEdit}
            name="logo_filename"
            type="file"
            fileType="image"
            title="Masukkan Foto"
            onChange={(e) => uploadFile(e, "logo")}
            message="JPG"
            component={formFile}
          />
        )}
      </FormContainer>
    </form>
  );
};

const validate = ({
  address,
  contact_person_name_1,
  contact_person_email_1,
  contact_person_phone_number_1,
  company_technicians_education,
  company_technicians_name,
  email,
  faximile,
  institutional,
  leader_name,
  img_name,
  logo_filename,
  name,
  owned_facility_factory,
  owned_facility_other,
  owned_facility_workshop,
  phone_number,
  position,
  qualification,
  region_name,
  has_relation_member,
  relation_member_name,
}) => {
  const errors = {};

  if (!address) {
    errors.address = "Field harus diisi";
  }
  if (!company_technicians_education) {
    errors.company_technicians_education = "Field harus diisi";
  }
  if (!company_technicians_name) {
    errors.company_technicians_name = "Field harus diisi";
  }
  if (!contact_person_name_1) {
    errors.contact_person_name_1 = "Field harus diisi";
  }
  if (!contact_person_email_1) {
    errors.contact_person_email_1 = "Field harus diisi";
  }
  if (!contact_person_phone_number_1) {
    errors.contact_person_phone_number_1 = "Field harus diisi";
  }

  if (!email) {
    errors.email = "Field harus diisi";
  } else if (!regexEmail.test(email)) {
    errors.email = "Field tidak valid";
  }
  // if (!faximile) {
  //   errors.faximile = "Field harus diisi";
  // }
  if (!institutional) {
    errors.institutional = "Field harus diisi";
  }
  if (!img_name) {
    errors.img_name = "Foto direktur harus diupload";
  }
  if (!logo_filename) {
    errors.logo_filename = "Logo perusahaan harus diupload";
  }
  if (!leader_name) {
    errors.leader_name = "Field harus diisi";
  }
  if (!name) {
    errors.name = "Field harus diisi";
  }
  // if (!owned_facility_factory) {
  //   errors.owned_facility_factory = "Field harus diisi";
  // }
  // if (!owned_facility_other) {
  //   errors.owned_facility_other = "Field harus diisi";
  // }
  // if (!owned_facility_workshop) {
  //   errors.owned_facility_workshop = "Field harus diisi";
  // }
  if (!phone_number) {
    errors.phone_number = "Field harus diisi";
  }
  if (!position) {
    errors.position = "Field harus diisi";
  }
  if (!qualification) {
    errors.qualification = "Field harus diisi";
  }
  if (!region_name) {
    errors.region_name = "Field harus diisi";
  }
  if (has_relation_member && !relation_member_name) {
    errors.relation_member_name = "Field harus diisi";
  }

  return errors;
};

Index = reduxForm({
  form: "memberEditData",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(Index);

const mapStateToProps = ({ member, region, branchMember }) => {
  const { data, detailData, pending } = member;
  const path = window.location.pathname.split("/");
  let initialValues = {};
  if (detailData) {
    initialValues = {
      address: detailData.member_profile_data.address,
      end_active_kta: detailData.member_profile_data.end_active_kta
        ? detailData.member_profile_data.end_active_kta
        : "-",
      approved_at: detailData.member_profile_data.approved_at,
      contact_person_name_1:
        detailData.member_profile_data.contact_person_name_1,
      contact_person_email_1:
        detailData.member_profile_data.contact_person_email_1,
      contact_person_phone_number_1:
        detailData.member_profile_data.contact_person_phone_number_1,
      contact_person_name_2:
        detailData.member_profile_data.contact_person_name_2,
      contact_person_email_2:
        detailData.member_profile_data.contact_person_email_2,
      contact_person_phone_number_2:
        detailData.member_profile_data.contact_person_phone_number_2,
      company_technicians_education:
        detailData.member_profile_data.company_technicians_education,
      company_technicians_name:
        detailData.member_profile_data.company_technicians_name,
      email: detailData.email,
      faximile: detailData.member_profile_data.faximile,
      kta_no: detailData.member_profile_data.kta_no,
      img_name: detailData.member_profile_data.img_name,
      logo_filename: detailData.member_profile_data.logo_filename,
      institutional: detailData.member_profile_data.institutional
        ? {
            label: detailData.member_profile_data.institutional.replace(
              /(^\w{1})|(\s+\w{1})/g,
              (letter) => letter.toUpperCase()
            ),
            value: detailData.member_profile_data.institutional,
          }
        : null,
      leader_name: detailData.member_profile_data.leader_name,
      name: detailData.name,
      owned_facility_factory:
        detailData.member_profile_data.owned_facility_factory,
      owned_facility_other: detailData.member_profile_data.owned_facility_other,
      owned_facility_workshop:
        detailData.member_profile_data.owned_facility_workshop,
      phone_number: detailData.member_profile_data.phone_number,
      position: detailData.member_profile_data.position,
      region_name: detailData.region_name
        ? {
            label: detailData.region_name,
            value: detailData.region_id,
          }
        : null,
      has_relation_member: detailData.member_profile_data.relation_member_name
        ? true
        : false,
      relation_member_name: detailData.member_profile_data.relation_member_id
        ? {
            label: detailData.member_profile_data.relation_member_name,
            value: detailData.member_profile_data.relation_member_id,
          }
        : null,
    };
  }
  return { data, detailData, pending, initialValues, region, branchMember };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetMemberModal: (modalType, isOpen) =>
      dispatch(setMemberModal(modalType, isOpen)),
    onSetMemberData: (data) => dispatch(setMemberData(data)),
  };
};

const styles = StyleSheet.create({
  imgDocument: {
    maxHeight: 200,
    maxWidth: 200,
    objectFit: "contain",
    marginRight: "auto",
    marginLeft: "auto",
  },
  logo: {
    maxHeight: 200,
    maxWidth: 200,
    objectFit: "contain",
    marginRight: "auto",
    marginLeft: "auto",
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
