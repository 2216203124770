import {
  GET_DASHBOARD_AGENCY_PENDING,
  GET_DASHBOARD_AGENCY_SUCCESS,
  GET_DASHBOARD_AGENCY_ERROR,
  GET_DASHBOARD_PRODUCED_PENDING,
  GET_DASHBOARD_PRODUCED_SUCCESS,
  GET_DASHBOARD_PRODUCED_ERROR,
  GET_DASHBOARD_DISTRIBUTION_PENDING,
  GET_DASHBOARD_DISTRIBUTION_SUCCESS,
  GET_DASHBOARD_DISTRIBUTION_ERROR,
  GET_DASHBOARD_STATISTIC_PENDING,
  GET_DASHBOARD_STATISTIC_SUCCESS,
  GET_DASHBOARD_STATISTIC_ERROR,
  GET_DASHBOARD_TOTAL_PENDING,
  GET_DASHBOARD_TOTAL_SUCCESS,
  GET_DASHBOARD_TOTAL_ERROR,
  GET_DASHBOARD_TOTAL_INSTITUTIONAL_PENDING,
  GET_DASHBOARD_TOTAL_INSTITUTIONAL_SUCCESS,
  GET_DASHBOARD_TOTAL_INSTITUTIONAL_ERROR,
  GET_DASHBOARD_LOCATION_PENDING,
  GET_DASHBOARD_LOCATION_SUCCESS,
  GET_DASHBOARD_LOCATION_ERROR,
} from "../../actions/dashboard";

const initialState = {
  pending: false,
  error: null,
  data: null,
  detailData: null,
  isOpenModal: false,
  modalType: "",
};

export const dashboardAgency = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_AGENCY_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_DASHBOARD_AGENCY_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data.data.data,
      };
    case GET_DASHBOARD_AGENCY_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const dashboardProduced = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_PRODUCED_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_DASHBOARD_PRODUCED_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data.data.data,
      };
    case GET_DASHBOARD_PRODUCED_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const dashboardDistribution = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_DISTRIBUTION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_DASHBOARD_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data.data.data,
      };
    case GET_DASHBOARD_DISTRIBUTION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const dashboardLocation = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_LOCATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_DASHBOARD_LOCATION_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data.data.data,
      };
    case GET_DASHBOARD_LOCATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const dashboardStatistic = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_STATISTIC_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_DASHBOARD_STATISTIC_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data.data.data,
      };
    case GET_DASHBOARD_STATISTIC_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const dashboardTotal = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_TOTAL_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_DASHBOARD_TOTAL_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data.data.data,
      };
    case GET_DASHBOARD_TOTAL_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const dashboardTotalInstitutional = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_TOTAL_INSTITUTIONAL_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_DASHBOARD_TOTAL_INSTITUTIONAL_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data.data.data,
      };
    case GET_DASHBOARD_TOTAL_INSTITUTIONAL_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};
