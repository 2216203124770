import React from "react";
import moment from "moment";
import { StyleSheet, css } from "aphrodite";

const Index = ({ title, description, start_date, end_date, total }) => {

  const setModal = (modalType, isOpen, data) => {
    console.log(data);
    // onSetPollingAdminModal(modalType, isOpen);
    // console.log(data);
    // onSetPollingAdminDataQuestion(data);
  };

  return (
    <>
      <div className="row m-3 justify-content-between">
        <div className="col border-right p-3">
          <span className="font-weight-bold">Nama Polling</span>
          <div className="pt-2">
            <span>{title ? title : "-"}</span>
          </div>
        </div>
        <div className="col border-right p-3">
          <span className="font-weight-bold">Deskripsi Polling</span>
          <div className="pt-2">
            <span>
              {description ? (
                <div dangerouslySetInnerHTML={{ __html: description }}></div>
              ) : (
                "-"
              )}
            </span>
          </div>
          <div className="pt-2">
            {description && (
              <span 
                className={css(styles.seeMoreLabel)}
                onClick={() => setModal("detailDeskripsi", true, description)}
                >
                Lihat Selengkapnya
              </span>
            )}
          </div>
        </div>
        <div className="col border-right p-3">
          <span className="font-weight-bold">Tanggal Mulai</span>
          <div className="pt-2">
            <span>
              {start_date ? moment(start_date).format("DD MMM YYYY") : "-"}
            </span>
          </div>
        </div>
        <div className="col border-right p-3">
          <span className="font-weight-bold">Tanggal Berakhir</span>
          <div className="pt-2">
            <span>
              {end_date ? moment(end_date).format("DD MMM YYYY") : "-"}
            </span>
          </div>
        </div>
        <div className="col p-3">
          <span className="font-weight-bold">Total Voter</span>
          <div className="pt-2">
            <span>{total ? total : "0"}</span>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  seeMoreLabel: {
    color: "#2CB96A",
    fontSize: 14,
    cursor: "pointer",
  },
});

export default Index;
