import React, { useRef } from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import { connect, useDispatch } from "react-redux";
import MapsCluster from "../maps-cluster";
import { withRouter } from "react-router-dom";

const Index = ({ data }) => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Column
        style={{
          boxShadow: "0px 3px 6px #00000029",
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
          height: "100%",
          width: "100%",
          minHeight: 300,
          backgroundColor: "white",
        }}
      >
        <div className={css(styles.cardHeader)}></div>
        <Row
          className={css(styles.wrapperTop)}
          style={{
            width: "100%",
            padding: "10px 20px",
            backgroundColor: "white",
          }}
          vertical="center"
          horizontal="space-between"
        >
          <label className={css(styles.titleLabel)}>Sebaran Anggota</label>
        </Row>
        <Column
          className={css(styles.wrapper)}
          style={{
            width: "100%",
            padding: "0 20px 20px",
            backgroundColor: "black",
          }}
          horizontal="center"
        >
          <div style={{ width: "100%", height: "100%", position: "relative" }}>
            <MapsCluster dataMember={data} />
          </div>
        </Column>
      </Column>
    </div>
  );
};

const styles = StyleSheet.create({
  titleLabel: {
    color: "#495057",
    fontSize: 18,
    fontWeight: "bold",
    margin: "5px 0",
  },
  cardLabel: {
    color: "#495057",
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 4,
  },
  wrapper: {
    // padding: 15,
    // height: 400,
    backgroundColor: "white",
  },
  borderBottom: {
    borderBottom: "1px solid #eeee",
  },
  cardHeader: {
    backgroundColor: "#2cb96a",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    height: 10,
    width: "100%",
  },
});

const mapStateToProps = ({}) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
