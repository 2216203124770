import API from "../../API";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
import { setToastModal } from "../..";
import { toastError, toastSuccess } from "../../../../components/commons/toast";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_AUDIT_PENDING = "GET_AUDIT_PENDING";
export const GET_AUDIT_SUCCESS = "GET_AUDIT_SUCCESS";
export const GET_AUDIT_ERROR = "GET_AUDIT_ERROR";
export const POST_AUDIT_PENDING = "POST_AUDIT_PENDING";
export const POST_AUDIT_SUCCESS = "POST_AUDIT_SUCCESS";
export const POST_AUDIT_ERROR = "POST_AUDIT_ERROR";
export const PUT_AUDIT_PENDING = "PUT_AUDIT_PENDING";
export const PUT_AUDIT_SUCCESS = "PUT_AUDIT_SUCCESS";
export const PUT_AUDIT_ERROR = "PUT_AUDIT_ERROR";
export const DELETE_AUDIT_PENDING = "DELETE_AUDIT_PENDING";
export const DELETE_AUDIT_SUCCESS = "DELETE_AUDIT_SUCCESS";
export const DELETE_AUDIT_ERROR = "DELETE_AUDIT_ERROR";

export const AUDIT_DATA = "AUDIT_DATA";
export const SET_AUDIT_MODAL = "SET_AUDIT_MODAL";

// URL: URL_{URL}
const AUDIT_URL = "/v1/audit_trail";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_AUDIT_PENDING));
  API.get(AUDIT_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_AUDIT_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_AUDIT_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_AUDIT_PENDING));
  API.post(AUDIT_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_AUDIT_SUCCESS, res));
      toastSuccess("Audit Trail berhasil ditambahkan");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_AUDIT_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_AUDIT_PENDING));
  API.put(AUDIT_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_AUDIT_SUCCESS, res));
      toastSuccess("Audit Trail berhasil diubah");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_AUDIT_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_AUDIT_PENDING));
  API.delete(AUDIT_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_AUDIT_SUCCESS, res));
      toastSuccess("Audit Trail berhasil dihapus");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_AUDIT_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const Audit = { get, post, put, deleted };
export default Audit;

export const setAuditData = (data) => (dispatch) =>
  dispatch({ type: AUDIT_DATA, data });

export const setAuditModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_AUDIT_MODAL, {
      modalType: modalType,
      isOpen: isOpen,
    })
  );
