import React from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import CardMember from "../card-member";

const Index = ({ data }) => {
  return (
    <Column style={{ marginBottom: 10 }}>
      <div style={{ marginBottom: 10 }}>
        <CardMember
          img_url="member-green.png"
          member_total={data?.total_member ? data?.total_member : 0}
          card_label="Total Anggota"
        />
      </div>
      <div style={{ marginBottom: 10 }}>
        <CardMember
          img_url="member-blue.png"
          member_total={
            data?.total_member_active ? data.total_member_active : 0
          }
          card_label="Anggota Aktif"
        />
      </div>
      <div style={{ marginBottom: 10 }}>
        <CardMember
          img_url="member-yellow.png"
          member_total={
            data?.total_member_inactive ? data.total_member_inactive : 0
          }
          card_label="Anggota Non-aktif"
        />
      </div>
    </Column>
  );
};

const styles = StyleSheet.create({
  memberTotal: {
    color: "#495057",
    fontSize: 30,
    fontWeight: "bold",
    margin: "5px 0px 15px 0px",
  },
  cardLabel: {
    color: "#BCBCBC",
    marginBottom: 0,
  },
  detailColumn: {
    textAlign: "right",
  },
  cardMemberWrapper: {
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: 5,
    padding: 15,
  },
});

export default Index;
