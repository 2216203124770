import React, { useState } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { ModalBody, ModalHeader } from "reactstrap";
import { formInput } from "../../../../components/commons/form";
import { setPollingMemberModal } from "../../../../store/actions/polling/member";
import CloseIcon from "@material-ui/icons/Close";
import { StyleSheet, css } from "aphrodite";

let DetailDeskripsi = ({ onSetPollingMemberModal, pending, initialValues }) => {
  return (
    <>
      <div className="row" style={{ margin:0}}>
        <div className="col-md-10" style={{ paddingRight: 0 }}>
          <ModalHeader className={css(styles.customHeader)}>Deskripsi Polling</ModalHeader>
        </div>
        <div className={css(styles.divCloseButton)}>
          <CloseIcon
            className={css(styles.closeButton)}
            onClick={() => onSetPollingMemberModal("", false)}
          />
        </div>
      </div>
      <ModalBody>
        {initialValues.description && (
          <div dangerouslySetInnerHTML={{ __html: initialValues.description }}></div>
        )}
        
      </ModalBody>
    </>
  );
};

DetailDeskripsi = reduxForm({
  form: "pollingMemberDetail",
})(DetailDeskripsi);

const mapStateToProps = ({ pollingMember: { detailData, pending } }) => {
  let initialValues = {};
  if (detailData) {
    initialValues = {
      description: detailData.description
    };
  }
  return {
    initialValues,
    pending,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetPollingMemberModal: (modalType, isOpen) =>
      dispatch(setPollingMemberModal(modalType, isOpen)),
  };
};

const styles = StyleSheet.create({
  closeButton: {
    fontSize: "20px",
    color: "#495057",
    ":hover": {
      color: "rgb(134 143 152)",
    },
  },
  divCloseButton: {
    display: "flex",
    paddingLeft: "0",
    margin: "auto 20px auto auto !important;",
    justifyContent: "center",
  },
  customHeader: {
    paddingLeft: '5px !important',
    paddingBottom: '0px !important',
    border: 'none'
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailDeskripsi);
