import React, { useRef } from "react";
import { StyleSheet, css } from "aphrodite";

const Index = ({ name, value, total }) => {
  const length = Math.round((value / total) * 100);
  return (
    <>
      <div className="border-bottom py-3">
        <div className="col p-0">
          <div className="mb-1">
            <span>{name ? name : "-"}</span>
          </div>
          <div className="d-flex align-items-center">
            <div
              style={{
                backgroundColor: "#2cb96a",
                width: `${length - 6}%`,
                height: 10,
                marginRight: 5,
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
              }}
            />
            <div>
              <span
                style={{ color: "#2cb96a", fontWeight: "bold", fontSize: 12 }}
              >
                {value ? value : 0}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// const styles = StyleSheet.create({
//   container: {
//     // backgroundColor: "#fafafa",
//     // marginBottom: 20,
//     // padding: 20,
//     borderBottom: "1px solid #D8D9DA",
//     // borderRadius: 5,
//   },
// });

export default Index;
