import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import IuranMember, {
  setIuranMemberData,
  setIuranMemberModal,
} from "../../../store/actions/iuran/member";
import { Row } from "simple-flexbox";
import MaterialTable from "material-table";
import TableStatus from "../../../components/global-components/TableStatus";
import SelectComponent from "../../../components/commons/form/select";
import { Paper, Button } from "@material-ui/core";
import Container from "../../../components/container";
import Modal from "./modal";
import { history } from "../../../utils";
import { statusIuranOptions } from "../../../utils/constant";
import moment from "moment";
import debounce from "lodash.debounce";
import { StyleSheet, css } from "aphrodite";

const bgColor = {
  2: "#fef2ea",
  Label2: "#f58337",
  3: "#fef2ea",
  Label3: "#f58337",
  1: "#E9F8F0",
  Label1: "#2CB96A",
  0: "#FDE9E9",
  Label0: "#ED2A2A",
};

const Index = ({
  onSetIuranMemberModal,
  onSetIuranMemberData,
  pending,
  data,
  isApprove,
}) => {
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();
  const tableRef = useRef();

  const handleRefresh = (state) => {
    setSearchText(state);
    tableRef.current && tableRef.current.onQueryChange();
  };

  const delayedQuery = debounce((value) => {
    return handleRefresh(value);
  }, 500);

  const handleSearchChange = (e) => {
    delayedQuery(e.target.value);
  };

  const handleStatusChange = (e) => {
    if (e) {
      setStatus(e.value);
    } else {
      setStatus("");
    }
    tableRef.current && tableRef.current.onQueryChange();
  };

  const setDetail = (data) => {
    onSetIuranMemberData(data);
    history.push(`/iuran/${data.id}`);
  };

  const setModal = (modalType, isOpen, data) => {
    onSetIuranMemberModal(modalType, isOpen);
    onSetIuranMemberData(data);
  };
  return (
    <Container>
      <Modal handleRefresh={(state) => handleRefresh(state)} />
      <Row className="m-3 justify-content-between">
        <Button
          color="primary"
          variant="contained"
          disabled={pending || !isApprove}
          onClick={() => setModal("add", true, null)}
        >
          Tambah Bukti Pembayaran
        </Button>
        <Row className="m-0 justify-content-between">
          <div></div>
          <div style={{ width: 120, marginRight: 20 }}>
            <SelectComponent
              onChange={(e) => handleStatusChange(e)}
              placeholder="Status"
              options={statusIuranOptions}
            />
          </div>
        </Row>
      </Row>
      <div className="m-3">
        <MaterialTable
          tableRef={tableRef}
          title="IuranMember"
          columns={[
            {
              title: "No",
              field: "no",
              width: 40,
            },
            {
              title: "Tanggal Iuran",
              render: ({ date }) => {
                return date ? moment(date).format("DD MMMM YYYY") : "-";
              },
            },
            {
              title: "Total Iuran",
              render: (rowData) => {
                const total = rowData.total
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
                return <>{rowData.total ? `Rp ${total}` : "-"}</>;
              },
            },
            {
              title: "Status",
              width: 150,
              render: ({ status }) => {
                return (
                  <TableStatus
                    status={
                      status === 0
                        ? "Diproses"
                        : status === 1
                        ? "Menunggu"
                        : status === 2
                        ? "Diterima"
                        : "Ditolak"
                    }
                  />
                );
              },
            },
            {
              title: "Aksi",
              width: 80,
              cellStyle: {
                paddingLeft: 0,
              },
              render: (rowData) => {
                return (
                  <div className="m-2">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setDetail(rowData)}
                      // onClick={() => setModal("detail", true, rowData)}
                    >
                      Detail
                    </Button>
                  </div>
                );
              },
            },
          ]}
          data={(q) =>
            new Promise((resolve) => {
              let param = {
                page: q.page + 1,
                length: 10,
                search_text: searchText,
                status,
              };
              dispatch(IuranMember.get(param, resolve));
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            showTitle: false,
            search: false,
            sorting: false,
            headerStyle: {
              backgroundColor: "#fff",
              fontWeight: "bold",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
        />
      </div>
    </Container>
  );
};

const mapStateToProps = ({ iuranMember: { pending, data } }) => {
  let isApprove = false;

  if (data) {
    isApprove =
      data.data.data[0]?.status === 2 || data.data.data.length === 0
        ? true
        : false;
  }

  return { pending, data, isApprove };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetIuranMemberModal: (modalType, isOpen) =>
      dispatch(setIuranMemberModal(modalType, isOpen)),
    onSetIuranMemberData: (data) => dispatch(setIuranMemberData(data)),
  };
};

const styles = StyleSheet.create({
  status: {
    padding: 5,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 30,
    whiteSpace: "nowrap",
    textAlign: "center",
    width: "100%",
    maxWidth: 150,
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
