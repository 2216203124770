import React, { useState, useRef, useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import IuranMember, {
  setIuranMemberData,
  setIuranMemberModal,
} from "../../../store/actions/iuran/member";
import { Column, Row } from "simple-flexbox";
import MaterialTable from "material-table";
import SearchIcon from "@material-ui/icons/Search";
import TableStatus from "../../../components/global-components/TableStatus";
import SelectComponent from "../../../components/commons/form/select";
import InputComponent from "../../../components/commons/form/input";
import { Paper, Button } from "@material-ui/core";
import Container from "../../../components/container";
import Modal from "./modal";
import { history } from "../../../utils";
import { statusIuranOptions } from "../../../utils/constant";
import moment from "moment";
import debounce from "lodash.debounce";
import Card from "./card";
import { StyleSheet, css } from "aphrodite";

const bgColor = {
  2: "#fef2ea",
  Label2: "#f58337",
  3: "#fef2ea",
  Label3: "#f58337",
  1: "#E9F8F0",
  Label1: "#2CB96A",
  0: "#FDE9E9",
  Label0: "#ED2A2A",
};

const Index = ({ 
  onSetIuranMemberModal, 
  onSetIuranMemberData, 
  pending,
  reports,
}) => {
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();
  const tableRef = useRef();

  useEffect(() => {
    getResume();
  }, []);

  const getResume = () => dispatch(IuranMember.getResume());
  
  const handleRefresh = (state) => {
    setSearchText(state);
    tableRef.current && tableRef.current.onQueryChange();
  };

  const delayedQuery = debounce((value) => {
    return handleRefresh(value);
  }, 500);

  const handleSearchChange = (e) => {
    delayedQuery(e.target.value);
  };

  const handleStatusChange = (e) => {
    if (e) {
      setStatus(e.value);
    } else {
      setStatus("");
    }
    tableRef.current && tableRef.current.onQueryChange();
  };

  const setDetail = (data) => {
    // onSetIuranMemberData(data);
    history.push(`/admin/iuran/${data.user_id}`);
  };

  const setModal = (modalType, isOpen, data) => {
    onSetIuranMemberModal(modalType, isOpen);
    onSetIuranMemberData(data);
  };

  return (
    <Fragment>
      <Row className="mb-3 flex-wrap">
        <Column>
          <div style={{ marginRight: 30, marginTop: 10 }}>
            <Card
              img_url="iuran-red.png"
              iuran_total={reports.total_unpaid ? reports.total_unpaid : 0}
              card_label="Belum Bayar"
            />
          </div>
        </Column>
        <Column>
          <div style={{ marginRight: 30, marginTop: 10 }}>
            <Card
              img_url="iuran-orange.png"
              iuran_total={reports.total_process ? reports.total_process : 0}
              card_label="Sedang Diproses"
            />
          </div>
        </Column>
        <Column>
          <div style={{ marginRight: 30, marginTop: 10 }}>
            <Card
              img_url="iuran-green.png"
              iuran_total={reports.total_paid ? reports.total_paid : 0}
              card_label="Sudah Bayar"
            />
          </div>
        </Column>
      </Row>
      <Container>
        <Modal handleRefresh={(state) => handleRefresh(state)} />
        <Row className="m-3 justify-content-between">
          {/* <Button
            color="primary"
            variant="contained"
            disabled={pending}
            onClick={() => setModal("add", true, null)}
          >
            Tambah Iuran
          </Button> */}
          <div></div>
          <Row className="m-0 justify-content-between">
            <div style={{ width: 120, marginRight: 20 }}>
              <SelectComponent
                onChange={(e) => handleStatusChange(e)}
                placeholder="Status"
                options={statusIuranOptions}
              />
            </div>
            <InputComponent
              onChange={(e) => handleSearchChange(e)}
              placeholder="Cari nama perusahaan"
              endIcon={SearchIcon}
            />
          </Row>
        </Row>
        <div className="m-3">
          <MaterialTable
            tableRef={tableRef}
            title="IuranMember"
            columns={[
              {
                title: "No",
                field: "no",
                width: 40,
              },
              {
                title: "Nama Perusahaan",
                render: ({ user_fullname }) => {
                  return user_fullname ? user_fullname : "-";
                },
              },
              {
                title: "Status",
                // width: 150,
                headerStyle: {
                  paddingLeft: 65,
                },
                render: ({ status }) => {
                  return (
                    <div
                      style={{
                        backgroundColor: bgColor[status],
                        color: bgColor["Label" + status],
                      }}
                      className={css(styles.status)}
                    >
                      <span>
                        {
                        status === 0
                          ? "Belum Bayar"
                          : status === 1
                          ? "Sudah Bayar"
                          : status === 2
                          ? "Diproses Admin Cabang"
                          : status === 3
                          ? "Diproses Admin Pusat"
                          : "Ditolak"
                        }
                      </span>
                    </div>
                  );
                },
              },
              {
                title: "Aksi",
                width: 80,
                cellStyle: {
                  paddingLeft: 0,
                },
                render: (rowData) => {
                  return (
                    <div className="m-2">
                      <Button
                        variant="outlined"
                        color="primary"
                        // onClick={() => setDetail(rowData)}
                        onClick={() => setDetail(rowData)}
                      >
                        Detail
                      </Button>
                    </div>
                  );
                },
              },
            ]}
            data={(q) =>
              new Promise((resolve) => {
                let param = {
                  page: q.page + 1,
                  length: 10,
                  search_text: searchText,
                  status,
                };
                dispatch(IuranMember.get(param, resolve));
              })
            }
            options={{
              pageSize: 10,
              paginationType: "stepped",
              pageSizeOptions: [],
              showTitle: false,
              search: false,
              sorting: false,
              headerStyle: {
                backgroundColor: "#fff",
                fontWeight: "bold",
              },
            }}
            localization={{
              body: {
                emptyDataSourceMessage: "Tidak ada data",
              },
            }}
            components={{
              Toolbar: () => <div />,
              Container: (props) => <Paper {...props} elevation={0} />,
            }}
          />
        </div>
      </Container>

    </Fragment>
  );
};

const mapStateToProps = ({ 
  iuranMember: { pending, data, reports },
}) => {
  return { 
    pending,
    data,
    reports
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetIuranMemberModal: (modalType, isOpen) =>
      dispatch(setIuranMemberModal(modalType, isOpen)),
    onSetIuranMemberData: (data) => dispatch(setIuranMemberData(data)),
  };
};

const styles = StyleSheet.create({
  status: {
    padding: 5,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 30,
    whiteSpace: "nowrap",
    textAlign: "center",
    width: "100%",
    maxWidth: 200,
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
