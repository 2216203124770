import { useEffect, useMemo, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import Container from "../../../../components/container";
import LeftContainer from "../../../../components/container/LeftContainer";
import RightContainer from "../../../../components/container/RightContainer";
import DetailQuestion from "../../../../components/detail-content/list-question";
import PollingMember, {
  setPollingMemberData,
  setPollingMemberModal,
  setPollingMemberStep,
} from "../../../../store/actions/polling/member";
import { Row } from "simple-flexbox";
import { Button } from "@material-ui/core";
import moment from "moment";
import { reduxForm } from "redux-form";
import Pagination from "@material-ui/lab/Pagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import { StyleSheet, css } from "aphrodite";
import Modal from "../modal";

let Index = ({ 
  onSetPollingMemberModal,
  onSetPollingMemberData, 
  pending, 
  detailData, 
  initialValues, 
  detailDataQuestion 
}) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const pageSize = 5;
  const length =
    detailData?.length > 0 ? Math.ceil(detailData?.length / pageSize) : 1;
  const id = window.location.pathname.split("/").pop();
  
  const [heightDetail, setHeightDetail] = useState(0)
  const detailRef = useRef(null)
  const [modalButtonDetail, setModalButtonDetail] = useState(0)
  const modalButtonDetailRef = useRef(null)
  const [showButtonDetail, setShowButtonDetail] = useState(false);

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    getListQuestion();
  }, []);

  const getListQuestion = () =>
    dispatch(
      PollingMember.getDetail({ page, length: pageSize, polling_id: id })
    );

  const minQuestions = useMemo(() => (page - 1) * pageSize, [page, pageSize]);
  const maxQuestions = useMemo(() => page * pageSize, [page, pageSize]);

  const setModal = (modalType, isOpen, data) => {
    onSetPollingMemberModal(modalType, isOpen);
    onSetPollingMemberData(data);
  };

  useEffect(() => {
    setHeightDetail(detailRef.current.clientHeight)
  })

  useEffect(() => {
    setModalButtonDetail(modalButtonDetailRef)
    if (detailRef.current !== null && heightDetail >= 75) {
      setShowButtonDetail(true)
    }
  })

  const setClassButtonDetail = () => {
    if (showButtonDetail) {
      return css(styles.maxThreeRow)
    }
    return css(styles.maxNoneRow)
  };

  return (
    <Container>
      <Modal />
      <Row>
        <LeftContainer>
          <div className="row">
            <div className="col">
              <div style={{ marginBottom: 30 }}>
                <h6 style={{ fontWeight: "bold" }}>Nama Polling</h6>
                <span>
                  {detailDataQuestion ? detailDataQuestion.title : "-"}
                </span>
              </div>
              <div style={{ marginBottom: 15 }}>
                <h6 style={{ fontWeight: "bold" }}>Deskripsi Polling</h6>
                <span
                  className={setClassButtonDetail()}
                  ref={detailRef}
                  >
                    {detailDataQuestion?.description ? (
                    <div dangerouslySetInnerHTML={{ __html: detailDataQuestion?.description }}></div>
                  ) : (
                    "-"
                  )}
                </span>
                {(showButtonDetail) && (
                  <div className="pt-2" style={{ marginBottom: 30 }}>
                    <span 
                      ref={modalButtonDetailRef}
                      className={css(styles.seeMoreLabel)}
                      onClick={() => setModal("detailDeskripsi", true, detailDataQuestion)}
                      >
                      Lihat Selengkapnya
                    </span>
                  </div>
                )}
              </div>
              <div style={{ marginBottom: 30 }}>
                <h6 style={{ fontWeight: "bold" }}>Tanggal Mulai</h6>
                <span>
                  {detailDataQuestion
                    ? moment(detailDataQuestion.start_date).format(
                        "DD MMM YYYY"
                      )
                    : "-"}
                </span>
              </div>
              <div style={{ marginBottom: 30 }}>
                <h6 style={{ fontWeight: "bold" }}>Tanggal Berakhir</h6>
                <span>
                  {detailDataQuestion
                    ? moment(detailDataQuestion.end_date).format("DD MMM YYYY")
                    : "-"}
                </span>
              </div>
              <div style={{ marginBottom: 30 }}>
                <h6 style={{ fontWeight: "bold" }}>Total Pertanyaan</h6>
                <span>{detailData ? detailData.length : "-"}</span>
              </div>
            </div>
          </div>
        </LeftContainer>
        <RightContainer>
          <form style={{ width: "100%" }}>
            <div className="row">
              <div className="col">
                {pending ? (
                  <CircularProgress />
                ) : detailData?.length > 0 ? (
                  <>
                    <div style={{ minHeight: 465 }}>
                      {detailData.map((item, index) => {
                        let temp = index + 1;
                        return (
                          <DetailQuestion
                            key={index}
                            data={item}
                            isDetail
                            value={initialValues["number" + temp]}
                            otherCheck={
                              item.type === 3 &&
                              initialValues["other_number" + temp + "_checkbox"]
                            }
                            show={index >= minQuestions && index < maxQuestions}
                          />
                        );
                      })}
                    </div>
                    <div className="d-flex justify-content-end border-top pt-3">
                      <Pagination
                        shape="rounded"
                        color="primary"
                        count={length}
                        page={page}
                        onChange={handleChange}
                      />
                    </div>
                  </>
                ) : (
                  <div>Tidak ada data</div>
                )}
              </div>
            </div>
          </form>
        </RightContainer>
      </Row>
    </Container>
  );
};

const validate = () => {
  const errors = {};
  return errors;
};

Index = reduxForm({
  form: "memberDetailPolling",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(Index);

const mapStateToProps = ({ pollingMember }) => {
  const { data, detailData: detailDataQuestion, pending } = pollingMember;
  const detailData = data?.data?.data?.length > 0 ? data.data.data : null;
  let initialValues = {};
  let name = "number";
  let other = "other_";
  let check = "_checkbox";

  if (detailData) {
    detailData.map((item, index) => {
      if (item.type === 1) {
        initialValues[name + item.number.toString()] = item.text_answer;
      }
      if (item.type === 2) {
        if (item.text_answer) {
          initialValues[name + item.number.toString()] = "other";
          initialValues[other + name + item.number.toString()] =
            item.text_answer;
        } else {
          initialValues[name + item.number.toString()] =
            item.number_answer_list[0];
        }
      }
      if (item.type === 3) {
        let answer = item.number_answer_list.sort();
        let count = 0;
        let answer_list = item.multiple_questions.map((val, ind) => {
          if (val.key === answer[count]) {
            count++;
            return true;
          } else {
            return false;
          }
        });
        initialValues[name + item.number.toString()] = answer_list;
        let has_quest_other = item.multiple_questions.filter(
          (e) => e.is_extra_text === 1
        );
        if (has_quest_other.length > 0) {
          if (answer.includes(has_quest_other[0].key)) {
            initialValues[other + name + item.number.toString() + check] = true;
            initialValues[other + name + item.number.toString()] =
              item.text_answer;
          }
        }
      }
      if (item.type === 4) {
        initialValues[name + item.number.toString()] =
          item.number_answer_list[0];
      }
    });
  }
  return { detailData, pending, initialValues, detailDataQuestion };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetPollingMemberModal: (modalType, isOpen) =>
      dispatch(setPollingMemberModal(modalType, isOpen)),
    onSetPollingMemberData: (data) => dispatch(setPollingMemberData(data)),
    onSetPollingMemberStep: (step) => dispatch(setPollingMemberStep(step)),
  };
};

const styles = StyleSheet.create({
  seeMoreLabel: {
    color: "#2CB96A",
    fontSize: 14,
    cursor: "pointer",
    paddingTop: 10,
  },
  maxThreeRow: {
    display: "-webkit-box",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  maxNoneRow: {
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
