import React, { useRef } from "react";
import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import { connect, useDispatch } from "react-redux";
import PollingAdmin from "../../../../store/actions/polling/admin";
import { withRouter } from "react-router-dom";
import { Row } from "simple-flexbox";

const Index = ({ data }) => {
  const tableRef = useRef();
  const dispatch = useDispatch();
  const path = window.location.pathname.split("/");
  const type = path.pop();
  const question_id = path.pop();
  const polling_id = path.pop();

  return (
    <>
      <div className="row">
        <div className="col">
          <MaterialTable
            tableRef={tableRef}
            title="DetailResponPolling"
            columns={[
              {
                title: "Nama Voter",
                render: ({ name }) => {
                  return (
                    <Row vertical="center" style={{ height: 50 }}>
                      {name ? name : "-"}
                    </Row>
                  );
                },
              },
              {
                title: "Respon",
                render: ({ answer }) => {
                  return (
                    <Row vertical="center" style={{ height: 50 }}>
                      {answer ? answer : "-"}
                    </Row>
                  );
                },
              },
            ]}
            data={(q) =>
              new Promise((resolve) => {
                let param = {
                  page: q.page + 1,
                  length: 5,
                  type,
                  question_id,
                  polling_id,
                };
                dispatch(PollingAdmin.getDetailAnswer(param, resolve));
              })
            }
            options={{
              pageSize: 5,
              paginationType: "stepped",
              pageSizeOptions: [],
              showTitle: false,
              search: false,
              sorting: false,
              headerStyle: {
                backgroundColor: "#fff",
                fontWeight: "bold",
              },
            }}
            localization={{
              body: {
                emptyDataSourceMessage: "Tidak ada data",
              },
            }}
            components={{
              Toolbar: () => <div />,
              Container: (props) => (
                <Paper {...props} elevation={0} className="bg-light p-3" />
              ),
            }}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ pollingAdmin: { pending } }) => {
  return { pending };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
