import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Container from "../../../../../components/container";
import LeftContainer from "../../../../../components/container/LeftContainer";
import RightContainer from "../../../../../components/container/RightContainer";
import DetailResponPolling from "../../../../../components/detail-content/respon-polling/detail";
import RingkasanResponPolling from "../../../../../components/detail-content/respon-polling/ringkasan";
import PollingAdmin, {
  setPollingAdminData,
  setPollingAdminModal,
} from "../../../../../store/actions/polling/admin";
import { Row, Column } from "simple-flexbox";
import { pollingOptions } from "../../../../../utils/constant";
import { ButtonGroup, Button } from "@material-ui/core";

let Index = ({ detailData, detailDataQuestion }) => {
  const dispatch = useDispatch();
  const [selectedMenu, setSelectedMenu] = useState("ringkasan");
  const path = window.location.pathname.split("/");

  return (
    <Container>
      <Row>
        <LeftContainer>
          <div className="row">
            <div className="col">
              <div style={{ marginBottom: 30 }}>
                <h5>Pertanyaan</h5>
                <span>
                  {detailDataQuestion ? detailDataQuestion.title : "-"}
                </span>
              </div>
              <div>
                <h5>Jenis Polling</h5>
                <span>
                  {detailDataQuestion
                    ? pollingOptions[detailDataQuestion.type - 1].label
                    : "-"}
                </span>
              </div>
            </div>
          </div>
        </LeftContainer>
        <RightContainer>
          <div className="row w-100">
            <div className="col">
              <ButtonGroup disableElevation color="primary" className="mb-3">
                <Button
                  variant={selectedMenu === "ringkasan" && "contained"}
                  onClick={() => setSelectedMenu("ringkasan")}
                >
                  Ringkasan
                </Button>
                <Button
                  variant={selectedMenu === "detail" && "contained"}
                  onClick={() => setSelectedMenu("detail")}
                >
                  Detail Respon
                </Button>
              </ButtonGroup>
              {selectedMenu === "ringkasan" && <RingkasanResponPolling />}
              {selectedMenu === "detail" && <DetailResponPolling />}
            </div>
          </div>
        </RightContainer>
      </Row>
    </Container>
  );
};

const mapStateToProps = ({
  pollingAdmin: { data, pending, detailDataQuestion },
}) => {
  const detailData = data?.data?.data?.length === 1 ? data.data.data[0] : null;

  return { data, detailData, pending, detailDataQuestion };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetPollingAdminModal: (modalType, isOpen) =>
      dispatch(setPollingAdminModal(modalType, isOpen)),
    onSetPollingAdminData: (data) => dispatch(setPollingAdminData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
