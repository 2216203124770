import React from "react";
import { connect } from "react-redux";
import { Modal } from "reactstrap";
import { setRequestModal } from "../../../../store/actions/anggota/permintaan";
import Approve from "./approve";
import Delete from "./delete";
import EditProfile from "./edit-profile";
import Reject from "./reject";
import ShowDocument from "./show-document";
import ShowPhoto from "./show-photo";

const index = ({
  isOpenModal,
  modalType,
  onSetRequestModal,
  handleRefresh,
  title,
  fileName,
  folderName,
}) => {
  const toggle = () => onSetRequestModal("", !isOpenModal);
  const child = (modalType) => {
    switch (modalType) {
      case "approve":
        return <Approve />;
      case "delete":
        return <Delete handleRefresh={handleRefresh} />;
      case "edit-profile":
        return <EditProfile />;
      case "reject":
        return <Reject />;
      case "show-document":
        return (
          <ShowDocument
            title={title}
            fileName={fileName}
            folderName={folderName}
          />
        );
      case "show-photo":
        return (
          <ShowPhoto
            title={title}
            fileName={fileName}
            folderName={folderName}
          />
        );
    }
  };
  return (
    <Modal
      className={modalType === "show-document" ? "modal-large" : "modal-small"}
      isOpen={isOpenModal}
      toggle={toggle}
      centered
    >
      {child(modalType)}
    </Modal>
  );
};

const mapStateToProps = ({
  request: { isOpenModal, modalType, title, folderName, fileName },
}) => {
  return { isOpenModal, modalType, folderName, fileName, title };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetRequestModal: (type, isOpen) =>
      dispatch(setRequestModal(type, isOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
