import API from "../../API";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
import { setToastModal } from "../../../actions";
import { toastError, toastSuccess } from "../../../../components/commons/toast";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_IURAN_ADMIN_PENDING = "GET_IURAN_ADMIN_PENDING";
export const GET_IURAN_ADMIN_SUCCESS = "GET_IURAN_ADMIN_SUCCESS";
export const GET_IURAN_ADMIN_ERROR = "GET_IURAN_ADMIN_ERROR";
export const POST_IURAN_ADMIN_PENDING = "POST_IURAN_ADMIN_PENDING";
export const POST_IURAN_ADMIN_SUCCESS = "POST_IURAN_ADMIN_SUCCESS";
export const POST_IURAN_ADMIN_ERROR = "POST_IURAN_ADMIN_ERROR";
export const PUT_IURAN_ADMIN_PENDING = "PUT_IURAN_ADMIN_PENDING";
export const PUT_IURAN_ADMIN_SUCCESS = "PUT_IURAN_ADMIN_SUCCESS";
export const PUT_IURAN_ADMIN_ERROR = "PUT_IURAN_ADMIN_ERROR";
export const DELETE_IURAN_ADMIN_PENDING = "DELETE_IURAN_ADMIN_PENDING";
export const DELETE_IURAN_ADMIN_SUCCESS = "DELETE_IURAN_ADMIN_SUCCESS";
export const DELETE_IURAN_ADMIN_ERROR = "DELETE_IURAN_ADMIN_ERROR";

export const IURAN_ADMIN_DATA = "IURAN_ADMIN_DATA";
export const SET_IURAN_ADMIN_MODAL = "SET_IURAN_ADMIN_MODAL";

// URL: URL_{URL}
const IURAN_ADMIN_URL = "/v1/member_dues";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_IURAN_ADMIN_PENDING));
  API.get(IURAN_ADMIN_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_IURAN_ADMIN_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_IURAN_ADMIN_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const getSetoran = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_IURAN_ADMIN_PENDING));
  API.get(IURAN_ADMIN_URL + "/find_for_deposit", { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_IURAN_ADMIN_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_IURAN_ADMIN_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_IURAN_ADMIN_PENDING));
  API.post(IURAN_ADMIN_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_IURAN_ADMIN_SUCCESS, res));
      toastSuccess("Iuran berhasil ditambahkan");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_IURAN_ADMIN_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_IURAN_ADMIN_PENDING));
  API.put(IURAN_ADMIN_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_IURAN_ADMIN_SUCCESS, res));
      toastSuccess("Iuran berhasil diubah");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_IURAN_ADMIN_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_IURAN_ADMIN_PENDING));
  API.delete(IURAN_ADMIN_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_IURAN_ADMIN_SUCCESS, res));
      toastSuccess("Iuran berhasil dihapus");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_IURAN_ADMIN_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const IuranAdmin = {
  get,
  post,
  put,
  deleted,
  getSetoran,
};
export default IuranAdmin;

export const setIuranAdminData = (data) => (dispatch) =>
  dispatch({ type: IURAN_ADMIN_DATA, data });

export const setIuranAdminModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_IURAN_ADMIN_MODAL, {
      modalType: modalType,
      isOpen: isOpen,
    })
  );
