import { useEffect, useMemo, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import Container from "../../../../components/container";
import LeftContainer from "../../../../components/container/LeftContainer";
import RightContainer from "../../../../components/container/RightContainer";
import DetailQuestion from "../../../../components/detail-content/list-question";
import PollingMember, {
  setPollingMemberData,
  setPollingMemberModal,
  setPollingMemberStep,
} from "../../../../store/actions/polling/member";
import { Row } from "simple-flexbox";
import { Button } from "@material-ui/core";
import moment from "moment";
import { reduxForm } from "redux-form";
import Pagination from "@material-ui/lab/Pagination";
import { toastError } from "../../../../components/commons/toast";
import { history } from "../../../../utils";
import { StyleSheet, css } from "aphrodite";
import Modal from "../modal";

let Index = ({
  onSetPollingMemberModal,
  onSetPollingMemberData,
  detailData,
  detailDataQuestion,
  handleSubmit,
  pending,
  invalid,
}) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const pageSize = 5;
  const length =
    detailData?.length > 0 ? Math.ceil(detailData.length / pageSize) : 1;

  const id = window.location.pathname.split("/").pop();

  const [heightDetail, setHeightDetail] = useState(0)
  const detailRef = useRef(null)
  const [modalButtonDetail, setModalButtonDetail] = useState(0)
  const modalButtonDetailRef = useRef(null)
  const [showButtonDetail, setShowButtonDetail] = useState(false);

  const onSubmit = (value) => {
    let name = "number";
    let other = "other_";
    let check = "_checkbox";
    const id = window.location.pathname.split("/").pop();
    let answer = [];
    detailData?.map((item, index) => {
      let number_question = index + 1;
      if (item.type === 1) {
        answer.push({
          is_extra_text: 0,
          label: null,
          number_answer: 0,
          question_id: item.id,
          text_answer: value[name + number_question],
          type: 1,
        });
      }
      if (item.type === 2) {
        if (value[name + number_question] === "other") {
          answer.push({
            is_extra_text: 1,
            label: "Other",
            number_answer: item.multiple_questions.length,
            question_id: item.id,
            text_answer: value[other + name + number_question],
            type: 2,
          });
        } else {
          let indexLabel = item.multiple_questions.findIndex(
            (item) => item.key === value[name + number_question]
          );
          let label = item.multiple_questions[indexLabel].value;
          answer.push({
            is_extra_text: 0,
            label: label,
            number_answer: value[name + number_question],
            question_id: item.id,
            text_answer: null,
            type: 2,
          });
        }
      }
      if (item.type === 3) {
        if (value[name + number_question]) {
          value[name + number_question].map((x, i) => {
            if (x) {
              answer.push({
                is_extra_text: 0,
                label: item.multiple_questions[i].value,
                number_answer: item.multiple_questions[i].key,
                question_id: item.id,
                text_answer: null,
                type: 3,
              });
            }
          });
        }
        if (value[other + name + number_question + check]) {
          answer.push({
            is_extra_text: 1,
            label: "Other",
            number_answer: item.multiple_questions.length,
            question_id: item.id,
            text_answer: value[other + name + number_question],
            type: 3,
          });
        }
      }
      if (item.type === 4) {
        answer.push({
          is_extra_text: 0,
          label: null,
          number_answer: value[name + number_question],
          question_id: item.id,
          text_answer: null,
          type: 4,
        });
      }
    });
    const param = {
      polling_id: id,
      answer_list: answer,
    };
    const callback = () => {
      history.push("/polling");
      // handleRefresh();
    };
    dispatch(PollingMember.post(param, callback));
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    getListQuestion();
  }, []);

  const getListQuestion = () =>
    dispatch(PollingMember.getDetail({ polling_id: id, length: 99 }));

  const minQuestions = useMemo(() => (page - 1) * pageSize, [page, pageSize]);
  const maxQuestions = useMemo(() => page * pageSize, [page, pageSize]);

  const setModal = (modalType, isOpen, data) => {
    onSetPollingMemberModal(modalType, isOpen);
    onSetPollingMemberData(data);
  };

  useEffect(() => {
    setHeightDetail(detailRef.current.clientHeight)
  })

  useEffect(() => {
    setModalButtonDetail(modalButtonDetailRef)
    if (detailRef.current !== null && heightDetail >= 75) {
      setShowButtonDetail(true)
    }
  })

  const setClassButtonDetail = () => {
    if (showButtonDetail) {
      return css(styles.maxThreeRow)
    }
    return css(styles.maxNoneRow)
  };

  return (
    <Container>
    <Modal />
      <Row>
        <LeftContainer>
          <div className="row">
            <div className="col">
              <div style={{ marginBottom: 30 }}>
                <h6 style={{ fontWeight: "bold" }}>Nama Polling</h6>
                <span>
                  {detailDataQuestion ? detailDataQuestion.title : "-"}
                </span>
              </div>
              <div style={{ marginBottom: 15 }}>
                <h6 style={{ fontWeight: "bold" }}>Deskripsi Polling</h6>
                <span
                  className={setClassButtonDetail()}
                  ref={detailRef}
                  >
                    {detailDataQuestion?.description ? (
                    <div dangerouslySetInnerHTML={{ __html: detailDataQuestion?.description }}></div>
                  ) : (
                    "-"
                  )}
                </span>
                {(showButtonDetail) && (
                  <div className="pt-2" style={{ marginBottom: 30 }}>
                    <span 
                      ref={modalButtonDetailRef}
                      className={css(styles.seeMoreLabel)}
                      onClick={() => setModal("detailDeskripsi", true, detailDataQuestion)}
                      >
                      Lihat Selengkapnya
                    </span>
                  </div>
                )}
              </div>
              <div style={{ marginBottom: 30 }}>
                <h6 style={{ fontWeight: "bold" }}>Tanggal Mulai</h6>
                <span>
                  {detailDataQuestion
                    ? moment(detailDataQuestion.start_date).format(
                        "DD MMM YYYY"
                      )
                    : "-"}
                </span>
              </div>
              <div style={{ marginBottom: 30 }}>
                <h6 style={{ fontWeight: "bold" }}>Tanggal Berakhir</h6>
                <span>
                  {detailDataQuestion
                    ? moment(detailDataQuestion.end_date).format("DD MMM YYYY")
                    : "-"}
                </span>
              </div>
              <div style={{ marginBottom: 30 }}>
                <h6 style={{ fontWeight: "bold" }}>Total Pertanyaan</h6>
                <span>{detailData ? detailData.length : "-"}</span>
              </div>
            </div>
          </div>
        </LeftContainer>
        <RightContainer>
          <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex justify-content-end mb-3">
              {page === length && detailData && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={pending}
                  type="submit"
                  onClick={() => {
                    if (invalid) {
                      toastError("Data masih ada yang belum diisi!");
                    }
                  }}
                >
                  Submit
                </Button>
              )}
            </div>
            <div className="row">
              <div className="col">
                {detailData?.length > 0 ? (
                  <>
                    {detailData?.map((item, index) => {
                      return (
                        <DetailQuestion
                          key={index}
                          data={item}
                          show={index >= minQuestions && index < maxQuestions}
                        />
                      );
                    })}
                    <div className="d-flex justify-content-end">
                      <Pagination
                        shape="rounded"
                        color="primary"
                        count={length}
                        page={page}
                        onChange={handleChange}
                      />
                    </div>
                  </>
                ) : (
                  <div>Tidak ada data</div>
                )}
              </div>
            </div>
          </form>
        </RightContainer>
      </Row>
    </Container>
  );
};

const validate = (value, { detailData }) => {
  const errors = {};
  let name = "number";
  let other = "other_";
  let check = "_checkbox";
  if (detailData) {
    for (let i = 1; i <= detailData.length; i++) {
      if (detailData[i - 1].type === 1) {
        if (!value[name + i]) {
          errors[name + i] = "Pertanyaan harus diisi";
        }
      } else if (detailData[i - 1].type === 2) {
        if (!value[name + i]) {
          errors[name + i] = "Pertanyaan harus dipilih";
        } else if (value[name + i] === "other" && !value[other + name + i]) {
          errors[other + name + i] = "Pertanyaan harus diisi";
        }
      } else if (detailData[i - 1].type === 3) {
        let hasOther = detailData[i - 1].multiple_questions.some(
          (item) => item.value === "Other"
        );
        if (hasOther) {
          if (
            (!value[name + i] || !value[name + i].includes(true)) &&
            !value[other + name + i + check]
          ) {
            errors[other + name + i + check] = "Pertanyaan harus diisi";
          } else if (
            value[other + name + i + check] &&
            !value[other + name + i]
          ) {
            errors[other + name + i] = "Pertanyaan harus diisi";
          }
        } else if (!value[name + i] || !value[name + i].includes(true)) {
          let err = [...Array(detailData[i - 1].multiple_questions.length)];
          err[detailData[i - 1].multiple_questions.length - 1] =
            "Pertanyaan harus diisi";
          errors[name + i] = err;
        }
      } else if (detailData[i - 1].type === 4) {
        if (!value[name + i]) {
          errors[name + i] = "Pertanyaan harus diisi";
        }
      }
    }
  }
  return errors;
};

Index = reduxForm({
  form: "memberPolling",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(Index);

const mapStateToProps = ({
  pollingMember: { data, detailData: detailDataQuestion, pending, step },
}) => {
  const detailData = data?.data?.data?.length > 0 ? data.data.data : null;

  return { detailData, detailDataQuestion, pending, step };
};

const styles = StyleSheet.create({
  seeMoreLabel: {
    color: "#2CB96A",
    fontSize: 14,
    cursor: "pointer",
    paddingTop: 10,
  },
  maxThreeRow: {
    display: "-webkit-box",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  maxNoneRow: {
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  }
});

const mapDispatchToProps = (dispatch) => {
  return {
    onSetPollingMemberModal: (modalType, isOpen) =>
      dispatch(setPollingMemberModal(modalType, isOpen)),
    onSetPollingMemberData: (data) => dispatch(setPollingMemberData(data)),
    onSetPollingMemberStep: (step) => dispatch(setPollingMemberStep(step)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
