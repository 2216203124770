import React from "react";

export default (props) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      opacity={props.opacity || ""}
      fill={props.fill || "#FFFFFF"}
      transform="translate(-4, 0)"
    >
      <path
        d="M13.477,10.781H6.768A14.624,14.624,0,0,0,12,9.9a6.516,6.516,0,0,0,1.472-.8V10.78Zm-6.976,0H0V9.1a6.692,6.692,0,0,0,1.474.8,14.487,14.487,0,0,0,5.027.883Zm.238-1.347C3.057,9.434.055,8.246,0,6.778V3.707a6.654,6.654,0,0,0,1.473.8,14.622,14.622,0,0,0,5.265.885A14.627,14.627,0,0,0,12,4.505a6.518,6.518,0,0,0,1.472-.8V6.738C13.477,8.224,10.454,9.434,6.739,9.434Zm0-5.391C3.068,4.043.074,2.863,0,1.4V0H13.477V1.348C13.477,2.834,10.454,4.043,6.739,4.043Z"
        transform="translate(6.749 7.195)"
      />
      <path
        d="M20.226,25.806c0,1.489-3.017,2.7-6.738,2.7s-6.738-1.207-6.738-2.7,3.017,0,6.738,0,6.738-1.489,6.738,0Z"
        transform="translate(0 -7.829)"
      />
      <path d="M20.226,7.2c0,1.489-3.018,2.7-6.738,2.7S6.75,8.684,6.75,7.2s3.018-2.7,6.738-2.7S20.226,5.706,20.226,7.2Z" />
    </g>
  </svg>
);
