import { StyleSheet, css } from "aphrodite";
import { Column, Row } from "simple-flexbox";
import { connect } from "react-redux";
import Avatar from "../../../../components/profile/member";
import AvatarButton from "../../../../components/profile/avatar-button";
import { MenuItem } from "@material-ui/core";
import IconCV from "../../../../assets/icon/icon-downloadcv";
import Modal from "../modal";
import Request, {
  setRequestData,
  setRequestModal,
  setAddRequestData,
} from "../../../../store/actions/anggota/permintaan";

const Profile = ({ onSetRequestModal, img_profile, name, description }) => {
  const path = window.location.pathname.split("/");
  const setModal = (modalType, isOpen, data) => {
    onSetRequestModal(modalType, isOpen);
  };

  let names = name ? name : "-";
  let descriptions = description ? description : "-";
  return (
    <Column style={{ width: "100%" }} horizontal="center">
      <Modal />
      <div
        style={{
          width: 100,
          height: 100,
          marginBottom: 20,
        }}
      >
        <Avatar image={img_profile} />
      </div>
      <h5 style={{ textAlign: "center", marginBottom: 20 }}>{names}</h5>
    </Column>
  );
};

const styles = StyleSheet.create({
  clickableIcon: {
    ":hover": {
      cursor: "pointer",
    },
  },
});

const mapStateToProps = ({ request: { data, detailData } }) => {
  return { data, detailData };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetRequestModal: (modalType, isOpen) =>
      dispatch(setRequestModal(modalType, isOpen)),
    onSetAddRequestData: (data) => dispatch(setAddRequestData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
