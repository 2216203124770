import React from "react";

export default (props) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      opacity={props.opacity || ""}
      fill={props.fill || "#FFFFFF"}
      transform="translate(5, 0)"
    >
      <path d="M8.491 17.456H0.608C0.45425 17.4564 0.30605 17.3986 0.193238 17.2941C0.0804255 17.1897 0.011379 17.0464 0 16.893L0 5.06802C0 4.98818 0.0157264 4.90912 0.0462812 4.83535C0.0768361 4.76158 0.121621 4.69456 0.178079 4.6381C0.234537 4.58164 0.301563 4.53686 0.375328 4.5063C0.449094 4.47575 0.528156 4.46002 0.608 4.46002H8.491C8.57084 4.46002 8.64991 4.47575 8.72367 4.5063C8.79744 4.53686 8.86446 4.58164 8.92092 4.6381C8.97738 4.69456 9.02216 4.76158 9.05272 4.83535C9.08327 4.90912 9.099 4.98818 9.099 5.06802V16.893C9.08762 17.0464 9.01857 17.1897 8.90576 17.2941C8.79295 17.3986 8.64475 17.4564 8.491 17.456ZM1.346 5.87502V7.00102C1.346 7.00102 3.574 8.90102 4.399 8.90102C5.224 8.90102 7.851 7.00102 7.851 7.00102V5.87502H1.346Z" />
      <path d="M11.3181 13.97H10.1921V3.37801H3.43506V2.25201H10.7551C10.9044 2.25201 11.0476 2.31133 11.1532 2.41691C11.2587 2.5225 11.3181 2.6657 11.3181 2.81501V13.97Z" />
      <path d="M13.5593 11.262H12.4333V1.126H6.23926V0H12.9963C13.1456 0 13.2888 0.0593159 13.3944 0.164899C13.4999 0.270482 13.5593 0.413683 13.5593 0.563V11.262Z" />
    </g>
  </svg>
);
