import React from "react";

export default (props) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={props.opacity || ""}
      d="M.666,13A.649.649,0,0,1,.2,12.807.613.613,0,0,1,0,12.35V.65A.617.617,0,0,1,.2.193.644.644,0,0,1,.666,0H19.334A.649.649,0,0,1,19.8.193.612.612,0,0,1,20,.65v11.7a.618.618,0,0,1-.2.457.646.646,0,0,1-.469.193Zm.667-3.9a2.6,2.6,0,0,1,1.886.761A2.47,2.47,0,0,1,4,11.7H16a2.476,2.476,0,0,1,.781-1.838A2.6,2.6,0,0,1,18.667,9.1V3.9a2.6,2.6,0,0,1-1.885-.761A2.472,2.472,0,0,1,16,1.3H4a2.476,2.476,0,0,1-.781,1.838A2.6,2.6,0,0,1,1.333,3.9ZM10.8,10.285V9.459h.619V5.908H10.9V5.082h1.69v.45a1.493,1.493,0,0,1,1.094-.506,1.355,1.355,0,0,1,1.149.565,2.148,2.148,0,0,1,.42,1.316,1.994,1.994,0,0,1-.433,1.318,1.424,1.424,0,0,1-1.155.522A1.55,1.55,0,0,1,12.588,8.3V9.459h.736v.826Zm1.988-4.1a1.062,1.062,0,0,0-.207.713,1.074,1.074,0,0,0,.2.685.663.663,0,0,0,.547.259.651.651,0,0,0,.517-.234,1.094,1.094,0,0,0,.2-.726,1.06,1.06,0,0,0-.2-.7.658.658,0,0,0-.526-.233A.677.677,0,0,0,12.783,6.188ZM9.076,8.667,7.883,6.412h-.6V7.8h.661v.87H5.178V7.8H5.9V4.386H5.178v-.87H8.365a1.665,1.665,0,0,1,1.3.443,1.458,1.458,0,0,1,.4,1,1.34,1.34,0,0,1-.915,1.281L10,7.8h.683v.87ZM7.278,5.59h.471q.874,0,.874-.607a.522.522,0,0,0-.2-.448.9.9,0,0,0-.549-.148h-.6Z"
      fill={props.fill || "#FFFFFF"}
      transform="translate(2, 4)"
    />
  </svg>
);
