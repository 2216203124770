import {
  GET_POLLING_MEMBER_PENDING,
  GET_POLLING_MEMBER_SUCCESS,
  GET_POLLING_MEMBER_ERROR,
  GET_DETAIL_POLLING_MEMBER_PENDING,
  GET_DETAIL_POLLING_MEMBER_SUCCESS,
  GET_DETAIL_POLLING_MEMBER_ERROR,
  POST_POLLING_MEMBER_PENDING,
  POST_POLLING_MEMBER_SUCCESS,
  POST_POLLING_MEMBER_ERROR,
  PUT_POLLING_MEMBER_PENDING,
  PUT_POLLING_MEMBER_SUCCESS,
  PUT_POLLING_MEMBER_ERROR,
  DELETE_POLLING_MEMBER_PENDING,
  DELETE_POLLING_MEMBER_SUCCESS,
  DELETE_POLLING_MEMBER_ERROR,
  POLLING_MEMBER_DATA,
  POLLING_MEMBER_DATA_QUESTION,
  SET_POLLING_MEMBER_MODAL,
  SET_POLLING_MEMBER_STEP,
} from "../../../actions/polling/member";

const initialState = {
  pending: false,
  error: null,
  data: null,
  detailData: null,
  isOpenModal: false,
  modalType: "",
  detailDataQuestion: null,
  step: 1,
};

const pollingMember = (state = initialState, action) => {
  switch (action.type) {
    case GET_POLLING_MEMBER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_POLLING_MEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_POLLING_MEMBER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case GET_DETAIL_POLLING_MEMBER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_DETAIL_POLLING_MEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_DETAIL_POLLING_MEMBER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POST_POLLING_MEMBER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case POST_POLLING_MEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case POST_POLLING_MEMBER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case PUT_POLLING_MEMBER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case PUT_POLLING_MEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case PUT_POLLING_MEMBER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case DELETE_POLLING_MEMBER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_POLLING_MEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case DELETE_POLLING_MEMBER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POLLING_MEMBER_DATA:
      return {
        ...state,
        detailData: action.data,
      };
    case POLLING_MEMBER_DATA_QUESTION:
      return {
        ...state,
        detailDataQuestion: action.data,
      };
    case SET_POLLING_MEMBER_MODAL:
      return {
        ...state,
        isOpenModal: action.data.isOpen,
        modalType: action.data.modalType,
      };
    case SET_POLLING_MEMBER_STEP:
      return {
        ...state,
        step: action.step,
      };
    default:
      return state;
  }
};

export default pollingMember;
