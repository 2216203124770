import API from "../API";
import { actionPending, actionSuccess, actionError } from "../actionTypes";
import { setToastModal } from "..";
import { toastError, toastSuccess } from "../../../components/commons/toast";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_MESSAGE_BLAST_PENDING = "GET_MESSAGE_BLAST_PENDING";
export const GET_MESSAGE_BLAST_SUCCESS = "GET_MESSAGE_BLAST_SUCCESS";
export const GET_MESSAGE_BLAST_ERROR = "GET_MESSAGE_BLAST_ERROR";
export const GET_DETAIL_MESSAGE_BLAST_PENDING =
  "GET_DETAIL_MESSAGE_BLAST_PENDING";
export const GET_DETAIL_MESSAGE_BLAST_SUCCESS =
  "GET_DETAIL_MESSAGE_BLAST_SUCCESS";
export const GET_DETAIL_MESSAGE_BLAST_ERROR = "GET_DETAIL_MESSAGE_BLAST_ERROR";
export const POST_MESSAGE_BLAST_PENDING = "POST_MESSAGE_BLAST_PENDING";
export const POST_MESSAGE_BLAST_SUCCESS = "POST_MESSAGE_BLAST_SUCCESS";
export const POST_MESSAGE_BLAST_ERROR = "POST_MESSAGE_BLAST_ERROR";
export const PUT_MESSAGE_BLAST_PENDING = "PUT_MESSAGE_BLAST_PENDING";
export const PUT_MESSAGE_BLAST_SUCCESS = "PUT_MESSAGE_BLAST_SUCCESS";
export const PUT_MESSAGE_BLAST_ERROR = "PUT_MESSAGE_BLAST_ERROR";
export const DELETE_MESSAGE_BLAST_PENDING = "DELETE_MESSAGE_BLAST_PENDING";
export const DELETE_MESSAGE_BLAST_SUCCESS = "DELETE_MESSAGE_BLAST_SUCCESS";
export const DELETE_MESSAGE_BLAST_ERROR = "DELETE_MESSAGE_BLAST_ERROR";

export const MESSAGE_BLAST_DATA = "MESSAGE_BLAST_DATA";
export const SET_MESSAGE_BLAST_MODAL = "SET_MESSAGE_BLAST_MODAL";

// URL: URL_{URL}
const MESSAGE_BLAST_URL = "/v1/message_blast";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_MESSAGE_BLAST_PENDING));
  API.get(MESSAGE_BLAST_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_MESSAGE_BLAST_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_MESSAGE_BLAST_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const getDetail = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DETAIL_MESSAGE_BLAST_PENDING));
  API.get(MESSAGE_BLAST_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DETAIL_MESSAGE_BLAST_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DETAIL_MESSAGE_BLAST_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_MESSAGE_BLAST_PENDING));
  API.post(MESSAGE_BLAST_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_MESSAGE_BLAST_SUCCESS, res));
      toastSuccess("Message Blast berhasil ditambahkan");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_MESSAGE_BLAST_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_MESSAGE_BLAST_PENDING));
  API.put(MESSAGE_BLAST_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_MESSAGE_BLAST_SUCCESS, res));
      toastSuccess("Message Blast berhasil diubah");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_MESSAGE_BLAST_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_MESSAGE_BLAST_PENDING));
  API.delete(MESSAGE_BLAST_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_MESSAGE_BLAST_SUCCESS, res));
      toastSuccess("Message Blast berhasil dihapus");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_MESSAGE_BLAST_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const MessageBlast = { get, post, put, deleted, getDetail };
export default MessageBlast;

export const setMessageBlastData = (data) => (dispatch) =>
  dispatch({ type: MESSAGE_BLAST_DATA, data });

export const setMessageBlastModal =
  (modalType, isOpen, title, folderName, fileName) => (dispatch) =>
    dispatch(
      actionSuccess(SET_MESSAGE_BLAST_MODAL, {
        modalType: modalType,
        isOpen: isOpen,
        title: title,
        folderName: folderName,
        fileName: fileName,
      })
    );
