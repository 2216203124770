import React, { useState } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { ModalBody, ModalHeader } from "reactstrap";
import { Button } from "@material-ui/core";
import { formInput } from "../../../components/commons/form";
import { setMessageBlastModal } from "../../../store/actions/message-blast";
import LabelInputVerticalComponent from "../../../components/global-components/LabelInputVertical";

let detail = ({ onSetMessageBlastModal, pending }) => {
  return (
    <>
      <ModalHeader>Lihat Wilayah</ModalHeader>
      <ModalBody>
        <form>
          <LabelInputVerticalComponent label="Kode Wilayah">
            <Field
              name="code"
              placeholder="Kode Wilayah"
              component={formInput}
              disabled
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Nama Wilayah">
            <Field
              name="name"
              placeholder="Nama Wilayah"
              component={formInput}
              disabled
            />
          </LabelInputVerticalComponent>
        </form>
        <Button
          variant="outlined"
          className="mt-3"
          disabled={pending}
          color="primary"
          onClick={() => onSetMessageBlastModal("", false)}
        >
          Kembali
        </Button>
      </ModalBody>
    </>
  );
};

detail = reduxForm({
  form: "messageBlastDetail",
})(detail);

const mapStateToProps = ({ messageBlast: { detailData, pending } }) => {
  let initialValues = {};
  if (detailData) {
    initialValues = {
      code: detailData.code,
      name: detailData.name,
    };
  }
  return {
    initialValues,
    pending,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetMessageBlastModal: (modalType, isOpen) =>
      dispatch(setMessageBlastModal(modalType, isOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(detail);
