import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Container from "../../components/container";
import LeftContainer from "../../components/container/LeftContainer";
import RightContainer from "../../components/container/RightContainer";
import DetailMenu from "../../components/global-components/DetailMenu";
import DetailStatus from "../../components/global-components/DetailStatus";
import IconDot from "../../assets/icon/icon-dot";
import IconCreate from "../../assets/icon/icon-create";
import Member, {
  setMemberData,
  setMemberModal,
} from "../../store/actions/anggota/member";
import Profile from "./profile";
import { Row, Column } from "simple-flexbox";
import Modal from "./modal";
import DataPerusahaan from "./data-perusahaan";
import AdministrasiPerusahaan from "./administrasi-perusahaan";
import KelengkapanBerkas from "./kelengkapan-berkas";
import { getUser } from "../../utils/user";
import { StyleSheet, css } from "aphrodite";
import CircularProgress from "@material-ui/core/CircularProgress";
import { LOGO_INSTITUTE } from "../../utils/constant";
import IuranMember from "../../store/actions/iuran/member";

let Index = ({ detailData, pending, iuranMember }) => {
  const [selectedMenu, setSelectedMenu] = useState("data-perusahaan");
  const dispatch = useDispatch();
  const user = getUser();

  useEffect(() => {
    getDetailMember();
    if (user) getIuran();
  }, []);

  let isMemberActive = false;
  if (iuranMember.data) {
    if (
      typeof iuranMember.data.data.data === "object" &&
      iuranMember.data.data.data.length > 1
    ) {
      isMemberActive = true;
    } else if (
      iuranMember.data.data.data.length > 0 &&
      iuranMember.data.data.data[0].status === 2
    ) {
      isMemberActive = true;
    }
  }
  const getIuran = () => dispatch(IuranMember.get());
  const getDetailMember = () => dispatch(Member.getDetail({ id: user.id }));
  let status = detailData?.member_profile_data?.status_active;

  return (
    <Container>
      <Modal />
      <Row>
        <LeftContainer horizontal="center">
          {pending ? (
            <CircularProgress color="primary" />
          ) : (
            <>
              <Profile
                img_profile={
                  detailData?.member_profile_data?.logo_filename
                    ? `${process.env.REACT_APP_GAKESLAB_URL}/v1/file/${LOGO_INSTITUTE}/${detailData.member_profile_data.logo_filename}`
                    : ""
                }
                name={detailData?.name}
              />
              <DetailStatus>
                <Row
                  className={
                    status === 0
                      ? css(styles.detailMemberStatusNonactiveID)
                      : css(styles.detailMemberStatusActiveID)
                  }
                >
                  <span
                    className={
                      status === 0
                        ? css(styles.detailMemberStatusNonactiveIDFont)
                        : css(styles.detailMemberStatusActiveIDFont)
                    }
                  >
                    ID{" "}
                    {detailData?.member_profile_data?.kta_no
                      ? detailData.member_profile_data.kta_no
                      : "-"}
                  </span>
                </Row>

                <Row
                  className={
                    status === 0
                      ? css(styles.detailMemberStatusNonactiveButton)
                      : css(styles.detailMemberStatusActiveButton)
                  }
                >
                  <span className={css(styles.detailMemberStatusButtonFont)}>
                    {status === 0 ? "Nonaktif" : "Aktif"}
                  </span>{" "}
                </Row>
              </DetailStatus>
              <DetailMenu
                onClick={() => setSelectedMenu("data-perusahaan")}
                menuName="Data Perusahaan"
                isActive={selectedMenu === "data-perusahaan"}
              />
              <DetailMenu
                onClick={() => setSelectedMenu("administrasi-perusahaan")}
                menuName="Administrasi Perusahaan"
                isActive={selectedMenu === "administrasi-perusahaan"}
              />
            </>
          )}
        </LeftContainer>
        <RightContainer>
          {selectedMenu === "data-perusahaan" ? (
            <DataPerusahaan isMemberActive={isMemberActive} />
          ) : selectedMenu === "administrasi-perusahaan" ? (
            <AdministrasiPerusahaan isMemberActive={isMemberActive} />
          ) : selectedMenu === "kelengkapan-berkas" ? (
            <KelengkapanBerkas isMemberActive={isMemberActive} />
          ) : (
            <div></div>
          )}
        </RightContainer>
      </Row>
    </Container>
  );
};

const mapStateToProps = ({
  member: { data, detailData, pending },
  region,
  iuranMember,
}) => {
  return { data, detailData, pending, region, iuranMember };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetMemberModal: (modalType, isOpen) =>
      dispatch(setMemberModal(modalType, isOpen)),
    onSetMemberData: (data) => dispatch(setMemberData(data)),
  };
};

const styles = StyleSheet.create({
  detailMemberStatusNonactiveID: {
    padding: "10px 20px",
    border: "2px solid #ff3232",
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  detailMemberStatusActiveID: {
    padding: "10px 20px",
    border: "2px solid #2CB96A",
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  detailMemberStatusNonactiveButton: {
    padding: "10px",
    backgroundColor: "#ff3232",
    border: "2px solid #ff3232",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    height: "100%",
    alignItems: "center",
  },
  detailMemberStatusActiveButton: {
    padding: "10px",
    backgroundColor: "#2CB96A",
    border: "2px solid #2CB96A",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    height: "100%",
    alignItems: "center",
  },
  detailMemberStatusNonactiveIDFont: {
    color: "#ff3232",
  },
  detailMemberStatusActiveIDFont: {
    color: "#2CB96A",
  },
  detailMemberStatusButtonFont: {
    color: "#FFFFFF",
    marginRight: 20,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
