import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import CanvasJSReact from "../../../assets/canvas/canvasjs.react";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Index = ({ center, zoom, data }) => {
  // let data = [];
  let result = null;

  // const data = [
  //   {
  //     label: 2017,
  //     y: 40,
  //   },
  //   {
  //     label: 2018,
  //     y: 50,
  //   },
  //   {
  //     label: 2019,
  //     y: 60,
  //   },
  //   {
  //     label: 2020,
  //     y: 70,
  //   },
  //   {
  //     label: 2021,
  //     y: 30,
  //   },
  // ];
  const dataStatistic = data?.map((item) => {
    return {
      label: item.year,
      y: item.total_member,
    };
  });
  const options = {
    animationEnabled: true,
    height: 240,
    axisX: {
      valueFormatString: "YYYY",
    },
    axisY: {
      includeZero: true,
      gridThickness: 0,
    },
    data: [
      {
        xValueFormatString: "YYYY",
        type: "splineArea",
        lineColor: "#FFC156",
        color: "#fdf1dc",
        toolTipContent: "<span style='\"'color: black;'\"'>{label}: {y}</span>",
        lineThickness: 8,
        markerColor: "white",
        markerBorderColor: "#FFC156",
        markerBorderThickness: 4,
        markerSize: 10,
        dataPoints: dataStatistic,
      },
    ],
  };
  result = (
    <CanvasJSChart
      options={options}
      /* onRef={ref => this.chart = ref} */
    />
  );

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Column
        style={{
          boxShadow: "0px 3px 6px #00000029",
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
          height: "100%",
          width: "100%",
          minHeight: 300,
          backgroundColor: "white",
        }}
      >
        <div className={css(styles.cardHeader)}></div>
        <Row
          className="border-bottom"
          style={{
            width: "100%",
            padding: "10px 20px",
            backgroundColor: "white",
          }}
          vertical="center"
          horizontal="space-between"
        >
          <label className={css(styles.titleLabel)}>
            Statistik Anggota Gakeslab
          </label>
        </Row>
        <Column
          className={css(styles.wrapper)}
          style={{
            width: "100%",
            padding: "0 20px 30px",
            backgroundColor: "red",
          }}
          horizontal="center"
        >
          {result && result !== null ? result : "Loading.."}
        </Column>
      </Column>
    </div>
  );
};

const styles = StyleSheet.create({
  titleLabel: {
    color: "#495057",
    fontSize: 18,
    fontWeight: "bold",
    margin: "5px 0",
  },
  cardLabel: {
    color: "#495057",
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 4,
  },
  wrapper: {
    // padding: 15,
    // height: 400,
    backgroundColor: "white",
  },
  borderBottom: {
    borderBottom: "1px solid #eeee",
  },
  cardHeader: {
    backgroundColor: "#FFC156",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    height: 10,
    width: "100%",
  },
});

export default Index;
