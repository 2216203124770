import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "../marker";

const Index = ({ center, zoom, dataMember }) => {
  // const data = [
  //   { latitude: -7.95715, longitude: 112.609 },
  //   { latitude: -7.95715, longitude: 112.609 },
  //   { latitude: -7.95589, longitude: 112.612 },
  //   { latitude: -7.63149, longitude: 111.518 },
  //   { latitude: -7.95715, longitude: 112.609 },
  //   { latitude: -7.94428, longitude: 112.621 },
  //   { latitude: -7.60113, longitude: 111.427 },
  //   { latitude: -7.94041, longitude: 112.625 },
  //   { latitude: -6.21352, longitude: 106.708 },
  //   { latitude: -7.63049, longitude: 111.518 },
  //   { latitude: -7.94041, longitude: 112.625 },
  //   { latitude: -7.95715, longitude: 112.609 },
  //   { latitude: -7.63049, longitude: 111.518 },
  //   { latitude: -6.21352, longitude: 106.708 },
  //   { latitude: -7.94428, longitude: 112.621 },
  //   { latitude: -7.63049, longitude: 111.518 },
  //   { latitude: -7.94428, longitude: 112.621 },
  //   { latitude: -7.63149, longitude: 111.518 },
  //   { latitude: -7.63049, longitude: 111.518 },
  //   { latitude: -7.63049, longitude: 111.518 },
  //   { latitude: -7.94041, longitude: 112.625 },
  // ];
  // console.log(dataMember);
  return (
    <div style={{ height: "350px", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCwjA-8-L9MPjI_h8lCkrTdBNcS0xbjQiE" }}
        defaultCenter={center}
        defaultZoom={zoom}
      >
        {dataMember?.map((item, idx) => (
          <Marker
            lat={item.latitude}
            lng={item.longitude}
            text={idx + 1}
            color="green"
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

Index.defaultProps = {
  center: {
    lat: -2,
    lng: 115,
  },
  zoom: 4.6,
};

export default Index;
