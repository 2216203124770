import API from "../../API";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
import { setToastModal } from "../../../actions";
import { toastError, toastSuccess } from "../../../../components/commons/toast";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_POSITION_PENDING = "GET_POSITION_PENDING";
export const GET_POSITION_SUCCESS = "GET_POSITION_SUCCESS";
export const GET_POSITION_ERROR = "GET_POSITION_ERROR";
export const POST_POSITION_PENDING = "POST_POSITION_PENDING";
export const POST_POSITION_SUCCESS = "POST_POSITION_SUCCESS";
export const POST_POSITION_ERROR = "POST_POSITION_ERROR";
export const PUT_POSITION_PENDING = "PUT_POSITION_PENDING";
export const PUT_POSITION_SUCCESS = "PUT_POSITION_SUCCESS";
export const PUT_POSITION_ERROR = "PUT_POSITION_ERROR";
export const DELETE_POSITION_PENDING = "DELETE_POSITION_PENDING";
export const DELETE_POSITION_SUCCESS = "DELETE_POSITION_SUCCESS";
export const DELETE_POSITION_ERROR = "DELETE_POSITION_ERROR";

export const POSITION_DATA = "POSITION_DATA";
export const SET_POSITION_MODAL = "SET_POSITION_MODAL";

// URL: URL_{URL}
const POSITION_URL = "/v1/position";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_POSITION_PENDING));
  API.get(POSITION_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_POSITION_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_POSITION_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_POSITION_PENDING));
  API.post(POSITION_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_POSITION_SUCCESS, res));
      toastSuccess("Jabatan berhasil ditambahkan");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_POSITION_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_POSITION_PENDING));
  API.put(POSITION_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_POSITION_SUCCESS, res));
      toastSuccess("Jabatan berhasil diubah");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_POSITION_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_POSITION_PENDING));
  API.delete(POSITION_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_POSITION_SUCCESS, res));
      toastSuccess("Jabatan berhasil dihapus");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_POSITION_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const Position = { get, post, put, deleted };
export default Position;

export const setPositionData = (data) => (dispatch) =>
  dispatch({ type: POSITION_DATA, data });

export const setPositionModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_POSITION_MODAL, { modalType: modalType, isOpen: isOpen })
  );
