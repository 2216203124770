import { React, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { reduxForm, Field, formValueSelector, FieldArray } from "redux-form";
import FormContainer from "../../../../components/container/Form";
import {
  formInput,
  FormDatepicker,
  normalizeDates,
  formatDates,
  formSelect,
  formInputNumber,
  formCheckbox,
  formFile,
} from "../../../../components/commons/form";
import Member, {
  setMemberData,
  setMemberModal,
} from "../../../../store/actions/anggota/member";
import Region from "../../../../store/actions/master/region";
import BranchMember from "../../../../store/actions/anggota/branch-member";
import File from "../../../../store/actions/file";
import Kesehatan from "../../../../store/actions/master/kesehatan";
import { Row, Column } from "simple-flexbox";
import { Button } from "@material-ui/core";
import {
  regexEmail,
  regexWebsite,
  kelembagaanOptions,
  MEMBER_PHOTO,
  LOGO_INSTITUTE,
  sertifikasiOptions,
} from "../../../../utils/constant";
import { getUser } from "../../../../utils/user";
import { StyleSheet, css } from "aphrodite";
import RenderArray from "./fieldArrayAlat";
import DetailContent from "../../../../components/global-components/DetailContent";

let Index = ({
  onSetMemberData,
  change,
  handleSubmit,
  detailData,
  region,
  pending,
  reset,
  branchMember,
  initialValues,
  labelSertification,
  detail_agency,
  kesehatan,
  detail_produced,
}) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [hasBranch, setHasBranch] = useState(initialValues.has_relation_member);
  const [imgName, setImgName] = useState(initialValues.img_name);
  const [logoName, setLogoName] = useState(initialValues.logo_filename);
  const notEdit = () => {
    setIsEdit(!isEdit);
    reset();
  };
  const unduhKTA = () => {
    window.open(
      process.env.REACT_APP_GAKESLAB_URL + "/v1/member/export/kta?user_id=" + id
    );
  };
  const user = getUser();
  const path = window.location.pathname.split("/");
  const id = path.pop();

  let medicalOptions;
  if (kesehatan.data) {
    medicalOptions = kesehatan.data.data.data.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  }

  useEffect(() => {
    setHasBranch(initialValues.has_relation_member);
    setImgName(initialValues.img_name);
    setLogoName(initialValues.logo_filename);
  }, [initialValues]);

  useEffect(() => {
    getMedical();
  }, []);

  const getMedical = () => dispatch(Kesehatan.get());

  const onSubmit = (value) => {
    const member_profile_data = detailData.member_profile_data;
    member_profile_data.supporting_file_data =
      detailData.member_profile_data.supporting_file_datas;
    let member_agency_tool_data = value.member_agency_tool_data?.map((item) => {
      return {
        name: item.name,
        medical_device_id: item.medical_device_id.value,
      };
    });

    let member_produced_tool_data = value.member_produced_tool_data?.map(
      (item) => {
        return {
          name: item.name,
          medical_device_id: item.medical_device_id.value,
        };
      }
    );
    let owned_certification = value.owned_certification.map(
      (item) => item.value
    );
    const param = {
      ...member_profile_data,
      id: id,
      address: value.address,
      company_technicians_education: value.company_technicians_education,
      company_technicians_name: value.company_technicians_name,
      contact_person_name_1: value.contact_person_name_1,
      contact_person_email_1: value.contact_person_email_1,
      contact_person_phone_number_1: value.contact_person_phone_number_1,
      contact_person_name_2: value.contact_person_name_2,
      contact_person_email_2: value.contact_person_email_2,
      contact_person_phone_number_2: value.contact_person_phone_number_2,
      email: value.email,
      kta_no: value.kta_no,
      website: value.website,
      institutional: value.institutional.value,
      img_name: imgName,
      logo_filename: logoName,
      leader_name: value.leader_name,
      name: value.name,
      owned_facility_factory: value.owned_facility_factory,
      owned_facility_other: value.owned_facility_other,
      owned_facility_workshop: value.owned_facility_workshop,
      phone_number: value.phone_number,
      position: value.position,
      relation_member_id: value.relation_member_name?.value,
      region_id: value.region_name.value,
      start_active_kta: value.start_active_kta,
      end_active_kta: value.end_active_kta,
      owned_certification: owned_certification.join(","),
      member_agency_tool_data: member_agency_tool_data,
      member_produced_tool_data: member_produced_tool_data,
    };

    const callback = () => {
      notEdit();
      dispatch(Member.getDetail({ id: id }));
    };

    dispatch(Member.put(param, callback));
  };

  const uploadFile = (e, name) => {
    let formData = new FormData();
    formData.append("file", e);

    const callback = (res) => {
      if (name === "img") {
        setImgName(res.data.data.fileName);
      } else {
        setLogoName(res.data.data.fileName);
      }
    };

    if (name === "img") {
      dispatch(File.upload(formData, callback, MEMBER_PHOTO));
    } else {
      dispatch(File.upload(formData, callback, LOGO_INSTITUTE));
    }
  };

  let regionOptions;
  if (region.data) {
    regionOptions = region.data.data.data.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
    regionOptions.push({ label: "Lainnya", value: "" });
  }

  let memberOptions;
  if (branchMember.data) {
    memberOptions = branchMember.data.data.data.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  }

  useEffect(() => {
    getRegion();
    getMember();
  }, []);

  const getRegion = () => dispatch(Region.get());
  const getMember = () => dispatch(BranchMember.get());

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      <Row
        vertical="center"
        horizontal="space-between"
        style={{ width: "100%", marginBottom: 20 }}
      >
        <div>
          <h5>Data Perusahaan</h5>
        </div>
        {user.role_code === "superadmin" && (
          <div className="d-flex justify-content-between">
            {isEdit ? (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={pending}
                  style={{ marginRight: 20 }}
                  onClick={notEdit}
                >
                  Batal
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={pending}
                  type="submit"
                >
                  Simpan
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={pending}
                  onClick={unduhKTA}
                  style={{ marginRight: 20 }}
                  hidden={true}
                >
                  Unduh KTA
                </Button>
                
                <Button
                  variant="contained"
                  color="primary"
                  disabled={pending}
                  onClick={notEdit}
                >
                  Edit
                </Button>
              </>
            )}
          </div>
        )}
      </Row>
      {!isEdit && user.role_code !== "member" && (
        <FormContainer label="Tanggal Approval">
          <Field
            name="approved_at"
            placeholder="Tanggal"
            isDetail={!isEdit}
            parse={normalizeDates}
            format={formatDates}
            component={FormDatepicker}
          />
        </FormContainer>
      )}
      {isEdit && (
        <FormContainer label="No KTA">
          <Field
            name="kta_no"
            placeholder="No KTA"
            isDetail={!isEdit}
            component={formInput}
          />
        </FormContainer>
      )}
      <FormContainer label="Wilayah">
        <Field
          isDetail={!isEdit}
          placeholder="Wilayah"
          name="region_name"
          options={regionOptions}
          isAsync
          asyncUrl="/v1/region"
          component={formSelect}
        />
      </FormContainer>
      {user.role_code !== "member" && (
        <FormContainer label="Cabang Perusahaan">
          {isEdit ? (
            <Column>
              <Field
                name="has_relation_member"
                component={formCheckbox}
                onChange={(e) => {
                  setHasBranch(e.target.checked);
                }}
                // validate={validate}
              />
              {hasBranch && (
                <Field
                  placeholder="Cabang Perusahaan"
                  name="relation_member_name"
                  options={memberOptions}
                  isAsync
                  asyncUrl="/v1/member"
                  component={formSelect}
                />
              )}
            </Column>
          ) : (
            <Field
              isDetail={!isEdit}
              name="relation_member_name"
              placeholder="Cabang Perusahaan"
              component={formSelect}
            />
          )}
        </FormContainer>
      )}
      <FormContainer label="Nama Perusahaan">
        <Field
          isDetail={!isEdit}
          name="name"
          placeholder="Nama Perusahaan"
          component={formInput}
        />
      </FormContainer>
      {!isEdit && user.role_code !== "member" && (
        <FormContainer label="Masa Aktif KTA">
          <Row className="align-items-center">
            {initialValues.start_active_kta && initialValues.end_active_kta ? (
              <>
                <Field
                  name="start_active_kta"
                  placeholder="Tanggal"
                  isDetail={!isEdit}
                  parse={normalizeDates}
                  format={formatDates}
                  component={FormDatepicker}
                />
                <div className="mx-3">
                  <span>-</span>
                </div>
                <Field
                  name="end_active_kta"
                  placeholder="Tanggal"
                  isDetail={!isEdit}
                  parse={normalizeDates}
                  format={formatDates}
                  component={FormDatepicker}
                />
              </>
            ) : (
              <div>-</div>
            )}
          </Row>
        </FormContainer>
      )}
      {user.role_code !== "member" && (
        <FormContainer label="Nama Pimpinan">
          <Field
            isDetail={!isEdit}
            name="leader_name"
            placeholder="Nama Pimpinan"
            component={formInput}
          />
        </FormContainer>
      )}
      {user.role_code !== "member" && (
        <FormContainer label="Jabatan">
          <Field
            isDetail={!isEdit}
            name="position"
            placeholder="Jabatan"
            component={formInput}
          />
        </FormContainer>
      )}
      <FormContainer label="Alamat Perusahaan">
        <Field
          isDetail={!isEdit}
          name="address"
          placeholder="Alamat Perusahaan"
          component={formInput}
        />
      </FormContainer>
      {user.role_code !== "member" && (
        <FormContainer label="Telepon Perusahaan">
          <Field
            isDetail={!isEdit}
            name="phone_number"
            placeholder="Telepon Perusahaan"
            component={formInputNumber}
          />
        </FormContainer>
      )}
      {user.role_code !== "member" && (
        <FormContainer label="Website Perusahaan">
          <Field
            isDetail={!isEdit}
            name="website"
            placeholder="Website Perusahaan"
            component={formInput}
          />
        </FormContainer>
      )}
      {user.role_code !== "member" && (
        <FormContainer label="Email">
          <Field
            isDetail={!isEdit}
            name="email"
            placeholder="Email"
            component={formInput}
          />
        </FormContainer>
      )}
      <FormContainer label="Kontak Person">
        <div className="row">
          <div className="col-6">
            <div>
              {!isEdit && (
                <div className="mt-2">
                  <span>Nama</span>
                </div>
              )}
              <Field
                isDetail={!isEdit}
                name="contact_person_name_1"
                placeholder="Nama"
                component={formInput}
              />
            </div>
            <div>
              {!isEdit && (
                <div className="mt-2">
                  <span>Email</span>
                </div>
              )}
              <Field
                isDetail={!isEdit}
                name="contact_person_email_1"
                placeholder="Email"
                component={formInput}
              />
            </div>
            <div>
              {!isEdit && (
                <div className="mt-2">
                  <span>No Telepon</span>
                </div>
              )}
              <Field
                isDetail={!isEdit}
                name="contact_person_phone_number_1"
                placeholder="No Telepon"
                component={formInput}
              />
            </div>
          </div>
          <div className="col-6">
            <div>
              {!isEdit && (
                <div className="mt-2">
                  <span>Nama</span>
                </div>
              )}
              <Field
                isDetail={!isEdit}
                name="contact_person_name_2"
                placeholder="Nama"
                component={formInput}
              />
            </div>
            <div>
              {!isEdit && (
                <div className="mt-2">
                  <span>Email</span>
                </div>
              )}
              <Field
                isDetail={!isEdit}
                name="contact_person_email_2"
                placeholder="Email"
                component={formInput}
              />
            </div>
            <div>
              {!isEdit && (
                <div className="mt-2">
                  <span>No Telepon</span>
                </div>
              )}
              <Field
                isDetail={!isEdit}
                name="contact_person_phone_number_2"
                placeholder="No Telepon"
                component={formInput}
              />
            </div>
          </div>
        </div>
      </FormContainer>
      {user.role_code !== "member" && (
        <FormContainer label="Kelembagaan">
          <Field
            isDetail={!isEdit}
            placeholder="Kelembagaan"
            name="institutional"
            options={kelembagaanOptions}
            component={formSelect}
          />
        </FormContainer>
      )}
      {user.role_code !== "member" && (
        <FormContainer label="Fasilitas Perusahaan">
          <Column>
            <div>
              {!isEdit && (
                <div className="mt-2">
                  <span>Pabrik</span>
                </div>
              )}
              <Field
                isDetail={!isEdit}
                name="owned_facility_factory"
                placeholder="Pabrik (alamat)"
                component={formInput}
              />
            </div>
            <div>
              {!isEdit && (
                <div className="mt-2">
                  <span>Workshop</span>
                </div>
              )}
              <Field
                isDetail={!isEdit}
                name="owned_facility_workshop"
                placeholder="Workshop (alamat)"
                component={formInput}
              />
            </div>
            <div>
              {!isEdit && (
                <div className="mt-2">
                  <span>Lainnya</span>
                </div>
              )}
              <Field
                isDetail={!isEdit}
                name="owned_facility_other"
                placeholder="Lain Lain"
                component={formInput}
              />
            </div>
          </Column>
        </FormContainer>
      )}
      {user.role_code !== "member" && (
        <FormContainer label="Tenaga Ahli">
          <Column>
            <div>
              {!isEdit && (
                <div className="mt-2">
                  <span>Nama</span>
                </div>
              )}
              <Field
                isDetail={!isEdit}
                name="company_technicians_name"
                placeholder="Nama"
                component={formInput}
              />
            </div>
            <div>
              {!isEdit && (
                <div className="mt-2">
                  <span>Pendidikan</span>
                </div>
              )}
              <Field
                isDetail={!isEdit}
                name="company_technicians_education"
                placeholder="Pendidikan"
                component={formInput}
              />
            </div>
          </Column>
        </FormContainer>
      )}
      {user.role_code !== "member" && (
        <FormContainer label="Foto Direktur">
          {!isEdit ? (
            <>
              {detailData?.member_profile_data?.img_name ? (
                <img
                  src={`${process.env.REACT_APP_GAKESLAB_URL}/v1/file/${MEMBER_PHOTO}/${detailData.member_profile_data.img_name}`}
                  alt="foto"
                  className={css(styles.imgDocument)}
                  width="100%"
                ></img>
              ) : (
                <span>Tidak ada Foto</span>
              )}
            </>
          ) : (
            <Field
              isDetail={!isEdit}
              name="img_name"
              type="file"
              fileType="image"
              title="Masukkan Foto"
              onChange={(e) => uploadFile(e, "img")}
              message="JPG"
              component={formFile}
            />
          )}
        </FormContainer>
      )}
      {user.role_code !== "member" && (
        <FormContainer label="Logo Perusahaan">
          {!isEdit ? (
            <>
              {detailData?.member_profile_data?.logo_filename ? (
                <img
                  src={`${process.env.REACT_APP_GAKESLAB_URL}/v1/file/${LOGO_INSTITUTE}/${detailData.member_profile_data.logo_filename}`}
                  alt="foto"
                  className={css(styles.logo)}
                  width="100%"
                ></img>
              ) : (
                <span>Tidak ada Foto</span>
              )}
            </>
          ) : (
            <Field
              isDetail={!isEdit}
              name="logo_filename"
              type="file"
              fileType="image"
              title="Masukkan Foto"
              onChange={(e) => uploadFile(e, "logo")}
              message="JPG"
              component={formFile}
            />
          )}
        </FormContainer>
      )}
      {user.role_code === "member" && (
        <FormContainer label="Sertifikasi Yang Dimiliki">
          <Field
            isDetail={!isEdit}
            placeholder="Sertifikasi"
            name="owned_certification"
            isMulti
            // onChange={() => change("leader_name", "akuuu adalah change")}
            options={sertifikasiOptions}
            component={formSelect}
          />
        </FormContainer>
      )}
      {user.role_code === "member" && (
        <DetailContent
          label="Kategori Alat Kesehatan yang Diageni/Didistribusi ( Untuk PAK/DAK/ CPAK )"
          alignItems="baseline"
          style={{ maxHeight: 150, overflowY: "auto" }}
          value={
            detailData?.member_agency_tool_data &&
            detailData.member_agency_tool_data.length > 0
              ? detail_agency.map((item, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      {item.name}
                      <br />
                      {item.list.map((val, ind) => (
                        <span>
                          {ind + 1}. {val.name}
                          <br />
                        </span>
                      ))}
                      <br />
                    </React.Fragment>
                  );
                })
              : "-"
          }
        />
      )}
      {user.role_code === "member" && (
        <DetailContent
          label="Kategori Alat Kesehatan yang Diproduksi ( Untuk Industri )"
          alignItems="baseline"
          style={{ maxHeight: 150, overflowY: "auto" }}
          value={
            detailData?.member_produced_tool_data &&
            detailData.member_produced_tool_data.length > 0
              ? detail_produced.map((item, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      {item.name}
                      <br />
                      {item.list.map((val, ind) => (
                        <span>
                          {ind + 1}. {val.name}
                          <br />
                        </span>
                      ))}
                      <br />
                    </React.Fragment>
                  );
                })
              : "-"
          }
        />
      )}
    </form>
  );
};

const validate = ({
  address,
  contact_person_name_1,
  contact_person_email_1,
  contact_person_phone_number_1,
  company_technicians_education,
  company_technicians_name,
  email,
  website,
  institutional,
  leader_name,
  img_name,
  logo_filename,
  name,
  owned_facility_factory,
  owned_facility_other,
  owned_facility_workshop,
  phone_number,
  position,
  qualification,
  region_name,
  start_active_kta,
  end_active_kta,
  has_relation_member,
  relation_member_name,
}) => {
  const errors = {};

  if (!address) {
    errors.address = "Field harus diisi";
  }
  if (!company_technicians_education) {
    errors.company_technicians_education = "Field harus diisi";
  }
  if (!company_technicians_name) {
    errors.company_technicians_name = "Field harus diisi";
  }
  if (!contact_person_name_1) {
    errors.contact_person_name_1 = "Field harus diisi";
  }
  if (!contact_person_email_1) {
    errors.contact_person_email_1 = "Field harus diisi";
  }
  if (!contact_person_phone_number_1) {
    errors.contact_person_phone_number_1 = "Field harus diisi";
  }

  if (!email) {
    errors.email = "Field harus diisi";
  } else if (!regexEmail.test(email)) {
    errors.email = "Field tidak valid";
  }
  if (website && !regexEmail.test(email)) {
    errors.website = "Field tidak valid";
  }
  if (!institutional) {
    errors.institutional = "Field harus diisi";
  }
  if (!img_name) {
    errors.img_name = "Foto direktur harus diupload";
  }
  if (!logo_filename) {
    errors.logo_filename = "Logo perusahaan harus diupload";
  }
  if (!leader_name) {
    errors.leader_name = "Field harus diisi";
  }
  if (!name) {
    errors.name = "Field harus diisi";
  }
  // if (!owned_facility_factory) {
  //   errors.owned_facility_factory = "Field harus diisi";
  // }
  // if (!owned_facility_other) {
  //   errors.owned_facility_other = "Field harus diisi";
  // }
  // if (!owned_facility_workshop) {
  //   errors.owned_facility_workshop = "Field harus diisi";
  // }
  if (!phone_number) {
    errors.phone_number = "Field harus diisi";
  }
  if (!position) {
    errors.position = "Field harus diisi";
  }
  if (!qualification) {
    errors.qualification = "Field harus diisi";
  }
  if (!region_name) {
    errors.region_name = "Field harus diisi";
  }
  if (!start_active_kta) {
    errors.start_active_kta = "Field harus diisi";
  }
  if (!end_active_kta) {
    errors.end_active_kta = "Field harus diisi";
  }
  if (has_relation_member && !relation_member_name) {
    errors.relation_member_name = "Field harus diisi";
  }

  return errors;
};

Index = reduxForm({
  form: "memberEditData",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(Index);

const mapStateToProps = ({ member, region, branchMember, kesehatan }) => {
  const { data, detailData, pending } = member;
  const path = window.location.pathname.split("/");
  let initialValues = {};
  let labelSertification = [];
  let detail_agency, detail_produced;
  if (detailData) {
    let certification =
      detailData.member_profile_data.owned_certification?.split(",");
    certification?.forEach((element) => {
      let temp = sertifikasiOptions.filter((item) => item.value === element);
      if (temp) labelSertification.push(temp[0]);
    });
    let member_agency_tool_data = detailData.member_agency_tool_data?.map(
      (item) => {
        return {
          name: item.name,
          medical_device_id: {
            label: item.medical_device_name,
            value: item.medical_device_id,
          },
        };
      }
    );
    let member_produced_tool_data = detailData.member_produced_tool_data?.map(
      (item) => {
        return {
          name: item.name,
          medical_device_id: {
            label: item.medical_device_name,
            value: item.medical_device_id,
          },
        };
      }
    );
    let category_agency = detailData.member_agency_tool_data?.map(
      (item) => item.medical_device_name
    );
    let category_produced = detailData.member_produced_tool_data?.map(
      (item) => item.medical_device_name
    );

    let filter_category_agency = category_agency?.filter(
      (item, index, self) => {
        return self.indexOf(item) === index;
      }
    );
    let filter_category_produced = category_produced?.filter(
      (item, index, self) => {
        return self.indexOf(item) === index;
      }
    );

    detail_agency = filter_category_agency?.map((item) => {
      let temp = detailData.member_agency_tool_data?.filter(
        (value) => value.medical_device_name === item
      );
      return {
        name: item,
        list: temp,
      };
    });

    detail_produced = filter_category_produced?.map((item) => {
      let temp = detailData.member_produced_tool_data?.filter(
        (value) => value.medical_device_name === item
      );
      return {
        name: item,
        list: temp,
      };
    });
    initialValues = {
      address: detailData.member_profile_data.address,
      approved_at: detailData.member_profile_data.approved_at,
      contact_person_name_1:
        detailData.member_profile_data.contact_person_name_1,
      contact_person_email_1:
        detailData.member_profile_data.contact_person_email_1,
      contact_person_phone_number_1:
        detailData.member_profile_data.contact_person_phone_number_1,
      contact_person_name_2:
        detailData.member_profile_data.contact_person_name_2,
      contact_person_email_2:
        detailData.member_profile_data.contact_person_email_2,
      contact_person_phone_number_2:
        detailData.member_profile_data.contact_person_phone_number_2,
      company_technicians_education:
        detailData.member_profile_data.company_technicians_education,
      company_technicians_name:
        detailData.member_profile_data.company_technicians_name,
      email: detailData.email,
      website: detailData.member_profile_data.website,
      kta_no: detailData.member_profile_data.kta_no,
      img_name: detailData.member_profile_data.img_name,
      logo_filename: detailData.member_profile_data.logo_filename,
      institutional: detailData.member_profile_data.institutional
        ? {
            label: detailData.member_profile_data.institutional.replace(
              /(^\w{1})|(\s+\w{1})/g,
              (letter) => letter.toUpperCase()
            ),
            value: detailData.member_profile_data.institutional,
          }
        : null,
      leader_name: detailData.member_profile_data.leader_name,
      name: detailData.name,
      owned_facility_factory:
        detailData.member_profile_data.owned_facility_factory,
      owned_facility_other: detailData.member_profile_data.owned_facility_other,
      owned_facility_workshop:
        detailData.member_profile_data.owned_facility_workshop,
      phone_number: detailData.member_profile_data.phone_number,
      position: detailData.member_profile_data.position,
      region_name: detailData.region_name
        ? {
            label: detailData.region_name,
            value: detailData.region_id,
          }
        : null,
      has_relation_member: detailData.member_profile_data.relation_member_name
        ? true
        : false,
      relation_member_name: detailData.member_profile_data.relation_member_id
        ? {
            label: detailData.member_profile_data.relation_member_name,
            value: detailData.member_profile_data.relation_member_id,
          }
        : null,
      start_active_kta: detailData.member_profile_data.start_active_kta,
      end_active_kta: detailData.member_profile_data.end_active_kta,
      owned_certification:
        detailData.member_profile_data.owned_certification &&
        labelSertification.length
          ? labelSertification
          : null,
      member_agency_tool_data: member_agency_tool_data,
    };
  }
  return {
    data,
    detailData,
    pending,
    initialValues,
    region,
    branchMember,
    kesehatan,
    detail_produced,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetMemberModal: (modalType, isOpen) =>
      dispatch(setMemberModal(modalType, isOpen)),
    onSetMemberData: (data) => dispatch(setMemberData(data)),
  };
};

const styles = StyleSheet.create({
  imgDocument: {
    maxHeight: 200,
    maxWidth: 200,
    objectFit: "contain",
    marginRight: "auto",
    marginLeft: "auto",
  },
  logo: {
    maxHeight: 200,
    maxWidth: 200,
    objectFit: "contain",
    marginRight: "auto",
    marginLeft: "auto",
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
