import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Container from "../../../../components/container";
import LeftContainer from "../../../../components/container/LeftContainer";
import RightContainer from "../../../../components/container/RightContainer";
import DetailMenu from "../../../../components/global-components/DetailMenu";
import DetailButton from "../../../../components/global-components/DetailButton";
import DetailStatus from "../../../../components/global-components/DetailStatus";
import IconDot from "../../../../assets/icon/icon-dot";
import IconCreate from "../../../../assets/icon/icon-create";
import MenuItem from "@material-ui/core/MenuItem";
import Member, {
  setMemberData,
  setMemberModal,
} from "../../../../store/actions/anggota/member";
import Profile from "./profile";
import { Row, Column } from "simple-flexbox";
import Modal from "../modal";
import DataPerusahaan from "./data-perusahaan";
import AdministrasiPerusahaan from "./administrasi-perusahaan";
import { StyleSheet, css } from "aphrodite";
import CircularProgress from "@material-ui/core/CircularProgress";
import { LOGO_INSTITUTE } from "../../../../utils/constant";
import { getUser } from "../../../../utils/user";

let Index = ({ detailData, pending, onSetMemberModal, onSetMemberData }) => {
  const [selectedMenu, setSelectedMenu] = useState("data-perusahaan");
  const dispatch = useDispatch();
  const user = getUser();
  const path = window.location.pathname.split("/");
  const id = path.pop();

  useEffect(() => {
    onSetMemberData(null);
  }, []);

  useEffect(() => {
    getDetailMember();
  }, [id]);

  const getDetailMember = () => dispatch(Member.getDetail({ id: id }));

  const setModal = (modalType, isOpen, status) => {
    onSetMemberModal(modalType, isOpen, status);
  };

  let status = detailData?.member_profile_data?.status_active;
  return (
    <Container>
      <Modal />
      <Row>
        <LeftContainer horizontal="center">
          {pending ? (
            <CircularProgress color="primary" />
          ) : (
            <>
              <Profile
                img_profile={
                  detailData?.member_profile_data?.logo_filename
                    ? `${process.env.REACT_APP_GAKESLAB_URL}/v1/file/${LOGO_INSTITUTE}/${detailData.member_profile_data.logo_filename}`
                    : ""
                }
                name={detailData?.name}
              />
              {user.role_code !== "member" && (
                <DetailStatus>
                  <Row
                    className={
                      status === 0
                        ? css(styles.detailMemberStatusNonactiveID)
                        : css(styles.detailMemberStatusActiveID)
                    }
                  >
                    <span
                      className={
                        status === 0
                          ? css(styles.detailMemberStatusNonactiveIDFont)
                          : css(styles.detailMemberStatusActiveIDFont)
                      }
                    >
                      ID{" "}
                      {detailData?.member_profile_data?.kta_no
                        ? detailData.member_profile_data.kta_no
                        : "-"}
                    </span>
                  </Row>
                  {user.role_code === "superadmin" ? (
                    <>
                      <DetailButton
                        button={(props) => (
                          <Row
                            onClick={props.onClick}
                            className={
                              status === 0
                                ? css(
                                    styles.detailMemberStatusNonactiveButton,
                                    styles.clickableIcon
                                  )
                                : css(
                                    styles.detailMemberStatusActiveButton,
                                    styles.clickableIcon
                                  )
                            }
                          >
                            <span
                              className={css(styles.detailMemberStatusButtonFont)}
                            >
                              {status === 0 ? "Nonaktif" : "Aktif"}
                            </span>{" "}
                            <IconCreate />
                          </Row>
                        )}
                        horizontal="left"
                      >
                        <MenuItem
                          onClick={() => {
                            if (
                              detailData?.member_profile_data?.status_active === 0
                            ) {
                              setModal("change-status", true, 1);
                            }
                          }}
                        >
                          <IconDot fill="#2CB96A" /> Aktif
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            if (
                              detailData?.member_profile_data?.status_active === 1
                            ) {
                              setModal("change-status", true, 0);
                            }
                          }}
                        >
                          <IconDot fill="Red" /> Nonaktif
                        </MenuItem>
                      </DetailButton>
                    </>
                  ) : (
                    <Row
                      className={
                        status === 0
                          ? css(styles.detailMemberStatusNonactiveButton)
                          : css(styles.detailMemberStatusActiveButton)
                      }
                    >
                      <span className={css(styles.detailMemberStatusButtonFont)}>
                        {status === 0 ? "Nonaktif" : "Aktif"}
                      </span>
                    </Row>
                  )}
                </DetailStatus>
              )}
              {user.role_code !== "member" && (
                <DetailMenu
                  onClick={() => setSelectedMenu("data-perusahaan")}
                  menuName="Data Perusahaan"
                  isActive={selectedMenu === "data-perusahaan"}
                />
              )}
              {user.role_code !== "member" && (
                <DetailMenu
                  onClick={() => setSelectedMenu("administrasi-perusahaan")}
                  menuName="Administrasi Perusahaan"
                  isActive={selectedMenu === "administrasi-perusahaan"}
                />
              )}
              {/* <DetailMenu
            onClick={() => setSelectedMenu("kelengkapan-berkas")}
            menuName="Kelengkapan Berkas"
            isActive={selectedMenu === "kelengkapan-berkas"}
          /> */}
            </>
          )}
        </LeftContainer>
        <RightContainer>
          {/* {pending ? (
            <CircularProgress color="primary" />
          ) : (
            <> */}
          {selectedMenu === "data-perusahaan" ? (
            <DataPerusahaan />
          ) : selectedMenu === "administrasi-perusahaan" ? (
            <AdministrasiPerusahaan />
          ) : (
            // selectedMenu === "kelengkapan-berkas" ? (
            //   <KelengkapanBerkas />
            // ) :
            <div></div>
          )}
          {/* </>
          )} */}
        </RightContainer>
      </Row>
    </Container>
  );
};

const mapStateToProps = ({ member: { data, detailData, pending }, region }) => {
  return { data, detailData, pending, region };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetMemberModal: (modalType, isOpen, active) =>
      dispatch(setMemberModal(modalType, isOpen, active)),
    onSetMemberData: (data) => dispatch(setMemberData(data)),
  };
};

const styles = StyleSheet.create({
  detailMemberStatusNonactiveID: {
    padding: "10px 20px",
    border: "2px solid #ff3232",
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  detailMemberStatusActiveID: {
    padding: "10px 20px",
    border: "2px solid #2CB96A",
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  detailMemberStatusNonactiveButton: {
    padding: "10px 20px",
    backgroundColor: "#ff3232",
    border: "2px solid #ff3232",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    height: "100%",
    alignItems: "center",
    // ":hover": {
    //   cursor: "pointer",
    // },
  },
  detailMemberStatusActiveButton: {
    padding: "10px 20px",
    backgroundColor: "#2CB96A",
    border: "2px solid #2CB96A",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    height: "100%",
    alignItems: "center",
    // ":hover": {
    //   cursor: "pointer",
    // },
  },
  detailMemberStatusNonactiveIDFont: {
    color: "#ff3232",
  },
  detailMemberStatusActiveIDFont: {
    color: "#2CB96A",
  },
  detailMemberStatusButtonFont: {
    color: "#FFFFFF",
    marginRight: 20,
  },
  changeStatusButton: {
    padding: 0,
    fontSize: 14,
  },
  clickableIcon: {
    ":hover": {
      cursor: "pointer",
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
