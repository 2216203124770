import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { ModalBody, ModalHeader } from "reactstrap";
import { Button } from "@material-ui/core";
import { formInput } from "../../../components/commons/form";
import Radio from "../../../components/commons/form/radio";
import { history } from "../../../utils";
import LabelInputVerticalComponent from "../../../components/global-components/LabelInputVertical";
import SetoranAdmin, {
  setSetoranAdminModal,
} from "../../../store/actions/setoran";

let Approval = ({
  onSetSetoranAdminModal,
  handleSubmit,
  detailData,
  pending,
  handleRefresh,
}) => {
  const dispatch = useDispatch();
  const [isReject, setIsReject] = useState("");

  const handleApprove = (e) => {
    setIsReject(e);
  };

  const onSubmit = ({ is_approve, rejected_notes }) => {
    const param = {
      id: detailData.id,
      is_approve,
      rejected_notes,
    };
    const callback = () => {
      onSetSetoranAdminModal("", false);
      history.push("/admin/setoran");
    };
    dispatch(SetoranAdmin.approve(param, callback));
  };
  return (
    <>
      <ModalHeader>Approval Setoran</ModalHeader>
      <ModalBody>
        {/* <span>Apakah Anda yakin melakukan approval bukti pembayaran?</span> */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <LabelInputVerticalComponent label="Jenis Approval">
            <Field
              name="is_approve"
              options={{
                true: "Approve",
                false: "Ditolak",
              }}
              onChange={handleApprove}
              component={Radio}
            />
          </LabelInputVerticalComponent>
          {isReject === "false" && (
            <LabelInputVerticalComponent label="Alasan Penolakan">
              <Field
                name="rejected_notes"
                placeholder="Masukkan Alasan Penolakan"
                component={formInput}
              />
            </LabelInputVerticalComponent>
          )}
          <div className="d-flex justify-content-between">
            <Button
              variant="outlined"
              className="mt-3"
              disabled={pending}
              color="primary"
              onClick={() => onSetSetoranAdminModal("", false)}
            >
              Batal
            </Button>
            <Button
              type="submit"
              className="mt-3"
              variant="contained"
              disabled={pending}
              color="primary"
            >
              Submit
            </Button>
          </div>
        </form>
      </ModalBody>
    </>
  );
};

const validate = ({ is_approve, rejected_notes }) => {
  const errors = {};
  if (!is_approve) {
    errors.is_approve = "Jenis Approval harus diisi";
  }
  if (!rejected_notes) {
    errors.rejected_notes = "Alasan Penolakan harus diisi";
  }

  return errors;
};

Approval = reduxForm({
  form: "setoranAdminApproval",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(Approval);

const mapStateToProps = ({ setoranAdmin: { detailData, pending } }) => {
  return {
    detailData,
    pending,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetSetoranAdminModal: (modalType, isOpen) =>
      dispatch(setSetoranAdminModal(modalType, isOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Approval);
