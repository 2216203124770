import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import User, {
  setUserData,
  setUserModal,
} from "../../../store/actions/system/pengguna";
import Region from "../../../store/actions/master/region";
import { Row } from "simple-flexbox";
import MaterialTable from "material-table";
import SearchIcon from "@material-ui/icons/Search";
import { Paper, Button, MenuItem } from "@material-ui/core";
import DetailButtonComponent from "../../../components/global-components/DetailButton";
import InputComponent from "../../../components/commons/form/input";
import SelectComponent from "../../../components/commons/form/select";
import Container from "../../../components/container";
import Modal from "./modal";
import debounce from "lodash.debounce";
import { history } from "../../../utils";
import { roleUserOptions } from "../../../utils/constant";

const Pengguna = ({ onSetUserModal, onSetUserData, pending, region }) => {
  const [searchText, setSearchText] = useState("");
  const [role, setRole] = useState("");
  const [regionId, setRegionId] = useState("");
  const dispatch = useDispatch();
  const tableRef = useRef();

  const handleRefresh = (state) => {
    setSearchText(state);
    tableRef.current && tableRef.current.onQueryChange();
  };

  const delayedQuery = debounce((value) => {
    return handleRefresh(value);
  }, 500);

  const handleSearchChange = (e) => {
    delayedQuery(e.target.value);
  };

  const handleRoleChange = (e) => {
    if (e) {
      setRole(e.value);
    } else {
      setRole("");
    }
    tableRef.current && tableRef.current.onQueryChange();
  };

  const handleRegionChange = (e) => {
    if (e) {
      setRegionId(e.value);
    } else {
      setRegionId("");
    }
    tableRef.current && tableRef.current.onQueryChange();
  };

  let regionOptions;
  if (region.data) {
    regionOptions = region.data.data.data.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  }

  useEffect(() => {
    getRegion();
  }, []);

  const getRegion = () => dispatch(Region.get());

  const setModal = (modalType, isOpen, data) => {
    onSetUserModal(modalType, isOpen);
    onSetUserData(data);
  };

  return (
    <Container>
      <Modal handleRefresh={(state) => handleRefresh(state)} />
      <Row className="m-3 justify-content-between">
        <Button
          color="primary"
          variant="contained"
          disabled={pending}
          onClick={() => setModal("add", true, null)}
        >
          Tambah Pengguna
        </Button>
        <Row className="m-0 justify-content-between">
          <div style={{ width: 120, marginRight: 20 }}>
            <SelectComponent
              onChange={(e) => handleRoleChange(e)}
              placeholder="Role"
              options={roleUserOptions}
            />
          </div>
          <div style={{ width: 120, marginRight: 20 }}>
            <SelectComponent
              onChange={(e) => handleRegionChange(e)}
              placeholder="Wilayah"
              options={regionOptions}
              isAsync
              asyncUrl="/v1/region"
            />
          </div>
          <InputComponent
            onChange={(e) => handleSearchChange(e)}
            placeholder="Cari nama pengguma"
            endIcon={SearchIcon}
          />
        </Row>
      </Row>
      <div className="m-3">
        <MaterialTable
          tableRef={tableRef}
          title="User"
          columns={[
            {
              title: "No",
              field: "no",
              width: 40,
            },
            {
              title: "Nama",
              render: ({ name }) => {
                return name ? name : "-";
              },
            },
            {
              title: "Email",
              render: ({ email }) => {
                return email ? email : "-";
              },
            },
            {
              title: "Wilayah",
              render: ({ region_name }) => {
                return region_name ? region_name : "-";
              },
            },
            {
              title: "Role",
              render: ({ role_name }) => {
                return role_name ? role_name : "-";
              },
            },
            {
              title: "Aksi",
              width: 80,
              cellStyle: {
                paddingLeft: 0,
              },
              render: (rowData) => {
                return (
                  <DetailButtonComponent>
                    <MenuItem onClick={() => setModal("detail", true, rowData)}>
                      Lihat Detail
                    </MenuItem>
                    <MenuItem onClick={() => setModal("edit", true, rowData)}>
                      Edit Data
                    </MenuItem>
                    <MenuItem onClick={() => setModal("delete", true, rowData)}>
                      Hapus Data
                    </MenuItem>
                  </DetailButtonComponent>
                );
              },
            },
          ]}
          data={(q) =>
            new Promise((resolve) => {
              let param = {
                page: q.page + 1,
                length: 10,
                search_text: searchText,
                region_id: regionId,
                role_code: role,
              };
              dispatch(User.get(param, resolve));
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            showTitle: false,
            search: false,
            sorting: false,
            headerStyle: {
              backgroundColor: "#fff",
              fontWeight: "bold",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
        />
      </div>
    </Container>
  );
};

const mapStateToProps = ({ user: { pending }, region }) => {
  return { pending, region };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetUserModal: (modalType, isOpen) =>
      dispatch(setUserModal(modalType, isOpen)),
    onSetUserData: (data) => dispatch(setUserData(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Pengguna)
);
