import React from "react";
import { connect } from "react-redux";
import { ModalBody, ModalHeader } from "reactstrap";
import { StyleSheet, css } from "aphrodite";

let Index = ({ folderName, fileName, title }) => {
  return (
    <>
      <ModalHeader>{title ? title : "Foto Profil"}</ModalHeader>
      <ModalBody>
        <div className="d-flex justify-content-center">
          <img
            src={`${process.env.REACT_APP_GAKESLAB_URL}/v1/file/${folderName}/${fileName}`}
            alt="foto"
            className={css(styles.imgDocument)}
            width="100%"
          ></img>
        </div>
      </ModalBody>
    </>
  );
};

const styles = StyleSheet.create({
  imgDocument: {
    maxHeight: 400,
    maxWidth: 400,
    objectFit: "contain",
    marginRight: "auto",
    marginLeft: "auto",
  },
});

const mapStateToProps = ({}) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
