import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { reduxForm, Field, FieldArray } from "redux-form";
import FormContainer from "../../../../components/container/Form";
import DetailContentLihatBerkas from "../../../../components/global-components/DetailContent/LihatBerkas";
import DetailContent from "../../../../components/global-components/DetailContent";
import { MEMBER_PHOTO, MEMBER_SUPPORT_FILE } from "../../../../utils/constant";
import File from "../../../../store/actions/file";
import Kesehatan from "../../../../store/actions/master/kesehatan";
import {
  formInput,
  FormDatepicker,
  normalizeDates,
  formatDates,
  formInputNumber,
  formSelect,
  formFile,
} from "../../../../components/commons/form";
import Radio from "../../../../components/commons/form/radio";
import Member, {
  setMemberData,
  setMemberModal,
} from "../../../../store/actions/anggota/member";
import { Row, Column } from "simple-flexbox";
import { Button } from "@material-ui/core";
import { regexEmail, sertifikasiOptions } from "../../../../utils/constant";
import { getUser } from "../../../../utils/user";
import RenderArray from "./fieldArrayAlat";
import { StyleSheet, css } from "aphrodite";

let Index = ({
  handleSubmit,
  detailData,
  pending,
  reset,
  onSetMemberModal,
  change,
  kesehatan,
  detail_agency,
  detail_produced,
  labelSertification,
}) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const notEdit = () => {
    setIsEdit(!isEdit);
    reset();
  };
  const user = getUser();

  const onSubmit = (value) => {
    const member_profile_data = detailData.member_profile_data;
    member_profile_data.email = detailData.email;
    member_profile_data.name = detailData.name;
    member_profile_data.region_id = detailData.region_id;
    member_profile_data.supporting_file_data =
      detailData.member_profile_data.supporting_file_datas;

    let member_agency_tool_data = value.member_agency_tool_data?.map((item) => {
      return {
        name: item.name,
        medical_device_id: item.medical_device_id.value,
      };
    });

    let member_produced_tool_data = value.member_produced_tool_data?.map(
      (item) => {
        return {
          name: item.name,
          medical_device_id: item.medical_device_id.value,
        };
      }
    );
    let owned_certification = value.owned_certification.map(
      (item) => item.value
    );
    const param = {
      ...member_profile_data,
      id: detailData.id,
      deed_of_amendment_date: value.deed_of_amendment_date,
      deed_of_amendment_number: value.deed_of_amendment_number,
      deed_of_amendment_filename: value.deed_of_amendment_filename,
      deed_of_establishment_date: value.deed_of_establishment_date,
      deed_of_establishment_number: value.deed_of_establishment_number,
      deed_of_establishment_filename: value.deed_of_establishment_filename,
      npwp: value.npwp,
      npwp_date: value.npwp_date,
      pak: value.pak,
      pak_date: value.pak_date,
      pak_filename: value.pak_filename,
      owned_certification: owned_certification.join(","),
      qualification: value.qualification,
      siup: value.siup,
      siup_date: value.siup_date,
      siup_filename: value.siup_filename,
      tdp: value.tdp,
      tdp_date: value.tdp_date,
      tdp_filename: value.tdp_filename,
      member_agency_tool_data: member_agency_tool_data,
      member_produced_tool_data: member_produced_tool_data,
    };
    const callback = () => {
      notEdit();
      dispatch(Member.getDetail({ id: detailData.id }));
    };

    dispatch(Member.put(param, callback));
  };

  const setModal = (modalType, isOpen, title, folderName, fileName) => {
    onSetMemberModal(modalType, isOpen, title, folderName, fileName);
  };

  const uploadFile = (e, name) => {
    let formData = new FormData();
    formData.append("file", e);

    const callback = (res) => {
      change(name, res.data.data.fileName);
    };

    dispatch(File.upload(formData, callback, MEMBER_SUPPORT_FILE));
  };

  let medicalOptions;
  if (kesehatan.data) {
    medicalOptions = kesehatan.data.data.data.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  }

  useEffect(() => {
    getMedical();
  }, []);

  const getMedical = () => dispatch(Kesehatan.get());
  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      <Row
        vertical="center"
        horizontal="space-between"
        style={{ width: "100%", marginBottom: 20 }}
      >
        <div>
          <h5>Administrasi Perusahaan</h5>
        </div>
        {user.role_code === "superadmin" && (
          <div className="d-flex justify-content-between">
            {isEdit ? (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={pending}
                  style={{ marginRight: 20 }}
                  onClick={notEdit}
                >
                  Batal
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={pending}
                  type="submit"
                >
                  Simpan
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disabled={pending}
                onClick={notEdit}
              >
                Edit
              </Button>
            )}
          </div>
        )}
      </Row>
      {user.role_code !== "member" && (
        <FormContainer label="No. SIUP/ Ijin Usaha PMA">
          <Column className="my-2">
            <Field
              isDetail={!isEdit}
              name="siup"
              placeholder="No. SIUP/ Ijin Usaha PMA"
              component={formInput}
            />
            {/* <FormContainer label="Tanggal SIUP"> */}
            <Field
              isDetail={!isEdit}
              name="siup_date"
              placeholder="Tanggal SIUP"
              parse={normalizeDates}
              format={formatDates}
              component={FormDatepicker}
            />
            {/* </FormContainer> */}
            {!isEdit ? (
              <>
                {detailData.member_profile_data.siup_filename ? (
                  <DetailContentLihatBerkas
                    onClick={() =>
                      setModal(
                        "show-document",
                        true,
                        "Berkas SIUP",
                        MEMBER_SUPPORT_FILE,
                        detailData.member_profile_data.siup_filename
                      )
                    }
                  />
                ) : (
                  <span>Belum ada berkas</span>
                )}
              </>
            ) : (
              <Row horizontal="space-between" className="my-3 shadow rounded p-3">
                <div className="d-flex flex-column w-100 mr-2">
                  <div className="mb-2">
                    <Field
                      disabled
                      name="siup_filename"
                      placeholder="Masukkan Nama Berkas"
                      component={formInput}
                    />
                  </div>
                  <Field
                    name="siup_filename_temp"
                    type="file"
                    fileType="pdf/image"
                    title="Masukkan Berkas"
                    message="PDF / JPG"
                    onChange={(e) => uploadFile(e, "siup_filename")}
                    component={formFile}
                  />
                </div>
              </Row>
            )}
          </Column>
        </FormContainer>
      )}
      {user.role_code !== "member" && (
        <FormContainer label="Kualifikasi">
          {isEdit ? (
            <Field
              name="qualification"
              component={Radio}
              options={{
                kecil: "Kecil",
                menengah: "Menengah",
                besar: "Besar",
              }}
            />
          ) : (
            <Field
              isDetail
              name="qualification"
              placeholder="Kualifikasi"
              component={formInput}
            />
          )}
        </FormContainer>
      )}
      {user.role_code !== "member" && (
        <FormContainer label="No. Izin PAK/DAK/ Cab.PAK/SPAK">
          <Column className="my-2">
            <Field
              isDetail={!isEdit}
              name="pak"
              placeholder="No. Izin PAK/DAK/ Cab.PAK/SPAK"
              component={formInput}
            />
            <Field
              name="pak_date"
              placeholder="Tanggal PAK"
              isDetail={!isEdit}
              parse={normalizeDates}
              format={formatDates}
              component={FormDatepicker}
            />
            {!isEdit ? (
              <>
                {detailData.member_profile_data.pak_filename ? (
                  <DetailContentLihatBerkas
                    onClick={() =>
                      setModal(
                        "show-document",
                        true,
                        "Berkas SIUP",
                        MEMBER_SUPPORT_FILE,
                        detailData.member_profile_data.pak_filename
                      )
                    }
                  />
                ) : (
                  <span>Belum ada berkas</span>
                )}
              </>
            ) : (
              <Row horizontal="space-between" className="my-3 shadow rounded p-3">
                <div className="d-flex flex-column w-100 mr-2">
                  <div className="mb-2">
                    <Field
                      disabled
                      name="pak_filename"
                      placeholder="Masukkan Nama Berkas"
                      component={formInput}
                    />
                  </div>
                  <Field
                    name="pak_filename_temp"
                    type="file"
                    fileType="pdf/image"
                    title="Masukkan Berkas"
                    message="PDF / JPG"
                    onChange={(e) => uploadFile(e, "pak_filename")}
                    component={formFile}
                  />
                </div>
              </Row>
            )}
          </Column>
        </FormContainer>
      )}
      {/* <FormContainer label="No. KTA Gakeslab">
        <Field
          isDetail={!isEdit}
          name="kta_no"
          placeholder="No. KTA Gakeslab"
          component={formInput}
        />
      </FormContainer> */}
      {user.role_code !== "member" && (
        <FormContainer label="Akta Notaris Pendirian">
          <Column className="my-2">
            <Field
              isDetail={!isEdit}
              name="deed_of_establishment_number"
              placeholder="Nomor"
              component={formInput}
            />
            <Field
              name="deed_of_establishment_date"
              placeholder="Tanggal"
              isDetail={!isEdit}
              parse={normalizeDates}
              format={formatDates}
              component={FormDatepicker}
            />
            {!isEdit ? (
              <>
                {detailData.member_profile_data.deed_of_establishment_filename ? (
                  <DetailContentLihatBerkas
                    onClick={() =>
                      setModal(
                        "show-document",
                        true,
                        "Berkas SIUP",
                        MEMBER_SUPPORT_FILE,
                        detailData.member_profile_data
                          .deed_of_establishment_filename
                      )
                    }
                  />
                ) : (
                  <span>Belum ada berkas</span>
                )}
              </>
            ) : (
              <Row horizontal="space-between" className="my-3 shadow rounded p-3">
                <div className="d-flex flex-column w-100 mr-2">
                  <div className="mb-2">
                    <Field
                      disabled
                      name="deed_of_establishment_filename"
                      placeholder="Masukkan Nama Berkas"
                      component={formInput}
                    />
                  </div>
                  <Field
                    name="deed_of_establishment_filename_temp"
                    type="file"
                    fileType="pdf/image"
                    title="Masukkan Berkas"
                    message="PDF / JPG"
                    onChange={(e) =>
                      uploadFile(e, "deed_of_establishment_filename")
                    }
                    component={formFile}
                  />
                </div>
              </Row>
            )}
          </Column>
        </FormContainer>
      )}
      {user.role_code !== "member" && (
        <FormContainer label="Akta Notaris Perubahan">
          <Column className="my-2">
            <Field
              isDetail={!isEdit}
              name="deed_of_amendment_number"
              placeholder="Nomor"
              component={formInput}
            />
            <Field
              name="deed_of_amendment_date"
              placeholder="Tanggal"
              isDetail={!isEdit}
              parse={normalizeDates}
              format={formatDates}
              component={FormDatepicker}
            />
            {!isEdit ? (
              <>
                {detailData.member_profile_data.deed_of_amendment_filename ? (
                  <DetailContentLihatBerkas
                    onClick={() =>
                      setModal(
                        "show-document",
                        true,
                        "Berkas SIUP",
                        MEMBER_SUPPORT_FILE,
                        detailData.member_profile_data.deed_of_amendment_filename
                      )
                    }
                  />
                ) : (
                  <span>Belum ada berkas</span>
                )}
              </>
            ) : (
              <Row horizontal="space-between" className="my-3 shadow rounded p-3">
                <div className="d-flex flex-column w-100 mr-2">
                  <div className="mb-2">
                    <Field
                      disabled
                      name="deed_of_amendment_filename"
                      placeholder="Masukkan Nama Berkas"
                      component={formInput}
                    />
                  </div>
                  <Field
                    name="deed_of_amendment_filename_temp"
                    type="file"
                    fileType="pdf/image"
                    title="Masukkan Berkas"
                    message="PDF / JPG"
                    onChange={(e) => uploadFile(e, "deed_of_amendment_filename")}
                    component={formFile}
                  />
                </div>
              </Row>
            )}
          </Column>
        </FormContainer>
      )}
      {user.role_code !== "member" && (
        <FormContainer label="No Tanda Daftar Perusahaan (TDP)">
          <Column className="my-2">
            <Field
              isDetail={!isEdit}
              name="tdp"
              placeholder="Nomor"
              component={formInput}
            />
            <Field
              name="tdp_date"
              placeholder="Tanggal"
              isDetail={!isEdit}
              parse={normalizeDates}
              format={formatDates}
              component={FormDatepicker}
            />
          </Column>
      </FormContainer>
      )}
      {user.role_code !== "member" && (
        <FormContainer label="No Pokok Wajib Perusahaan (NPWP)">
          <Column className="my-2">
            <Field
              isDetail={!isEdit}
              name="npwp"
              placeholder="Nomor"
              component={formInput}
            />
            <Field
              name="npwp_date"
              placeholder="Tanggal"
              isDetail={!isEdit}
              parse={normalizeDates}
              format={formatDates}
              component={FormDatepicker}
            />
          </Column>
        </FormContainer>
      )}
      {!isEdit ? (
        <DetailContent
          label="Sertifikasi Yang Dimiliki"
          alignItems="baseline"
          style={{ maxHeight: 150, overflowY: "auto" }}
          value={
            labelSertification && labelSertification.length > 0
              ? labelSertification.map((item, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <span>
                        {idx + 1}. {item?.label}
                        <br />
                      </span>
                    </React.Fragment>
                  );
                })
              : "-"
          }
        />
      ) : (
        <FormContainer label="Sertifikasi Yang Dimiliki">
          <Field
            isDetail={!isEdit}
            placeholder="Sertifikasi"
            name="owned_certification"
            isMulti
            // onChange={() => change("leader_name", "akuuu adalah change")}
            options={sertifikasiOptions}
            component={formSelect}
          />
        </FormContainer>
      )}
      {!isEdit ? (
        <DetailContent
          label="Kategori Alat Kesehatan yang Diageni/Didistribusi ( Untuk PAK/DAK/ CPAK )"
          alignItems="baseline"
          style={{ maxHeight: 150, overflowY: "auto" }}
          value={
            detailData?.member_agency_tool_data &&
            detailData.member_agency_tool_data.length > 0
              ? detail_agency.map((item, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      {item.name}
                      <br />
                      {item.list.map((val, ind) => (
                        <span>
                          {ind + 1}. {val.name}
                          <br />
                        </span>
                      ))}
                      <br />
                    </React.Fragment>
                  );
                })
              : "-"
          }
        />
      ) : (
        <FormContainer label="Kategori Alat Kesehatan yang Diageni/Didistribusi ( Untuk PAK/DAK/ CPAK )">
          <FieldArray
            name="member_agency_tool_data"
            options={medicalOptions}
            component={RenderArray}
          />
        </FormContainer>
      )}
      {!isEdit ? (
        <DetailContent
          label="Kategori Alat Kesehatan yang Diproduksi ( Untuk Industri )"
          alignItems="baseline"
          style={{ maxHeight: 150, overflowY: "auto" }}
          value={
            detailData?.member_produced_tool_data &&
            detailData.member_produced_tool_data.length > 0
              ? detail_produced.map((item, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      {item.name}
                      <br />
                      {item.list.map((val, ind) => (
                        <span>
                          {ind + 1}. {val.name}
                          <br />
                        </span>
                      ))}
                      <br />
                    </React.Fragment>
                  );
                })
              : "-"
          }
        />
      ) : (
        <FormContainer label="Kategori Alat Kesehatan yang Diproduksi ( Untuk Industri )">
          <FieldArray
            name="member_produced_tool_data"
            options={medicalOptions}
            component={RenderArray}
          />
        </FormContainer>
      )}
    </form>
  );
};

const validate = ({
  address,
  agency_owned_item_name,
  agency_owned_principal,
  agency_owned_registration_number,
  agency_owned_type,
  another_license,
  company_net_worth,
  company_technicians_education,
  company_technicians_name,
  deed_of_amendment_date,
  deed_of_amendment_number,
  deed_of_establishment_date,
  deed_of_establishment_number,
  email,
  faximile,
  //    img_name,
  institutional,
  kta_no,
  leader_name,
  name,
  npwp,
  npwp_date,
  owned_facility_factory,
  owned_facility_other,
  owned_facility_workshop,
  pak,
  pak_date,
  phone_number,
  position,
  qualification,
  region_name,
  siup,
  siup_date,
  // supporting_file_data,
  tdp,
  tdp_date,
  tools_produced_amount,
  tools_produced_item_name,
  tools_produced_registration_number,
  tools_produced_size,
}) => {
  const errors = {};

  if (!address) {
    errors.address = "Field harus diisi";
  }
  if (!agency_owned_item_name) {
    errors.agency_owned_item_name = "Field harus diisi";
  }
  if (!agency_owned_principal) {
    errors.agency_owned_principal = "Field harus diisi";
  }
  if (!agency_owned_registration_number) {
    errors.agency_owned_registration_number = "Field harus diisi";
  }
  if (!agency_owned_type) {
    errors.agency_owned_type = "Field harus diisi";
  }
  if (!another_license) {
    errors.another_license = "Field harus diisi";
  }
  if (!company_net_worth) {
    errors.company_net_worth = "Field harus diisi";
  }
  if (!company_technicians_education) {
    errors.company_technicians_education = "Field harus diisi";
  }
  if (!company_technicians_name) {
    errors.company_technicians_name = "Field harus diisi";
  }
  if (!deed_of_amendment_date) {
    errors.deed_of_amendment_date = "Field harus diisi";
  }
  if (!deed_of_amendment_number) {
    errors.deed_of_amendment_number = "Field harus diisi";
  }
  if (!deed_of_establishment_date) {
    errors.deed_of_establishment_date = "Field harus diisi";
  }
  if (!deed_of_establishment_number) {
    errors.deed_of_establishment_number = "Field harus diisi";
  }
  if (!email) {
    errors.email = "Field harus diisi";
  } else if (!regexEmail.test(email)) {
    errors.email = "Field tidak valid";
  }
  if (!faximile) {
    errors.faximile = "Field harus diisi";
  }
  if (!institutional) {
    errors.institutional = "Field harus diisi";
  }
  if (!kta_no) {
    errors.kta_no = "Field harus diisi";
  }
  if (!leader_name) {
    errors.leader_name = "Field harus diisi";
  }
  if (!name) {
    errors.name = "Field harus diisi";
  }
  if (!npwp) {
    errors.npwp = "Field harus diisi";
  }
  if (!npwp_date) {
    errors.npwp_date = "Field harus diisi";
  }
  if (!owned_facility_factory) {
    errors.owned_facility_factory = "Field harus diisi";
  }
  if (!owned_facility_other) {
    errors.owned_facility_other = "Field harus diisi";
  }
  if (!owned_facility_workshop) {
    errors.owned_facility_workshop = "Field harus diisi";
  }
  if (!pak) {
    errors.pak = "Field harus diisi";
  }
  if (!pak_date) {
    errors.pak_date = "Field harus diisi";
  }
  if (!phone_number) {
    errors.phone_number = "Field harus diisi";
  }
  if (!position) {
    errors.position = "Field harus diisi";
  }
  if (!qualification) {
    errors.qualification = "Field harus diisi";
  }
  if (!region_name) {
    errors.region_name = "Field harus diisi";
  }
  if (!siup) {
    errors.siup = "Field harus diisi";
  }
  if (!siup_date) {
    errors.siup_date = "Field harus diisi";
  }
  if (!tdp) {
    errors.tdp = "Field harus diisi";
  }
  if (!tdp_date) {
    errors.tdp_date = "Field harus diisi";
  }
  if (!tools_produced_amount) {
    errors.tools_produced_amount = "Field harus diisi";
  }
  if (!tools_produced_item_name) {
    errors.tools_produced_item_name = "Field harus diisi";
  }
  if (!tools_produced_registration_number) {
    errors.tools_produced_registration_number = "Field harus diisi";
  }
  if (!tools_produced_size) {
    errors.tools_produced_size = "Field harus diisi";
  }
  return errors;
};

Index = reduxForm({
  form: "memberEditAdministrasi",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(Index);

const mapStateToProps = ({
  member: { data, detailData, pending },
  region,
  kesehatan,
}) => {
  const path = window.location.pathname.split("/");
  let initialValues = {};
  let detail_agency, detail_produced;
  let labelSertification = [];
  if (detailData) {
    let certification =
      detailData.member_profile_data.owned_certification?.split(",");
    certification?.forEach((element) => {
      let temp = sertifikasiOptions.filter((item) => item.value === element);
      if (temp) labelSertification.push(temp[0]);
    });
    let member_agency_tool_data = detailData.member_agency_tool_data?.map(
      (item) => {
        return {
          name: item.name,
          medical_device_id: {
            label: item.medical_device_name,
            value: item.medical_device_id,
          },
        };
      }
    );
    let member_produced_tool_data = detailData.member_produced_tool_data?.map(
      (item) => {
        return {
          name: item.name,
          medical_device_id: {
            label: item.medical_device_name,
            value: item.medical_device_id,
          },
        };
      }
    );
    let category_agency = detailData.member_agency_tool_data?.map(
      (item) => item.medical_device_name
    );
    let category_produced = detailData.member_produced_tool_data?.map(
      (item) => item.medical_device_name
    );

    let filter_category_agency = category_agency?.filter(
      (item, index, self) => {
        return self.indexOf(item) === index;
      }
    );
    let filter_category_produced = category_produced?.filter(
      (item, index, self) => {
        return self.indexOf(item) === index;
      }
    );

    detail_agency = filter_category_agency?.map((item) => {
      let temp = detailData.member_agency_tool_data?.filter(
        (value) => value.medical_device_name === item
      );
      return {
        name: item,
        list: temp,
      };
    });

    detail_produced = filter_category_produced?.map((item) => {
      let temp = detailData.member_produced_tool_data?.filter(
        (value) => value.medical_device_name === item
      );
      return {
        name: item,
        list: temp,
      };
    });

    initialValues = {
      agency_owned_item_name:
        detailData.member_profile_data.agency_owned_item_name,
      agency_owned_principal:
        detailData.member_profile_data.agency_owned_principal,
      agency_owned_registration_number:
        detailData.member_profile_data.agency_owned_registration_number,
      agency_owned_type: detailData.member_profile_data.agency_owned_type,
      another_license: detailData.member_profile_data.another_license,
      company_net_worth: detailData.member_profile_data.company_net_worth,
      deed_of_amendment_date:
        detailData.member_profile_data.deed_of_amendment_date,
      deed_of_amendment_number:
        detailData.member_profile_data.deed_of_amendment_number,
      deed_of_amendment_filename:
        detailData.member_profile_data.deed_of_amendment_filename,
      deed_of_amendment_filename_temp:
        detailData.member_profile_data.deed_of_amendment_filename,
      deed_of_establishment_date:
        detailData.member_profile_data.deed_of_establishment_date,
      deed_of_establishment_number:
        detailData.member_profile_data.deed_of_establishment_number,
      deed_of_establishment_filename:
        detailData.member_profile_data.deed_of_establishment_filename,
      deed_of_establishment_filename_temp:
        detailData.member_profile_data.deed_of_establishment_filename,
      kta_no: detailData.member_profile_data.kta_no,
      npwp: detailData.member_profile_data.npwp,
      npwp_date: detailData.member_profile_data.npwp_date,
      owned_certification:
        detailData.member_profile_data.owned_certification &&
        labelSertification.length
          ? labelSertification
          : null,
      pak: detailData.member_profile_data.pak,
      pak_date: detailData.member_profile_data.pak_date,
      pak_filename: detailData.member_profile_data.pak_filename,
      pak_filename_temp: detailData.member_profile_data.pak_filename,
      qualification: detailData.member_profile_data.qualification,
      siup: detailData.member_profile_data.siup,
      siup_date: detailData.member_profile_data.siup_date,
      siup_filename: detailData.member_profile_data.siup_filename,
      siup_filename_temp: detailData.member_profile_data.siup_filename,
      tdp: detailData.member_profile_data.tdp,
      tdp_date: detailData.member_profile_data.tdp_date,
      tdp_filename: detailData.member_profile_data.tdp_filename,
      tdp_filename_temp: detailData.member_profile_data.tdp_filename,
      tools_produced_amount:
        detailData.member_profile_data.tools_produced_amount,
      tools_produced_item_name:
        detailData.member_profile_data.tools_produced_item_name,
      tools_produced_registration_number:
        detailData.member_profile_data.tools_produced_registration_number,
      tools_produced_size: detailData.member_profile_data.tools_produced_size,
      member_agency_tool_data: member_agency_tool_data,
      member_produced_tool_data: member_produced_tool_data,
    };
  }
  return {
    data,
    detailData,
    pending,
    initialValues,
    region,
    kesehatan,
    detail_agency,
    detail_produced,
    labelSertification,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetMemberModal: (modalType, isOpen, title, folderName, fileName) =>
      dispatch(setMemberModal(modalType, isOpen, title, folderName, fileName)),
    onSetMemberData: (data) => dispatch(setMemberData(data)),
  };
};

const styles = StyleSheet.create({
  dot: {
    height: 8,
    width: 8,
    borderRadius: 4,
    backgroundColor: "black",
    margin: "0px 10px",
  },
  icon: {
    position: "absolute",
    top: 0,
    height: 20,
    color: "#495057",
    width: 20,
    // left: 10,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
