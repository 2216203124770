import React from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";

const Index = ({ data }) => {
  console.log(data);

  return (
    <Column>
      <div className={css(styles.cardHeader)}></div>
      <Column
        className={css(styles.cardSupportWrapper)}
        style={{ width: "100%", minHeight: 320 }}
      >
        <Column
          className={css(styles.wrapperTop, styles.borderBottom)}
          style={{ width: "100%" }}
          vertical="center"
          // horizontal="center"
        >
          <label className={css(styles.titleLabel)}>Kelembagaan</label>
          <div className="col p-0">
            {data?.map((item, index) => (
              <div
                className="d-flex justify-content-between border-bottom"
                style={{ padding: "18px 0" }}
                key={index}
              >
                <label style={{ marginBottom: 0 }}>{item.name}</label>
                <label style={{ marginBottom: 0, color: "#B3B5C3" }}>
                  {item.total}
                </label>
              </div>
            ))}
          </div>
        </Column>
      </Column>
    </Column>
  );
};

const styles = StyleSheet.create({
  titleLabel: {
    color: "#495057",
    fontSize: 18,
    fontWeight: "bold",
    margin: "0px 0px 15px 0px",
  },
  cardLabel: {
    color: "#BCBCBC",
    marginBottom: 0,
  },
  cardSupportWrapper: {
    boxShadow: "0px 3px 6px #00000029",
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: "white",
    padding: 15,
  },
  cardHeader: {
    backgroundColor: "#FFC156",
    height: 10,
    width: "100%",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
  },
  supportWrapper: {
    background: "#F5F5F5 0% 0% no-repeat padding-box",
    borderRadius: 5,
    padding: 15,
    marginBottom: 10,
    height: 140,
  },
});

export default Index;
