import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Container from "../../../components/container";
import LeftContainer from "../../../components/container/LeftContainer";
import RightContainer from "../../../components/container/RightContainer";
import StatusSquare from "../../../components/global-components/StatusSquare";
import SetoranAdmin, {
  setSetoranAdminData,
  setSetoranAdminModal,
} from "../../../store/actions/setoran";
import Modal from "../modal";
import { Row } from "simple-flexbox";
import { Button } from "@material-ui/core";
import moment from "moment";
import { reduxForm } from "redux-form";
import Pagination from "@material-ui/lab/Pagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import Right from "./rightContent";
import { getUser } from "../../../utils/user";
import { StyleSheet, css } from "aphrodite";

let Index = ({
  pending,
  detailData,
  initialValues,
  onSetSetoranAdminModal,
}) => {
  const dispatch = useDispatch();
  const user = getUser();
  const path = window.location.pathname.split("/");
  const id = path.pop();

  useEffect(() => {
    getDetailSetoranAdmin();
  }, []);

  useEffect(() => {
    getDetailSetoranAdmin();
  }, [id]);

  const getDetailSetoranAdmin = () =>
    dispatch(SetoranAdmin.getDetail({ id: id }));

  const setModal = (modalType, isOpen, status) => {
    onSetSetoranAdminModal(modalType, isOpen, status);
  };

  let status = detailData?.status;

  return (
    <Container>
      <Modal />
      <Row>
        <LeftContainer>
          {pending ? (
            <CircularProgress color="primary" />
          ) : (
            <>
              <StatusSquare
                statusLabel={
                  status === 0
                    ? "Menunggu Diproses Admin Pusat"
                    : status === 1
                    ? "Setoran Diterima"
                    : status === 2
                    ? "Setoran Ditolak"
                    : "-"
                }
                statusTitle={
                  status === 0
                    ? "Diproses"
                    : status === 1
                    ? "Diterima"
                    : status === 2
                    ? "Ditolak"
                    : "-"
                }
                status={status}
              />
            </>
          )}
          {detailData?.status === 0 && user.role_code === "superadmin" && (
            <Row style={{ width: "100%" }} horizontal="space-between">
              <Button
                variant="contained"
                color="primary"
                disabled={pending}
                className={css(styles.approveButton)}
                onClick={() => setModal("approval", true, null)}
              >
                Verifikasi
              </Button>
              {/* <Button
                variant="contained"
                color="primary"
                disabled={pending}
                className={css(styles.rejectButton)}
                onClick={() => setModal("reject", true, null)}
              >
                Tolak
              </Button> */}
            </Row>
          )}
        </LeftContainer>
        <RightContainer>
          <Right />
        </RightContainer>
      </Row>
    </Container>
  );
};

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    // marginRight: 10,
    boxShadow: "none",
    width: "45%",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    // marginRight: 10,
    color: "#495057",
    boxShadow: "none",
    width: "45%",
  },
});

const validate = () => {
  const errors = {};
  return errors;
};

Index = reduxForm({
  form: "memberDetailSetoran",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(Index);

const mapStateToProps = ({ setoranAdmin }) => {
  const { detailData, pending } = setoranAdmin;
  let initialValues = {};

  if (detailData) {
  }
  return { detailData, pending, initialValues };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetSetoranAdminModal: (modalType, isOpen) =>
      dispatch(setSetoranAdminModal(modalType, isOpen)),
    onSetSetoranAdminData: (data) => dispatch(setSetoranAdminData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
