import axios from "axios";

let user = JSON.parse(localStorage.getItem("user_gakeslab"));

const instance = axios.create({
  baseURL: process.env.REACT_APP_GAKESLAB_URL,
  headers: { "x-auth-token": user !== null ? user.token : "" },
});

export default instance;
