import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Audit, {
  setAuditData,
  setAuditModal,
} from "../../../store/actions/system/audit-trail";
import TableStatus from "../../../components/global-components/TableStatus";
import { Row } from "simple-flexbox";
import MaterialTable from "material-table";
import SearchIcon from "@material-ui/icons/Search";
import { Paper } from "@material-ui/core";
import InputComponent from "../../../components/commons/form/input";
import SelectComponent from "../../../components/commons/form/select";
import Container from "../../../components/container";
import Modal from "./modal";
import debounce from "lodash.debounce";
import { history } from "../../../utils";
import { allRoleOptions, statusAuditOptions } from "../../../utils/constant";
import moment from "moment";

const Pengguna = ({ onSetAuditModal, onSetAuditData, pending, region }) => {
  const [searchText, setSearchText] = useState("");
  const [role, setRole] = useState("");
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();
  const tableRef = useRef();

  const handleRefresh = (state) => {
    setSearchText(state);
    tableRef.current && tableRef.current.onQueryChange();
  };

  const delayedQuery = debounce((value) => {
    return handleRefresh(value);
  }, 500);

  const handleSearchChange = (e) => {
    delayedQuery(e.target.value);
  };

  const handleRoleChange = (e) => {
    if (e) {
      setRole(e.value);
    } else {
      setRole("");
    }
    tableRef.current && tableRef.current.onQueryChange();
  };

  const handleStatusChange = (e) => {
    if (e) {
      setStatus(e.value);
    } else {
      setStatus("");
    }
    tableRef.current && tableRef.current.onQueryChange();
  };

  return (
    <Container>
      <Modal handleRefresh={(state) => handleRefresh(state)} />
      <Row className="m-3 justify-content-between">
        <div></div>
        <Row className="m-0 justify-content-between">
          <div style={{ width: 120, marginRight: 20 }}>
            <SelectComponent
              onChange={(e) => handleRoleChange(e)}
              placeholder="Role"
              options={allRoleOptions}
            />
          </div>
          <div style={{ width: 120, marginRight: 20 }}>
            <SelectComponent
              onChange={(e) => handleStatusChange(e)}
              placeholder="Status"
              options={statusAuditOptions}
            />
          </div>
          <InputComponent
            onChange={(e) => handleSearchChange(e)}
            placeholder="Cari nama pengguna"
            endIcon={SearchIcon}
          />
        </Row>
      </Row>
      <div className="m-3">
        <MaterialTable
          tableRef={tableRef}
          title="Audit"
          columns={[
            {
              title: "No",
              field: "no",
              width: 40,
            },
            {
              title: "Waktu",
              render: ({ created_at }) => {
                return created_at
                  ? moment(created_at).format("DD MMMM YYYY hh:mm")
                  : "-";
              },
            },
            {
              title: "Nama Pengguna",
              render: ({ name }) => {
                return name ? name : "-";
              },
            },
            {
              title: "Role",
              render: ({ role_name }) => {
                return role_name ? role_name : "-";
              },
            },
            {
              title: "Aktivitas",
              render: ({ activity }) => {
                return activity ? activity : "-";
              },
            },
            {
              title: "Status",
              render: ({ status }) => {
                return (
                  <TableStatus status={status === 200 ? "Berhasil" : "Gagal"} />
                );
              },
            },
          ]}
          data={
            // [
            //   {
            //     no: 1,
            //     created_at: "2021-05-21",
            //     user_fullname: "Fiizi",
            //     activity: "Tambah",
            //     role_name: "Admin Pusat",
            //     status: 1,
            //   },
            //   {
            //     no: 2,
            //     created_at: "2021-05-21",
            //     user_fullname: "Fiizi",
            //     activity: "Kurang",
            //     role_name: "Admin Pusat",
            //     status: 0,
            //   },
            //   {
            //     no: 3,
            //     created_at: "2021-05-21",
            //     user_fullname: "Fiizi",
            //     activity: "Kali",
            //     role_name: "Anggota",
            //     status: 1,
            //   },
            //   {
            //     no: 4,
            //     created_at: "2021-05-21",
            //     user_fullname: "Fiizi",
            //     activity: "Bagi",
            //     role_name: "Admin Cabang",
            //     status: 0,
            //   },
            //   {
            //     no: 5,
            //     created_at: "2021-05-21",
            //     user_fullname: "Fiizi",
            //     activity: "Edit",
            //     role_name: "Admin Cabang",
            //     status: 1,
            //   },
            // ]
            (q) =>
              new Promise((resolve) => {
                let param = {
                  page: q.page + 1,
                  length: 10,
                  search_text: searchText,
                  is_success: status,
                  role_code_user: role,
                };
                dispatch(Audit.get(param, resolve));
              })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            showTitle: false,
            search: false,
            sorting: false,
            headerStyle: {
              backgroundColor: "#fff",
              fontWeight: "bold",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
        />
      </div>
    </Container>
  );
};

const mapStateToProps = ({ audit: { pending } }) => {
  return { pending };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetAuditModal: (modalType, isOpen) =>
      dispatch(setAuditModal(modalType, isOpen)),
    onSetAuditData: (data) => dispatch(setAuditData(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Pengguna)
);
