import API from "../../API";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
import { setToastModal } from "../..";
import { toastError, toastSuccess } from "../../../../components/commons/toast";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_IURAN_MEMBER_PENDING = "GET_IURAN_MEMBER_PENDING";
export const GET_IURAN_MEMBER_SUCCESS = "GET_IURAN_MEMBER_SUCCESS";
export const GET_IURAN_MEMBER_ERROR = "GET_IURAN_MEMBER_ERROR";
export const GET_IURAN_DETAIL_MEMBER_PENDING =
  "GET_IURAN_DETAIL_MEMBER_PENDING";
export const GET_IURAN_DETAIL_MEMBER_SUCCESS =
  "GET_IURAN_DETAIL_MEMBER_SUCCESS";
export const GET_IURAN_DETAIL_MEMBER_ERROR = "GET_IURAN_DETAIL_MEMBER_ERROR";
export const GET_IURAN_RESUME_PENDING = "GET_IURAN_RESUME_PENDING";
export const GET_IURAN_RESUME_SUCCESS = "GET_IURAN_RESUME_SUCCESS";
export const GET_IURAN_RESUME_ERROR = "GET_IURAN_RESUME_ERROR";
export const POST_IURAN_MEMBER_PENDING = "POST_IURAN_MEMBER_PENDING";
export const POST_IURAN_MEMBER_SUCCESS = "POST_IURAN_MEMBER_SUCCESS";
export const POST_IURAN_MEMBER_ERROR = "POST_IURAN_MEMBER_ERROR";
export const APPROVE_IURAN_MEMBER_PENDING = "APPROVE_IURAN_MEMBER_PENDING";
export const APPROVE_IURAN_MEMBER_SUCCESS = "APPROVE_IURAN_MEMBER_SUCCESS";
export const APPROVE_IURAN_MEMBER_ERROR = "APPROVE_IURAN_MEMBER_ERROR";
export const PUT_IURAN_MEMBER_PENDING = "PUT_IURAN_MEMBER_PENDING";
export const PUT_IURAN_MEMBER_SUCCESS = "PUT_IURAN_MEMBER_SUCCESS";
export const PUT_IURAN_MEMBER_ERROR = "PUT_IURAN_MEMBER_ERROR";
export const DELETE_IURAN_MEMBER_PENDING = "DELETE_IURAN_MEMBER_PENDING";
export const DELETE_IURAN_MEMBER_SUCCESS = "DELETE_IURAN_MEMBER_SUCCESS";
export const DELETE_IURAN_MEMBER_ERROR = "DELETE_IURAN_MEMBER_ERROR";

export const IURAN_MEMBER_DATA = "IURAN_MEMBER_DATA";
export const SET_IURAN_MEMBER_MODAL = "SET_IURAN_MEMBER_MODAL";

// URL: URL_{URL}
const IURAN_MEMBER_URL = "/v1/member_dues";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_IURAN_MEMBER_PENDING));
  API.get(IURAN_MEMBER_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_IURAN_MEMBER_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_IURAN_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const getDetail = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_IURAN_DETAIL_MEMBER_PENDING));
  API.get(IURAN_MEMBER_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_IURAN_DETAIL_MEMBER_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_IURAN_DETAIL_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const getSetoran = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_IURAN_MEMBER_PENDING));
  API.get(IURAN_MEMBER_URL + "/find_for_deposit", { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_IURAN_MEMBER_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_IURAN_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const getResume = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_IURAN_RESUME_PENDING));
  API.get(IURAN_MEMBER_URL + "/resume", { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_IURAN_RESUME_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_IURAN_RESUME_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_IURAN_MEMBER_PENDING));
  API.post(IURAN_MEMBER_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_IURAN_MEMBER_SUCCESS, res));
      toastSuccess("Iuran berhasil dilakukan");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_IURAN_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const approve = (param, callback) => (dispatch) => {
  dispatch(actionPending(APPROVE_IURAN_MEMBER_PENDING));
  API.post(IURAN_MEMBER_URL + "/approve", param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(APPROVE_IURAN_MEMBER_SUCCESS, res));
      toastSuccess("Approve Iuran berhasil dilakukan");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(APPROVE_IURAN_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_IURAN_MEMBER_PENDING));
  API.put(IURAN_MEMBER_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_IURAN_MEMBER_SUCCESS, res));
      toastSuccess("Iuran berhasil diubah");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_IURAN_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_IURAN_MEMBER_PENDING));
  API.delete(IURAN_MEMBER_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_IURAN_MEMBER_SUCCESS, res));
      toastSuccess("Iuran berhasil dihapus");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_IURAN_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const IuranMember = { get, post, put, deleted, approve, getSetoran, getDetail, getResume };
export default IuranMember;

export const setIuranMemberData = (data) => (dispatch) =>
  dispatch({ type: IURAN_MEMBER_DATA, data });

export const setIuranMemberModal =
  (modalType, isOpen, title, folderName, fileName) => (dispatch) =>
    dispatch(
      actionSuccess(SET_IURAN_MEMBER_MODAL, {
        modalType: modalType,
        isOpen: isOpen,
        title: title,
        folderName: folderName,
        fileName: fileName,
      })
    );
