import React from "react";
import { connect, useDispatch } from "react-redux";
import { reduxForm } from "redux-form";
import { ModalBody, ModalHeader } from "reactstrap";
import { Button } from "@material-ui/core";
import PollingAdmin, {
  setPollingAdminModal,
} from "../../../../../store/actions/polling/admin";

let Delete = ({
  onSetPollingAdminModal,
  handleSubmit,
  detailData,
  detailDataQuestion,
  handleRefresh,
  pending,
}) => {
  const dispatch = useDispatch();

  const onSubmit = () => {
    const id = window.location.pathname.split("/").pop();
    let indexRemove = detailData.list_questions.findIndex((item) => {
      return item.number === detailDataQuestion.number;
    });

    let tempQuestion = detailData.list_questions;
    tempQuestion.splice(indexRemove, 1);
    let tempSortQuestion = tempQuestion.map((item, index) => {
      return {
        ...item,
        number: index + 1,
      };
    });
    const param = {
      ...detailData,
      id: id,
      list_questions: tempSortQuestion,
    };
    const callback = () => {
      onSetPollingAdminModal("", false);
      handleRefresh();
    };
    dispatch(PollingAdmin.put(param, callback));
  };
  return (
    <>
      <ModalHeader>Hapus Pertanyaan</ModalHeader>
      <ModalBody>
        Apakah anda yakin untuk menghapus pertanyaan ini ?
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex justify-content-between">
            <Button
              variant="outlined"
              className="mt-3"
              disabled={pending}
              color="primary"
              onClick={() => onSetPollingAdminModal("", false)}
            >
              Batal
            </Button>
            <Button
              type="submit"
              disabled={pending}
              className="mt-3"
              variant="contained"
              color="primary"
            >
              Hapus
            </Button>
          </div>
        </form>
      </ModalBody>
    </>
  );
};

Delete = reduxForm({
  form: "pollingAdminDelete",
})(Delete);

const mapStateToProps = ({
  pollingAdmin: { data, detailDataQuestion, pending },
}) => {
  const detailData = data?.data?.data[0];
  return {
    detailDataQuestion,
    detailData,
    pending,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetPollingAdminModal: (modalType, isOpen) =>
      dispatch(setPollingAdminModal(modalType, isOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Delete);
