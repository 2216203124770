import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Container from "../../../../components/container";
import LeftContainer from "../../../../components/container/LeftContainer";
import RightContainer from "../../../../components/container/RightContainer";
import DetailQuestion from "../../../../components/detail-content/list-question";
import StatusSquare from "../../../../components/global-components/StatusSquare";
import IuranMember, {
  setIuranMemberData,
  setIuranMemberModal,
} from "../../../../store/actions/iuran/member";
import { Row } from "simple-flexbox";
import { Button } from "@material-ui/core";
import moment from "moment";
import { reduxForm } from "redux-form";
import Pagination from "@material-ui/lab/Pagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import Right from "./rightContent";
import Modal from "../modal";

let Index = ({ pending, detailData, initialValues }) => {
  const dispatch = useDispatch();
  const path = window.location.pathname.split("/");
  const id = path.pop();

  useEffect(() => {
    getDetailIuran();
  }, []);

  useEffect(() => {
    getDetailIuran();
  }, [id]);

  const getDetailIuran = () => dispatch(IuranMember.getDetail({ id: id }));

  return (
    <Container>
      <Modal />
      <Row>
        <LeftContainer>
          {pending ? (
            <CircularProgress color="primary" />
          ) : (
            <>
              <StatusSquare
                statusTitle={
                  detailData?.status === 0
                    ? "Diproses"
                    : detailData?.status === 1
                    ? "Menunggu"
                    : detailData?.status === 2
                    ? "Aktif"
                    : "Ditolak"
                }
                statusLabel={
                  detailData?.status === 0
                    ? "Diproses Admin Cabang"
                    : detailData?.status === 1
                    ? "Diproses Admin Pusat"
                    : detailData?.status === 2
                    ? "Iuran telah diterima"
                    : "Iuran telah ditolak"
                }
                status={
                  detailData?.status === 0 || detailData?.status === 1
                    ? 0
                    : detailData?.status === 2
                    ? 1
                    : 2
                }
              />
            </>
          )}
        </LeftContainer>
        <RightContainer>
          <Right />
        </RightContainer>
      </Row>
    </Container>
  );
};

const validate = () => {
  const errors = {};
  return errors;
};

Index = reduxForm({
  form: "memberDetailIuran",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(Index);

const mapStateToProps = ({ iuranMember }) => {
  const { detailData, pending } = iuranMember;
  let initialValues = {};

  return { detailData, pending, initialValues };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetIuranMemberModal: (modalType, isOpen) =>
      dispatch(setIuranMemberModal(modalType, isOpen)),
    onSetIuranMemberData: (data) => dispatch(setIuranMemberData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
