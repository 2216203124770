import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Container from "../../../components/container";
import LeftContainer from "../../../components/container/LeftContainer";
import RightContainer from "../../../components/container/RightContainer";
import DetailMenu from "../../../components/global-components/DetailMenu";
import DetailContent from "../../../components/global-components/DetailContent";
import DetailContentLihatBerkas from "../../../components/global-components/DetailContent/LihatBerkas";
import MessageBlast, {
  setMessageBlastData,
  setMessageBlastModal,
} from "../../../store/actions/message-blast";
import { Row, Column } from "simple-flexbox";
import Modal from "../modal";
import moment from "moment";
import { MESSAGE_BLAST_FILE } from "../../../utils/constant";
import CircularProgress from "@material-ui/core/CircularProgress";

let Index = ({ detailData, onSetMessageBlastModal, pending }) => {
  const dispatch = useDispatch();
  const id = window.location.pathname.split("/").pop();

  useEffect(() => {
    getDetailMessage();
  }, []);

  useEffect(() => {
    getDetailMessage();
  }, [id]);

  const getDetailMessage = () => dispatch(MessageBlast.getDetail({ id: id }));

  const setModal = (modalType, isOpen, title, folderName, fileName) => {
    onSetMessageBlastModal(modalType, isOpen, title, folderName, fileName);
  };

  return (
    <Container>
      <Modal />
      <Row>
        <LeftContainer>
          <h5>{detailData?.title}</h5>
        </LeftContainer>
        <RightContainer>
          {pending || !detailData ? (
            <CircularProgress
              // size={14}
              color="primary"
              // style={{ marginRight: 10 }}
            />
          ) : (
            <>
              <DetailContent
                label="Tanggal"
                value={
                  detailData.created_at
                    ? moment(detailData.created_at).format("DD MMMM YYYY hh:mm")
                    : "-"
                }
              />
              <DetailContent
                label="Pesan"
                alignItems="baseline"
                value={
                  detailData.body ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: detailData.body }}
                    ></div>
                  ) : (
                    "-"
                  )
                }
              />
              <DetailContent
                label="Penerima"
                alignItems="baseline"
                style={{ maxHeight: 125, overflowY: "auto" }}
                value={
                  detailData.receiver_names &&
                  detailData.receiver_names.length > 0
                    ? detailData.receiver_names.map((item, idx) => {
                        return (
                          <React.Fragment key={idx}>
                            {idx + 1}. {item}
                            <br />
                            <br />
                          </React.Fragment>
                        );
                      })
                    : "-"
                }
              />
              <DetailContent
                label="Berkas"
                alignItems="baseline"
                value={
                  detailData.attachments && detailData.attachments.length > 0
                    ? detailData.attachments.map((item, idx) => {
                        let index = idx + 1;
                        return (
                          <div>
                            <DetailContentLihatBerkas
                              display="inline"
                              key={idx}
                              onClick={() =>
                                setModal(
                                  "show-document",
                                  true,
                                  "Berkas " + index,
                                  MESSAGE_BLAST_FILE,
                                  item
                                )
                              }
                            />
                            <br />
                          </div>
                        );
                      })
                    : "-"
                }
              />
            </>
          )}
        </RightContainer>
      </Row>
    </Container>
  );
};

const mapStateToProps = ({
  messageBlast: { data, detailData: res, pending },
}) => {
  const detailData = res?.data?.data[0];
  return { data, detailData, pending };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetMessageBlastModal: (modalType, isOpen, title, folderName, fileName) =>
      dispatch(
        setMessageBlastModal(modalType, isOpen, title, folderName, fileName)
      ),
    onSetMessageBlastData: (data) => dispatch(setMessageBlastData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
