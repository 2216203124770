import React, { useRef } from "react";
import { StyleSheet, css } from "aphrodite";

const Index = ({ title, vote, total, higher }) => {
  const length = Math.round((vote / total) * 100);
  return (
    <>
      <div className={css(styles.container, higher && styles.higher)}>
        <div className="col p-0">
          <div className="mb-1">
            <span>{title ? title : "-"}</span>
            <span style={{ marginLeft: 5, fontWeight: "bold", fontSize: 12 }}>
              {vote}/{total}
            </span>
          </div>
          <div className="d-flex align-items-center">
            <div
              style={{
                backgroundColor: "#2CB96A",
                width: `${length - 6}%`,
                height: 15,
                marginRight: 5,
              }}
            />
            <div>
              <span
                style={{ color: "#2CB96A", fontWeight: "bold", fontSize: 12 }}
              >
                {length ? length : 0} %
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fafafa",
    marginBottom: 20,
    padding: 20,
    borderRadius: 5,
  },
  higher: {
    border: "1px solid #2CB96A",
  },
});

export default Index;
