import {
  GET_POLLING_ADMIN_PENDING,
  GET_POLLING_ADMIN_SUCCESS,
  GET_POLLING_ADMIN_ERROR,
  GET_DETAIL_ANSWER_POLLING_ADMIN_PENDING,
  GET_DETAIL_ANSWER_POLLING_ADMIN_SUCCESS,
  GET_DETAIL_ANSWER_POLLING_ADMIN_ERROR,
  GET_DETAIL_SUMMARY_POLLING_ADMIN_PENDING,
  GET_DETAIL_SUMMARY_POLLING_ADMIN_SUCCESS,
  GET_DETAIL_SUMMARY_POLLING_ADMIN_ERROR,
  POST_POLLING_ADMIN_PENDING,
  POST_POLLING_ADMIN_SUCCESS,
  POST_POLLING_ADMIN_ERROR,
  PUT_POLLING_ADMIN_PENDING,
  PUT_POLLING_ADMIN_SUCCESS,
  PUT_POLLING_ADMIN_ERROR,
  DELETE_POLLING_ADMIN_PENDING,
  DELETE_POLLING_ADMIN_SUCCESS,
  DELETE_POLLING_ADMIN_ERROR,
  POLLING_ADMIN_DATA,
  POLLING_ADMIN_DATA_QUESTION,
  POLLING_ADMIN_DATA_ANSWER,
  SET_POLLING_ADMIN_MODAL,
} from "../../../actions/polling/admin";

const initialState = {
  pending: false,
  error: null,
  data: null,
  detailData: null,
  isOpenModal: false,
  modalType: "",
  detailDataQuestion: null,
  detailDataAnswer: null,
};

const pollingAdmin = (state = initialState, action) => {
  switch (action.type) {
    case GET_POLLING_ADMIN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_POLLING_ADMIN_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_POLLING_ADMIN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case GET_DETAIL_ANSWER_POLLING_ADMIN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_DETAIL_ANSWER_POLLING_ADMIN_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_DETAIL_ANSWER_POLLING_ADMIN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case GET_DETAIL_SUMMARY_POLLING_ADMIN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_DETAIL_SUMMARY_POLLING_ADMIN_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_DETAIL_SUMMARY_POLLING_ADMIN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POST_POLLING_ADMIN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case POST_POLLING_ADMIN_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case POST_POLLING_ADMIN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case PUT_POLLING_ADMIN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case PUT_POLLING_ADMIN_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case PUT_POLLING_ADMIN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case DELETE_POLLING_ADMIN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_POLLING_ADMIN_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case DELETE_POLLING_ADMIN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POLLING_ADMIN_DATA:
      return {
        ...state,
        detailData: action.data,
      };
    case POLLING_ADMIN_DATA_QUESTION:
      return {
        ...state,
        detailDataQuestion: action.data,
      };
    case POLLING_ADMIN_DATA_ANSWER:
      return {
        ...state,
        detailDataAnswer: action.data,
      };
    case SET_POLLING_ADMIN_MODAL:
      return {
        ...state,
        isOpenModal: action.data.isOpen,
        modalType: action.data.modalType,
      };
    default:
      return state;
  }
};

export default pollingAdmin;
