import React, { useRef, useState } from "react";
import { StyleSheet, css } from "aphrodite";
import { reduxForm, Field } from "redux-form";
import { formInput, formCheckbox, formValidation } from "../../commons/form";
import Slider from "../../commons/form/slider";
import Radio from "../../commons/form/radio";

const Index = ({ data, validate, show, isDetail, value, otherCheck }) => {
  const [isOtherRadio, setIsOtherRadio] = useState(
    data.type === 2 ? value : ""
  );
  const [isOtherCheck, setIsOtherCheck] = useState(
    otherCheck ? otherCheck : false
  );
  let optionsRadio = {};
  let hasOther = data.multiple_questions?.some(
    (item) => item.value === "Other"
  );
  let multiQuest = data.multiple_questions?.filter(
    (item) => item.value !== "Other"
  );
  if (hasOther) {
    if (data.type === 2) {
      optionsRadio["other"] = "Lainnya";
    }
  }
  if (data.type === 2) {
    multiQuest.map((item) => {
      return (optionsRadio[item.key] = item.value);
    });
  }

  return (
    <div className={css([styles.list, show ? null : styles.hidden])}>
      {data.title}
      {data.type === 1 && (
        <Field
          name={"number" + data.number.toString()}
          placeholder="Masukkan Jawaban"
          component={formInput}
          disabled={isDetail}
          // validate={validate}
        />
      )}
      {data.type === 2 && (
        <>
          <Field
            name={"number" + data.number.toString()}
            component={Radio}
            options={optionsRadio}
            disabled={isDetail}
            onChange={(e) => {
              setIsOtherRadio(e);
            }}
            // validate={validate}
          />
          {isOtherRadio === "other" && (
            <Field
              name={"other_number" + data.number.toString()}
              disabled={isDetail}
              placeholder="Masukkan Jawaban"
              component={formInput}
              // validate={validate}
            />
          )}
        </>
      )}
      {data.type === 3 && (
        <>
          {multiQuest.map((item, index) => {
            return (
              <Field
                name={"number" + data.number.toString() + `[${index}]`}
                disabled={isDetail}
                component={formCheckbox}
                label={item.value}
                key={index}
                // validate={validate}
              />
            );
          })}
          {hasOther && (
            <Field
              name={"other_number" + data.number.toString() + "_checkbox"}
              disabled={isDetail}
              component={formCheckbox}
              label="Lainnya"
              onChange={(e) => {
                setIsOtherCheck(e.target.checked);
              }}
              // validate={validate}
            />
          )}
          {isOtherCheck && (
            <Field
              name={"other_number" + data.number.toString()}
              disabled={isDetail}
              placeholder="Masukkan Jawaban"
              component={formInput}
              // validate={validate}
            />
          )}
        </>
      )}
      {data.type === 4 && (
        <Field
          name={"number" + data.number.toString()}
          disabled={isDetail}
          min={data.min_scale}
          max={data.max_scale}
          component={Slider}
          // validate={validate}
        />
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  list: {
    background: "#FAFAFA",
    border: "1px solid #D8D9DA",
    boxSizing: "border-box",
    borderRadius: "5px",
    padding: "20px",
    marginBottom: "20px",
  },
  hidden: {
    display: "none",
  },
});

export default Index;
