import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import PollingMember, {
  setPollingMemberData,
  setPollingMemberModal,
} from "../../../store/actions/polling/member";
import MaterialTable from "material-table";
import { Paper, Button } from "@material-ui/core";
import Container from "../../../components/container";
import Modal from "./modal";
import { history } from "../../../utils";
import { isInTime, isMoreTime } from "../../../utils/date";
import moment from "moment";

const Index = ({
  onSetPollingMemberModal,
  onSetPollingMemberData,
  pending,
}) => {
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const tableRef = useRef();

  const handleRefresh = (state) => {
    setSearchText(state);
    tableRef.current && tableRef.current.onQueryChange();
  };

  const setDetail = (data) => {
    onSetPollingMemberData(data);
    history.push(`/polling/detail/${data.id}`);
  };

  const setVoting = (data) => {
    onSetPollingMemberData(data);
    history.push(`/polling/${data.id}`);
  };

  return (
    <Container>
      <Modal handleRefresh={(state) => handleRefresh(state)} />
      <div className="m-3">
        <MaterialTable
          tableRef={tableRef}
          title="PollingMember"
          columns={[
            {
              title: "No",
              field: "no",
              width: 40,
            },
            {
              title: "Judul Polling",
              render: ({ title }) => {
                return title ? title : "-";
              },
            },
            {
              title: "Tanggal Mulai",
              render: ({ start_date }) => {
                return start_date
                  ? moment(start_date).format("DD MMM YYYY")
                  : "-";
              },
            },
            {
              title: "Tanggal Berakhir",
              render: ({ end_date }) => {
                return end_date ? moment(end_date).format("DD MMM YYYY") : "-";
              },
            },
            {
              title: "Aksi",
              width: 80,
              cellStyle: {
                paddingLeft: 0,
              },
              render: (rowData) => {
                return (
                  <div className="m-2">
                    {rowData.is_voting ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        // disabled={
                        //   rowData.is_voting ||
                        //   !isInTime(rowData.start_date, rowData.end_date) ||
                        //   isMoreTime(rowData.start_time)
                        // }
                        onClick={() => setDetail(rowData)}
                      >
                        Detail
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={
                          !isInTime(rowData.start_date, rowData.end_date) ||
                          isMoreTime(rowData.start_time)
                        }
                        onClick={() => setVoting(rowData)}
                      >
                        Vote
                      </Button>
                    )}
                  </div>
                );
              },
            },
          ]}
          data={(q) =>
            new Promise((resolve) => {
              let param = {
                page: q.page + 1,
                length: 10,
                search_text: searchText,
              };
              dispatch(PollingMember.get(param, resolve));
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            showTitle: false,
            search: false,
            sorting: false,
            headerStyle: {
              backgroundColor: "#fff",
              fontWeight: "bold",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
        />
      </div>
    </Container>
  );
};

const mapStateToProps = ({ pollingMember: { pending } }) => {
  return { pending };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetPollingMemberModal: (modalType, isOpen) =>
      dispatch(setPollingMemberModal(modalType, isOpen)),
    onSetPollingMemberData: (data) => dispatch(setPollingMemberData(data)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
