import React, { useEffect, useState } from "react";
import { formInput, formFile } from "../../../../components/commons/form";
import { MEMBER_SUPPORT_FILE } from "../../../../utils/constant";
import File from "../../../../store/actions/file";
import { Field, change } from "redux-form";
import { Row, Column } from "simple-flexbox";
import CancelIcon from "@material-ui/icons/Cancel";
import { connect, useDispatch } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import ErrorIcon from "@material-ui/icons/Error";
import AddIcon from "@material-ui/icons/Add";

let RenderArray = ({
  fields,
  meta,
  isDetail,
  pending,
  file,
  change,
  callValueFile,
}) => {
  const dispatch = useDispatch();
  const { submitFailed, error } = meta;
  const [posisi, setPosisi] = useState(0);
  const [arrayFile, setArrayFile] = useState([]);

  const uploadFile = (e, index) => {
    let formData = new FormData();
    formData.append("file", e);

    const callback = (res) => {
      setPosisi(index);
      setArrayFile((prevArray) => {
        prevArray[index] = res.data.data.fileName;
        return [...prevArray];
      });
    };

    dispatch(File.upload(formData, callback, MEMBER_SUPPORT_FILE));
  };

  if (fields.length > 0 && fields.length === arrayFile.length) {
    callValueFile(arrayFile);
  }

  return (
    <>
      {submitFailed && error && (
        <Row
          style={{
            height: 40,
            color: "#ED2A2A",
            //   border: "1px solid #F9D7D7",
            borderRadius: 3,
            marginBottom: 20,
            marginTop: 20,
            backgroundColor: "#FFDEDE",
            //   opacity: 0.5,
          }}
          horizontal="center"
          vertical="center"
          className="error"
        >
          <span>
            <ErrorIcon style={{ marginRight: 10 }} />
            {error}
          </span>
        </Row>
      )}
      {fields.map((supporting_file_data, index) => {
        return (
          <Row
            horizontal="space-between"
            key={index}
            className="m-3 shadow rounded p-3"
          >
            <div className="d-flex flex-column w-100 mr-2">
              <div className="mb-2">
                <Field
                  isDetail={isDetail}
                  name={`${supporting_file_data}.name`}
                  placeholder="Masukkan Nama Berkas"
                  component={formInput}
                />
              </div>
              <Field
                isDetail={isDetail}
                name={`${supporting_file_data}.file`}
                type="file"
                fileType="pdf/image"
                title="Masukkan Berkas"
                message="PDF / JPG"
                onChange={(e) => uploadFile(e, index)}
                component={formFile}
              />
            </div>
            {!isDetail && (
              <CancelIcon
                className={css(styles.closeButton)}
                onClick={() => fields.remove(index)}
              />
            )}
          </Row>
        );
      })}
      {!isDetail && (
        <Column horizontal="center" vertical="center" className="my-3">
          <div
            style={{
              width: "100%",
              borderBottom: "2px solid #EDEDEE",
              top: 18,
              position: "relative",
            }}
          />
          <div
            style={{
              padding: "0px 10px",
              backgroundColor: "white",
              zIndex: 0,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              className={css(styles.approveButton)}
              startIcon={<AddIcon />}
              onClick={() => fields.push()}
              disabled={pending}
            >
              Tambah Slot Berkas
            </Button>
          </div>
        </Column>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  closeButton: {
    color: "#CDCFD1",
    marginTop: 7,
    ":hover": {
      color: "#ED2A2A",
    },
  },
  approveButton: {
    zIndex: 0,
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
    // paddingBottom: "10px",
  },
});

const mapStateToProps = ({ file }) => {
  const { pending } = file;

  return { file, pending };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RenderArray);
