import API from "../API";
import { actionPending, actionSuccess, actionError } from "../actionTypes";
import { setToastModal } from "../../actions";
import { toastError, toastSuccess } from "../../../components/commons/toast";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_DASHBOARD_AGENCY_PENDING = "GET_DASHBOARD_AGENCY_PENDING";
export const GET_DASHBOARD_AGENCY_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_AGENCY_ERROR = "GET_DASHBOARD_AGENCY_ERROR";
export const GET_DASHBOARD_PRODUCED_PENDING = "GET_DASHBOARD_PRODUCED_PENDING";
export const GET_DASHBOARD_PRODUCED_SUCCESS = "GET_DASHBOARD_PRODUCED_SUCCESS";
export const GET_DASHBOARD_PRODUCED_ERROR = "GET_DASHBOARD_PRODUCED_ERROR";
export const GET_DASHBOARD_DISTRIBUTION_PENDING =
  "GET_DASHBOARD_DISTRIBUTION_PENDING";
export const GET_DASHBOARD_DISTRIBUTION_SUCCESS =
  "GET_DASHBOARD_DISTRIBUTION_SUCCESS";
export const GET_DASHBOARD_DISTRIBUTION_ERROR =
  "GET_DASHBOARD_DISTRIBUTION_ERROR";
export const GET_DASHBOARD_STATISTIC_PENDING =
  "GET_DASHBOARD_STATISTIC_PENDING";
export const GET_DASHBOARD_STATISTIC_SUCCESS =
  "GET_DASHBOARD_STATISTIC_SUCCESS";
export const GET_DASHBOARD_STATISTIC_ERROR = "GET_DASHBOARD_STATISTIC_ERROR";
export const GET_DASHBOARD_TOTAL_PENDING = "GET_DASHBOARD_TOTAL_PENDING";
export const GET_DASHBOARD_TOTAL_SUCCESS = "GET_DASHBOARD_TOTAL_SUCCESS";
export const GET_DASHBOARD_TOTAL_ERROR = "GET_DASHBOARD_TOTAL_ERROR";
export const GET_DASHBOARD_LOCATION_PENDING = "GET_DASHBOARD_LOCATION_PENDING";
export const GET_DASHBOARD_LOCATION_SUCCESS = "GET_DASHBOARD_LOCATION_SUCCESS";
export const GET_DASHBOARD_LOCATION_ERROR = "GET_DASHBOARD_LOCATION_ERROR";
export const GET_DASHBOARD_TOTAL_INSTITUTIONAL_PENDING =
  "GET_DASHBOARD_TOTAL_INSTITUTIONAL_PENDING";
export const GET_DASHBOARD_TOTAL_INSTITUTIONAL_SUCCESS =
  "GET_DASHBOARD_TOTAL_INSTITUTIONAL_SUCCESS";
export const GET_DASHBOARD_TOTAL_INSTITUTIONAL_ERROR =
  "GET_DASHBOARD_TOTAL_INSTITUTIONAL_ERROR";

export const DASHBOARD_DATA = "DASHBOARD_DATA";
export const SET_DASHBOARD_MODAL = "SET_DASHBOARD_MODAL";

// URL: URL_{URL}
const DASHBOARD_URL = "/v1/dashboard";

const getAgency = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DASHBOARD_AGENCY_PENDING));
  API.get(DASHBOARD_URL + "/find_category_agency_member", { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DASHBOARD_AGENCY_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DASHBOARD_AGENCY_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const getProduced = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DASHBOARD_PRODUCED_PENDING));
  API.get(DASHBOARD_URL + "/find_category_produced_tools_member", {
    params: param,
  })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DASHBOARD_PRODUCED_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DASHBOARD_PRODUCED_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const getDistribution = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DASHBOARD_DISTRIBUTION_PENDING));
  API.get(DASHBOARD_URL + "/find_member_distribution", { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DASHBOARD_DISTRIBUTION_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DASHBOARD_DISTRIBUTION_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const getLocation = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DASHBOARD_LOCATION_PENDING));
  API.get(DASHBOARD_URL + "/find_region_distribution", { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DASHBOARD_LOCATION_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DASHBOARD_LOCATION_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const getStatistic = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DASHBOARD_STATISTIC_PENDING));
  API.get(DASHBOARD_URL + "/find_statistics_member", { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DASHBOARD_STATISTIC_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DASHBOARD_STATISTIC_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const getTotal = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DASHBOARD_TOTAL_PENDING));
  API.get(DASHBOARD_URL + "/find_total_member", { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DASHBOARD_TOTAL_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DASHBOARD_TOTAL_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const getTotalInstitutional =
  (param, resolve, reject, callback) => (dispatch) => {
    dispatch(actionPending(GET_DASHBOARD_TOTAL_INSTITUTIONAL_PENDING));
    API.get(DASHBOARD_URL + "/find_total_member_by_institutional", {
      params: param,
    })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(GET_DASHBOARD_TOTAL_INSTITUTIONAL_SUCCESS, res));
        const records_total = res.data.recordsTotal;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        if (resolve) {
          resolve({
            data: data,
            page: param?.page - 1,
            totalCount: records_total,
          });
        }
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        dispatch(actionError(GET_DASHBOARD_TOTAL_INSTITUTIONAL_ERROR));
        toastError(err?.response?.data?.message);
      });
  };

const Dashboard = {
  getAgency,
  getProduced,
  getDistribution,
  getLocation,
  getStatistic,
  getTotal,
  getTotalInstitutional,
};
export default Dashboard;

export const setDashboardData = (data) => (dispatch) =>
  dispatch({ type: DASHBOARD_DATA, data });

export const setDashboardModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_DASHBOARD_MODAL, { modalType: modalType, isOpen: isOpen })
  );
