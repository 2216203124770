import React from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import CountUp from "react-countup";

const Index = (props) => {
  const { iuran_total, card_label, img_url } = props;
  return (
    <Row
      className={css(styles.cardWrapper)}
      style={{ width: "100%", height: "100%" }}
      horizontal="space-between"
    >
      <img
        style={{ height: 70, width: 70, marginRight: 30 }}
        src={require("../../../../assets/img/" + img_url).default}
        alt="Iuran"
      ></img>
      <Column vertical="center" className={css(styles.detailColumn)}>
        <label className={css(styles.iuranTotal)}>
          <CountUp end={parseInt(iuran_total)} separator="." />
        </label>
        <label className={css(styles.cardLabel)}>{card_label}</label>
      </Column>
    </Row>
  );
};

const styles = StyleSheet.create({
  iuranTotal: {
    color: "#495057",
    fontSize: 30,
    fontWeight: "bold",
    // margin: "5px 0px 30px 0px",
  },
  cardLabel: {
    color: "#BCBCBC",
    marginBottom: 0,
  },
  detailColumn: {
    textAlign: "right",
  },
  cardWrapper: {
    boxShadow: "0px 3px 6px #00000029",
    backgroundColor: "white",
    borderRadius: 5,
    padding: 15,
  },
});

export default Index;
