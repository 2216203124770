import API from "../API";
import { actionPending, actionSuccess, actionError } from "../actionTypes";
import { toastSuccess, toastError } from "../../../components/commons/toast";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_FILE_PENDING = "GET_FILE_PENDING";
export const GET_FILE_SUCCESS = "GET_FILE_SUCCESS";
export const GET_FILE_ERROR = "GET_FILE_ERROR";
export const UPLOAD_FILE_PENDING = "UPLOAD_FILE_PENDING";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_ERROR = "UPLOAD_FILE_ERROR";

export const FILE_DATA = "FILE_DATA";
export const SET_FILE_MODAL = "SET_FILE_MODAL";

// URL: URL_{URL}
const FILE_URL = "/v1/file";

const get = (param) => (dispatch) => {
  dispatch(actionPending(GET_FILE_PENDING));
  API.get(`${FILE_URL}/${param.folderName}/${param.fileName}`, {
    params: param,
    responseType: "blob",
  })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_FILE_SUCCESS, res));
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", param.fileName);
      document.body.appendChild(link);
      link.click();

      return res;
    })
    .catch((err) => {
      dispatch(actionError(UPLOAD_FILE_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const upload = (param, callback, folderName) => (dispatch) => {
  dispatch(actionPending(UPLOAD_FILE_PENDING));
  API.post(`${FILE_URL}/${folderName}/upload`, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(UPLOAD_FILE_SUCCESS, res));
      // toastSuccess("Upload File berhasil ditambahkan");
      if (callback) {
        callback(res);
      }
    })
    .catch((err) => {
      dispatch(actionError(UPLOAD_FILE_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const File = { get, upload };
export default File;

export const setFileData = (data) => (dispatch) =>
  dispatch({ type: FILE_DATA, data });

export const setFileModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_FILE_MODAL, {
      modalType: modalType,
      isOpen: isOpen,
    })
  );
