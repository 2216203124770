import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import MessageBlast, {
  setMessageBlastData,
  setMessageBlastModal,
} from "../../store/actions/message-blast";
import { Row } from "simple-flexbox";
import MaterialTable from "material-table";
import { Paper, Button, MenuItem } from "@material-ui/core";
import DetailButtonComponent from "../../components/global-components/DetailButton";
import SelectComponent from "../../components/commons/form/select";
import Container from "../../components/container";
import Modal from "./modal";
import debounce from "lodash.debounce";
import { history } from "../../utils";
import Region from "../../store/actions/master/region";
import moment from "moment";

const Index = ({
  onSetMessageBlastModal,
  onSetMessageBlastData,
  pending,
  region,
}) => {
  const [searchText, setSearchText] = useState("");
  const [regionId, setRegionId] = useState("");
  const dispatch = useDispatch();
  const tableRef = useRef();

  const handleRefresh = (state) => {
    setSearchText(state);
    tableRef.current && tableRef.current.onQueryChange();
  };

  const delayedQuery = debounce((value) => {
    return handleRefresh(value);
  }, 500);

  const handleSearchChange = (e) => {
    delayedQuery(e.target.value);
  };

  const setModal = (modalType, isOpen, data) => {
    onSetMessageBlastModal(modalType, isOpen);
    onSetMessageBlastData(data);
  };

  const goDetail = (data) => {
    // onSetMessageBlastData(data);
    history.push(`/message-blast/detail/${data.id}`);
  };

  const handleRegionChange = (e) => {
    if (e) {
      setRegionId(e.value);
    } else {
      setRegionId("");
    }
    tableRef.current && tableRef.current.onQueryChange();
  };

  let regionOptions;
  if (region.data) {
    regionOptions = region.data.data.data.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  }

  useEffect(() => {
    getRegion();
  }, []);

  const getRegion = () => dispatch(Region.get());

  return (
    <Container>
      <Modal handleRefresh={(state) => handleRefresh(state)} />
      <Row className="m-3 justify-content-between">
        <Button
          color="primary"
          variant="contained"
          disabled={pending}
          onClick={() => history.push(`/message-blast/add`)}
        >
          Tambah Message
        </Button>
        {/* <div style={{ width: 120, marginRight: 20 }}>
          <SelectComponent
            onChange={(e) => handleRegionChange(e)}
            placeholder="Wilayah"
            options={regionOptions}
            isAsync
            asyncUrl="/v1/region"
          />
        </div> */}
      </Row>
      <div className="m-3">
        <MaterialTable
          tableRef={tableRef}
          title="MessageBlast"
          columns={[
            {
              title: "No",
              field: "no",
              width: 40,
            },
            {
              title: "Tanggal Kirim",
              render: ({ created_at }) => {
                return created_at
                  ? moment(created_at).format("DD MMM YYYY hh:mm")
                  : "-";
              },
            },
            {
              title: "Judul Email",
              render: ({ title }) => {
                return title ? title : "-";
              },
            },
            {
              title: "Total Penerima",
              render: ({ receiver_emails }) => {
                return receiver_emails && receiver_emails.length > 0
                  ? receiver_emails.length
                  : "-";
              },
            },
            {
              title: "Aksi",
              width: 80,
              cellStyle: {
                paddingLeft: 0,
              },
              render: (rowData) => {
                return (
                  <DetailButtonComponent>
                    <MenuItem onClick={() => goDetail(rowData)}>
                      Lihat Detail
                    </MenuItem>
                  </DetailButtonComponent>
                );
              },
            },
          ]}
          data={(q) =>
            new Promise((resolve) => {
              let param = {
                page: q.page + 1,
                length: 10,
                search_text: searchText,
                region_id: regionId,
              };
              dispatch(MessageBlast.get(param, resolve));
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            showTitle: false,
            search: false,
            sorting: false,
            headerStyle: {
              backgroundColor: "#fff",
              fontWeight: "bold",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
        />
      </div>
    </Container>
  );
};

const mapStateToProps = ({ messageBlast: { pending }, region }) => {
  return { pending, region };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetMessageBlastModal: (modalType, isOpen) =>
      dispatch(setMessageBlastModal(modalType, isOpen)),
    onSetMessageBlastData: (data) => dispatch(setMessageBlastData(data)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
