import {
  GET_POSITION_PENDING,
  GET_POSITION_SUCCESS,
  GET_POSITION_ERROR,
  POST_POSITION_PENDING,
  POST_POSITION_SUCCESS,
  POST_POSITION_ERROR,
  PUT_POSITION_PENDING,
  PUT_POSITION_SUCCESS,
  PUT_POSITION_ERROR,
  DELETE_POSITION_PENDING,
  DELETE_POSITION_SUCCESS,
  DELETE_POSITION_ERROR,
  POSITION_DATA,
  SET_POSITION_MODAL,
} from "../../../actions/master/position";

const initialState = {
  pending: false,
  error: null,
  data: null,
  detailData: null,
  isOpenModal: false,
  modalType: "",
};

const position = (state = initialState, action) => {
  switch (action.type) {
    case GET_POSITION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_POSITION_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_POSITION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POST_POSITION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case POST_POSITION_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case POST_POSITION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case PUT_POSITION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case PUT_POSITION_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case PUT_POSITION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case DELETE_POSITION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_POSITION_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case DELETE_POSITION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POSITION_DATA:
      return {
        ...state,
        detailData: action.data,
      };
    case SET_POSITION_MODAL:
      return {
        ...state,
        isOpenModal: action.data.isOpen,
        modalType: action.data.modalType,
      };
    default:
      return state;
  }
};

export default position;
