import API from "../../API";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
import { setToastModal } from "../../../actions";
import { toastError, toastSuccess } from "../../../../components/commons/toast";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_REGION_PENDING = "GET_REGION_PENDING";
export const GET_REGION_SUCCESS = "GET_REGION_SUCCESS";
export const GET_REGION_ERROR = "GET_REGION_ERROR";
export const POST_REGION_PENDING = "POST_REGION_PENDING";
export const POST_REGION_SUCCESS = "POST_REGION_SUCCESS";
export const POST_REGION_ERROR = "POST_REGION_ERROR";
export const PUT_REGION_PENDING = "PUT_REGION_PENDING";
export const PUT_REGION_SUCCESS = "PUT_REGION_SUCCESS";
export const PUT_REGION_ERROR = "PUT_REGION_ERROR";
export const DELETE_REGION_PENDING = "DELETE_REGION_PENDING";
export const DELETE_REGION_SUCCESS = "DELETE_REGION_SUCCESS";
export const DELETE_REGION_ERROR = "DELETE_REGION_ERROR";

export const REGION_DATA = "REGION_DATA";
export const SET_REGION_MODAL = "SET_REGION_MODAL";

// URL: URL_{URL}
const REGION_URL = "/v1/region";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_REGION_PENDING));
  API.get(REGION_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_REGION_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_REGION_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_REGION_PENDING));
  API.post(REGION_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_REGION_SUCCESS, res));
      toastSuccess("Wilayah berhasil ditambahkan");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_REGION_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_REGION_PENDING));
  API.put(REGION_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_REGION_SUCCESS, res));
      toastSuccess("Wilayah berhasil diubah");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_REGION_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_REGION_PENDING));
  API.delete(REGION_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_REGION_SUCCESS, res));
      toastSuccess("Wilayah berhasil dihapus");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_REGION_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const Region = { get, post, put, deleted };
export default Region;

export const setRegionData = (data) => (dispatch) =>
  dispatch({ type: REGION_DATA, data });

export const setRegionModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_REGION_MODAL, { modalType: modalType, isOpen: isOpen })
  );
