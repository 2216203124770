import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { ModalBody, ModalHeader } from "reactstrap";
import { Button } from "@material-ui/core";
import { formInput } from "../../../../components/commons/form";
import Region, {
  setRegionModal,
} from "../../../../store/actions/master/region";
import LabelInputVerticalComponent from "../../../../components/global-components/LabelInputVertical";

let Edit = ({
  onSetRegionModal,
  handleSubmit,
  detailData,
  handleRefresh,
  pending,
}) => {
  const dispatch = useDispatch();

  const onSubmit = ({ code, name }) => {
    const param = {
      id: detailData.id,
      code,
      name,
    };
    const callback = () => {
      onSetRegionModal("", false);
      handleRefresh();
    };
    dispatch(Region.put(param, callback));
  };
  return (
    <>
      <ModalHeader>Edit Wilayah</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <LabelInputVerticalComponent label="Kode Wilayah">
            <Field
              name="code"
              placeholder="Kode Wilayah"
              disabled
              component={formInput}
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Nama Wilayah">
            <Field
              name="name"
              placeholder="Nama Wilayah"
              component={formInput}
            />
          </LabelInputVerticalComponent>

          <div className="d-flex justify-content-between">
            <Button
              variant="outlined"
              className="mt-3"
              disabled={pending}
              color="primary"
              onClick={() => onSetRegionModal("", false)}
            >
              Batal
            </Button>
            <Button
              type="submit"
              className="mt-3"
              disabled={pending}
              variant="contained"
              color="primary"
            >
              Simpan
            </Button>
          </div>
        </form>
      </ModalBody>
    </>
  );
};

const validate = ({ code, name }) => {
  const errors = {};
  // if (!code) {
  //   errors.code = "Kode wilayah harus diisi";
  // }
  if (!name) {
    errors.name = "Nama wilayah harus diisi";
  }

  return errors;
};

Edit = reduxForm({
  form: "regionEdit",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(Edit);

const mapStateToProps = ({ region: { detailData, pending } }) => {
  let initialValues = {};
  if (detailData) {
    initialValues = {
      code: detailData.code,
      name: detailData.name,
    };
  }
  return {
    detailData,
    initialValues,
    pending,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetRegionModal: (modalType, isOpen) =>
      dispatch(setRegionModal(modalType, isOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
