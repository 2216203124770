import API from "../API";
import { actionPending, actionSuccess, actionError } from "../actionTypes";
import { setToastModal } from "..";
import { toastError, toastSuccess } from "../../../components/commons/toast";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_SETORAN_ADMIN_PENDING = "GET_SETORAN_ADMIN_PENDING";
export const GET_SETORAN_ADMIN_SUCCESS = "GET_SETORAN_ADMIN_SUCCESS";
export const GET_SETORAN_ADMIN_ERROR = "GET_SETORAN_ADMIN_ERROR";
export const GET_DETAIL_SETORAN_ADMIN_PENDING =
  "GET_DETAIL_SETORAN_ADMIN_PENDING";
export const GET_DETAIL_SETORAN_ADMIN_SUCCESS =
  "GET_DETAIL_SETORAN_ADMIN_SUCCESS";
export const GET_DETAIL_SETORAN_ADMIN_ERROR = "GET_DETAIL_SETORAN_ADMIN_ERROR";
export const POST_SETORAN_ADMIN_PENDING = "POST_SETORAN_ADMIN_PENDING";
export const POST_SETORAN_ADMIN_SUCCESS = "POST_SETORAN_ADMIN_SUCCESS";
export const POST_SETORAN_ADMIN_ERROR = "POST_SETORAN_ADMIN_ERROR";
export const APPROVE_SETORAN_ADMIN_PENDING = "APPROVE_SETORAN_ADMIN_PENDING";
export const APPROVE_SETORAN_ADMIN_SUCCESS = "APPROVE_SETORAN_ADMIN_SUCCESS";
export const APPROVE_SETORAN_ADMIN_ERROR = "APPROVE_SETORAN_ADMIN_ERROR";
export const PUT_SETORAN_ADMIN_PENDING = "PUT_SETORAN_ADMIN_PENDING";
export const PUT_SETORAN_ADMIN_SUCCESS = "PUT_SETORAN_ADMIN_SUCCESS";
export const PUT_SETORAN_ADMIN_ERROR = "PUT_SETORAN_ADMIN_ERROR";
export const DELETE_SETORAN_ADMIN_PENDING = "DELETE_SETORAN_ADMIN_PENDING";
export const DELETE_SETORAN_ADMIN_SUCCESS = "DELETE_SETORAN_ADMIN_SUCCESS";
export const DELETE_SETORAN_ADMIN_ERROR = "DELETE_SETORAN_ADMIN_ERROR";

export const SETORAN_ADMIN_DATA = "SETORAN_ADMIN_DATA";
export const SET_SETORAN_ADMIN_MODAL = "SET_SETORAN_ADMIN_MODAL";

// URL: URL_{URL}
const SETORAN_ADMIN_URL = "/v1/member_deposit";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_SETORAN_ADMIN_PENDING));
  API.get(SETORAN_ADMIN_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_SETORAN_ADMIN_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_SETORAN_ADMIN_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const getDetail = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DETAIL_SETORAN_ADMIN_PENDING));
  API.get(SETORAN_ADMIN_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DETAIL_SETORAN_ADMIN_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DETAIL_SETORAN_ADMIN_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_SETORAN_ADMIN_PENDING));
  API.post(SETORAN_ADMIN_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_SETORAN_ADMIN_SUCCESS, res));
      toastSuccess("Setoran berhasil dilakukan");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_SETORAN_ADMIN_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const approve = (param, callback) => (dispatch) => {
  dispatch(actionPending(APPROVE_SETORAN_ADMIN_PENDING));
  API.post(SETORAN_ADMIN_URL + "/approve", param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(APPROVE_SETORAN_ADMIN_SUCCESS, res));
      toastSuccess("Approve Setoran berhasil dilakukan");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(APPROVE_SETORAN_ADMIN_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_SETORAN_ADMIN_PENDING));
  API.put(SETORAN_ADMIN_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_SETORAN_ADMIN_SUCCESS, res));
      toastSuccess("Setoran berhasil diubah");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_SETORAN_ADMIN_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_SETORAN_ADMIN_PENDING));
  API.delete(SETORAN_ADMIN_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_SETORAN_ADMIN_SUCCESS, res));
      toastSuccess("Setoran berhasil dihapus");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_SETORAN_ADMIN_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const SetoranAdmin = { get, post, put, deleted, approve, getDetail };
export default SetoranAdmin;

export const setSetoranAdminData = (data) => (dispatch) =>
  dispatch({ type: SETORAN_ADMIN_DATA, data });

export const setSetoranAdminModal =
  (modalType, isOpen, title, folderName, fileName) => (dispatch) =>
    dispatch(
      actionSuccess(SET_SETORAN_ADMIN_MODAL, {
        modalType: modalType,
        isOpen: isOpen,
        title: title,
        folderName: folderName,
        fileName: fileName,
      })
    );
