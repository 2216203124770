import API from "../../API";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
import { setToastModal } from "../../../actions";
import { toastError, toastSuccess } from "../../../../components/commons/toast";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_ADMINISTRATOR_PENDING = "GET_ADMINISTRATOR_PENDING";
export const GET_ADMINISTRATOR_SUCCESS = "GET_ADMINISTRATOR_SUCCESS";
export const GET_ADMINISTRATOR_ERROR = "GET_ADMINISTRATOR_ERROR";
export const POST_ADMINISTRATOR_PENDING = "POST_ADMINISTRATOR_PENDING";
export const POST_ADMINISTRATOR_SUCCESS = "POST_ADMINISTRATOR_SUCCESS";
export const POST_ADMINISTRATOR_ERROR = "POST_ADMINISTRATOR_ERROR";
export const PUT_ADMINISTRATOR_PENDING = "PUT_ADMINISTRATOR_PENDING";
export const PUT_ADMINISTRATOR_SUCCESS = "PUT_ADMINISTRATOR_SUCCESS";
export const PUT_ADMINISTRATOR_ERROR = "PUT_ADMINISTRATOR_ERROR";
export const DELETE_ADMINISTRATOR_PENDING = "DELETE_ADMINISTRATOR_PENDING";
export const DELETE_ADMINISTRATOR_SUCCESS = "DELETE_ADMINISTRATOR_SUCCESS";
export const DELETE_ADMINISTRATOR_ERROR = "DELETE_ADMINISTRATOR_ERROR";

export const ADMINISTRATOR_DATA = "ADMINISTRATOR_DATA";
export const SET_ADMINISTRATOR_MODAL = "SET_ADMINISTRATOR_MODAL";

// URL: URL_{URL}
const ADMINISTRATOR_URL = "/v1/administrator";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_ADMINISTRATOR_PENDING));
  API.get(ADMINISTRATOR_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_ADMINISTRATOR_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_ADMINISTRATOR_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_ADMINISTRATOR_PENDING));
  API.post(ADMINISTRATOR_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_ADMINISTRATOR_SUCCESS, res));
      toastSuccess("Pengurus berhasil ditambahkan");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_ADMINISTRATOR_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_ADMINISTRATOR_PENDING));
  API.put(ADMINISTRATOR_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_ADMINISTRATOR_SUCCESS, res));
      toastSuccess("Pengurus berhasil diubah");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_ADMINISTRATOR_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_ADMINISTRATOR_PENDING));
  API.delete(ADMINISTRATOR_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_ADMINISTRATOR_SUCCESS, res));
      toastSuccess("Pengurus berhasil dihapus");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_ADMINISTRATOR_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const Administrator = { get, post, put, deleted };
export default Administrator;

export const setAdministratorData = (data) => (dispatch) =>
  dispatch({ type: ADMINISTRATOR_DATA, data });

export const setAdministratorModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_ADMINISTRATOR_MODAL, {
      modalType: modalType,
      isOpen: isOpen,
    })
  );
