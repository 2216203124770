import {
  GET_REQUEST_PENDING,
  GET_REQUEST_SUCCESS,
  GET_REQUEST_ERROR,
  GET_DETAIL_REQUEST_PENDING,
  GET_DETAIL_REQUEST_SUCCESS,
  GET_DETAIL_REQUEST_ERROR,
  POST_REQUEST_PENDING,
  POST_REQUEST_SUCCESS,
  POST_REQUEST_ERROR,
  PUT_REQUEST_PENDING,
  PUT_REQUEST_SUCCESS,
  PUT_REQUEST_ERROR,
  DELETE_REQUEST_PENDING,
  DELETE_REQUEST_SUCCESS,
  DELETE_REQUEST_ERROR,
  APPROVED_REQUEST_PENDING,
  APPROVED_REQUEST_SUCCESS,
  APPROVED_REQUEST_ERROR,
  REJECTED_REQUEST_PENDING,
  REJECTED_REQUEST_SUCCESS,
  REJECTED_REQUEST_ERROR,
  EDIT_PHOTO_REQUEST_PENDING,
  EDIT_PHOTO_REQUEST_SUCCESS,
  EDIT_PHOTO_REQUEST_ERROR,
  REQUEST_DATA,
  ADD_REQUEST_DATA,
  SET_REQUEST_MODAL,
} from "../../../actions/anggota/permintaan";

const initialState = {
  pending: false,
  error: null,
  data: null,
  detailData: null,
  isOpenModal: false,
  modalType: "",
  title: "",
  folderName: "",
  fileName: "",
};

const request = (state = initialState, action) => {
  switch (action.type) {
    case GET_REQUEST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_REQUEST_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_REQUEST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case GET_DETAIL_REQUEST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_DETAIL_REQUEST_SUCCESS:
      return {
        ...state,
        pending: false,
        detailData: action.data.data.data[0],
      };
    case GET_DETAIL_REQUEST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POST_REQUEST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case POST_REQUEST_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case POST_REQUEST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case PUT_REQUEST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case PUT_REQUEST_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case PUT_REQUEST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case DELETE_REQUEST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_REQUEST_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case DELETE_REQUEST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case APPROVED_REQUEST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case APPROVED_REQUEST_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case APPROVED_REQUEST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case REJECTED_REQUEST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case REJECTED_REQUEST_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case REJECTED_REQUEST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case EDIT_PHOTO_REQUEST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_PHOTO_REQUEST_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case EDIT_PHOTO_REQUEST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case REQUEST_DATA:
      return {
        ...state,
        detailData: action.data,
      };
    case SET_REQUEST_MODAL:
      return {
        ...state,
        isOpenModal: action.data.isOpen,
        modalType: action.data.modalType,
        title: action.data.title,
        folderName: action.data.folderName,
        fileName: action.data.fileName,
      };
    case ADD_REQUEST_DATA:
      return {
        ...state,
        detailData: action.data,
      };
    default:
      return state;
  }
};

export default request;
