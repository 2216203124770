import React from "react";
import { string } from "prop-types";
import { StyleSheet, css } from "aphrodite";

const bgColor = {
  Aktif: "#E9F8F0",
  AktifLabel: "#2CB96A",
  Nonaktif: "#FDE9E9",
  NonaktifLabel: "#ED2A2A",
  Berhasil: "#E9F8F0",
  BerhasilLabel: "#2CB96A",
  Gagal: "#FDE9E9",
  GagalLabel: "#ED2A2A",
  Diproses: "#fef2ea",
  DiprosesLabel: "#f58337",
  Baru: "#fef2ea",
  BaruLabel: "#f58337",
  Menunggu: "#fef2ea",
  MenungguLabel: "#f58337",
  Diterima: "#E9F8F0",
  DiterimaLabel: "#2CB96A",
  Ditolak: "#FDE9E9",
  DitolakLabel: "#ED2A2A",
};

function TableStatusComponent(props) {
  const { status, style } = props;

  return (
    <div
      style={{
        backgroundColor: bgColor[status],
        color: bgColor[status + "Label"],
        ...style,
      }}
      className={css(styles.status)}
    >
      <span>{status}</span>
    </div>
  );
}

TableStatusComponent.propTypes = {
  name: string,
};

const styles = StyleSheet.create({
  status: {
    padding: 5,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 30,
    whiteSpace: "nowrap",
    textAlign: "center",
    width: "100%",
    maxWidth: 150,
  },
});

export default TableStatusComponent;
