import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { ModalBody, ModalHeader } from "reactstrap";
import { Button } from "@material-ui/core";
import { Row, Column } from "simple-flexbox";
import {
  formInput,
  formSelect,
  formFile,
  formYearPicker,
} from "../../../../components/commons/form";
import Administrator, {
  setAdministratorModal,
} from "../../../../store/actions/anggota/administrator";
import LabelInputVerticalComponent from "../../../../components/global-components/LabelInputVertical";
import File from "../../../../store/actions/file";
import Position from "../../../../store/actions/master/position";
import Region from "../../../../store/actions/master/region";
import { ADMINISTRATOR_PHOTO, regexEmail } from "../../../../utils/constant";

let Edit = ({
  onSetAdministratorModal,
  handleSubmit,
  detailData,
  handleRefresh,
  pending,
  region,
  file,
  position,
}) => {
  const dispatch = useDispatch();
  const [imgName, setImgName] = useState(detailData.img_name);

  const onSubmit = ({
    name,
    position,
    email,
    region,
    start_year,
    end_year,
    img_name,
  }) => {
    const param = {
      id: detailData.id,
      name,
      position_id: position.value,
      email,
      region_id: region.value,
      start_year,
      end_year,
      img_name: imgName,
    };
    const callback = () => {
      onSetAdministratorModal("", false);
      handleRefresh();
    };
    dispatch(Administrator.put(param, callback));
  };

  let regionOptions;
  if (region.data) {
    regionOptions = region.data.data.data.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  }

  let positionOptions;
  if (position.data) {
    positionOptions = position.data.data.data.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  }

  useEffect(() => {
    getPosition();
    getRegion();
  }, []);

  const getPosition = () => dispatch(Position.get());
  const getRegion = () => dispatch(Region.get());

  const uploadFile = (e) => {
    let formData = new FormData();
    formData.append("file", e);

    const callback = (res) => {
      setImgName(res.data.data.fileName);
    };

    dispatch(File.upload(formData, callback, ADMINISTRATOR_PHOTO));
  };

  return (
    <>
      <ModalHeader>Edit Pengurus</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <LabelInputVerticalComponent label="Nama Pengurus">
            <Field
              name="name"
              placeholder="Nama Pengurus"
              component={formInput}
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Email">
            <Field
              name="email"
              placeholder="Email Pengurus"
              component={formInput}
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Jabatan">
            <Field
              placeholder="Jabatan"
              name="position"
              options={positionOptions}
              isAsync
              asyncUrl="/v1/position"
              component={formSelect}
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Wilayah">
            <Field
              placeholder="Wilayah"
              name="region"
              options={regionOptions}
              isAsync
              asyncUrl="/v1/region"
              component={formSelect}
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Periode">
            <div className="row">
              <div className="col-6">
                <Field
                  name="start_year"
                  // placeholder="Awal Periode"
                  position="start"
                  inputVariant="outlined"
                  fullWidth={true}
                  component={formYearPicker}
                />
              </div>
              <div className="d-flex align-self-center mx-1">s/d</div>
              <div className="col-5">
                <Field
                  name="end_year"
                  // placeholder="Akhir Periode"
                  position="start"
                  inputVariant="outlined"
                  fullWidth={true}
                  component={formYearPicker}
                />
              </div>
            </div>
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Foto">
            <Field
              name="img_name"
              type="file"
              fileType="image"
              title="Upload"
              onChange={(e) => uploadFile(e)}
              message="JPG"
              component={formFile}
            />
          </LabelInputVerticalComponent>
          <div className="d-flex justify-content-between">
            <Button
              variant="outlined"
              className="mt-3"
              disabled={pending}
              color="primary"
              onClick={() => onSetAdministratorModal("", false)}
            >
              Batal
            </Button>
            <Button
              type="submit"
              className="mt-3"
              disabled={pending || file?.pending}
              variant="contained"
              color="primary"
            >
              Simpan
            </Button>
          </div>
        </form>
      </ModalBody>
    </>
  );
};

const validate = ({
  name,
  position,
  email,
  region,
  start_year,
  end_year,
  img_name,
}) => {
  const errors = {};
  if (!name) {
    errors.name = "Nama Pengurus harus diisi";
  }
  if (!position) {
    errors.position = "Jabatan Pengurus harus diisi";
  }
  if (!email) {
    errors.email = "Bidang Pengurus harus diisi";
  } else if (!regexEmail.test(email)) {
    errors.email = "Email tidak valid";
  }
  if (!region) {
    errors.region = "Wilayah harus diisi";
  }
  if (!start_year) {
    errors.start_year = "Awal Periode harus diisi";
  }
  if (!end_year) {
    errors.end_year = "Akhir Periode harus diisi";
  }

  if (start_year && end_year && parseInt(start_year) > parseInt(end_year)) {
    errors.end_year = "Akhir Periode tidak valid";
  }

  if (!img_name) {
    errors.img_name = "Foto Pengurus harus diisi";
  }

  return errors;
};

Edit = reduxForm({
  form: "administratorEdit",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(Edit);

const mapStateToProps = ({
  administrator: { detailData, pending },
  region,
  file,
  position,
}) => {
  let initialValues = {};
  if (detailData) {
    initialValues = {
      name: detailData.name,
      position: {
        value: detailData.position_id,
        label: detailData.position_name,
      },
      email: detailData.email,
      region: { value: detailData.region_id, label: detailData.region_name },
      start_year: detailData.start_year,
      end_year: detailData.end_year,
      img_name: detailData.img_name,
    };
  }
  return {
    detailData,
    initialValues,
    pending,
    region,
    position,
    file,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetAdministratorModal: (modalType, isOpen) =>
      dispatch(setAdministratorModal(modalType, isOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
