import {
  GET_IURAN_MEMBER_PENDING,
  GET_IURAN_MEMBER_SUCCESS,
  GET_IURAN_MEMBER_ERROR,
  GET_IURAN_DETAIL_MEMBER_PENDING,
  GET_IURAN_DETAIL_MEMBER_SUCCESS,
  GET_IURAN_DETAIL_MEMBER_ERROR,
  GET_IURAN_RESUME_PENDING,
  GET_IURAN_RESUME_SUCCESS,
  GET_IURAN_RESUME_ERROR,
  POST_IURAN_MEMBER_PENDING,
  POST_IURAN_MEMBER_SUCCESS,
  POST_IURAN_MEMBER_ERROR,
  APPROVE_IURAN_MEMBER_PENDING,
  APPROVE_IURAN_MEMBER_SUCCESS,
  APPROVE_IURAN_MEMBER_ERROR,
  PUT_IURAN_MEMBER_PENDING,
  PUT_IURAN_MEMBER_SUCCESS,
  PUT_IURAN_MEMBER_ERROR,
  DELETE_IURAN_MEMBER_PENDING,
  DELETE_IURAN_MEMBER_SUCCESS,
  DELETE_IURAN_MEMBER_ERROR,
  IURAN_MEMBER_DATA,
  SET_IURAN_MEMBER_MODAL,
} from "../../../actions/iuran/member";

const initialState = {
  pending: false,
  error: null,
  data: null,
  detailData: null,
  isOpenModal: false,
  modalType: "",
  title: "",
  folderName: "",
  fileName: "",
  reports: [],
};

const iuranMember = (state = initialState, action) => {
  switch (action.type) {
    case GET_IURAN_MEMBER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_IURAN_MEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_IURAN_MEMBER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case GET_IURAN_DETAIL_MEMBER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_IURAN_DETAIL_MEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
        detailData: action.data.data.data[0],
      };
    case GET_IURAN_DETAIL_MEMBER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case GET_IURAN_RESUME_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_IURAN_RESUME_SUCCESS:
      return {
        ...state,
        pending: false,
        reports: action.data.data.data,
      };
    case GET_IURAN_RESUME_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POST_IURAN_MEMBER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case POST_IURAN_MEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case POST_IURAN_MEMBER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case APPROVE_IURAN_MEMBER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case APPROVE_IURAN_MEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case APPROVE_IURAN_MEMBER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case PUT_IURAN_MEMBER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case PUT_IURAN_MEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case PUT_IURAN_MEMBER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case DELETE_IURAN_MEMBER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_IURAN_MEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case DELETE_IURAN_MEMBER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case IURAN_MEMBER_DATA:
      return {
        ...state,
        detailData: action.data,
      };

    case SET_IURAN_MEMBER_MODAL:
      return {
        ...state,
        isOpenModal: action.data.isOpen,
        modalType: action.data.modalType,
        title: action.data.title,
        folderName: action.data.folderName,
        fileName: action.data.fileName,
      };
    default:
      return state;
  }
};

export default iuranMember;
