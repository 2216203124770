import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Request, {
  setRequestData,
  setRequestModal,
  setAddRequestData,
} from "../../../store/actions/anggota/permintaan";
import Region from "../../../store/actions/master/region";
import { Row } from "simple-flexbox";
import MaterialTable from "material-table";
import SearchIcon from "@material-ui/icons/Search";
import { Paper, Button, MenuItem } from "@material-ui/core";
import DetailButton from "../../../components/global-components/DetailButton";
import TableStatus from "../../../components/global-components/TableStatus";
import InputComponent from "../../../components/commons/form/input";
import SelectComponent from "../../../components/commons/form/select";
import Container from "../../../components/container";
import debounce from "lodash.debounce";
import Modal from "./modal";
import { history } from "../../../utils";
import { kelembagaanOptions } from "../../../utils/constant";

const PermintaanAnggota = ({
  onSetRequestModal,
  onSetRequestData,
  onSetAddRequestData,
  region,
  pending,
}) => {
  const [searchText, setSearchText] = useState("");
  const [institutional, setInstitutional] = useState("");
  const [status, setStatus] = useState("");
  const [regionId, setRegionId] = useState("");
  const dispatch = useDispatch();
  const tableRef = useRef();

  const handleRefresh = (state) => {
    setSearchText(state);
    tableRef.current && tableRef.current.onQueryChange();
  };

  const delayedQuery = debounce((value) => {
    return handleRefresh(value);
  }, 500);

  const handleSearchChange = (e) => {
    delayedQuery(e.target.value);
  };

  const handleInstitutChange = (e) => {
    if (e) {
      setInstitutional(e.value);
    } else {
      setInstitutional("");
    }
    tableRef.current && tableRef.current.onQueryChange();
  };

  const handleRegionChange = (e) => {
    if (e) {
      setRegionId(e.value);
    } else {
      setRegionId("");
    }
    tableRef.current && tableRef.current.onQueryChange();
  };

  let regionOptions;
  if (region.data) {
    regionOptions = region.data.data.data.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  }

  useEffect(() => {
    getRegion();
    onSetRequestData(null);
  }, []);

  const getRegion = () => dispatch(Region.get());

  const setData = (type, data) => {
    onSetRequestData(data);
    history.push(`/request/${type}/${data.id}`);
  };

  const setModal = (modalType, isOpen, data) => {
    onSetRequestModal(modalType, isOpen);
    onSetRequestData(data);
  };

  const addRequest = () => {
    onSetRequestData(null);
    history.push("/request/add");
  };

  return (
    <Container>
      <Modal handleRefresh={(state) => handleRefresh(state)} />
      <Row className="m-3 justify-content-end">
        <Row className="m-0 justify-content-between">
          <div style={{ width: 120, marginRight: 20 }}>
            <SelectComponent
              onChange={(e) => handleInstitutChange(e)}
              placeholder="Kelembagaan"
              options={kelembagaanOptions}
            />
          </div>
          <div style={{ width: 120, marginRight: 20 }}>
            <SelectComponent
              onChange={(e) => handleRegionChange(e)}
              placeholder="Wilayah"
              options={regionOptions}
              isAsync
              asyncUrl="/v1/region"
            />
          </div>
          <InputComponent
            onChange={(e) => handleSearchChange(e)}
            placeholder="Cari nama anggota"
            endIcon={SearchIcon}
          />
        </Row>
      </Row>
      <div className="m-3">
        <MaterialTable
          tableRef={tableRef}
          title="Request"
          columns={[
            {
              title: "No",
              field: "no",
              width: 40,
            },
            {
              title: "No KTA",
              render: ({ member_profile_data }) => {
                return member_profile_data.kta_no
                  ? member_profile_data.kta_no
                  : "-";
              },
            },
            {
              title: "Nama Perusahaan",
              render: ({ name }) => {
                return name ? name : "-";
              },
            },
            {
              title: "Alamat",
              render: ({ member_profile_data }) => {
                return member_profile_data.address
                  ? member_profile_data.address
                  : "-";
              },
            },
            {
              title: "Wilayah",
              render: ({ region_name }) => {
                return region_name ? region_name : "-";
              },
            },
            {
              title: "Status",
              render: ({ member_profile_data }) => {
                return (
                  <TableStatus
                    status={
                      member_profile_data.status === 0
                        ? "Baru"
                        : member_profile_data.status === 1
                        ? "Diterima"
                        : "Ditolak"
                    }
                  />
                );
              },
            },
            {
              title: "Aksi",
              width: 80,
              cellStyle: {
                paddingLeft: 0,
              },
              render: (rowData) => {
                return (
                  <DetailButton>
                    <MenuItem onClick={() => setData("detail", rowData)}>
                      Lihat Detail
                    </MenuItem>
                  </DetailButton>
                );
              },
            },
          ]}
          data={(q) =>
            new Promise((resolve) => {
              let param = {
                page: q.page + 1,
                length: 10,
                search_text: searchText,
                region_id: regionId,
                status_active: status,
                institutional,
              };
              dispatch(Request.get(param, resolve));
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            showTitle: false,
            search: false,
            sorting: false,
            headerStyle: {
              backgroundColor: "#fff",
              fontWeight: "bold",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
        />
      </div>
    </Container>
  );
};

const mapStateToProps = ({ region, request: { pending } }) => {
  return { region, pending };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetRequestModal: (modalType, isOpen) =>
      dispatch(setRequestModal(modalType, isOpen)),
    onSetRequestData: (data) => dispatch(setRequestData(data)),
    onSetAddRequestData: (data) => dispatch(setAddRequestData(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PermintaanAnggota)
);
