import API from "../../API";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
import { setToastModal } from "../..";
import { toastError, toastSuccess } from "../../../../components/commons/toast";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_BRANCH_MEMBER_PENDING = "GET_BRANCH_MEMBER_PENDING";
export const GET_BRANCH_MEMBER_SUCCESS = "GET_BRANCH_MEMBER_SUCCESS";
export const GET_BRANCH_MEMBER_ERROR = "GET_BRANCH_MEMBER_ERROR";
export const GET_DETAIL_BRANCH_MEMBER_PENDING =
  "GET_DETAIL_BRANCH_MEMBER_PENDING";
export const GET_DETAIL_BRANCH_MEMBER_SUCCESS =
  "GET_DETAIL_BRANCH_MEMBER_SUCCESS";
export const GET_DETAIL_BRANCH_MEMBER_ERROR =
  "GET_DETAIL_GET_BRANCH_MEMBER_ERROR";
export const POST_BRANCH_MEMBER_PENDING = "POST_BRANCH_MEMBER_PENDING";
export const POST_BRANCH_MEMBER_SUCCESS = "POST_BRANCH_MEMBER_SUCCESS";
export const POST_BRANCH_MEMBER_ERROR = "POST_BRANCH_MEMBER_ERROR";
export const PUT_BRANCH_MEMBER_PENDING = "PUT_BRANCH_MEMBER_PENDING";
export const PUT_BRANCH_MEMBER_SUCCESS = "PUT_BRANCH_MEMBER_SUCCESS";
export const PUT_BRANCH_MEMBER_ERROR = "PUT_BRANCH_MEMBER_ERROR";
export const DELETE_BRANCH_MEMBER_PENDING = "DELETE_BRANCH_MEMBER_PENDING";
export const DELETE_BRANCH_MEMBER_SUCCESS = "DELETE_BRANCH_MEMBER_SUCCESS";
export const DELETE_BRANCH_MEMBER_ERROR = "DELETE_BRANCH_MEMBER_ERROR";
export const APPROVED_BRANCH_MEMBER_PENDING = "APPROVED_BRANCH_MEMBER_PENDING";
export const APPROVED_BRANCH_MEMBER_SUCCESS = "APPROVED_BRANCH_MEMBER_SUCCESS";
export const APPROVED_BRANCH_MEMBER_ERROR = "APPROVED_BRANCH_MEMBER_ERROR";
export const REJECTED_BRANCH_MEMBER_PENDING = "REJECTED_BRANCH_MEMBER_PENDING";
export const REJECTED_BRANCH_MEMBER_SUCCESS = "REJECTED_BRANCH_MEMBER_SUCCESS";
export const REJECTED_BRANCH_MEMBER_ERROR = "REJECTED_BRANCH_MEMBER_ERROR";
export const EDIT_PHOTO_BRANCH_MEMBER_PENDING =
  "EDIT_PHOTO_BRANCH_MEMBER_PENDING";
export const EDIT_PHOTO_BRANCH_MEMBER_SUCCESS =
  "EDIT_PHOTO_BRANCH_MEMBER_SUCCESS";
export const EDIT_PHOTO_BRANCH_MEMBER_ERROR = "EDIT_PHOTO_BRANCH_MEMBER_ERROR";

export const BRANCH_MEMBER_DATA = "BRANCH_MEMBER_DATA";
export const ADD_BRANCH_MEMBER_DATA = "ADD_BRANCH_MEMBER_DATA";
export const SET_BRANCH_MEMBER_MODAL = "SET_BRANCH_MEMBER_MODAL";

// URL: URL_{URL}
const BRANCH_MEMBER_URL = "/v1/member";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_BRANCH_MEMBER_PENDING));
  API.get(BRANCH_MEMBER_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_BRANCH_MEMBER_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_BRANCH_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const getDetail = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DETAIL_BRANCH_MEMBER_PENDING));
  API.get(BRANCH_MEMBER_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DETAIL_BRANCH_MEMBER_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DETAIL_BRANCH_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_BRANCH_MEMBER_PENDING));
  API.post(BRANCH_MEMBER_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_BRANCH_MEMBER_SUCCESS, res));
      toastSuccess("Anggota berhasil ditambahkan");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_BRANCH_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_BRANCH_MEMBER_PENDING));
  API.put(BRANCH_MEMBER_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_BRANCH_MEMBER_SUCCESS, res));
      toastSuccess("Anggota berhasil diubah");
      if (callback) {
        callback(res);
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_BRANCH_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_BRANCH_MEMBER_PENDING));
  API.delete(BRANCH_MEMBER_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_BRANCH_MEMBER_SUCCESS, res));
      toastSuccess("Anggota berhasil dihapus");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_BRANCH_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const approved = (param, callback) => (dispatch) => {
  dispatch(actionPending(APPROVED_BRANCH_MEMBER_PENDING));
  API.put(BRANCH_MEMBER_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(APPROVED_BRANCH_MEMBER_SUCCESS, res));
      toastSuccess("Anggota berhasil diterima");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(APPROVED_BRANCH_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const rejected = (param, callback) => (dispatch) => {
  dispatch(actionPending(REJECTED_BRANCH_MEMBER_PENDING));
  API.put(BRANCH_MEMBER_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(REJECTED_BRANCH_MEMBER_SUCCESS, res));
      toastSuccess("Anggota berhasil ditolak");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(REJECTED_BRANCH_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const editPhoto = (param, callback) => (dispatch) => {
  dispatch(actionPending(EDIT_PHOTO_BRANCH_MEMBER_PENDING));
  API.post(BRANCH_MEMBER_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(EDIT_PHOTO_BRANCH_MEMBER_SUCCESS, res));
      toastSuccess("Foto Anggota berhasil diubah");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(EDIT_PHOTO_BRANCH_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};
const Member = {
  get,
  post,
  put,
  deleted,
  approved,
  rejected,
  editPhoto,
  getDetail,
};
export default Member;

export const setMemberData = (data) => (dispatch) =>
  dispatch({ type: BRANCH_MEMBER_DATA, data });

export const setMemberModal =
  (modalType, isOpen, title, folderName, fileName) => (dispatch) =>
    dispatch(
      actionSuccess(SET_BRANCH_MEMBER_MODAL, {
        modalType: modalType,
        isOpen: isOpen,
        title: title,
        folderName: folderName,
        fileName: fileName,
      })
    );

export const setAddMemberData = (data) => (dispatch) =>
  dispatch({ type: ADD_BRANCH_MEMBER_DATA, data });
