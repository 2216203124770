import React, { useState } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { ModalBody, ModalHeader } from "reactstrap";
import { Button } from "@material-ui/core";
import { formInput } from "../../../../components/commons/form";
import { setUserModal } from "../../../../store/actions/system/pengguna";
import LabelInputVerticalComponent from "../../../../components/global-components/LabelInputVertical";

let detail = ({ onSetUserModal, pending }) => {
  return (
    <>
      <ModalHeader>Lihat Pengguna</ModalHeader>
      <ModalBody>
        <form>
          <LabelInputVerticalComponent label="Nama Pengguna">
            <Field
              name="name"
              placeholder="Nama Pengguna"
              component={formInput}
              disabled
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Email">
            <Field
              name="email"
              placeholder="Email"
              disabled
              component={formInput}
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Wilayah">
            <Field
              name="region"
              placeholder="Wilayah"
              component={formInput}
              disabled
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Role">
            <Field
              name="role"
              placeholder="Role"
              component={formInput}
              disabled
            />
          </LabelInputVerticalComponent>
        </form>
        <Button
          variant="outlined"
          className="mt-3"
          disabled={pending}
          color="primary"
          onClick={() => onSetUserModal("", false)}
        >
          Kembali
        </Button>
      </ModalBody>
    </>
  );
};

detail = reduxForm({
  form: "userDetail",
})(detail);

const mapStateToProps = ({ user: { detailData, pending } }) => {
  let initialValues = {};
  if (detailData) {
    initialValues = {
      name: detailData.name,
      email: detailData.email,
      region: detailData.region_name,
      role: detailData.role_name,
    };
  }
  return {
    initialValues,
    pending,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetUserModal: (modalType, isOpen) =>
      dispatch(setUserModal(modalType, isOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(detail);
