import React, { useRef } from "react";
import { connect, useDispatch } from "react-redux";
import Container from "../../../../components/container";
import IuranMember, {
  setIuranMemberData,
  setIuranMemberModal,
} from "../../../../store/actions/iuran/member";
import MaterialTable from "material-table";
import { IURAN_FOLDER } from "../../../../utils/constant";
import { Row } from "simple-flexbox";
import { Paper, Button } from "@material-ui/core";
import DetailContentLihatBerkas from "../../../../components/global-components/DetailContent/LihatBerkas";
import { reduxForm } from "redux-form";
import { StyleSheet, css } from "aphrodite";
import Modal from "../modal";
import { getUser } from "../../../../utils/user";
import moment from "moment";
import TableStatus from "../../../../components/global-components/TableStatus";

let Index = ({
  pending,
  onSetIuranMemberData,
  onSetIuranMemberModal,
  detailData,
  initialValues,
}) => {
  const user = getUser();
  const dispatch = useDispatch();
  const tableRef = useRef();

  const setModalDocument = (modalType, isOpen, title, folderName, fileName) => {
    onSetIuranMemberModal(modalType, isOpen, title, folderName, fileName);
  };

  const setModal = (modalType, isOpen, data) => {
    onSetIuranMemberData(data);
    onSetIuranMemberModal(modalType, isOpen);
  };

  const path = window.location.pathname;
  const id = path.split("/").pop();
  let columns = [
    {
      title: "No",
      field: "no",
      width: 40,
    },
    {
      title: "Tanggal Iuran",
      render: ({ date }) => {
        return date ? moment(date).format("DD MMMM YYYY") : "-";
      },
    },
    {
      title: "Total Iuran",
      render: (rowData) => {
        const total = rowData.total
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        return <>{rowData.total ? `Rp ${total}` : "-"}</>;
      },
    },
    {
      title: "Nama Perusahaan",
      render: ({ user_fullname }) => {
        return user_fullname ? user_fullname : "-";
      },
    },
    {
      title: "Status",
      width: 150,
      render: ({ status }) => {
        return (
          <TableStatus
            status={
              status === 0
                ? "Diproses"
                : status === 1
                ? "Menunggu"
                : status === 2
                ? "Diterima"
                : "Ditolak"
            }
          />
        );
      },
    },
    {
      title: "Bukti Pembayaran",
      width: 150,
      render: ({ filename }) => {
        return filename ? (
          <DetailContentLihatBerkas
            onClick={() =>
              setModalDocument(
                "show-document",
                true,
                "Bukti Pembayaran",
                IURAN_FOLDER,
                filename
              )
            }
          />
        ) : (
          <span>Belum ada filename</span>
        );
      },
    },
  ];

  if (user.role_code === "admin_cabang") {
    columns.splice(6, 0, {
      title: "Aksi",
      width: 80,
      cellStyle: {
        paddingLeft: 0,
      },
      render: (rowData) => {
        return (
          <div className="m-2">
            <Button
              variant="outlined"
              color="primary"
              disabled={rowData.status !== 0}
              // onClick={() => setDetail(rowData)}
              onClick={() => setModal("approval", true, rowData)}
            >
              Approval
            </Button>
          </div>
        );
      },
    });
  }
  return (
    <Container>
      <Modal />
      <div className="m-3">
        <MaterialTable
          tableRef={tableRef}
          title="IuranDetailMember"
          columns={columns}
          data={(q) =>
            new Promise((resolve) => {
              let param = {
                page: q.page + 1,
                length: 10,
                user_member_id: id,
              };
              dispatch(IuranMember.get(param, resolve));
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            showTitle: false,
            search: false,
            sorting: false,
            headerStyle: {
              backgroundColor: "#fff",
              fontWeight: "bold",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
        />
      </div>
    </Container>
  );
};

const validate = () => {
  const errors = {};
  return errors;
};

Index = reduxForm({
  form: "memberDetailIuran",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(Index);

const mapStateToProps = ({ iuranMember }) => {
  const { detailData, pending } = iuranMember;

  return { detailData, pending };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetIuranMemberModal: (modalType, isOpen, title, folderName, fileName) =>
      dispatch(
        setIuranMemberModal(modalType, isOpen, title, folderName, fileName)
      ),
    onSetIuranMemberData: (data) => dispatch(setIuranMemberData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
