import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Container from "../../../../components/container";
import LeftContainer from "../../../../components/container/LeftContainer";
import RightContainer from "../../../../components/container/RightContainer";
import StatusSquare from "../../../../components/global-components/StatusSquare";
import { reduxForm, FieldArray, Field } from "redux-form";
import IuranMember, {
  setIuranMemberData,
  setIuranMemberModal,
} from "../../../../store/actions/iuran/member";
import { Button } from "@material-ui/core";
import { Row, Column } from "simple-flexbox";
import { IURAN_FOLDER } from "../../../../utils/constant";
import File from "../../../../store/actions/file";
import DetailContentLihatBerkas from "../../../../components/global-components/DetailContent/LihatBerkas";
import { history } from "../../../../utils";
import moment from "moment";
import FormContainer from "../../../../components/container/Form";
import {
  formInputNumber,
  formTextArea,
  formFile,
  FormDatepicker,
  normalizeDates,
  formatDates,
  formInput,
} from "../../../../components/commons/form";
import Modal from "..";

let Index = ({
  detailData,
  handleSubmit,
  pending,
  change,
  onSetIuranMemberModal,
  file,
}) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const notEdit = () => setIsEdit(!isEdit);
  const [fileName, setFileName] = useState(null);

  const onSubmit = ({ date, total, description }) => {
    const param = {
      id: detailData.id,
      date: moment().format("YYYY-MM-DD"),
      total,
      description,
      filename: fileName,
    };

    const callback = () => {
      history.push("/iuran");
    };

    dispatch(IuranMember.put(param, callback));
  };

  // change("date", moment().format("DD MMMM YYYY"));

  const uploadFile = (e) => {
    let formData = new FormData();
    formData.append("file", e);

    const callback = (res) => {
      setFileName(res.data.data.fileName);
    };

    dispatch(File.upload(formData, callback, IURAN_FOLDER));
  };

  const setModal = (modalType, isOpen, title, folderName, fileName) => {
    onSetIuranMemberModal(modalType, isOpen, title, folderName, fileName);
  };

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      <Row
        vertical="center"
        horizontal="space-between"
        style={{ width: "100%", marginBottom: 20 }}
      >
        <div>
          <h5>{isEdit ? "Ubah" : "Detail"} Iuran</h5>
        </div>
        <div className="d-flex justify-content-between">
          {detailData?.status === 3 && (
            <Button
              color="primary"
              variant="contained"
              disabled={pending}
              onClick={() => setModal("edit", true, null)}
            >
              Unggah Ulang Bukti Pembayaran
            </Button>
          )}
        </div>
      </Row>
      <FormContainer label="Tanggal">
        <Field
          name="date"
          placeholder="Tanggal"
          isDetail={!isEdit}
          // disabled
          // parse={normalizeDates}
          // format={formatDates}
          component={formInput}
          // showClearDate={false}
        />
      </FormContainer>
      <FormContainer label="Total Iuran">
        <Field
          name="total"
          isDetail={!isEdit}
          placeholder="Nominal Iuran"
          component={formInputNumber}
          thousandSeparator
          prefix="Rp "
        />
      </FormContainer>
      <FormContainer label="Keterangan">
        <Field
          name="description"
          isDetail={!isEdit}
          placeholder="Keterangan"
          rows={5}
          component={formTextArea}
        />
      </FormContainer>
      <FormContainer label="Berkas">
        {isEdit ? (
          <Field
            name="filename"
            type="file"
            fileType="pdf/image"
            title="Masukkan Bukti"
            onChange={(e) => uploadFile(e)}
            message="PDF / JPG"
            component={formFile}
          />
        ) : detailData?.filename ? (
          <DetailContentLihatBerkas
            onClick={() =>
              setModal(
                "show-document",
                true,
                "Bukti Pembayaran",
                IURAN_FOLDER,
                detailData.filename
              )
            }
          />
        ) : (
          <span>Belum ada filename</span>
        )}
      </FormContainer>
      {detailData?.status === 3 && (
        <FormContainer label="Alasan Penolakan">
          <Field
            name="rejected_notes"
            placeholder="Alasan Ditolak"
            isDetail={!isEdit}
            component={formInput}
          />
        </FormContainer>
      )}
    </form>
  );
};

const validate = ({ total, description, filename }) => {
  const errors = {};
  if (!total) {
    errors.total = "Total Iuran harus diisi";
  }
  if (!filename) {
    errors.filename = "Berkas harus diisi";
  }
  if (!description) {
    errors.description = "Keterangan harus diisi";
  }

  return errors;
};

Index = reduxForm({
  form: "iuranMemberDetail",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(Index);

const mapStateToProps = ({ iuranMember, file }) => {
  const { detailData, pending } = iuranMember;
  let initialValues = {};
  if (detailData) {
    console.log(detailData.date);
    initialValues = {
      date: detailData.date
        ? moment(detailData.date).format("DD MMMM YYYY")
        : "-",
      total: detailData.total,
      description: detailData.description,
      rejected_notes: detailData.rejected_notes,
    };
  }
  return { detailData, pending, initialValues, file };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetIuranMemberModal: (modalType, isOpen, title, folderName, fileName) =>
      dispatch(
        setIuranMemberModal(modalType, isOpen, title, folderName, fileName)
      ),
    onSetIuranMemberData: (data) => dispatch(setIuranMemberData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
