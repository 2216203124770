import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { reduxForm, Field, FieldArray } from "redux-form";
import { ModalBody, ModalHeader } from "reactstrap";
import { Row, Column } from "simple-flexbox";
import { Button } from "@material-ui/core";
import {
  formInput,
  formSelect,
  formCheckbox,
} from "../../../../../components/commons/form";
import PollingAdmin, {
  setPollingAdminModal,
} from "../../../../../store/actions/polling/admin";
import LabelInputVerticalComponent from "../../../../../components/global-components/LabelInputVertical";
import { pollingOptions } from "../../../../../utils/constant";
import RenderArrayJawaban from "./fieldArrayJawaban";

let Edit = ({
  onSetPollingAdminModal,
  handleSubmit,
  detailData,
  detailDataQuestion,
  handleRefresh,
  pending,
}) => {
  const dispatch = useDispatch();
  const [type, setType] = useState(detailDataQuestion.type);

  const onSubmit = ({
    title,
    type,
    multiple_questions,
    min_scale,
    max_scale,
    hasOther,
  }) => {
    const id = window.location.pathname.split("/").pop();
    let answer = multiple_questions?.map((item, index) => {
      return {
        is_extra_text: 0,
        key: index + 1,
        number: index + 1,
        value: item.value,
      };
    });
    if (hasOther) {
      answer.push({
        is_extra_text: 1,
        key: answer.length + 1,
        number: answer.length + 1,
        value: "Other",
      });
    }
    let list_question = detailData.list_questions;

    const question = {
      title,
      type: type.value,
      multiple_questions: answer,
      min_scale: min_scale?.value,
      max_scale: max_scale?.value,
      number: detailDataQuestion.number,
    };
    let indexEdit = list_question.findIndex((item) => {
      return item.number === detailDataQuestion.number;
    });

    list_question[indexEdit] = question;
    const param = {
      id: id,
      ...detailData,
      list_questions: list_question,
    };
    const callback = () => {
      onSetPollingAdminModal("", false);
      handleRefresh();
    };
    dispatch(PollingAdmin.put(param, callback));
  };

  let slideOptions = Array.from(Array(10), (_, index) => index + 1).map(
    (item) => {
      return { value: item, label: item };
    }
  );

  return (
    <>
      <ModalHeader>Edit Pertanyaan</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <LabelInputVerticalComponent label="Pertanyaan">
            <Field
              name="title"
              placeholder="Pertanyaan"
              component={formInput}
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Jenis Polling">
            <Field
              placeholder="Jenis Polling"
              name="type"
              options={pollingOptions}
              component={formSelect}
              onChange={(e) => {
                setType(e.value);
              }}
            />
          </LabelInputVerticalComponent>
          {(type === 2 || type === 3) && (
            <Column>
              <FieldArray
                name="multiple_questions"
                component={RenderArrayJawaban}
              />
              <Field
                name="hasOther"
                label="Pilihan Lainnya"
                placeholder="Pertanyaan"
                component={formCheckbox}
              />
            </Column>
          )}
          {type === 4 && (
            <Row horizontal="space-between">
              <div className="w-100">
                <Field
                  placeholder="Dari"
                  name="min_scale"
                  options={slideOptions}
                  component={formSelect}
                />
              </div>
              <div className="mx-3"></div>
              <div className="w-100">
                <Field
                  placeholder="Hingga"
                  name="max_scale"
                  options={slideOptions}
                  component={formSelect}
                />
              </div>
            </Row>
          )}
          <div className="d-flex justify-content-between">
            <Button
              variant="outlined"
              className="mt-3"
              disabled={pending}
              color="primary"
              onClick={() => onSetPollingAdminModal("", false)}
            >
              Batal
            </Button>
            <Button
              type="submit"
              className="mt-3"
              disabled={pending}
              variant="contained"
              color="primary"
            >
              Simpan
            </Button>
          </div>
        </form>
      </ModalBody>
    </>
  );
};

const validate = ({
  title,
  type,
  multiple_questions,
  min_scale,
  max_scale,
}) => {
  const errors = {};
  if (!title) {
    errors.title = "Judul Polling harus diisi";
  }
  if (!type) {
    errors.type = "Jenis Polling harus diisi";
  }
  if (!min_scale) {
    errors.min_scale = "Scale harus diisi";
  }
  if (!max_scale) {
    errors.max_scale = "Scale harus diisi";
  }

  const multiple_questionsArrayErrors = [];
  if (!multiple_questions || !multiple_questions.length) {
    errors.multiple_questions = { _error: "Jawaban tidak boleh kosong" };
  } else {
    multiple_questions.forEach((item, index) => {
      const multiple_questionsErrors = {};
      if (!item || !item.value) {
        multiple_questionsErrors.value = "Tentukan Pilihan Jawaban";
        multiple_questionsArrayErrors[index] = multiple_questionsErrors;
      }
    });
  }
  if (multiple_questionsArrayErrors.length) {
    errors.multiple_questions = multiple_questionsArrayErrors;
  }
  return errors;
};

Edit = reduxForm({
  form: "pollingAdminEditQuestion",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(Edit);

const mapStateToProps = ({
  pollingAdmin: { detailDataQuestion, data, pending },
}) => {
  let initialValues = {};
  const detailData = data?.data?.data[0];
  if (detailDataQuestion) {
    let dataMulti = detailDataQuestion.multiple_questions?.filter(
      (item) => item.value !== "Other"
    );
    initialValues = {
      title: detailDataQuestion.title,
      type: {
        label: pollingOptions[detailDataQuestion.type - 1].label,
        value: detailDataQuestion.type,
      },
      multiple_questions: dataMulti,
      min_scale: {
        label: detailDataQuestion.min_scale,
        value: detailDataQuestion.min_scale,
      },
      max_scale: {
        label: detailDataQuestion.max_scale,
        value: detailDataQuestion.max_scale,
      },
      hasOther: detailDataQuestion.multiple_questions?.some(
        (item) => item.value === "Other"
      ),
    };
    // console.log(initialValues);
  }
  return {
    detailData,
    detailDataQuestion,
    initialValues,
    pending,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetPollingAdminModal: (modalType, isOpen) =>
      dispatch(setPollingAdminModal(modalType, isOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
