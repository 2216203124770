import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "./components/layout";
import PrivateRoute from "./components/commons/privateRoute";
import { getUser } from "./utils/user";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

// views
import Index from "./views/Index";
import Login from "./views/login";
import ForgotPassword from "./views/login/forgot-password";
import Verifikasi from "./views/login/verifikasi";
import Admin from "./views/anggota/administrator";
import Member from "./views/anggota/member";
import DetailMember from "./views/anggota/member/detail";
import Request from "./views/anggota/permintaan";
import AddRequest from "./views/anggota/permintaan/detail";
import MasterRegion from "./views/master/region";
import MasterKesehatan from "./views/master/kesehatan";
import MasterPosition from "./views/master/position";
import SystemUser from "./views/system/pengguna";
import Profile from "./views/profile";
import PollingAdmin from "./views/polling/admin";
import DetailPollingAdmin from "./views/polling/admin/detail";
import DetailResponPollingAdmin from "./views/polling/admin/detail/detail-respon";
import PollingMember from "./views/polling/member";
import DetailPollingMember from "./views/polling/member/detail";
import VotingPollingMember from "./views/polling/member/voting";
import MessageBlast from "./views/message-blast";
import DetailMessageBlast from "./views/message-blast/detail";
import AddMessageBlast from "./views/message-blast/add";
import IuranMember from "./views/iuran/member";
import DetailIuranMember from "./views/iuran/member/detail";
import IuranAdminCabang from "./views/iuran/admin-cabang";
import DetailIuranAdminCabang from "./views/iuran/admin-cabang/detail";
import SetoranAdmin from "./views/setoran";
import DetailSetoranAdmin from "./views/setoran/detail";
import Dashboard from "./views/dashboard";
import Notification from "./views/notification";
import AuditTrail from "./views/system/audit-trail";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2CB96A",
      contrastText: "#FFFFFF",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    button: {
      textTransform: "none",
    },
    fontFamily: [
      "Circular Std Book",
      "Roboto",
      "Segoe UI",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "sans-serif",
    ],
  },
});

Object.assign(theme, {
  overrides: {
    MUIRichTextEditor: {
      container: {
        marginTop: 20,
        border: "1px solid #EFF0F2",
        minHeight: 300,
        borderRadius: 5,
        backgroundColor: "#FCFCFC",
      },
      toolbar: {
        // backgroundColor: "red",
        borderBottom: "1px solid #EFF0F2",
      },
      root: {
        width: "100%",
      },
      editorContainer: {
        padding: 20,
        fontFamily: "Circular Std Book",
        fontSize: 14,
        lineHeight: 1.6,
        color: "#7F8388",
      },
    },
  },
});

const App = () => {
  const ContentRoute = () => {
    let role;
    if (getUser()) {
      role = getUser().role_code;
    }
    return (
      <Layout>
        <Switch>
          <PrivateRoute
            head={["Dashboard"]}
            path="/dashboard"
            roles={["superadmin"]}
            exact
            component={Dashboard}
          />
          <PrivateRoute
            head={["Anggota", "Permintaan"]}
            path="/request"
            roles={["superadmin", "admin_cabang"]}
            exact
            component={Request}
          />
          <PrivateRoute
            head={["Anggota", "Permintaan", "Tambah Permintaan Anggota"]}
            path="/request/add"
            roles={["superadmin"]}
            goBack
            exact
            component={AddRequest}
          />
          <PrivateRoute
            head={["Anggota", "Permintaan", "Detail Permintaan Anggota"]}
            path="/request/detail/:id"
            roles={["superadmin", "admin_cabang"]}
            goBack
            exact
            component={AddRequest}
          />
          <PrivateRoute
            head={["Anggota", "Permintaan", "Edit Permintaan Anggota"]}
            path="/request/edit/:id"
            roles={["superadmin"]}
            goBack
            exact
            component={AddRequest}
          />
          <PrivateRoute
            head={["Anggota", "Anggota"]}
            path="/member"
            roles={["superadmin", "admin_cabang"]}
            exact
            component={Member}
          />
          <PrivateRoute
            head={["Anggota", "Anggota", "Detail Anggota"]}
            path="/member/detail/:id"
            roles={["superadmin", "admin_cabang", "member"]}
            goBack
            exact
            component={DetailMember}
          />
          <PrivateRoute
            head={["Anggota", "Anggota", "Edit Anggota"]}
            path="/member/edit/:id"
            roles={["superadmin"]}
            goBack
            exact
            component={DetailMember}
          />
          <PrivateRoute
            head={["Anggota", "Pengurus"]}
            path="/administrator"
            roles={["superadmin"]}
            exact
            component={Admin}
          />
          <PrivateRoute
            head={["Message Blast"]}
            path="/message-blast"
            roles={["superadmin"]}
            exact
            component={MessageBlast}
          />
          <PrivateRoute
            head={["Message Blast", "Tambah Message Blast"]}
            path="/message-blast/add"
            roles={["superadmin"]}
            goBack
            exact
            component={AddMessageBlast}
          />
          <PrivateRoute
            head={["Message Blast", "Detail Message Blast"]}
            path="/message-blast/detail/:id"
            roles={["superadmin"]}
            goBack
            exact
            component={DetailMessageBlast}
          />
          <PrivateRoute
            head={["Setoran"]}
            path="/admin/setoran"
            roles={["superadmin", "admin_cabang"]}
            exact
            component={SetoranAdmin}
          />
          <PrivateRoute
            head={["Setoran", "Detail Setoran"]}
            path="/admin/setoran/detail/:id"
            roles={["superadmin", "admin_cabang"]}
            goBack
            exact
            component={DetailSetoranAdmin}
          />
          <PrivateRoute
            head={["Setoran", "Edit Setoran"]}
            path="/admin/setoran/edit/:id"
            roles={["admin_cabang"]}
            goBack
            exact
            component={DetailSetoranAdmin}
          />
          <PrivateRoute
            head={["Iuran"]}
            path="/admin/iuran"
            roles={["superadmin", "admin_cabang"]}
            exact
            component={IuranAdminCabang}
          />
          <PrivateRoute
            head={["Iuran", "Detail Iuran"]}
            path="/admin/iuran/:id"
            roles={["superadmin", "admin_cabang"]}
            goBack
            exact
            component={DetailIuranAdminCabang}
          />
          <PrivateRoute
            head={["Data Master", "Alat Kesehatan"]}
            path="/master/medical-devices"
            roles={["superadmin"]}
            exact
            component={MasterKesehatan}
          />
          <PrivateRoute
            head={["Data Master", "Wilayah"]}
            path="/master/region"
            roles={["superadmin"]}
            exact
            component={MasterRegion}
          />
          <PrivateRoute
            head={["Data Master", "Jabatan"]}
            path="/master/position"
            roles={["superadmin"]}
            exact
            component={MasterPosition}
          />
          <PrivateRoute
            head={["Notifikasi"]}
            path="/notification"
            roles={["superadmin", "admin_cabang", "member"]}
            exact
            component={Notification}
          />
          <PrivateRoute
            head={["Polling"]}
            path="/admin/polling"
            roles={["superadmin"]}
            exact
            component={PollingAdmin}
          />
          <PrivateRoute
            head={["Polling", "Detail Polling"]}
            path="/admin/polling/detail/:id"
            roles={["superadmin"]}
            goBack
            exact
            component={DetailPollingAdmin}
          />
          <PrivateRoute
            head={["Polling", "Detail Polling", "Detail Respon"]}
            path="/admin/polling/detail/:polling_id/:question_id/:type"
            roles={["superadmin"]}
            goBack
            exact
            component={DetailResponPollingAdmin}
          />
          <PrivateRoute
            head={["Sistem", "Pengguna"]}
            path="/system/member"
            roles={["superadmin"]}
            exact
            component={SystemUser}
          />
          <PrivateRoute
            head={["Sistem", "Audit Trail"]}
            path="/audit-trail"
            roles={["superadmin"]}
            exact
            component={AuditTrail}
          />
          <PrivateRoute
            head={["Profil"]}
            path="/profile"
            roles={["member"]}
            exact
            component={Profile}
          />
          <PrivateRoute
            head={["Anggota", "Anggota"]}
            path="/member/list"
            roles={["member"]}
            exact
            component={Member}
          />
          <PrivateRoute
            head={["Iuran"]}
            path="/iuran"
            roles={["member"]}
            exact
            component={IuranMember}
          />
          <PrivateRoute
            head={["Iuran", "Detail Iuran"]}
            path="/iuran/:id"
            roles={["member"]}
            goBack
            exact
            component={DetailIuranMember}
          />
          <PrivateRoute
            head={["Polling"]}
            path="/polling"
            roles={["member"]}
            exact
            component={PollingMember}
          />
          <PrivateRoute
            head={["Polling", "List Pertanyaan"]}
            path="/polling/:id"
            roles={["member"]}
            goBack
            exact
            component={VotingPollingMember}
          />
          <PrivateRoute
            head={["Polling", "Detail List Pertanyaan"]}
            path="/polling/detail/:id"
            roles={["member"]}
            goBack
            exact
            component={DetailPollingMember}
          />
          <PrivateRoute roles={[]} exact component={() => <div></div>} />
        </Switch>
      </Layout>
    );
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <Switch>
          {/* <Route path="/" exact component={Login} /> */}
          <Route path="/login" exact component={Login} />
          <Route path="/forgot-password" exact component={ForgotPassword} />
          <Route path="/verifikasi/:token" exact component={Verifikasi} />
          <Route component={ContentRoute} />
        </Switch>
      </ThemeProvider>
    </>
  );
};

export default App;
