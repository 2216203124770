import React from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import MemberList from "../location-member-list";

const Index = ({ dataAgency, dataProduced }) => {
  // const dataAgency = [
  //   {
  //     name: "Gigi",
  //     total: 25,
  //   },
  //   {
  //     name: "Medical",
  //     total: 20,
  //   },
  //   {
  //     name: "Orthopedic",
  //     total: 15,
  //   },
  //   {
  //     name: "Masker",
  //     total: 10,
  //   },
  //   {
  //     name: "Stetoskop",
  //     total: 5,
  //   },
  // ];
  // const dataDistribute = [
  //   {
  //     name: "Gigi",
  //     total: 25,
  //   },
  //   {
  //     name: "Medical",
  //     total: 20,
  //   },
  //   {
  //     name: "Orthopedic",
  //     total: 15,
  //   },
  //   {
  //     name: "Masker",
  //     total: 10,
  //   },
  //   // {
  //   //   name: "Stetoskop",
  //   //   total: 5,
  //   // },
  // ];
  return (
    <Column>
      <label className={css(styles.titleLabel)}>Kategori Anggota</label>
      <div className="row">
        <div className="col-6">
          <div className={css(styles.cardHeaderAgency)}></div>
          <Column
            className={css(styles.cardSupportWrapper)}
            style={{ width: "100%", minHeight: 207 }}
          >
            <Column
              className={css(styles.wrapperTop, styles.borderBottom)}
              style={{ width: "100%" }}
              vertical="center"
              // horizontal="center"
            >
              <label className={css(styles.subTitleLabel)}>
                Diageni/Distribusi
              </label>
              <div className="col p-0">
                {dataAgency?.map((item, index) => (
                  <div
                    className="d-flex justify-content-between border-bottom"
                    style={{ marginTop: 8 }}
                  >
                    <label>
                      {index + 1}. {item.medical_device_name}
                    </label>
                    <label style={{ color: "#B3B5C3" }}>
                      {item.total_member}
                    </label>
                  </div>
                ))}
              </div>
            </Column>
          </Column>
        </div>
        <div className="col-6">
          <div className={css(styles.cardHeaderDistribute)}></div>
          <Column
            className={css(styles.cardSupportWrapper)}
            style={{ width: "100%", minHeight: 207 }}
          >
            <Column
              className={css(styles.wrapperTop, styles.borderBottom)}
              style={{ width: "100%" }}
              vertical="center"
              // horizontal="center"
            >
              <label className={css(styles.subTitleLabel)}>Diproduksi</label>
              <div className="col p-0">
                {dataProduced?.map((item, index) => (
                  <div
                    className="d-flex justify-content-between border-bottom"
                    style={{ marginTop: 8 }}
                  >
                    <label>
                      {index + 1}. {item.medical_device_name}
                    </label>
                    <label style={{ color: "#B3B5C3" }}>
                      {item.total_member}
                    </label>
                  </div>
                ))}
              </div>
            </Column>
          </Column>
        </div>
      </div>
    </Column>
  );
};

const styles = StyleSheet.create({
  titleLabel: {
    color: "#495057",
    fontSize: 18,
    fontWeight: "bold",
    margin: "0px 0px 15px 0px",
  },
  subTitleLabel: {
    color: "#495057",
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: "10px",
  },
  cardLabel: {
    color: "#BCBCBC",
    marginBottom: 0,
  },
  cardSupportWrapper: {
    boxShadow: "0px 3px 6px #00000029",
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    padding: 15,
    backgroundColor: "white",
  },
  cardHeaderAgency: {
    backgroundColor: "#2cb96a",
    height: 10,
    width: "100%",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
  },
  cardHeaderDistribute: {
    backgroundColor: "#00A3EB",
    height: 10,
    width: "100%",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
  },
  supportWrapper: {
    background: "#F5F5F5 0% 0% no-repeat padding-box",
    borderRadius: 5,
    padding: 15,
    marginBottom: 10,
    height: 140,
  },
});

export default Index;
