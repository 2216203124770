import {
  GET_MESSAGE_BLAST_PENDING,
  GET_MESSAGE_BLAST_SUCCESS,
  GET_MESSAGE_BLAST_ERROR,
  GET_DETAIL_MESSAGE_BLAST_PENDING,
  GET_DETAIL_MESSAGE_BLAST_SUCCESS,
  GET_DETAIL_MESSAGE_BLAST_ERROR,
  POST_MESSAGE_BLAST_PENDING,
  POST_MESSAGE_BLAST_SUCCESS,
  POST_MESSAGE_BLAST_ERROR,
  PUT_MESSAGE_BLAST_PENDING,
  PUT_MESSAGE_BLAST_SUCCESS,
  PUT_MESSAGE_BLAST_ERROR,
  DELETE_MESSAGE_BLAST_PENDING,
  DELETE_MESSAGE_BLAST_SUCCESS,
  DELETE_MESSAGE_BLAST_ERROR,
  MESSAGE_BLAST_DATA,
  SET_MESSAGE_BLAST_MODAL,
} from "../../actions/message-blast";

const initialState = {
  pending: false,
  error: null,
  data: null,
  detailData: null,
  isOpenModal: false,
  modalType: "",
  title: "",
  folderName: "",
  fileName: "",
};

const messageBlast = (state = initialState, action) => {
  switch (action.type) {
    case GET_MESSAGE_BLAST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_MESSAGE_BLAST_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_MESSAGE_BLAST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case GET_DETAIL_MESSAGE_BLAST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_DETAIL_MESSAGE_BLAST_SUCCESS:
      return {
        ...state,
        pending: false,
        detailData: action.data,
      };
    case GET_DETAIL_MESSAGE_BLAST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POST_MESSAGE_BLAST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case POST_MESSAGE_BLAST_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case POST_MESSAGE_BLAST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case PUT_MESSAGE_BLAST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case PUT_MESSAGE_BLAST_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case PUT_MESSAGE_BLAST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case DELETE_MESSAGE_BLAST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_MESSAGE_BLAST_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case DELETE_MESSAGE_BLAST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case MESSAGE_BLAST_DATA:
      return {
        ...state,
        detailData: action.data,
      };
    case SET_MESSAGE_BLAST_MODAL:
      return {
        ...state,
        isOpenModal: action.data.isOpen,
        modalType: action.data.modalType,
        title: action.data.title,
        folderName: action.data.folderName,
        fileName: action.data.fileName,
      };
    default:
      return state;
  }
};

export default messageBlast;
