import React from "react";
import { connect } from "react-redux";
import { Modal } from "reactstrap";
import { setIuranMemberModal } from "../../../../store/actions/iuran/member";
import Add from "./add";
import Approval from "./approval";
import Approve from "./approve";
import Reject from "./reject";
import Detail from "./detail";
import ShowDocument from "./show-document";

const index = ({
  isOpenModal,
  modalType,
  onSetIuranMemberModal,
  handleRefresh,
  title,
  fileName,
  folderName,
}) => {
  const toggle = () => onSetIuranMemberModal("", !isOpenModal);
  const child = (modalType) => {
    switch (modalType) {
      case "add":
        return <Add handleRefresh={handleRefresh} />;
      case "approval":
        return <Approval handleRefresh={handleRefresh} />;
      case "approve":
        return <Approve handleRefresh={handleRefresh} />;
      case "reject":
        return <Reject handleRefresh={handleRefresh} />;
      case "detail":
        return <Detail />;
      case "show-document":
        return (
          <ShowDocument
            title={title}
            fileName={fileName}
            folderName={folderName}
          />
        );
      default:
        <div></div>;
    }
  };
  return (
    <Modal
      className="modal-medium"
      isOpen={isOpenModal}
      toggle={toggle}
      centered
    >
      {child(modalType)}
    </Modal>
  );
};

const mapStateToProps = ({
  iuranMember: { isOpenModal, modalType, title, folderName, fileName },
}) => {
  return { isOpenModal, modalType, title, folderName, fileName };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetIuranMemberModal: (type, isOpen) =>
      dispatch(setIuranMemberModal(type, isOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
