import {
  GET_ALAT_KESEHATAN_PENDING,
  GET_ALAT_KESEHATAN_SUCCESS,
  GET_ALAT_KESEHATAN_ERROR,
  POST_ALAT_KESEHATAN_PENDING,
  POST_ALAT_KESEHATAN_SUCCESS,
  POST_ALAT_KESEHATAN_ERROR,
  PUT_ALAT_KESEHATAN_PENDING,
  PUT_ALAT_KESEHATAN_SUCCESS,
  PUT_ALAT_KESEHATAN_ERROR,
  DELETE_ALAT_KESEHATAN_PENDING,
  DELETE_ALAT_KESEHATAN_SUCCESS,
  DELETE_ALAT_KESEHATAN_ERROR,
  ALAT_KESEHATAN_DATA,
  SET_ALAT_KESEHATAN_MODAL,
} from "../../../actions/master/kesehatan";

const initialState = {
  pending: false,
  error: null,
  data: null,
  detailData: null,
  isOpenModal: false,
  modalType: "",
};

const kesehatan = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALAT_KESEHATAN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_ALAT_KESEHATAN_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_ALAT_KESEHATAN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POST_ALAT_KESEHATAN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case POST_ALAT_KESEHATAN_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case POST_ALAT_KESEHATAN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case PUT_ALAT_KESEHATAN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case PUT_ALAT_KESEHATAN_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case PUT_ALAT_KESEHATAN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case DELETE_ALAT_KESEHATAN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_ALAT_KESEHATAN_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case DELETE_ALAT_KESEHATAN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case ALAT_KESEHATAN_DATA:
      return {
        ...state,
        detailData: action.data,
      };
    case SET_ALAT_KESEHATAN_MODAL:
      return {
        ...state,
        isOpenModal: action.data.isOpen,
        modalType: action.data.modalType,
      };
    default:
      return state;
  }
};

export default kesehatan;
