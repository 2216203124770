import React from "react";

export default (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={props.opacity || ""}
      d="M9.94775 1.31579C9.25708 1.31579 8.5947 1.59304 8.10633 2.08656C7.61795 2.58007 7.34359 3.24943 7.34359 3.94737V6.57894H6.0415V3.94737C6.0415 2.90046 6.45305 1.89643 7.18562 1.15616C7.91817 0.415883 8.91175 0 9.94775 0C10.9837 0 11.9773 0.415883 12.7099 1.15616C13.4424 1.89643 13.854 2.90046 13.854 3.94737V6.57894H12.5519V3.94737C12.5519 3.24943 12.2775 2.58007 11.7892 2.08656C11.3008 1.59304 10.6384 1.31579 9.94775 1.31579Z"
      fill={props.fill || "#FFFFFF"}
      transform="translate(2,0)"
    />
    <path
      opacity={props.opacity || ""}
      d="M1.875 6.52631C0.839466 6.52631 0 7.37461 0 8.42105V18.1053C0 19.1517 0.839466 20 1.875 20H18.125C19.1606 20 20 19.1517 20 18.1053V8.42105C20 7.37461 19.1606 6.52631 18.125 6.52631H1.875ZM10.9896 12.2255H14.2708C14.6734 12.2255 15 12.5353 15 12.9173V13.609C15 13.991 14.6734 14.3007 14.2708 14.3007H10.9896V17.4136C10.9896 17.7954 10.6631 18.1053 10.2604 18.1053H9.53126C9.12861 18.1053 8.80209 17.7954 8.80209 17.4136V14.3007H5.52083C5.1182 14.3007 4.79167 13.991 4.79167 13.609V12.9173C4.79167 12.5353 5.1182 12.2255 5.52083 12.2255H8.80209V9.11278C8.80209 8.73081 9.12861 8.42105 9.53126 8.42105H10.2604C10.6631 8.42105 10.9896 8.73081 10.9896 9.11278V12.2255Z"
      fill={props.fill || "#FFFFFF"}
      transform="translate(2,0)"
    />
  </svg>
);
