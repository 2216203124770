import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import PollingAdmin, {
  setPollingAdminDataQuestion,
  setPollingAdminModal,
} from "../../../../store/actions/polling/admin";
import { Row, Column } from "simple-flexbox";
import MaterialTable from "material-table";
import SearchIcon from "@material-ui/icons/Search";
import { Paper, Button, MenuItem } from "@material-ui/core";
import DetailButtonComponent from "../../../../components/global-components/DetailButton";
import InputComponent from "../../../../components/commons/form/input";
import Container from "../../../../components/container";
import Modal from "./modal";
import debounce from "lodash.debounce";
import { history } from "../../../../utils";
import { isInTime, isMoreTime } from "../../../../utils/date";
import moment from "moment";
import HeaderDetailPolling from "../../../../components/header-content/detail-polling";
import API from "../../../../store/actions/API";
import { toastError, toastSuccess } from "../../../../components/commons/toast";
import { StyleSheet, css } from "aphrodite";

const DetailPolling = ({
  onSetPollingAdminModal,
  onSetPollingAdminDataQuestion,
  pending,
  detailData,
}) => {
  const [searchText, setSearchText] = useState("");
  const [pendingDownload, setPendingDownload] = useState("");
  const dispatch = useDispatch();
  const tableRef = useRef();
  const [heightDetail, setHeightDetail] = useState(0)
  const detailRef = useRef(null)
  const [modalButtonDetail, setModalButtonDetail] = useState(0)
  const modalButtonDetailRef = useRef(null)
  const [showButtonDetail, setShowButtonDetail] = useState(false);

  let listQuestion = detailData?.list_questions
    ? detailData.list_questions
    : [];

  const id = window.location.pathname.split("/").pop();

  const handleRefresh = (state) => {
    setSearchText("");
    getListQuestion();
  };

  const delayedQuery = debounce((value) => {
    return setSearchText(value);
  }, 500);

  const handleSearchChange = (e) => {
    delayedQuery(e.target.value);
  };

  const setModal = (modalType, isOpen, data) => {
    onSetPollingAdminModal(modalType, isOpen);
    onSetPollingAdminDataQuestion(data);
  };

  const setDetail = (data) => {
    onSetPollingAdminDataQuestion(data);
    history.push(`/admin/polling/detail/${id}/${data.id}/${data.type}`);
  };

  useEffect(() => {
    getListQuestion();
  }, []);

  useEffect(() => {
    getListQuestion();
  }, [id]);

  useEffect(() => {
    setHeightDetail(detailRef.current.clientHeight)
  })

  useEffect(() => {
    setModalButtonDetail(modalButtonDetailRef)
    if (detailRef.current !== null && heightDetail >= 75) {
      setShowButtonDetail(true)
    }
  })

  const setClassButtonDetail = () => {
    if (showButtonDetail) {
      return css(styles.maxThreeRow)
    }
    return css(styles.maxNoneRow)
  };

  const getListQuestion = () => dispatch(PollingAdmin.get({ id: id }));

  const exportExcel = () => {
    setPendingDownload(true);
    API.get(`v1/polling/export`, {
      params: { id: id },
      responseType: "blob",
    })
      .then((res) => {
        setPendingDownload(false);
        if (res.error) {
          throw res.error;
        }
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${detailData?.title}_export.xlsx`);
        document.body.appendChild(link);
        link.click();

        return res;
      })
      .catch((err) => {
        setPendingDownload(false);
        toastError(err?.response?.data?.message);
      });
  };
  return (
    <Container>
      <Modal handleRefresh={(state) => handleRefresh(state)} />
      <div className="row m-3 justify-content-between">
        <div className="col border-right p-3">
          <span className="font-weight-bold">Nama Polling</span>
          <div className="pt-2">
            <span>{detailData?.title ? detailData?.title : "-"}</span>
          </div>
        </div>
        <div className="col border-right p-3">
          <span className="font-weight-bold">Deskripsi Polling</span>
          <div className="pt-2">
            <span 
              // className={css(styles.maxThreeRow)}
              className={setClassButtonDetail()}
              ref={detailRef}
              >
              {detailData?.description ? (
                <div dangerouslySetInnerHTML={{ __html: detailData?.description }}></div>
              ) : (
                "-"
              )}
            </span>
          </div>
          <div className="pt-2">
            {(showButtonDetail) && (
              <span 
                ref={modalButtonDetailRef}
                className={css(styles.seeMoreLabel)}
                onClick={() => setModal("detailDeskripsi", true, detailData)}
                >
                Lihat Selengkapnya
              </span>
            )}
          </div>
        </div>
        <div className="col border-right p-3">
          <span className="font-weight-bold">Tanggal Mulai</span>
          <div className="pt-2">
            <span>
              {detailData?.start_date ? moment(detailData?.start_date).format("DD MMM YYYY") : "-"}
            </span>
          </div>
        </div>
        <div className="col border-right p-3">
          <span className="font-weight-bold">Tanggal Berakhir</span>
          <div className="pt-2">
            <span>
              {detailData?.end_date ? moment(detailData?.end_date).format("DD MMM YYYY") : "-"}
            </span>
          </div>
        </div>
        <div className="col p-3">
          <span className="font-weight-bold">Total Voter</span>
          <div className="pt-2">
            <span>{detailData?.total_voter ? detailData?.total_voter : "0"}</span>
          </div>
        </div>
      </div>
      
      <Row className="m-3 justify-content-between">
        {!isInTime(detailData?.start_date, detailData?.end_date) &&
        !isMoreTime(detailData?.start_date) ? (
          <Button
            color="primary"
            variant="contained"
            disabled={pending}
            onClick={() => setModal("add", true, null)}
          >
            Tambah Pertanyaan
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            disabled={pending || pendingDownload}
            onClick={() => exportExcel()}
          >
            Export Excel
          </Button>
        )}
        <InputComponent
          onChange={(e) => handleSearchChange(e)}
          placeholder="Cari Pertanyaan"
          endIcon={SearchIcon}
        />
      </Row>
      <div className="m-3">
        <MaterialTable
          tableRef={tableRef}
          title="PollingAdmin"
          columns={[
            {
              title: "No",
              // field: "number",
              render: ({ tableData }) => {
                return tableData ? tableData.id + 1 : "-";
              },
              width: 40,
            },
            {
              title: "Pertanyaan",
              render: ({ title }) => {
                return title ? title : "-";
              },
            },
            {
              title: "Jenis Pertanyaan",
              render: ({ type }) => {
                return type === 1
                  ? "Short Answer"
                  : type === 2
                  ? "Multiple Choice"
                  : type === 3
                  ? "Checklist"
                  : type === 4
                  ? "Scale"
                  : "-";
              },
            },
            {
              title: "Aksi",
              width: 80,
              cellStyle: {
                paddingLeft: 0,
              },
              render: (rowData) => {
                return (
                  <DetailButtonComponent>
                    {isInTime(detailData?.start_date, detailData?.end_date) ||
                    isMoreTime(detailData?.start_date) ? (
                      <MenuItem onClick={() => setDetail(rowData)}>
                        Lihat Detail
                      </MenuItem>
                    ) : (
                      <div>
                        <MenuItem
                          onClick={() => setModal("edit", true, rowData)}
                        >
                          Edit Data
                        </MenuItem>
                        <MenuItem
                          onClick={() => setModal("delete", true, rowData)}
                        >
                          Hapus Data
                        </MenuItem>
                      </div>
                    )}
                  </DetailButtonComponent>
                );
              },
            },
          ]}
          data={listQuestion.filter((item) => {
            if (item.title) {
              return item.title
                .toLowerCase()
                .includes(searchText.toLowerCase());
            } else {
              return false;
            }
          })}
          isLoading={pending}
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            showTitle: false,
            search: false,
            sorting: false,
            headerStyle: {
              backgroundColor: "#fff",
              fontWeight: "bold",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
        />
      </div>
    </Container>
  );
};

const styles = StyleSheet.create({
  seeMoreLabel: {
    color: "#2CB96A",
    fontSize: 14,
    cursor: "pointer",
  },
  maxThreeRow: {
    display: "-webkit-box",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  maxNoneRow: {
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  }
});

const mapStateToProps = ({ pollingAdmin }) => {
  const { data, pending } = pollingAdmin;

  const detailData = data?.data?.data?.length === 1 ? data.data.data[0] : null;
  return { detailData, pending };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetPollingAdminModal: (modalType, isOpen) =>
      dispatch(setPollingAdminModal(modalType, isOpen)),
    onSetPollingAdminDataQuestion: (data) =>
      dispatch(setPollingAdminDataQuestion(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DetailPolling)
);
