import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { ModalBody, ModalHeader } from "reactstrap";
import { Row, Column } from "simple-flexbox";
import { Button } from "@material-ui/core";
import {
  formInputNumber,
  formTextArea,
  formFile,
  FormDatepicker,
  normalizeDates,
  formatDates,
  formSelect,
} from "../../../components/commons/form";
import LabelInputVerticalComponent from "../../../components/global-components/LabelInputVertical";
import SetoranAdmin, {
  setSetoranAdminModal,
} from "../../../store/actions/setoran";
import IuranMember from "../../../store/actions/iuran/member";
import { IURAN_FOLDER } from "../../../utils/constant";
import File from "../../../store/actions/file";
import moment from "moment";

let Add = ({
  onSetSetoranAdminModal,
  handleSubmit,
  handleRefresh,
  pending,
  file,
  iuranMember,
}) => {
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState(null);

  const onSubmit = ({ date, total, description, member_dues }) => {
    let list_dues = member_dues.map((item) => item.value);
    const param = {
      date: moment(date).format("YYYY-MM-DD"),
      total: total.toString().replace("Rp ", "").replace(/\D/g, ""),
      description,
      filename: fileName,
      list_dues,
    };
    const callback = () => {
      onSetSetoranAdminModal("", false);
      handleRefresh();
    };
    dispatch(SetoranAdmin.post(param, callback));
  };

  const uploadFile = (e) => {
    let formData = new FormData();
    formData.append("file", e);

    const callback = (res) => {
      console.log(res);
      setFileName(res.data.data.fileName);
    };

    dispatch(File.upload(formData, callback, IURAN_FOLDER));
  };

  let iuranOptions;
  if (iuranMember.data) {
    iuranOptions = iuranMember.data.data.data.map((item) => {
      return {
        label: item.user_fullname,
        value: item.id,
      };
    });
  }

  useEffect(() => {
    getIuranMember();
  }, []);

  const getIuranMember = () => dispatch(IuranMember.getSetoran());

  return (
    <>
      <ModalHeader>Tambah Setoran</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <LabelInputVerticalComponent label="Tanggal">
            <Field
              name="date"
              placeholder="Tanggal"
              disabled
              parse={normalizeDates}
              format={formatDates}
              component={FormDatepicker}
              showClearDate={false}
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Total Setoran">
            <Field
              name="total"
              placeholder="Nominal Setoran"
              component={formInputNumber}
              thousandSeparator
              prefix="Rp "
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Daftar Anggota">
            <Field
              placeholder="Daftar Anggota"
              name="member_dues"
              options={iuranOptions}
              isAsync
              isMulti
              asyncUrl="/v1/member_dues"
              param={{ status: 1 }}
              customLabel={{ label: "user_fullname", value: "id" }}
              component={formSelect}
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Keterangan">
            <Field
              name="description"
              placeholder="Keterangan"
              rows={5}
              component={formTextArea}
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Bukti Pembayaran">
            <Field
              name="filename"
              type="file"
              fileType="pdf/image"
              title="Masukkan Bukti"
              onChange={(e) => uploadFile(e)}
              message="PDF / JPG"
              component={formFile}
            />
          </LabelInputVerticalComponent>
          <div className="d-flex justify-content-between">
            <Button
              variant="outlined"
              className="mt-3"
              disabled={pending}
              color="primary"
              onClick={() => onSetSetoranAdminModal("", false)}
            >
              Batal
            </Button>
            <Button
              type="submit"
              className="mt-3"
              disabled={pending || file.pending}
              variant="contained"
              color="primary"
            >
              Simpan
            </Button>
          </div>
        </form>
      </ModalBody>
    </>
  );
};

const validate = ({ total, description, filename, member_dues }) => {
  const errors = {};
  if (!total) {
    errors.total = "Total Setoran harus diisi";
  }
  if (!filename) {
    errors.filename = "Berkas harus diisi";
  }
  if (!description) {
    errors.description = "Keterangan harus diisi";
  }
  if (!member_dues) {
    errors.member_dues = "Anggota Iuran harus diisi";
  }

  return errors;
};

Add = reduxForm({
  form: "setoranAdminAdd",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(Add);

const mapStateToProps = ({ setoranAdmin: { pending }, file, iuranMember }) => {
  let initialValues = {};

  initialValues.date = moment().format("DD MMMM YYYY");
  return { pending, initialValues, file, iuranMember };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetSetoranAdminModal: (modalType, isOpen) =>
      dispatch(setSetoranAdminModal(modalType, isOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Add);
