import React from "react";
import { connect } from "react-redux";
import { Modal } from "reactstrap";
import { setPollingMemberModal } from "../../../../store/actions/polling/member";
import Detail from "./detail";
import Add from "./add";
import Edit from "./edit";
import Delete from "./delete";
import DetailDeskripsi from "./detailDeskripsi";

const index = ({
  isOpenModal,
  modalType,
  onSetPollingMemberModal,
  handleRefresh,
}) => {
  const toggle = () => onSetPollingMemberModal("", !isOpenModal);
  const child = (modalType) => {
    switch (modalType) {
      case "detailDeskripsi":
        return <DetailDeskripsi handleRefresh={handleRefresh} />;
      case "add":
        return <Add handleRefresh={handleRefresh} />;
      case "detail":
        return <Detail />;
      case "edit":
        return <Edit handleRefresh={handleRefresh} />;
      case "delete":
        return <Delete handleRefresh={handleRefresh} />;
    }
  };
  return (
    <Modal
      className="modal-medium"
      isOpen={isOpenModal}
      toggle={toggle}
      centered
    >
      {child(modalType)}
    </Modal>
  );
};

const mapStateToProps = ({ pollingMember: { isOpenModal, modalType } }) => {
  return { isOpenModal, modalType };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetPollingMemberModal: (type, isOpen) =>
      dispatch(setPollingMemberModal(type, isOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
