import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { ModalBody, ModalHeader } from "reactstrap";
import { Button } from "@material-ui/core";
import { formInput } from "../../../../components/commons/form";
import LabelInputVerticalComponent from "../../../../components/global-components/LabelInputVertical";
import Region, {
  setRegionModal,
} from "../../../../store/actions/master/region";

let Add = ({ onSetRegionModal, handleSubmit, handleRefresh, pending }) => {
  const dispatch = useDispatch();

  const onSubmit = ({ code, name }) => {
    const param = {
      code,
      name,
    };
    const callback = () => {
      onSetRegionModal("", false);
      handleRefresh();
    };
    dispatch(Region.post(param, callback));
  };
  return (
    <>
      <ModalHeader>Tambah Wilayah</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* <LabelInputVerticalComponent label="Kode Wilayah">
            <Field
              name="code"
              disabled
              placeholder="Kode Wilayah"
              component={formInput}
            />
          </LabelInputVerticalComponent> */}
          <LabelInputVerticalComponent label="Nama Wilayah">
            <Field
              name="name"
              placeholder="Nama Wilayah"
              component={formInput}
            />
          </LabelInputVerticalComponent>
          <div className="d-flex justify-content-between">
            <Button
              variant="outlined"
              className="mt-3"
              disabled={pending}
              color="primary"
              onClick={() => onSetRegionModal("", false)}
            >
              Batal
            </Button>
            <Button
              type="submit"
              className="mt-3"
              disabled={pending}
              variant="contained"
              color="primary"
            >
              Simpan
            </Button>
          </div>
        </form>
      </ModalBody>
    </>
  );
};

const validate = ({ code, name }) => {
  const errors = {};
  // if (!code) {
  //   errors.code = "Kode wilayah harus diisi";
  // }
  if (!name) {
    errors.name = "Nama wilayah harus diisi";
  }

  return errors;
};

Add = reduxForm({
  form: "regionAdd",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(Add);

const mapStateToProps = ({ region: { pending } }) => {
  return { pending };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetRegionModal: (modalType, isOpen) =>
      dispatch(setRegionModal(modalType, isOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Add);
