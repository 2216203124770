import React from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import MemberList from "../location-member-list";

const Index = ({ data, total }) => {
  // const data = [
  //   {
  //     name: "Jawa Barat",
  //     value: 65,
  //     total: 65,
  //   },
  //   {
  //     name: "Jawa Timur",
  //     value: 45,
  //     total: 65,
  //   },
  //   {
  //     name: "Jawa Tengah",
  //     value: 25,
  //     total: 65,
  //   },
  //   {
  //     name: "Jawa Utara",
  //     value: 15,
  //     total: 65,
  //   },
  //   {
  //     name: "Jawa Selatan",
  //     value: 5,
  //     total: 65,
  //   },
  // ];
  return (
    <Column>
      <div className={css(styles.cardHeader)}></div>
      <Column
        className={css(styles.cardSupportWrapper)}
        style={{ width: "100%", minHeight: 400 }}
      >
        <Column
          className={css(styles.wrapperTop, styles.borderBottom)}
          style={{ width: "100%" }}
          vertical="center"
          horizontal="center"
        >
          <label className={css(styles.titleLabel)}>Lokasi Anggota</label>
          {data?.map((item, index) => (
            <div className="w-100">
              <MemberList
                key={index}
                name={item.name}
                value={item.total_member}
                total={total}
              />
            </div>
          ))}
        </Column>
      </Column>
    </Column>
  );
};

const styles = StyleSheet.create({
  titleLabel: {
    color: "#495057",
    fontSize: 18,
    fontWeight: "bold",
    margin: "0px 0px 15px 0px",
  },
  cardLabel: {
    color: "#BCBCBC",
    marginBottom: 0,
  },
  cardSupportWrapper: {
    boxShadow: "0px 3px 6px #00000029",
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: "white",
    padding: 15,
  },
  cardHeader: {
    backgroundColor: "#2cb96a",
    height: 10,
    width: "100%",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
  },
  supportWrapper: {
    background: "#F5F5F5 0% 0% no-repeat padding-box",
    borderRadius: 5,
    padding: 15,
    marginBottom: 10,
    height: 140,
  },
});

export default Index;
