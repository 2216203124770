export function isInTime(start_date, end_date) {
  let D1 = new Date(start_date);
  let D2 = new Date(end_date);
  let D3 = new Date();

  if (
    D3.toLocaleDateString() == D1.toLocaleDateString() ||
    D3.toLocaleDateString() == D2.toLocaleDateString()
  ){
    return true
  }
  return D3.getTime() <= D2.getTime() && D3.getTime() >= D1.getTime();
}

export function isMoreTime(date) {
  let D1 = new Date(date);
  let now = new Date();

  return D1.getTime() <= now.getTime();
}
