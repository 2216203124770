import {
  GET_ADMINISTRATOR_PENDING,
  GET_ADMINISTRATOR_SUCCESS,
  GET_ADMINISTRATOR_ERROR,
  POST_ADMINISTRATOR_PENDING,
  POST_ADMINISTRATOR_SUCCESS,
  POST_ADMINISTRATOR_ERROR,
  PUT_ADMINISTRATOR_PENDING,
  PUT_ADMINISTRATOR_SUCCESS,
  PUT_ADMINISTRATOR_ERROR,
  DELETE_ADMINISTRATOR_PENDING,
  DELETE_ADMINISTRATOR_SUCCESS,
  DELETE_ADMINISTRATOR_ERROR,
  ADMINISTRATOR_DATA,
  SET_ADMINISTRATOR_MODAL,
} from "../../../actions/anggota/administrator";

const initialState = {
  pending: false,
  error: null,
  data: null,
  detailData: null,
  isOpenModal: false,
  modalType: "",
};

const administrator = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMINISTRATOR_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_ADMINISTRATOR_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_ADMINISTRATOR_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POST_ADMINISTRATOR_PENDING:
      return {
        ...state,
        pending: true,
      };
    case POST_ADMINISTRATOR_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case POST_ADMINISTRATOR_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case PUT_ADMINISTRATOR_PENDING:
      return {
        ...state,
        pending: true,
      };
    case PUT_ADMINISTRATOR_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case PUT_ADMINISTRATOR_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case DELETE_ADMINISTRATOR_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_ADMINISTRATOR_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case DELETE_ADMINISTRATOR_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case ADMINISTRATOR_DATA:
      return {
        ...state,
        detailData: action.data,
      };
    case SET_ADMINISTRATOR_MODAL:
      return {
        ...state,
        isOpenModal: action.data.isOpen,
        modalType: action.data.modalType,
      };
    default:
      return state;
  }
};

export default administrator;
