import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  getTextAlignClassName,
  getTextAlignStyles,
  getTextAlignBlockMetadata,
} from "react-rte";

class RichTextMarkdown extends Component {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.string,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { value: undefined };
  }

  componentDidMount() {
    this.RichTextEditor = window.RichTextEditor;
    this.setState({
      value: this.props.input.value
        ? this.RichTextEditor.createValueFromString(
            this.props.input.value,
            "html",
            {
              customBlockFn: getTextAlignBlockMetadata,
            }
          )
        : this.RichTextEditor.createEmptyValue(),
    });
  }

  handleChange = (value) => {
    this.setState({ value });
    let markdown = value.toString("html", {
      blockStyleFn: getTextAlignStyles,
    });
    if (
      markdown.length === 2 &&
      markdown.charCodeAt(0) === 8203 &&
      markdown.charCodeAt(1) === 10
    ) {
      markdown = "";
    }
    this.props.input.onChange(markdown);
  };

  render() {
    const {
      RichTextEditor,
      state: { value },
      handleChange,
    } = this;
    const toolbarConfig = {
      display: [
        "INLINE_STYLE_BUTTONS",
        "BLOCK_ALIGNMENT_BUTTONS",
        "BLOCK_TYPE_BUTTONS",
        // "LINK_BUTTONS",
        // "IMAGE_BUTTON",
        "BLOCK_TYPE_DROPDOWN",
        "HISTORY_BUTTONS",
      ],
      INLINE_STYLE_BUTTONS: [
        { label: "Bold", style: "BOLD" },
        { label: "Italic", style: "ITALIC" },
        { label: "Strikethrough", style: "STRIKETHROUGH" },
        { label: "Monospace", style: "CODE" },
        { label: "Underline", style: "UNDERLINE" },
      ],
      BLOCK_ALIGNMENT_BUTTONS: [
        { label: "Align Left", style: "ALIGN_LEFT" },
        { label: "Align Center", style: "ALIGN_CENTER" },
        { label: "Align Right", style: "ALIGN_RIGHT" },
        { label: "Align Justify", style: "ALIGN_JUSTIFY" },
      ],
      BLOCK_TYPE_DROPDOWN: [
        { label: "Normal", style: "unstyled" },
        { label: "Heading Large", style: "header-one" },
        { label: "Heading Medium", style: "header-two" },
        { label: "Heading Small", style: "header-three" },
        { label: "Code Block", style: "code-block" },
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: "UL", style: "unordered-list-item" },
        { label: "OL", style: "ordered-list-item" },
        { label: "Blockquote", style: "blockquote" },
      ],
    };
    return RichTextEditor ? (
      <>
        <div>
          <RichTextEditor
            value={value}
            editorClassName="demo-editor"
            onChange={handleChange}
            placeholder={this.props.placeholder}
            blockStyleFn={getTextAlignClassName}
            toolbarConfig={toolbarConfig}
          />
          <div className="mb-1"></div>
          <span className="form-validation">
            {this.props.meta.touched && this.props.meta.error}
          </span>
          <div className="mb-1"></div>
        </div>
      </>
    ) : (
      <div />
    );
  }
}

export default RichTextMarkdown;
