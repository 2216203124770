import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { reduxForm, Field, FieldArray } from "redux-form";
import { ModalBody, ModalHeader } from "reactstrap";
import { Row, Column } from "simple-flexbox";
import { Button } from "@material-ui/core";
import {
  formInput,
  formSelect,
  FormDatepicker,
  normalizeDates,
  formatDates,
  formCheckbox,
} from "../../../../components/commons/form";
import LabelInputVerticalComponent from "../../../../components/global-components/LabelInputVertical";
import PollingAdmin, {
  setPollingAdminModal,
} from "../../../../store/actions/polling/admin";
import Region from "../../../../store/actions/master/region";
import Member from "../../../../store/actions/anggota/member";

let Add = ({
  onSetPollingAdminModal,
  handleSubmit,
  handleRefresh,
  region,
  member,
  pending,
}) => {
  const dispatch = useDispatch();
  const [isGoalName, setIsGoalName] = useState(false);
  const [isGoalRegion, setIsGoalRegion] = useState(false);

  const onSubmit = ({
    title,
    start_date,
    end_date,
    goalMember,
    goalRegion,
    member_name,
    region_name,
  }) => {
    let list_member = goalMember ? member_name?.map((item) => item.value) : [];
    let list_region = goalRegion ? region_name?.map((item) => item.value) : [];
    let total_member = member.data.data.recordsTotal;
    let total_region = region.data.data.recordsTotal;

    const param = {
      title,
      start_date,
      end_date,
      list_questions: [],
      list_members: list_member,
      list_regions: list_region,
      is_all_member: total_member === list_member.length ? 1 : 0,
      is_all_region: total_region === list_region.length ? 1 : 0,
    };
    const callback = () => {
      onSetPollingAdminModal("", false);
      handleRefresh();
    };
    dispatch(PollingAdmin.post(param, callback));
  };

  let regionOptions, memberOptions;
  if (region.data) {
    regionOptions = region.data.data.data.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  }

  if (member.data) {
    memberOptions = member.data.data.data.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  }

  useEffect(() => {
    getRegion();
    getMember();
  }, []);

  const getRegion = () => dispatch(Region.get());
  const getMember = () => dispatch(Member.get());

  return (
    <>
      <ModalHeader>Buat Polling</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <LabelInputVerticalComponent label="Judul Polling">
            <Field
              name="title"
              placeholder="Judul Polling"
              component={formInput}
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Tanggal">
            <Row horizontal="space-between">
              <Field
                name="start_date"
                placeholder="Tanggal Mulai"
                parse={normalizeDates}
                format={formatDates}
                component={FormDatepicker}
              />
              <Field
                name="end_date"
                placeholder="Tanggal Berakhir"
                parse={normalizeDates}
                format={formatDates}
                component={FormDatepicker}
              />
            </Row>
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Tujuan">
            <Column>
              <div className="mb-2">
                <Field
                  name="goalMember"
                  label="Anggota"
                  onChange={(e) => {
                    setIsGoalName(e.target.checked);
                  }}
                  component={formCheckbox}
                />
                {/* <label className="ml-2">Nama</label> */}
                {isGoalName && (
                  <Field
                    placeholder="Nama Anggota"
                    name="member_name"
                    options={memberOptions}
                    isAsync
                    isMulti
                    asyncUrl="/v1/member"
                    component={formSelect}
                  />
                )}
              </div>
              <div className="mb-2">
                <Field
                  type="checkbox"
                  name="goalRegion"
                  label="Wilayah"
                  onChange={(e) => {
                    setIsGoalRegion(e.target.checked);
                  }}
                  component={formCheckbox}
                />
                {/* <label className="ml-2">Wilayah</label> */}
                {isGoalRegion && (
                  <Field
                    placeholder="Wilayah"
                    name="region_name"
                    options={regionOptions}
                    isAsync
                    isMulti
                    asyncUrl="/v1/region"
                    component={formSelect}
                  />
                )}
              </div>
            </Column>
          </LabelInputVerticalComponent>
          <div className="d-flex justify-content-between">
            <Button
              variant="outlined"
              className="mt-3"
              disabled={pending}
              color="primary"
              onClick={() => onSetPollingAdminModal("", false)}
            >
              Batal
            </Button>
            <Button
              type="submit"
              className="mt-3"
              disabled={pending}
              variant="contained"
              color="primary"
            >
              Simpan
            </Button>
          </div>
        </form>
      </ModalBody>
    </>
  );
};

const validate = ({
  title,
  start_date,
  end_date,
  goalMember,
  goalRegion,
  member_name,
  region_name,
}) => {
  const errors = {};
  if (!title) {
    errors.title = "Judul Polling harus diisi";
  }
  if (!start_date) {
    errors.start_date = "Tanggal Mulai harus diisi";
  }
  if (!end_date) {
    errors.end_date = "Tanggal Berakhir harus diisi";
  }
  if (!goalMember && !goalRegion) {
    errors.goalRegion = "Tujuan harus diisi";
  }
  if (goalMember) {
    if (!member_name) {
      errors.member_name = "Tujuan Anggota harus diisi";
    }
  }
  if (goalRegion) {
    if (!region_name) {
      errors.region_name = "Tujuan Wilayah harus diisi";
    }
  }
  return errors;
};

Add = reduxForm({
  form: "pollingAdminAdd",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(Add);

const mapStateToProps = ({ pollingAdmin: { pending }, region, member }) => {
  return { pending, region, member };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetPollingAdminModal: (modalType, isOpen) =>
      dispatch(setPollingAdminModal(modalType, isOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Add);
