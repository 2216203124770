import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Notification, {
  setNotificationData,
  setNotificationModal,
} from "../../store/actions/notification";
import { Row, Column } from "simple-flexbox";
import MaterialTable from "material-table";
import SearchIcon from "@material-ui/icons/Search";
import { Paper, Button, MenuItem } from "@material-ui/core";
import InputComponent from "../../components/commons/form/input";
import Container from "../../components/container";
import { setActiveMenu } from "../../store/actions";
import { StyleSheet, css } from "aphrodite";
import IconArrowForward from "../../assets/icon/icon-arrow-forward";
import debounce from "lodash.debounce";
import { history } from "../../utils";
import moment from "moment";
import { getUser } from "../../utils/user";
import { notifAdminAction, notifMemberAction } from "../../utils/constant";

const Index = ({
  onSetNotificationModal,
  onSetNotificationData,
  pending,
  onSetActiveMenu,
}) => {
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const tableRef = useRef();
  const user = getUser();

  const handleRefresh = (state) => {
    setSearchText(state);
    tableRef.current && tableRef.current.onQueryChange();
  };

  const delayedQuery = debounce((value) => {
    return handleRefresh(value);
  }, 500);

  const handleSearchChange = (e) => {
    delayedQuery(e.target.value);
  };

  const setModal = (modalType, isOpen, data) => {
    onSetNotificationModal(modalType, isOpen);
    onSetNotificationData(data);
  };

  const notificationRedirect = (data, selected) => {
    let payload = JSON.parse(data.payload);
    dispatch(Notification.put({ id: data.id }));

    let route, id;
    if (user.role_code === "member") {
      let notRedirect = ["CHANGE_PASSWORD", "MESSAGE_BLAST"];
      route = selected.route;
      id = payload.id;

      if (!notRedirect.includes(data.type_notif)) {
        if (
          data.type_notif === "REGISTRATION" ||
          data.type_notif === "EXPIRED_KTA"
        ) {
          history.push(route);
        } else {
          history.push(route + id);
        }
        onSetActiveMenu(selected.activeMenu);
      }
    } else {
      route = selected.route;
      if (data.type_notif === "MEMBER_DUES") {
        id = payload.user_id;
      } else {
        id = payload.id;
      }

      if (data.type_notif !== "MESSAGE_BLAST") {
        history.push(route + id);
        onSetActiveMenu(selected.activeMenu);
      }
    }
  };

  return (
    <Container>
      <Row className="m-3 justify-content-between">
        <div></div>
        <InputComponent
          onChange={(e) => handleSearchChange(e)}
          placeholder="Cari notifikasi"
          endIcon={SearchIcon}
        />
      </Row>
      <div className="m-3">
        <MaterialTable
          tableRef={tableRef}
          title="Notification"
          columns={[
            {
              title: "No",
              field: "no",
              width: 40,
            },
            {
              title: "Waktu",
              render: ({ created_at }) => {
                return created_at
                  ? moment(created_at).format("DD MMMM YYYY hh:mm")
                  : "-";
              },
            },
            {
              title: "Notifikasi",
              render: ({ title, body, created_at, species_notif }) => (
                <Row className="py-3">
                  <span style={{ fontWeight: "bold", whiteSpace: "normal" }}>
                    {title} :{" "}
                    <span
                      dangerouslySetInnerHTML={{ __html: body }}
                      style={{ fontWeight: "normal" }}
                    />
                  </span>
                </Row>
              ),
            },
            {
              title: "",
              render: (rowData) => {
                let action =
                  user.role_code === "member"
                    ? notifMemberAction
                    : notifAdminAction;
                const selected = action.filter(
                  (item) => item.type === rowData.type_notif
                );

                return (
                  <div
                    className={css(styles.arrowButton)}
                    onClick={() => notificationRedirect(rowData, selected[0])}
                  >
                    <IconArrowForward fill="#000000" />
                  </div>
                );
              },
            },
          ]}
          data={(q) =>
            new Promise((resolve) => {
              let param = {
                page: q.page + 1,
                length: 10,
                search_text: searchText,
              };
              dispatch(Notification.get(param, resolve));
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            showTitle: false,
            search: false,
            sorting: false,
            headerStyle: {
              backgroundColor: "#fff",
              fontWeight: "bold",
            },
            rowStyle: (rowData) => ({
              backgroundColor:
                rowData.is_read === 0 ? "#E9F8F0" : "transparent",
            }),
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
        />
      </div>
    </Container>
  );
};

const styles = StyleSheet.create({
  arrowButton: {
    ":hover": {
      cursor: "pointer",
    },
  },
});

const mapStateToProps = ({ notification: { pending } }) => {
  return { pending };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetActiveMenu: (menu) => dispatch(setActiveMenu(menu)),
    onSetNotificationModal: (modalType, isOpen) =>
      dispatch(setNotificationModal(modalType, isOpen)),
    onSetNotificationData: (data) => dispatch(setNotificationData(data)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
