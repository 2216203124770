import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { ModalBody, ModalHeader } from "reactstrap";
import { Row, Column } from "simple-flexbox";
import { Button } from "@material-ui/core";
import {
  formInputNumber,
  formTextArea,
  formFile,
  FormDatepicker,
  normalizeDates,
  formatDates,
} from "../../../../components/commons/form";
import LabelInputVerticalComponent from "../../../../components/global-components/LabelInputVertical";
import IuranMember, {
  setIuranMemberModal,
} from "../../../../store/actions/iuran/member";
import { IURAN_FOLDER } from "../../../../utils/constant";
import File from "../../../../store/actions/file";
import moment from "moment";

let Add = ({
  onSetIuranMemberModal,
  handleSubmit,
  handleRefresh,
  pending,
  file,
}) => {
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState(null);

  const onSubmit = ({ date, total, description }) => {
    const param = {
      date: moment(date).format("YYYY-MM-DD"),
      total: total.toString().replace("Rp ", "").replace(/\D/g, ""),
      description,
      filename: fileName,
    };
    const callback = () => {
      onSetIuranMemberModal("", false);
      handleRefresh();
    };
    dispatch(IuranMember.post(param, callback));
  };

  const uploadFile = (e) => {
    let formData = new FormData();
    formData.append("file", e);

    const callback = (res) => {
      console.log(res);
      setFileName(res.data.data.fileName);
    };

    dispatch(File.upload(formData, callback, IURAN_FOLDER));
  };

  return (
    <>
      <ModalHeader>Tambah Iuran</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <LabelInputVerticalComponent label="Tanggal">
            <Field
              name="date"
              placeholder="Tanggal"
              disabled
              parse={normalizeDates}
              format={formatDates}
              component={FormDatepicker}
              showClearDate={false}
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Total Iuran">
            <Field
              name="total"
              placeholder="Nominal Iuran"
              component={formInputNumber}
              thousandSeparator
              prefix="Rp "
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Keterangan">
            <Field
              name="description"
              placeholder="Keterangan"
              rows={5}
              component={formTextArea}
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Bukti Pembayaran">
            <Field
              name="filename"
              type="file"
              fileType="pdf/image"
              title="Masukkan Bukti"
              onChange={(e) => uploadFile(e)}
              message="PDF / JPG"
              component={formFile}
            />
          </LabelInputVerticalComponent>
          <div className="d-flex justify-content-between">
            <Button
              variant="outlined"
              className="mt-3"
              disabled={pending}
              color="primary"
              onClick={() => onSetIuranMemberModal("", false)}
            >
              Batal
            </Button>
            <Button
              type="submit"
              className="mt-3"
              disabled={pending || file.pending}
              variant="contained"
              color="primary"
            >
              Simpan
            </Button>
          </div>
        </form>
      </ModalBody>
    </>
  );
};

const validate = ({ total, description, filename }) => {
  const errors = {};
  if (!total) {
    errors.total = "Total Iuran harus diisi";
  }
  if (!filename) {
    errors.filename = "Berkas harus diisi";
  }
  if (!description) {
    errors.description = "Keterangan harus diisi";
  }

  return errors;
};

Add = reduxForm({
  form: "iuranMemberAdd",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(Add);

const mapStateToProps = ({ iuranMember: { pending }, file }) => {
  let initialValues = {};

  initialValues.date = moment().format("DD MMMM YYYY");
  return { pending, initialValues, file };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetIuranMemberModal: (modalType, isOpen) =>
      dispatch(setIuranMemberModal(modalType, isOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Add);
