import API from "../../API";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
import { setToastModal } from "../../../actions";
import { toastError, toastSuccess } from "../../../../components/commons/toast";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_POLLING_ADMIN_PENDING = "GET_POLLING_ADMIN_PENDING";
export const GET_POLLING_ADMIN_SUCCESS = "GET_POLLING_ADMIN_SUCCESS";
export const GET_POLLING_ADMIN_ERROR = "GET_POLLING_ADMIN_ERROR";
export const GET_DETAIL_ANSWER_POLLING_ADMIN_PENDING =
  "GET_DETAIL_ANSWER_POLLING_ADMIN_PENDING";
export const GET_DETAIL_ANSWER_POLLING_ADMIN_SUCCESS =
  "GET_DETAIL_ANSWER_POLLING_ADMIN_SUCCESS";
export const GET_DETAIL_ANSWER_POLLING_ADMIN_ERROR =
  "GET_DETAIL_ANSWER_POLLING_ADMIN_ERROR";
export const GET_DETAIL_SUMMARY_POLLING_ADMIN_PENDING =
  "GET_DETAIL_SUMMARY_POLLING_ADMIN_PENDING";
export const GET_DETAIL_SUMMARY_POLLING_ADMIN_SUCCESS =
  "GET_DETAIL_SUMMARY_POLLING_ADMIN_SUCCESS";
export const GET_DETAIL_SUMMARY_POLLING_ADMIN_ERROR =
  "GET_DETAIL_SUMMARY_POLLING_ADMIN_ERROR";
export const POST_POLLING_ADMIN_PENDING = "POST_POLLING_ADMIN_PENDING";
export const POST_POLLING_ADMIN_SUCCESS = "POST_POLLING_ADMIN_SUCCESS";
export const POST_POLLING_ADMIN_ERROR = "POST_POLLING_ADMIN_ERROR";
export const PUT_POLLING_ADMIN_PENDING = "PUT_POLLING_ADMIN_PENDING";
export const PUT_POLLING_ADMIN_SUCCESS = "PUT_POLLING_ADMIN_SUCCESS";
export const PUT_POLLING_ADMIN_ERROR = "PUT_POLLING_ADMIN_ERROR";
export const DELETE_POLLING_ADMIN_PENDING = "DELETE_POLLING_ADMIN_PENDING";
export const DELETE_POLLING_ADMIN_SUCCESS = "DELETE_POLLING_ADMIN_SUCCESS";
export const DELETE_POLLING_ADMIN_ERROR = "DELETE_POLLING_ADMIN_ERROR";

export const POLLING_ADMIN_DATA = "POLLING_ADMIN_DATA";
export const POLLING_ADMIN_DATA_QUESTION = "POLLING_ADMIN_DATA_QUESTION";
export const POLLING_ADMIN_DATA_ANSWER = "POLLING_ADMIN_DATA_ANSWER";
export const SET_POLLING_ADMIN_MODAL = "SET_POLLING_ADMIN_MODAL";

// URL: URL_{URL}
const POLLING_ADMIN_URL = "/v1/polling";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_POLLING_ADMIN_PENDING));
  API.get(POLLING_ADMIN_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_POLLING_ADMIN_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_POLLING_ADMIN_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const getDetailAnswer = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DETAIL_ANSWER_POLLING_ADMIN_PENDING));
  API.get(POLLING_ADMIN_URL + "/answer", { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DETAIL_ANSWER_POLLING_ADMIN_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DETAIL_ANSWER_POLLING_ADMIN_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const getSummaryAnswer = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DETAIL_SUMMARY_POLLING_ADMIN_PENDING));
  API.get(POLLING_ADMIN_URL + "/answer/find_summary", { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DETAIL_SUMMARY_POLLING_ADMIN_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DETAIL_SUMMARY_POLLING_ADMIN_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_POLLING_ADMIN_PENDING));
  API.post(POLLING_ADMIN_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_POLLING_ADMIN_SUCCESS, res));
      toastSuccess("Polling berhasil ditambahkan");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_POLLING_ADMIN_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_POLLING_ADMIN_PENDING));
  API.put(POLLING_ADMIN_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_POLLING_ADMIN_SUCCESS, res));
      toastSuccess("Polling berhasil diubah");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_POLLING_ADMIN_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_POLLING_ADMIN_PENDING));
  API.delete(POLLING_ADMIN_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_POLLING_ADMIN_SUCCESS, res));
      toastSuccess("Polling berhasil dihapus");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_POLLING_ADMIN_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const PollingAdmin = {
  get,
  post,
  put,
  deleted,
  getDetailAnswer,
  getSummaryAnswer,
};
export default PollingAdmin;

export const setPollingAdminData = (data) => (dispatch) =>
  dispatch({ type: POLLING_ADMIN_DATA, data });

export const setPollingAdminModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_POLLING_ADMIN_MODAL, {
      modalType: modalType,
      isOpen: isOpen,
    })
  );

export const setPollingAdminDataQuestion = (data) => (dispatch) =>
  dispatch({ type: POLLING_ADMIN_DATA_QUESTION, data });

export const setPollingAdminDataAnswer = (data) => (dispatch) =>
  dispatch({ type: POLLING_ADMIN_DATA_ANSWER, data });
