import React, { useState } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { ModalBody, ModalHeader } from "reactstrap";
import { Button } from "@material-ui/core";
import { formInput } from "../../../../components/commons/form";
import { setPositionModal } from "../../../../store/actions/master/position";
import LabelInputVerticalComponent from "../../../../components/global-components/LabelInputVertical";

let detail = ({ onSetPositionModal, pending }) => {
  return (
    <>
      <ModalHeader>Lihat Jabatan</ModalHeader>
      <ModalBody>
        <form>
          <LabelInputVerticalComponent label="Kode Jabatan">
            <Field
              name="code"
              placeholder="Kode Jabatan"
              component={formInput}
              disabled
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Nama Jabatan">
            <Field
              name="name"
              placeholder="Nama Jabatan"
              component={formInput}
              disabled
            />
          </LabelInputVerticalComponent>
        </form>
        <Button
          variant="outlined"
          className="mt-3"
          disabled={pending}
          color="primary"
          onClick={() => onSetPositionModal("", false)}
        >
          Kembali
        </Button>
      </ModalBody>
    </>
  );
};

detail = reduxForm({
  form: "positionDetail",
})(detail);

const mapStateToProps = ({ position: { detailData, pending } }) => {
  let initialValues = {};
  if (detailData) {
    initialValues = {
      code: detailData.code,
      name: detailData.name,
    };
  }
  return {
    initialValues,
    pending,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetPositionModal: (modalType, isOpen) =>
      dispatch(setPositionModal(modalType, isOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(detail);
