import React from "react";
import { connect, useDispatch } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { ModalBody, ModalHeader } from "reactstrap";
import { Button } from "@material-ui/core";
import { formInput } from "../../../../components/commons/form";
import { history } from "../../../../utils";
import LabelInputVerticalComponent from "../../../../components/global-components/LabelInputVertical";
import IuranMember, {
  setIuranMemberModal,
} from "../../../../store/actions/iuran/member";

let Reject = ({
  onSetIuranMemberModal,
  handleSubmit,
  detailData,
  pending,
  handleRefresh,
}) => {
  const dispatch = useDispatch();

  const onSubmit = ({ rejected_notes }) => {
    const param = {
      id: detailData.id,
      is_approve: false,
      rejected_notes,
    };
    const callback = () => {
      onSetIuranMemberModal("", false);
      history.push("/admin/iuran");
    };
    dispatch(IuranMember.approve(param, callback));
  };
  return (
    <>
      <ModalHeader>Tolak Iuran</ModalHeader>
      <ModalBody>
        {/* Apakah anda yakin untuk menolak anggota {detailData.name} ? */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <LabelInputVerticalComponent label="Alasan Penolakan">
            <Field
              name="rejected_notes"
              placeholder="Masukkan Alasan Penolakan"
              component={formInput}
            />
          </LabelInputVerticalComponent>
          <div className="d-flex justify-content-between">
            <Button
              variant="outlined"
              className="mt-3"
              disabled={pending}
              color="primary"
              onClick={() => onSetIuranMemberModal("", false)}
            >
              Batal
            </Button>
            <Button
              type="submit"
              className="mt-3"
              variant="contained"
              disabled={pending}
              color="primary"
            >
              Submit
            </Button>
          </div>
        </form>
      </ModalBody>
    </>
  );
};

const validate = ({ rejected_notes }) => {
  const errors = {};
  if (!rejected_notes) {
    errors.rejected_notes = "Alasan Penolakan harus diisi";
  }

  return errors;
};

Reject = reduxForm({
  form: "iuranMemberReject",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(Reject);

const mapStateToProps = ({ iuranMember: { detailData, pending } }) => {
  return {
    detailData,
    pending,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetIuranMemberModal: (modalType, isOpen) =>
      dispatch(setIuranMemberModal(modalType, isOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reject);
