import { StyleSheet, css } from "aphrodite";
import { Column, Row } from "simple-flexbox";
import { connect } from "react-redux";
import Avatar from "../../components/profile/member";
import Member, {
  setMemberData,
  setMemberModal,
  setAddMemberData,
} from "../../store/actions/anggota/member";

const Profile = ({ onSetMemberModal, img_profile, name, description }) => {
  const path = window.location.pathname.split("/");

  let names = name ? name : "-";
  let descriptions = description ? description : "-";
  return (
    <Column style={{ width: "100%" }} horizontal="center">
      <div
        style={{
          width: 100,
          height: 100,
          marginBottom: 20,
        }}
      >
        <Avatar image={img_profile} />
      </div>
      <h5 style={{ textAlign: "center", marginBottom: 20 }}>{names}</h5>
    </Column>
  );
};

const styles = StyleSheet.create({
  clickableIcon: {
    ":hover": {
      cursor: "pointer",
    },
  },
});

const mapStateToProps = ({ member: { data, detailData } }) => {
  return { data, detailData };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetMemberModal: (modalType, isOpen) =>
      dispatch(setMemberModal(modalType, isOpen)),
    onSetAddMemberData: (data) => dispatch(setAddMemberData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
