import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import SetoranAdmin, {
  setSetoranAdminData,
  setSetoranAdminModal,
} from "../../store/actions/setoran";
import { Row } from "simple-flexbox";
import MaterialTable from "material-table";
import TableStatus from "../../components/global-components/TableStatus";
import SelectComponent from "../../components/commons/form/select";
import { Paper, Button, MenuItem } from "@material-ui/core";
import Container from "../../components/container";
import DetailButtonComponent from "../../components/global-components/DetailButton";
import Modal from "./modal";
import { history } from "../../utils";
import { statusSetoranOptions } from "../../utils/constant";
import { getUser } from "../../utils/user";
import moment from "moment";
import debounce from "lodash.debounce";

const Index = ({
  onSetSetoranAdminModal,
  onSetSetoranAdminData,
  pending,
  data,
  isApprove,
}) => {
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();
  const tableRef = useRef();
  const user = getUser();
  // const isApprove =
  //   data?.data[0]?.status === 0 || data?.data[0]?.status === 2 ? true : false;

  const handleRefresh = (state) => {
    setSearchText(state);
    tableRef.current && tableRef.current.onQueryChange();
  };

  const delayedQuery = debounce((value) => {
    return handleRefresh(value);
  }, 500);

  const handleSearchChange = (e) => {
    delayedQuery(e.target.value);
  };

  const handleStatusChange = (e) => {
    if (e) {
      setStatus(e.value);
    } else {
      setStatus("");
    }
    tableRef.current && tableRef.current.onQueryChange();
  };

  const setDetail = (type, data) => {
    onSetSetoranAdminData(data);
    history.push(`/admin/setoran/${type}/${data.id}`);
  };

  const setModal = (modalType, isOpen, data) => {
    onSetSetoranAdminModal(modalType, isOpen);
    onSetSetoranAdminData(data);
  };

  const columns = [
    {
      title: "No",
      field: "no",
      width: 40,
    },
    {
      title: "Tanggal Setoran",
      render: ({ date }) => {
        return date ? moment(date).format("DD MMMM YYYY") : "-";
      },
    },
    {
      title: "Total Setoran",
      render: (rowData) => {
        const total = rowData.total
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        return <>{rowData.total ? `Rp ${total}` : "-"}</>;
      },
    },
    {
      title: "Status",
      width: 150,
      render: ({ status }) => {
        return (
          <TableStatus
            status={
              status === 0 ? "Diproses" : status === 1 ? "Diterima" : "Ditolak"
            }
          />
        );
      },
    },
    {
      title: "Aksi",
      width: 80,
      cellStyle: {
        paddingLeft: 0,
      },
      render: (rowData) => {
        let role = user.role_code;
        return (
          <>
            {role === "superadmin" && (
              <div className="m-2">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setDetail("detail", rowData)}
                  // onClick={() => setModal("detail", true, rowData)}
                >
                  Detail
                </Button>
              </div>
            )}
            {role === "admin_cabang" && (
              <DetailButtonComponent>
                <MenuItem onClick={() => setDetail("detail", rowData)}>
                  Lihat Detail
                </MenuItem>
                {/* <MenuItem onClick={() => setModal("delete", true, rowData)}>
                  Hapus Data
                </MenuItem> */}
              </DetailButtonComponent>
            )}
          </>
        );
      },
    },
  ];

  if (user.role_code === "superadmin") {
    columns.splice(3, 0, {
      title: "Wilayah",
      render: ({ region_name }) => {
        return region_name ? region_name : "-";
      },
    });
  }
  return (
    <Container>
      <Modal handleRefresh={(state) => handleRefresh(state)} />
      <Row className="m-3 justify-content-between">
        {user.role_code === "admin_cabang" ? (
          <Button
            color="primary"
            variant="contained"
            disabled={pending || isApprove}
            onClick={() => setModal("add", true, null)}
          >
            Tambah Setoran
          </Button>
        ) : (
          <div></div>
        )}
        <Row className="m-0 justify-content-between">
          <div></div>
          <div style={{ width: 120, marginRight: 20 }}>
            <SelectComponent
              onChange={(e) => handleStatusChange(e)}
              placeholder="Status"
              options={statusSetoranOptions}
            />
          </div>
        </Row>
      </Row>
      <div className="m-3">
        <MaterialTable
          tableRef={tableRef}
          title="SetoranAdmin"
          columns={columns}
          data={(q) =>
            new Promise((resolve) => {
              let param = {
                page: q.page + 1,
                length: 10,
                search_text: searchText,
                status,
              };
              dispatch(SetoranAdmin.get(param, resolve));
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            showTitle: false,
            search: false,
            sorting: false,
            headerStyle: {
              backgroundColor: "#fff",
              fontWeight: "bold",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
        />
      </div>
    </Container>
  );
};

const mapStateToProps = ({ setoranAdmin: { pending, data } }) => {
  let isApprove = false;

  if (data) {
    isApprove =
      data.data.data[0]?.status === 0 || data.data.data[0]?.status === 2
        ? true
        : false;
  }
  return { pending, data, isApprove };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetSetoranAdminModal: (modalType, isOpen) =>
      dispatch(setSetoranAdminModal(modalType, isOpen)),
    onSetSetoranAdminData: (data) => dispatch(setSetoranAdminData(data)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
