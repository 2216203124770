import API from "../API";
import { actionPending, actionSuccess, actionError } from "../actionTypes";
import { setToastModal } from "..";
import { toastError, toastSuccess } from "../../../components/commons/toast";
import getHeader from "../header-token";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_NOTIFICATION_PENDING = "GET_NOTIFICATION_PENDING";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_ERROR = "GET_NOTIFICATION_ERROR";
export const POST_NOTIFICATION_PENDING = "POST_NOTIFICATION_PENDING";
export const POST_NOTIFICATION_SUCCESS = "POST_NOTIFICATION_SUCCESS";
export const POST_NOTIFICATION_ERROR = "POST_NOTIFICATION_ERROR";
export const PUT_NOTIFICATION_PENDING = "PUT_NOTIFICATION_PENDING";
export const PUT_NOTIFICATION_SUCCESS = "PUT_NOTIFICATION_SUCCESS";
export const PUT_NOTIFICATION_ERROR = "PUT_NOTIFICATION_ERROR";
export const DELETE_NOTIFICATION_PENDING = "DELETE_NOTIFICATION_PENDING";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_ERROR = "DELETE_NOTIFICATION_ERROR";

export const NOTIFICATION_DATA = "NOTIFICATION_DATA";
export const SET_NOTIFICATION_MODAL = "SET_NOTIFICATION_MODAL";

// URL: URL_{URL}
const NOTIFICATION_URL = "/v1/notification";

const get =
  (param = {}, resolve, reject, callback) =>
  (dispatch) => {
    dispatch(actionPending(GET_NOTIFICATION_PENDING));
    API.get(NOTIFICATION_URL, { params: param }, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(GET_NOTIFICATION_SUCCESS, res));
        const records_total = res.data.recordsTotal;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        if (resolve) {
          resolve({
            data: data,
            page: param?.page - 1,
            totalCount: records_total,
          });
        }
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        dispatch(actionError(GET_NOTIFICATION_ERROR));
        toastError(err?.response?.data?.message);
      });
  };

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_NOTIFICATION_PENDING));
  API.post(NOTIFICATION_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_NOTIFICATION_SUCCESS, res));
      // toastSuccess("Notifikasi berhasil ditambahkan");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_NOTIFICATION_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const put = (param, callback) => (dispatch) => {
  const id = param.id;
  dispatch(actionPending(PUT_NOTIFICATION_PENDING));
  API.put(NOTIFICATION_URL + "/read?id=" + id, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_NOTIFICATION_SUCCESS, res));
      // toastSuccess("Notifikasi berhasil diubah");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_NOTIFICATION_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_NOTIFICATION_PENDING));
  API.delete(NOTIFICATION_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_NOTIFICATION_SUCCESS, res));
      toastSuccess("Notifikasi berhasil dihapus");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_NOTIFICATION_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const Notification = { get, post, put, deleted };
export default Notification;

export const setNotificationData = (data) => (dispatch) =>
  dispatch({ type: NOTIFICATION_DATA, data });

export const setNotificationModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_NOTIFICATION_MODAL, {
      modalType: modalType,
      isOpen: isOpen,
    })
  );
