import React, { useState } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { ModalBody, ModalHeader } from "reactstrap";
import { Button } from "@material-ui/core";
import { formInput, formSelect } from "../../../../components/commons/form";
import { setAdministratorModal } from "../../../../store/actions/anggota/administrator";
import LabelInputVerticalComponent from "../../../../components/global-components/LabelInputVertical";
import { ADMINISTRATOR_PHOTO } from "../../../../utils/constant";

let detail = ({ onSetAdministratorModal, pending, detailData }) => {
  return (
    <>
      <ModalHeader>Detail Pengurus</ModalHeader>
      <ModalBody>
        <form>
          <LabelInputVerticalComponent label="Nama Pengurus">
            <Field
              name="name"
              disabled
              placeholder="Nama Pengurus"
              component={formInput}
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Email">
            <Field
              name="email"
              disabled
              placeholder="Email Pengurus"
              component={formInput}
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Jabatan">
            <Field
              placeholder="Jabatan"
              disabled
              name="position"
              component={formSelect}
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Wilayah">
            <Field
              placeholder="Wilayah"
              disabled
              name="region"
              component={formSelect}
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Periode">
            <Field
              name="start_year"
              disabled
              placeholder="Awal Periode"
              component={formInput}
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Foto">
            {detailData.img_name ? (
              <img
                src={`${process.env.REACT_APP_GAKESLAB_URL}/v1/file/${ADMINISTRATOR_PHOTO}/${detailData.img_name}`}
                alt="foto_pengurus"
                width="100%"
              />
            ) : (
              <span>Belum ada berkas</span>
            )}
          </LabelInputVerticalComponent>
        </form>
        <Button
          variant="outlined"
          disabled={pending}
          className="mt-3"
          color="primary"
          onClick={() => onSetAdministratorModal("", false)}
        >
          Kembali
        </Button>
      </ModalBody>
    </>
  );
};

detail = reduxForm({
  form: "administratorDetail",
})(detail);

const mapStateToProps = ({ administrator: { detailData, pending } }) => {
  let initialValues = {};
  if (detailData) {
    initialValues = {
      name: detailData.name,
      position: {
        value: detailData.position_id,
        label: detailData.position_name,
      },
      email: detailData.email,
      region: { value: detailData.region_id, label: detailData.region_name },
      start_year: `${detailData.start_year}/${detailData.end_year}`,
      img_name: detailData.img_name,
    };
  }
  return {
    initialValues,
    pending,
    detailData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetAdministratorModal: (modalType, isOpen) =>
      dispatch(setAdministratorModal(modalType, isOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(detail);
