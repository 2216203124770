import API from "../../API";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
import { setToastModal } from "../../../actions";
import { toastError, toastSuccess } from "../../../../components/commons/toast";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_MEMBER_PENDING = "GET_MEMBER_PENDING";
export const GET_MEMBER_SUCCESS = "GET_MEMBER_SUCCESS";
export const GET_MEMBER_ERROR = "GET_MEMBER_ERROR";
export const GET_DETAIL_MEMBER_PENDING = "GET_DETAIL_MEMBER_PENDING";
export const GET_DETAIL_MEMBER_SUCCESS = "GET_DETAIL_MEMBER_SUCCESS";
export const GET_DETAIL_MEMBER_ERROR = "GET_DETAIL_GET_MEMBER_ERROR";
export const POST_MEMBER_PENDING = "POST_MEMBER_PENDING";
export const POST_MEMBER_SUCCESS = "POST_MEMBER_SUCCESS";
export const POST_MEMBER_ERROR = "POST_MEMBER_ERROR";
export const PUT_MEMBER_PENDING = "PUT_MEMBER_PENDING";
export const PUT_MEMBER_SUCCESS = "PUT_MEMBER_SUCCESS";
export const PUT_MEMBER_ERROR = "PUT_MEMBER_ERROR";
export const DELETE_MEMBER_PENDING = "DELETE_MEMBER_PENDING";
export const DELETE_MEMBER_SUCCESS = "DELETE_MEMBER_SUCCESS";
export const DELETE_MEMBER_ERROR = "DELETE_MEMBER_ERROR";
export const APPROVED_MEMBER_PENDING = "APPROVED_MEMBER_PENDING";
export const APPROVED_MEMBER_SUCCESS = "APPROVED_MEMBER_SUCCESS";
export const APPROVED_MEMBER_ERROR = "APPROVED_MEMBER_ERROR";
export const REJECTED_MEMBER_PENDING = "REJECTED_MEMBER_PENDING";
export const REJECTED_MEMBER_SUCCESS = "REJECTED_MEMBER_SUCCESS";
export const REJECTED_MEMBER_ERROR = "REJECTED_MEMBER_ERROR";
export const EDIT_PHOTO_MEMBER_PENDING = "EDIT_PHOTO_MEMBER_PENDING";
export const EDIT_PHOTO_MEMBER_SUCCESS = "EDIT_PHOTO_MEMBER_SUCCESS";
export const EDIT_PHOTO_MEMBER_ERROR = "EDIT_PHOTO_MEMBER_ERROR";

export const MEMBER_DATA = "MEMBER_DATA";
export const ADD_MEMBER_DATA = "ADD_MEMBER_DATA";
export const SET_MEMBER_MODAL = "SET_MEMBER_MODAL";

// URL: URL_{URL}
const MEMBER_URL = "/v1/member";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_MEMBER_PENDING));
  API.get(MEMBER_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_MEMBER_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const getDetail = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DETAIL_MEMBER_PENDING));
  API.get(MEMBER_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DETAIL_MEMBER_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DETAIL_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_MEMBER_PENDING));
  API.post(MEMBER_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_MEMBER_SUCCESS, res));
      toastSuccess("Anggota berhasil ditambahkan");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_MEMBER_PENDING));
  API.put(MEMBER_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_MEMBER_SUCCESS, res));
      toastSuccess("Anggota berhasil diubah");
      if (callback) {
        callback(res);
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_MEMBER_PENDING));
  API.delete(MEMBER_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_MEMBER_SUCCESS, res));
      toastSuccess("Anggota berhasil dihapus");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const approved = (param, callback) => (dispatch) => {
  dispatch(actionPending(APPROVED_MEMBER_PENDING));
  API.put(MEMBER_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(APPROVED_MEMBER_SUCCESS, res));
      toastSuccess("Anggota berhasil diterima");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(APPROVED_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const rejected = (param, callback) => (dispatch) => {
  dispatch(actionPending(REJECTED_MEMBER_PENDING));
  API.put(MEMBER_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(REJECTED_MEMBER_SUCCESS, res));
      toastSuccess("Anggota berhasil ditolak");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(REJECTED_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const editPhoto = (param, callback) => (dispatch) => {
  dispatch(actionPending(EDIT_PHOTO_MEMBER_PENDING));
  API.post(MEMBER_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(EDIT_PHOTO_MEMBER_SUCCESS, res));
      toastSuccess("Foto Anggota berhasil diubah");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(EDIT_PHOTO_MEMBER_ERROR));
      toastError(err?.response?.data?.message);
    });
};
const Member = {
  get,
  post,
  put,
  deleted,
  approved,
  rejected,
  editPhoto,
  getDetail,
};
export default Member;

export const setMemberData = (data) => (dispatch) =>
  dispatch({ type: MEMBER_DATA, data });

export const setMemberModal =
  (modalType, isOpen, title, folderName, fileName) => (dispatch) =>
    dispatch(
      actionSuccess(SET_MEMBER_MODAL, {
        modalType: modalType,
        isOpen: isOpen,
        title: title,
        folderName: folderName,
        fileName: fileName,
      })
    );

export const setAddMemberData = (data) => (dispatch) =>
  dispatch({ type: ADD_MEMBER_DATA, data });
