import React from "react";

export default (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    transform="translate(4,0)"
  >
    <path
      opacity={props.opacity || ""}
      d="M8.61539 0V4.25C8.61539 4.6625 9.03077 5 9.53846 5H14.7692V5.57268C14.6052 5.57967 14.441 5.59513 14.2771 5.6192C13.0204 5.80383 11.8884 6.48015 11.1303 7.49937C10.3722 8.51859 10.05 9.79723 10.2346 11.054C10.4192 12.3108 11.0956 13.4427 12.1148 14.2008C12.8906 14.7779 13.8168 15.1024 14.7692 15.1431V15.25C14.7692 15.6656 14.3577 16 13.8462 16H0.923077C0.411538 16 0 15.6656 0 15.25V0.75C0 0.334375 0.411538 0 0.923077 0H8.61539ZM14.7692 14.526V14.5261C13.9486 14.4859 13.1515 14.2038 12.4826 13.7063C11.5946 13.0457 11.0053 12.0595 10.8444 10.9644C10.6835 9.86938 10.9643 8.75528 11.6248 7.86722C12.2854 6.97916 13.2717 6.38987 14.3667 6.229C14.5008 6.20931 14.6351 6.19624 14.7692 6.18967V6.18972C14.6353 6.19631 14.5013 6.20937 14.3675 6.22902C13.2725 6.38989 12.2862 6.97917 11.6256 7.86724C10.9651 8.7553 10.6844 9.8694 10.8452 10.9644C11.0061 12.0595 11.5954 13.0458 12.4834 13.7063C13.1521 14.2037 13.9489 14.4857 14.7692 14.526ZM14.7692 4V3.80938C14.7692 3.6125 14.6731 3.42188 14.5 3.28125L10.7346 0.21875C10.5615 0.078125 10.3269 0 10.0808 0H9.84615V4H14.7692Z"
      fill={props.fill || "#FFFFFF"}
    />
    <path
      opacity={props.opacity || ""}
      d="M20.49 14.7082L18.6924 12.8509C18.6113 12.7671 18.5013 12.7206 18.3859 12.7206H18.092C18.5896 12.063 18.8853 11.2359 18.8853 10.3362C18.8853 8.19581 17.2067 6.46155 15.135 6.46155C13.0634 6.46155 11.3848 8.19581 11.3848 10.3362C11.3848 12.4765 13.0634 14.2108 15.135 14.2108C16.0059 14.2108 16.8064 13.9053 17.4429 13.3912V13.6948C17.4429 13.814 17.488 13.9276 17.5691 14.0115L19.3667 15.8687C19.5362 16.0438 19.8103 16.0438 19.9779 15.8687L20.4882 15.3415C20.6577 15.1664 20.6577 14.8833 20.49 14.7082ZM15.135 12.7206C13.8603 12.7206 12.8272 11.655 12.8272 10.3362C12.8272 9.01917 13.8585 7.95179 15.135 7.95179C16.4098 7.95179 17.4429 9.01731 17.4429 10.3362C17.4429 11.6532 16.4116 12.7206 15.135 12.7206Z"
      fill={props.fill || "#FFFFFF"}
    />
  </svg>
);
