import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { ModalBody, ModalHeader } from "reactstrap";
import { Button } from "@material-ui/core";
import { formInput, formSelect } from "../../../components/commons/form";
import RTE from "../../../components/commons/form/rte";
import LabelInputVerticalComponent from "../../../components/global-components/LabelInputVertical";
import { Row, Column } from "simple-flexbox";
import MessageBlast, {
  setMessageBlastModal,
  setMessageBlastData,
} from "../../../store/actions/message-blast";
import RichTextEditor from "react-rte";
import Region from "../../../store/actions/master/region";
import Member from "../../../store/actions/anggota/member";
import Admin from "../../../store/actions/cms-public/admin";

let Add = ({
  onSetMessageBlastModal,
  onSetMessageBlastData,
  handleSubmit,
  handleRefresh,
  pending,
  region,
  member,
  admin,
}) => {
  const dispatch = useDispatch();
  const [isGoalName, setIsGoalName] = useState(false);
  const [isGoalRegion, setIsGoalRegion] = useState(false);
  const [isGoalAdmin, setIsGoalAdmin] = useState(false);

  const onSubmit = ({ code, name }) => {
    const param = {
      code,
      name,
    };
    const callback = () => {
      onSetMessageBlastModal("", false);
      handleRefresh();
    };
    dispatch(MessageBlast.post(param, callback));
  };

  let regionOptions, memberOptions, adminOptions;
  if (region.data) {
    regionOptions = region.data.data.data.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  }

  if (member.data) {
    memberOptions = member.data.data.data.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  }

  if (admin.data) {
    adminOptions = admin.data.data.map((item) => {
      return {
        label: item.acf.name,
        value: item.id,
      };
    });
  }

  useEffect(() => {
    getRegion();
    getMember();
    getAdmin();
  }, []);

  const getRegion = () => dispatch(Region.get());
  const getMember = () => dispatch(Member.get());
  const getAdmin = () => dispatch(Admin.get());

  window.RichTextEditor = RichTextEditor;
  return (
    <>
      <ModalHeader>Tambah Message</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <LabelInputVerticalComponent label="Judul Email">
            <Field
              name="title"
              placeholder="Judul email"
              component={formInput}
            />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Pesan">
            <Field name="message" component={RTE} />
          </LabelInputVerticalComponent>
          <LabelInputVerticalComponent label="Tujuan">
            <Column>
              <div className="mb-2">
                <Field
                  type="checkbox"
                  name="tujuanName"
                  onChange={(e) => {
                    setIsGoalName(e.target.checked);
                  }}
                  component="input"
                />
                <label className="ml-2">Nama</label>
                {isGoalName && (
                  <Field
                    placeholder="Nama Anggota"
                    name="member"
                    options={memberOptions}
                    isAsync
                    isMulti
                    asyncUrl="/v1/member"
                    component={formSelect}
                  />
                )}
              </div>
              <div className="mb-2">
                <Field
                  type="checkbox"
                  name="tujuanRegion"
                  onChange={(e) => {
                    setIsGoalRegion(e.target.checked);
                  }}
                  component="input"
                />
                <label className="ml-2">Wilayah</label>
                {isGoalRegion && (
                  <Field
                    placeholder="Wilayah"
                    name="region"
                    options={regionOptions}
                    isAsync
                    isMulti
                    asyncUrl="/v1/region"
                    component={formSelect}
                  />
                )}
              </div>
              <div className="mb-2">
                <Field
                  type="checkbox"
                  name="tujuanAdmin"
                  onChange={(e) => {
                    setIsGoalAdmin(e.target.checked);
                  }}
                  component="input"
                />
                <label className="ml-2">Pengurus</label>
                {isGoalAdmin && (
                  <Field
                    placeholder="Nama Pengurus"
                    name="admin"
                    options={adminOptions}
                    isMulti
                    component={formSelect}
                  />
                )}
              </div>
            </Column>
          </LabelInputVerticalComponent>
          <div className="d-flex justify-content-between">
            <Button
              variant="outlined"
              className="mt-3"
              disabled={pending}
              color="primary"
              onClick={() => onSetMessageBlastModal("", false)}
            >
              Batal
            </Button>
            <Button
              type="submit"
              className="mt-3"
              disabled={pending}
              variant="contained"
              color="primary"
            >
              Simpan
            </Button>
          </div>
        </form>
      </ModalBody>
    </>
  );
};

const validate = (value) => {
  const errors = {};
  if (!value.message) {
    errors.message = "Pesan harus diisi";
  }

  return errors;
};

Add = reduxForm({
  form: "messageBlastAdd",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(Add);

const mapStateToProps = ({
  messageBlast: { pending },
  member,
  region,
  admin,
}) => {
  return { pending, member, region, admin };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetMessageBlastModal: (modalType, isOpen) =>
      dispatch(setMessageBlastModal(modalType, isOpen)),
    onSetMessageBlastData: (data) => dispatch(setMessageBlastData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Add);
