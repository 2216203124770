import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import auth from "./auth";
import global from "./global";
import {
  setSelectedHeader as header,
  setSelectedLinkHeader as linkHeader,
  setGoBack as isBackButton,
  setHeaderModal as headerModal,
} from "./header";
import region from "./master/region";
import position from "./master/position";
import kesehatan from "./master/kesehatan";
import member from "./anggota/member";
import request from "./anggota/permintaan";
import administrator from "./anggota/administrator";
import user from "./system/pengguna";
import file from "./file";
import pollingAdmin from "./polling/admin";
import pollingMember from "./polling/member";
import messageBlast from "./message-blast";
import admin from "./cms-public/admin";
import iuranAdmin from "./iuran/admin";
import iuranMember from "./iuran/member";
import branchMember from "./anggota/branch-member";
import setoranAdmin from "./setoran";
import notification from "./notification";
import audit from "./system/audit-trail";
import {
  dashboardAgency,
  dashboardProduced,
  dashboardDistribution,
  dashboardStatistic,
  dashboardTotal,
  dashboardLocation,
  dashboardTotalInstitutional,
} from "./dashboard";

const appReducer = combineReducers({
  form: formReducer,
  auth,
  global,
  header,
  linkHeader,
  region,
  kesehatan,
  member,
  request,
  isBackButton,
  headerModal,
  user,
  file,
  pollingAdmin,
  pollingMember,
  messageBlast,
  admin,
  administrator,
  iuranAdmin,
  iuranMember,
  position,
  branchMember,
  setoranAdmin,
  dashboardAgency,
  dashboardProduced,
  dashboardDistribution,
  dashboardStatistic,
  dashboardTotal,
  dashboardLocation,
  notification,
  audit,
  dashboardTotalInstitutional,
});

const rootReducer = (state, action) => {
  if (action.type === "CLEAR_DATA") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
