import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { reduxForm, FieldArray, Field } from "redux-form";
import FormContainer from "../../components/container/Form";
import { MEMBER_PHOTO, MEMBER_SUPPORT_FILE } from "../../utils/constant";
import DetailContentLihatBerkas from "../../components/global-components/DetailContent/LihatBerkas";
import { formFile } from "../../components/commons/form";
import RenderArray from "./berkasArrayInput";
import File from "../../store/actions/file";
import Member, {
  setMemberData,
  setMemberModal,
} from "../../store/actions/anggota/member";
import { Row, Column } from "simple-flexbox";
import { Button } from "@material-ui/core";
import { getUser } from "../../utils/user";

let Index = ({
  member,
  onSetMemberData,
  onSetMemberModal,
  handleSubmit,
  detailData,
  pending,
  file,
}) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [arrayFile, setArrayFile] = useState([]);
  const [imgName, setImgName] = useState(null);
  const notEdit = () => setIsEdit(!isEdit);
  const user = getUser();

  const onSubmit = (value) => {
    const member_profile_data = detailData.member_profile_data;
    member_profile_data.email = detailData.email;
    member_profile_data.name = detailData.name;
    member_profile_data.region_id = detailData.region_id;

    const support_file = value.supporting_file_data.map((item, index) => {
      return {
        name: item.name,
        filename: arrayFile[index],
      };
    });

    const param = {
      ...member_profile_data,
      id: detailData.id,
      img_name: imgName,
      supporting_file_data: support_file,
    };

    const callback = (e) => {
      notEdit();
      getProfile();
    };

    dispatch(Member.put(param, callback));
  };

  const uploadFile = (e) => {
    let formData = new FormData();
    formData.append("file", e);

    const callback = (res) => {
      setImgName(res.data.data.fileName);
    };

    dispatch(File.upload(formData, callback, MEMBER_PHOTO));
  };

  const setModal = (modalType, isOpen, title, folderName, fileName) => {
    onSetMemberModal(modalType, isOpen, title, folderName, fileName);
  };

  const getProfile = () => dispatch(Member.get({ id: user.id }));

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      <Row
        vertical="center"
        horizontal="space-between"
        style={{ width: "100%", marginBottom: 20 }}
      >
        <div>
          <h5>Kelengkapan Berkas</h5>
        </div>
        <div className="d-flex justify-content-between">
          {isEdit ? (
            <>
              <Button
                variant="outlined"
                color="primary"
                disabled={pending}
                style={{ marginRight: 20 }}
                onClick={notEdit}
              >
                Batal
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={pending}
                type="submit"
              >
                Submit
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled={pending}
              onClick={notEdit}
            >
              Edit
            </Button>
          )}
        </div>
      </Row>
      <FormContainer label="Pas Foto 3x4">
        {isEdit ? (
          <Field
            isDetail={!isEdit}
            name="img_name"
            type="file"
            fileType="image"
            title="Masukkan Foto"
            onChange={(e) => uploadFile(e)}
            message="JPG"
            component={formFile}
          />
        ) : detailData?.member_profile_data?.img_name ? (
          <DetailContentLihatBerkas
            onClick={() =>
              setModal(
                "show-photo",
                true,
                "Pas Foto 3x4",
                MEMBER_PHOTO,
                detailData?.member_profile_data?.img_name
              )
            }
          />
        ) : (
          <span>Belum ada berkas</span>
        )}
      </FormContainer>
      <FormContainer label="Kelengkapan Berkas">
        {isEdit ? (
          <FieldArray
            isDetail={!isEdit}
            name="supporting_file_data"
            placeholder="Masukkan Berkas"
            component={RenderArray}
            callValueFile={(e) => setArrayFile(e)}
          />
        ) : detailData?.member_profile_data?.supporting_file_datas.length >
          0 ? (
          detailData.member_profile_data.supporting_file_datas.map(
            (item, index) => (
              <>
                <p>{item.name}</p>
                <DetailContentLihatBerkas
                  key={index}
                  onClick={() =>
                    setModal(
                      "show-document",
                      true,
                      item.name,
                      MEMBER_SUPPORT_FILE,
                      item.filename
                    )
                  }
                />
              </>
            )
          )
        ) : (
          <span>Belum ada berkas</span>
        )}
      </FormContainer>
    </form>
  );
};

const validate = ({ supporting_file_data, img_name }) => {
  const errors = {};
  const supporting_file_dataArrayErrors = [];
  if (!supporting_file_data || !supporting_file_data.length) {
    errors.supporting_file_data = { _error: "Berkas tidak boleh kosong" };
  } else {
    supporting_file_data.forEach((item, index) => {
      const supporting_file_dataErrors = {};
      if (!item || !item.name) {
        supporting_file_dataErrors.name = "Tentukan Nama Berkas";
        supporting_file_dataArrayErrors[index] = supporting_file_dataErrors;
      }
      if (!item || !item.file) {
        supporting_file_dataErrors.file = "Berkas harus dimasukkan";
        supporting_file_dataArrayErrors[index] = supporting_file_dataErrors;
      }
    });
  }
  if (supporting_file_dataArrayErrors.length) {
    errors.supporting_file_data = supporting_file_dataArrayErrors;
  }

  if (!img_name) {
    errors.img_name = "Masukkan Foto";
  }

  return errors;
};

Index = reduxForm({
  form: "memberEditBerkas",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(Index);

const mapStateToProps = ({ member, region, file }) => {
  const { data, pending } = member;
  const detailData = data?.data?.data[0];
  let initialValues = {};
  if (detailData) {
    initialValues = {};
  }
  return { data, pending, detailData, initialValues, region, member };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetMemberModal: (modalType, isOpen, title, folderName, fileName) =>
      dispatch(setMemberModal(modalType, isOpen, title, folderName, fileName)),
    onSetMemberData: (data) => dispatch(setMemberData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
