import React from "react";
import { connect } from "react-redux";
import { Modal } from "reactstrap";
import { setSetoranAdminModal } from "../../../store/actions/setoran";
import Add from "./add";
import Detail from "./detail";
import ShowDocument from "./show-document";
import Approval from "./approval";

const index = ({
  isOpenModal,
  modalType,
  onSetSetoranAdminModal,
  handleRefresh,
  title,
  fileName,
  folderName,
}) => {
  const toggle = () => onSetSetoranAdminModal("", !isOpenModal);
  const child = (modalType) => {
    switch (modalType) {
      case "add":
        return <Add handleRefresh={handleRefresh} />;
      case "approval":
        return <Approval handleRefresh={handleRefresh} />;
      case "detail":
        return <Detail />;
      case "show-document":
        return (
          <ShowDocument
            title={title}
            fileName={fileName}
            folderName={folderName}
          />
        );
    }
  };
  return (
    <Modal
      className="modal-medium"
      isOpen={isOpenModal}
      toggle={toggle}
      centered
    >
      {child(modalType)}
    </Modal>
  );
};

const mapStateToProps = ({
  setoranAdmin: { isOpenModal, modalType, title, folderName, fileName },
}) => {
  return { isOpenModal, modalType, title, folderName, fileName };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetSetoranAdminModal: (type, isOpen) =>
      dispatch(setSetoranAdminModal(type, isOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
