export const regexPassword = /^.*(?=.{8,})(?=.*\d)(?=.*[a-zA-Z]).*$/;
export const regexEmail =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const regexUrl =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;

export const MEMBER_PHOTO = "member_photo";
export const MEMBER_SUPPORT_FILE = "member_supporting_files";
export const ADMINISTRATOR_PHOTO = "administrator";
export const MESSAGE_BLAST_FILE = "message_blast";
export const LOGO_INSTITUTE = "logo";
export const IURAN_FOLDER = "member_dues";

export const statusMember = [
  {
    value: 1,
    label: "Aktif",
  },
  {
    value: 0,
    label: "Tidak Aktif",
  },
];

export const statusRequest = [
  {
    value: 0,
    label: "Baru",
  },
  {
    value: 1,
    label: "Diterima",
  },
  {
    value: 2,
    label: "Ditolak",
  },
];

export const roleUserOptions = [
  {
    value: "superadmin",
    label: "Superadmin",
  },
  // {
  //   value: "superadmin",
  //   label: "Admin Pusat",
  // },
  {
    value: "admin_cabang",
    label: "Admin Cabang",
  },
];

export const allRoleOptions = [
  {
    value: "superadmin",
    label: "Superadmin",
  },
  // {
  //   value: "superadmin",
  //   label: "Admin Pusat",
  // },
  {
    value: "admin_cabang",
    label: "Admin Cabang",
  },
  {
    value: "member",
    label: "Anggota",
  },
];

export const kelembagaanOptions = [
  {
    value: "Distributor",
    label: "Distributor",
  },
  {
    value: "Eksportir",
    label: "Eksportir",
  },
  {
    value: "Importir",
    label: "Importir",
  },
  {
    value: "Agen Tunggal",
    label: "Agen Tunggal",
  },
  {
    value: "Industri",
    label: "Industri",
  },
];

export const pollingOptions = [
  {
    value: 1,
    label: "Short Answer",
  },
  {
    value: 2,
    label: "Multiple Choice",
  },
  {
    value: 3,
    label: "Checklist",
  },
  {
    value: 4,
    label: "Scale",
  },
];

export const statusIuranOptions = [
  {
    value: 0,
    label: "Belum Bayar",
  },
  {
    value: 2,
    label: "Diproses Admin Cabang",
  },
  {
    value: 3,
    label: "Diproses Admin Pusat",
  },
  {
    value: 1,
    label: "Sudah Bayar",
  },
  {
    value: -1,
    label: "Ditolak",
  },
];

export const statusAuditOptions = [
  {
    value: 0,
    label: "Gagal",
  },
  {
    value: 1,
    label: "Berhasil",
  },
];

export const statusSetoranOptions = [
  {
    value: 0,
    label: "Diproses",
  },
  {
    value: 1,
    label: "Disetujui",
  },
  {
    value: 2,
    label: "Ditolak",
  },
];

export const sertifikasiOptions = [
  {
    value: "cdakb",
    label: "CDAKB",
  },
  {
    value: "cpakb",
    label: "CPAKB",
  },
  {
    value: "iso 13485",
    label: "ISO 13485",
  },
  {
    value: "iso 9001",
    label: "ISO 9001",
  },
  {
    value: "iso 14001",
    label: "ISO 14001",
  },
  {
    value: "iso 11135",
    label: "ISO 11135",
  },
  {
    value: "iso 37001",
    label: "ISO 37001",
  },
  {
    value: "fda",
    label: "FDA",
  },
  {
    value: "ce",
    label: "CE",
  },
];

export const notifAdminAction = [
  {
    type: "MEMBER_DEPOSIT",
    route: "/admin/setoran/detail/",
    activeMenu: "Iuran",
  },
  {
    type: "MEMBER_DUES",
    route: "admin/iuran/",
    activeMenu: "Iuran",
  },
  {
    type: "REGISTRATION",
    route: "/request/detail/",
    activeMenu: "Anggota",
  },
  {
    type: "POLLING",
    route: "/admin/polling/detail/",
    activeMenu: "Polling",
  },
  {
    type: "MESSAGE_BLAST",
    route: "",
    activeMenu: "",
  },
];

export const notifMemberAction = [
  {
    type: "MEMBER_DUES",
    route: "/iuran/",
    activeMenu: "Iuran",
  },
  {
    type: "REGISTRATION",
    route: "/profile",
    activeMenu: "Profil",
  },
  {
    type: "POLLING",
    route: "/polling/",
    activeMenu: "Polling",
  },
  {
    type: "CHANGE_PASSWORD",
    route: "",
    activeMenu: "",
  },
  {
    type: "MESSAGE_BLAST",
    route: "",
    activeMenu: "",
  },
  {
    type: "EXPIRED_KTA",
    route: "/iuran",
    activeMenu: "Iuran",
  },
];
