import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { reduxForm, FieldArray, Field } from "redux-form";
import SetoranAdmin, {
  setSetoranAdminData,
  setSetoranAdminModal,
} from "../../../store/actions/setoran";
import { Button } from "@material-ui/core";
import { Row, Column } from "simple-flexbox";
import { IURAN_FOLDER } from "../../../utils/constant";
import File from "../../../store/actions/file";
import DetailContentLihatBerkas from "../../../components/global-components/DetailContent/LihatBerkas";
import DetailContent from "../../../components/global-components/DetailContent";
import { history } from "../../../utils";
import moment from "moment";
import FormContainer from "../../../components/container/Form";
import IuranMember from "../../../store/actions/iuran/member";
import {
  formInputNumber,
  formSelect,
  formTextArea,
  formFile,
  FormDatepicker,
  normalizeDates,
  formatDates,
} from "../../../components/commons/form";
import Modal from "..";
import { getUser } from "../../../utils/user";

let Index = ({
  detailData,
  handleSubmit,
  pending,
  change,
  onSetSetoranAdminModal,
  file,
  iuranMember,
}) => {
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState(null);
  const user = getUser();
  const path = window.location.pathname.split("/");
  const isEdit = path.includes("edit");
  const id = path.pop();

  const onSubmit = ({ date, total, description }) => {
    const param = {
      id: detailData.id,
      date: moment().format("YYYY-MM-DD"),
      total: total.toString().replace("Rp ", "").replace(/\D/g, ""),
      description,
      filename: fileName,
    };

    const callback = () => {
      onSetSetoranAdminModal("", false);
      history.push("/admin/setoran");
    };

    dispatch(SetoranAdmin.put(param, callback));
  };

  change("date", moment().format("DD MMMM YYYY"));

  const uploadFile = (e) => {
    let formData = new FormData();
    formData.append("file", e);

    const callback = (res) => {
      setFileName(res.data.data.fileName);
    };

    dispatch(File.upload(formData, callback, IURAN_FOLDER));
  };

  const setModal = (modalType, isOpen, title, folderName, fileName) => {
    onSetSetoranAdminModal(modalType, isOpen, title, folderName, fileName);
  };

  let iuranOptions;
  if (iuranMember.data) {
    iuranOptions = iuranMember.data.data.data.map((item) => {
      return {
        label: item.user_fullname,
        value: item.id,
      };
    });
  }

  useEffect(() => {
    getIuranMember();
  }, []);

  const getIuranMember = () => dispatch(IuranMember.get({ status: 1 }));

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      <Row
        vertical="center"
        horizontal="space-between"
        style={{ width: "100%", marginBottom: 20 }}
      >
        <div>
          <h5>{isEdit ? "Upload Ulang" : "Detail"} Setoran</h5>
        </div>
        <div className="d-flex justify-content-between">
          {isEdit ? (
            <>
              {/* <Button
                variant="outlined"
                color="primary"
                disabled={pending}
                style={{ marginRight: 20 }}
                onClick={() => {
                  setIsEdit(!isEdit);
                  change("date", detailData.date);
                }}
              >
                Batal
              </Button> */}
              <div></div>
              <Button
                variant="contained"
                color="primary"
                disabled={pending || file.pending}
                type="submit"
              >
                Submit
              </Button>
            </>
          ) : pending ? (
            <CircularProgress color="primary" />
          ) : (
            <>
              {detailData?.status === 2 && user.role_code === "admin_cabang" && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={pending}
                  onClick={() => {
                    history.push(`/admin/setoran/edit/${id}`);
                  }}
                >
                  Unggah Ulang Bukti Pembayaran
                </Button>
              )}
              <div></div>
            </>
          )}
        </div>
      </Row>
      <FormContainer label="Tanggal">
        <Field
          name="date"
          placeholder="Tanggal"
          isDetail={!isEdit}
          disabled
          parse={normalizeDates}
          format={formatDates}
          component={FormDatepicker}
          showClearDate={false}
        />
      </FormContainer>
      <FormContainer label="Total Setoran">
        <Field
          name="total"
          isDetail={!isEdit}
          placeholder="Nominal Setoran"
          component={formInputNumber}
          thousandSeparator
          prefix="Rp "
        />
      </FormContainer>
      <FormContainer label="Keterangan">
        <Field
          name="description"
          isDetail={!isEdit}
          placeholder="Keterangan"
          rows={5}
          component={formTextArea}
        />
      </FormContainer>
      <FormContainer label="Berkas">
        {isEdit ? (
          <Field
            name="filename"
            type="file"
            fileType="pdf/image"
            title="Masukkan Bukti"
            onChange={(e) => uploadFile(e)}
            message="PDF / JPG"
            component={formFile}
          />
        ) : detailData?.filename ? (
          <DetailContentLihatBerkas
            onClick={() =>
              setModal(
                "show-document",
                true,
                "Bukti Pembayaran",
                IURAN_FOLDER,
                detailData.filename
              )
            }
          />
        ) : (
          <span>Belum ada berkas</span>
        )}
      </FormContainer>
      {!isEdit ? (
        <DetailContent
          label="Daftar Anggota"
          alignItems="baseline"
          style={{ maxHeight: 150, overflowY: "auto" }}
          value={
            detailData?.member_names && detailData.member_names.length > 0
              ? detailData.member_names.map((item, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      {idx + 1}. {item}
                      <br />
                    </React.Fragment>
                  );
                })
              : "-"
          }
        />
      ) : (
        <FormContainer label="Daftar Anggota">
          <Field
            placeholder="Daftar Anggota"
            name="member_dues"
            options={iuranOptions}
            isAsync
            isMulti
            asyncUrl="/v1/member_dues"
            param={{ status: 1 }}
            customLabel={{ label: "user_fullname", value: "id" }}
            component={formSelect}
          />
        </FormContainer>
      )}
      {detailData?.status === 2 && (
        <FormContainer label="Alasan Penolakan">
          <Field
            name="rejected_notes"
            isDetail={!isEdit}
            placeholder="Alasan Penolakan"
            rows={3}
            component={formTextArea}
          />
        </FormContainer>
      )}
    </form>
  );
};

const validate = ({ total, description, filename, member_dues }) => {
  const errors = {};
  if (!total) {
    errors.total = "Total Setoran harus diisi";
  }
  if (!filename) {
    errors.filename = "Berkas harus diisi";
  }
  if (!description) {
    errors.description = "Keterangan harus diisi";
  }

  return errors;
};

Index = reduxForm({
  form: "setoranAdminDetail",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(Index);

const mapStateToProps = ({ setoranAdmin, file, iuranMember }) => {
  const { detailData, pending } = setoranAdmin;
  let initialValues = {};
  if (detailData) {
    let member_dues = detailData.dues_ids?.map((item, index) => {
      return {
        value: item,
        label: detailData.member_names[index],
      };
    });
    initialValues = {
      date: detailData.date,
      total: detailData.total,
      description: detailData.description,
      rejected_notes: detailData.rejected_notes,
      member_dues,
    };
  }
  return { detailData, pending, initialValues, file, iuranMember };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetSetoranAdminModal: (modalType, isOpen, title, folderName, fileName) =>
      dispatch(
        setSetoranAdminModal(modalType, isOpen, title, folderName, fileName)
      ),
    onSetSetoranAdminData: (data) => dispatch(setSetoranAdminData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
