import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { reduxForm, Field } from "redux-form";
import FormContainer from "../../../../components/container/Form";
import {
  formInput,
  formSelect,
  formInputNumber,
} from "../../../../components/commons/form";
import Request, {
  setRequestData,
  setRequestModal,
} from "../../../../store/actions/anggota/permintaan";
import Region from "../../../../store/actions/master/region";
import { Row, Column } from "simple-flexbox";
import { Button } from "@material-ui/core";
import {
  regexEmail,
  kelembagaanOptions,
  MEMBER_PHOTO,
} from "../../../../utils/constant";
import { getUser } from "../../../../utils/user";
import { history } from "../../../../utils";
import { StyleSheet, css } from "aphrodite";

let Index = ({
  onSetRequestData,
  handleSubmit,
  detailData,
  region,
  pending,
  reset,
}) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const notEdit = () => {
    setIsEdit(!isEdit);
    reset();
  };
  const user = getUser();

  const onSubmit = (value) => {
    const member_profile_data = detailData.member_profile_data;
    const member_agency_tool_data = detailData.member_agency_tool_data;
    const member_produced_tool_data = detailData.member_produced_tool_data;
    const param = {
      ...member_profile_data,
      ...member_agency_tool_data,
      ...member_produced_tool_data,
      id: detailData.id,
      region_id: value.region_name.value,
      email: detailData.email,
      name: detailData.name,
    };
    const callback = () => {
      notEdit();
      history.push("/request");
    };

    dispatch(Request.put(param, callback));
  };

  let regionOptions;
  if (region.data) {
    regionOptions = region.data.data.data.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
    regionOptions.push({ label: "Lainnya", value: "" });
  }

  useEffect(() => {
    getRegion();
  }, []);

  const getRegion = () => dispatch(Region.get());

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      <Row
        vertical="center"
        horizontal="space-between"
        style={{ width: "100%", marginBottom: 20 }}
      >
        <div>
          <h5>Data Perusahaan</h5>
        </div>
        <div className="d-flex justify-content-between">
          {isEdit ? (
            <>
              <Button
                variant="outlined"
                color="primary"
                disabled={pending}
                style={{ marginRight: 20 }}
                onClick={notEdit}
              >
                Batal
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={pending}
                type="submit"
              >
                Simpan
              </Button>
            </>
          ) : user.role_code === "superadmin" ? (
            <Button
              variant="contained"
              color="primary"
              disabled={pending}
              onClick={notEdit}
            >
              Edit
            </Button>
          ) : (
            <div></div>
          )}
        </div>
      </Row>
      {!isEdit && (
        // <FormContainer label="Tanggal Approval">
        //   <Field
        //     name="approved_at"
        //     placeholder="Tanggal"
        //     isDetail
        //     parse={normalizeDates}
        //     format={formatDates}
        //     component={FormDatepicker}
        //   />
        // </FormContainer>
        <div></div>
      )}
      <FormContainer label="Wilayah">
        <Field
          isDetail={!isEdit}
          placeholder="Wilayah"
          name="region_name"
          // onChange={() => change("leader_name", "akuuu adalah change")}
          options={regionOptions}
          isAsync
          asyncUrl="/v1/region"
          component={formSelect}
        />
      </FormContainer>
      <FormContainer label="Cabang Perusahaan">
        <Field
          isDetail
          name="relation_member_name"
          placeholder="Cabang Perusahaan"
          component={formInput}
        />
      </FormContainer>
      <FormContainer label="Nama Pimpinan">
        <Field
          isDetail
          name="leader_name"
          placeholder="Nama Pimpinan"
          component={formInput}
        />
      </FormContainer>
      <FormContainer label="Jabatan">
        <Field
          isDetail
          name="position"
          placeholder="Jabatan"
          component={formInput}
        />
      </FormContainer>
      <FormContainer label="Alamat Perusahaan">
        <Field
          isDetail
          name="address"
          placeholder="Alamat Perusahaan"
          component={formInput}
        />
      </FormContainer>
      <FormContainer label="Telepon Perusahaan">
        <Field
          isDetail
          name="phone_number"
          placeholder="Telepon Perusahaan"
          component={formInputNumber}
        />
      </FormContainer>
      <FormContainer label="Website Perusahaan">
        <Field
          isDetail
          name="website"
          placeholder="Website Perusahaan"
          component={formInput}
        />
      </FormContainer>
      <FormContainer label="Email">
        <Field
          isDetail
          name="email"
          placeholder="Email"
          component={formInput}
        />
      </FormContainer>
      <FormContainer label="Kontak Person">
        <div className="row">
          <div className="col-6">
            <div>
              {/* {!isEdit && ( */}
              <div className="mt-2">
                <span>Nama</span>
              </div>
              {/* )} */}
              <Field
                isDetail
                name="contact_person_name_1"
                placeholder="Nama"
                component={formInput}
              />
            </div>
            <div>
              {/* {!isEdit && ( */}
              <div className="mt-2">
                <span>Email</span>
              </div>
              {/* )} */}
              <Field
                isDetail
                name="contact_person_email_1"
                placeholder="Email"
                component={formInput}
              />
            </div>
            <div>
              {/* {!isEdit && ( */}
              <div className="mt-2">
                <span>No Telepon</span>
              </div>
              {/* )} */}
              <Field
                isDetail
                name="contact_person_phone_number_1"
                placeholder="No Telepon"
                component={formInput}
              />
            </div>
          </div>
          <div className="col-6">
            <div>
              {/* {!isEdit && ( */}
              <div className="mt-2">
                <span>Nama</span>
              </div>
              {/* )} */}
              <Field
                isDetail
                name="contact_person_name_2"
                placeholder="Nama"
                component={formInput}
              />
            </div>
            <div>
              {/* {!isEdit && ( */}
              <div className="mt-2">
                <span>Email</span>
              </div>
              {/* )} */}
              <Field
                isDetail
                name="contact_person_email_2"
                placeholder="Email"
                component={formInput}
              />
            </div>
            <div>
              {/* {!isEdit && ( */}
              <div className="mt-2">
                <span>No Telepon</span>
              </div>
              {/* )} */}
              <Field
                isDetail
                name="contact_person_phone_number_2"
                placeholder="No Telepon"
                component={formInput}
              />
            </div>
          </div>
        </div>
      </FormContainer>
      <FormContainer label="Kelembagaan">
        <Field
          isDetail
          placeholder="Kelembagaan"
          name="institutional"
          options={kelembagaanOptions}
          component={formSelect}
        />
      </FormContainer>
      <FormContainer label="Fasilitas Perusahaan">
        <Column>
          <div>
            {/* {!isEdit && ( */}
            <div className="mt-2">
              <span>Pabrik</span>
            </div>
            {/* )} */}
            <Field
              isDetail
              name="owned_facility_factory"
              placeholder="Pabrik (alamat)"
              component={formInput}
            />
          </div>
          <div>
            {/* {!isEdit && ( */}
            <div className="mt-2">
              <span>Workshop</span>
            </div>
            {/* )} */}
            <Field
              isDetail
              name="owned_facility_workshop"
              placeholder="Workshop (alamat)"
              component={formInput}
            />
          </div>
          <div>
            {/* {!isEdit && ( */}
            <div className="mt-2">
              <span>Lainnya</span>
            </div>
            {/* )} */}
            <Field
              isDetail
              name="owned_facility_other"
              placeholder="Lain Lain"
              component={formInput}
            />
          </div>
        </Column>
      </FormContainer>
      <FormContainer label="Tenaga Ahli">
        <Column>
          <div>
            {/* {!isEdit && ( */}
            <div className="mt-2">
              <span>Nama</span>
            </div>
            {/* )} */}
            <Field
              isDetail
              name="company_technicians_name"
              placeholder="Nama"
              component={formInput}
            />
          </div>
          <div>
            {/* {!isEdit && ( */}
            <div className="mt-2">
              <span>Pendidikan</span>
            </div>
            {/* )} */}
            <Field
              isDetail
              name="company_technicians_education"
              placeholder="Pendidikan"
              component={formInput}
            />
          </div>
        </Column>
      </FormContainer>
      <FormContainer label="Foto Direktur">
        {detailData?.member_profile_data?.img_name ? (
          <img
            src={`${process.env.REACT_APP_GAKESLAB_URL}/v1/file/${MEMBER_PHOTO}/${detailData.member_profile_data.img_name}`}
            alt="foto"
            className={css(styles.imgDocument)}
            width="100%"
          ></img>
        ) : (
          <span>Tidak ada Foto</span>
        )}
      </FormContainer>
    </form>
  );
};

const validate = ({
  // address,
  // company_technicians_education,
  // company_technicians_name,
  // email,
  // website,
  // institutional,
  // leader_name,
  // name,
  // owned_facility_factory,
  // owned_facility_other,
  // owned_facility_workshop,
  // phone_number,
  // position,
  // qualification,
  region_name,
}) => {
  const errors = {};

  // if (!address) {
  //   errors.address = "Field harus diisi";
  // }
  // if (!company_technicians_education) {
  //   errors.company_technicians_education = "Field harus diisi";
  // }
  // if (!company_technicians_name) {
  //   errors.company_technicians_name = "Field harus diisi";
  // }
  // if (!email) {
  //   errors.email = "Field harus diisi";
  // } else if (!regexEmail.test(email)) {
  //   errors.email = "Field tidak valid";
  // }
  // if (!website) {
  //   errors.website = "Field harus diisi";
  // }
  // if (!institutional) {
  //   errors.institutional = "Field harus diisi";
  // }
  // if (!leader_name) {
  //   errors.leader_name = "Field harus diisi";
  // }
  // if (!name) {
  //   errors.name = "Field harus diisi";
  // }
  // if (!owned_facility_factory) {
  //   errors.owned_facility_factory = "Field harus diisi";
  // }
  // if (!owned_facility_other) {
  //   errors.owned_facility_other = "Field harus diisi";
  // }
  // if (!owned_facility_workshop) {
  //   errors.owned_facility_workshop = "Field harus diisi";
  // }
  // if (!phone_number) {
  //   errors.phone_number = "Field harus diisi";
  // }
  // if (!position) {
  //   errors.position = "Field harus diisi";
  // }
  // if (!qualification) {
  //   errors.qualification = "Field harus diisi";
  // }
  if (!region_name) {
    errors.region_name = "Wilayah harus diisi";
  }

  return errors;
};

Index = reduxForm({
  form: "requestEditData",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(Index);

const mapStateToProps = ({
  request: { data, detailData, pending },
  region,
}) => {
  const path = window.location.pathname.split("/");
  let initialValues = {};
  if (detailData) {
    initialValues = {
      address: detailData.member_profile_data.address,
      approved_at: detailData.member_profile_data.approved_at,
      relation_member_name: detailData.member_profile_data.relation_member_name,
      company_technicians_education:
        detailData.member_profile_data.company_technicians_education,
      company_technicians_name:
        detailData.member_profile_data.company_technicians_name,
      email: detailData.email,
      website: detailData.member_profile_data.website,
      contact_person_name_1:
        detailData.member_profile_data.contact_person_name_1,
      contact_person_email_1:
        detailData.member_profile_data.contact_person_email_1,
      contact_person_phone_number_1:
        detailData.member_profile_data.contact_person_phone_number_1,
      contact_person_name_2:
        detailData.member_profile_data.contact_person_name_2,
      contact_person_email_2:
        detailData.member_profile_data.contact_person_email_2,
      contact_person_phone_number_2:
        detailData.member_profile_data.contact_person_phone_number_2,
      institutional: detailData.member_profile_data.institutional
        ? {
            label: detailData.member_profile_data.institutional.replace(
              /(^\w{1})|(\s+\w{1})/g,
              (letter) => letter.toUpperCase()
            ),
            value: detailData.member_profile_data.institutional,
          }
        : null,
      leader_name: detailData.member_profile_data.leader_name,
      name: detailData.name,
      owned_facility_factory:
        detailData.member_profile_data.owned_facility_factory,
      owned_facility_other: detailData.member_profile_data.owned_facility_other,
      owned_facility_workshop:
        detailData.member_profile_data.owned_facility_workshop,
      phone_number: detailData.member_profile_data.phone_number,
      position: detailData.member_profile_data.position,
      qualification: detailData.member_profile_data.qualification,
      region_name: detailData.region_name
        ? {
            label: detailData.region_name,
            value: detailData.region_id,
          }
        : null,
    };
  }
  return { data, detailData, pending, initialValues, region };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetRequestModal: (modalType, isOpen) =>
      dispatch(setRequestModal(modalType, isOpen)),
    onSetRequestData: (data) => dispatch(setRequestData(data)),
  };
};

const styles = StyleSheet.create({
  imgDocument: {
    maxHeight: 200,
    maxWidth: 200,
    objectFit: "contain",
    margin: "10px auto",
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
