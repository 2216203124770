import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Dashboard from "../../store/actions/dashboard";
import { Row } from "simple-flexbox";
import CardMaps from "../../components/dashboard/card-map";
import CardMember from "../../components/dashboard/card-member-pusat";
import CardLocationMember from "../../components/dashboard/card-location-member";
import CardCategoryMember from "../../components/dashboard/card-category-member";
import CardStatisticMember from "../../components/dashboard/card-statistic-member";
import CardInstitutional from "../../components/dashboard/card-institunional";

const Index = ({
  dataAgency,
  dataProduced,
  dataDistribution,
  dataStatistic,
  dataTotal,
  dataLocation,
  dataTotalInstitutional,
}) => {
  const dispatch = useDispatch();
  const this_year = new Date().getFullYear();

  useEffect(() => {
    getDashboardAgency();
    getDashboardProduced();
    getDashboardDistribution();
    getDashboardLocation();
    getDashboardStatistic();
    getDashboardTotal();
    getDashboardTotalInstitutional();
  }, []);

  const getDashboardAgency = () => dispatch(Dashboard.getAgency());
  const getDashboardProduced = () => dispatch(Dashboard.getProduced());
  const getDashboardDistribution = () => dispatch(Dashboard.getDistribution());
  const getDashboardLocation = () => dispatch(Dashboard.getLocation());
  const getDashboardStatistic = () =>
    dispatch(
      Dashboard.getStatistic({ start_year: this_year - 4, end_year: this_year })
    );
  const getDashboardTotal = () => dispatch(Dashboard.getTotal());
  const getDashboardTotalInstitutional = () =>
    dispatch(Dashboard.getTotalInstitutional());

  return (
    <>
      <Row
        horizontal="space-between"
        alignItems="stretch"
        style={{ marginBottom: 20 }}
      >
        <div style={{ padding: "0 10px", width: "75%" }}>
          <div style={{ marginBottom: 20 }}>
            <CardMaps data={dataDistribution} />
          </div>
          <div style={{ marginBottom: 20 }}>
            <CardCategoryMember
              dataAgency={dataAgency}
              dataProduced={dataProduced}
            />
          </div>
          <div style={{ marginBottom: 20 }}>
            <CardStatisticMember data={dataStatistic} />
          </div>
        </div>
        <div style={{ padding: "0 10px", width: "25%" }}>
          <div style={{ marginBottom: 40 }}>
            <CardMember data={dataTotal} />
          </div>
          <div style={{ marginBottom: 40 }}>
            <CardInstitutional data={dataTotalInstitutional} />
          </div>
          <div style={{ marginBottom: 40 }}>
            <CardLocationMember
              data={dataLocation}
              total={dataTotal?.total_member}
            />
          </div>
        </div>
      </Row>
    </>
  );
};

const mapStateToProps = ({
  dashboardAgency,
  dashboardProduced,
  dashboardDistribution,
  dashboardStatistic,
  dashboardTotal,
  dashboardLocation,
  dashboardTotalInstitutional,
}) => {
  const { data: dataAgency } = dashboardAgency;
  const { data: dataProduced } = dashboardProduced;
  const { data: dataDistribution } = dashboardDistribution;
  const { data: dataLocation } = dashboardLocation;
  const { data: dataStatistic } = dashboardStatistic;
  const { data: dataTotal } = dashboardTotal;
  const { data: dataTotalInstitutional } = dashboardTotalInstitutional;

  return {
    dataAgency,
    dataProduced,
    dataDistribution,
    dataStatistic,
    dataTotal,
    dataLocation,
    dataTotalInstitutional,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
